import React, { useEffect, useState } from 'react';
import {
  Container,
  DashboardContent,
  Section,
  Sidebar,
} from '../../components';
// import BottomBar from '../../components/Dashboard/Vendor/BottomBar';
import {
  SidebarUpperOptions,
  SidebarToolsOptions,
} from '../../data/dashboardData';

function VendorDashboard() {
  useEffect(() => {
    document.title = 'Vendor Dashboard - Sufuri';
  }, []);

  const [selectedOption, setSelectedOption] = useState('overview');
  const [showSidebar, setShowSidebar] = useState(false);

  return (
    <Container className="relative">
      <Section className="flex flex-row min-h-screen">
        <Sidebar
          className={`${
            showSidebar ? 'lg:w-[20%] w-[70%]' : 'hidden lg:block'
          } fixed h-full overflow-y-auto lg:block bg-[#000000] z-[9999]`}
          sidebarUpperOptions={SidebarUpperOptions}
          sidebarToolsOptions={SidebarToolsOptions}
          selectedOption={selectedOption}
          setSelectedOption={setSelectedOption}
          showSidebar={showSidebar}
          setShowSidebar={setShowSidebar}
        />
        {/* <BottomBar
          className="block lg:hidden"
          sidebarUpperOptions={SidebarUpperOptions}
          sidebarToolsOptions={SidebarToolsOptions}
          selectedOption={selectedOption}
          setSelectedOption={setSelectedOption}
          showSidebar={showSidebar}
        /> */}
        <div className={`${showSidebar ? 'w-[5%] lg:w-[20%]' : 'w-0'}`} />
        <DashboardContent
          className={`${
            showSidebar ? 'lg:ml-[0%]' : 'lg:ml-[7%] xl:ml-[5%]'
          } flex-1 lg:px-[2%] overflow-y-auto`}
          selectedOption={selectedOption}
          setShowSidebar={setShowSidebar}
          showSidebar={showSidebar}
        />
      </Section>
    </Container>
  );
}

export default VendorDashboard;
