import React from 'react';

export const TableHead = ({
  toggleAllWishlistSelection,
  selectedProductIds,
  wishlist,
}) => {
  return (
    <thead className="text-xs text-white">
      <tr>
        <th scope="col" className="p-4">
          <div className="flex items-center">
            <label id="checkbox-all-products" htmlFor="checkbox-all-products">
              <input
                id="checkbox-all-products"
                type="checkbox"
                name="checkbox-all-products"
                className="w-[14px] h-[14px] bg-gray-700 rounded appearance-none checked:bg-[#ca8a23]"
                onChange={() => toggleAllWishlistSelection()}
                checked={
                  selectedProductIds.length === wishlist.length &&
                  wishlist.length !== 0
                }
              />
              <span className="sr-only">Format</span>
            </label>
          </div>
        </th>
        <th scope="col" className="px-6 py-3">
          Product Name
        </th>
        <th scope="col" className="px-6 py-3">
          Unit Price
        </th>
        <th scope="col" className="px-6 py-3">
          Date Added
        </th>
        <th scope="col" className="px-6 py-3">
          Stock Status
        </th>
        <th scope="col" className="px-6 py-3">
          Qty
        </th>
        <th scope="col" className="px-6 py-3">
          Actions
        </th>
      </tr>
      <tr className="relative">
        <hr className="absolute w-full border border-gray-800 rounded-md" />
      </tr>
    </thead>
  );
};
