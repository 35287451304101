import React from 'react';
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  // CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';

const ReChartAreaGraph = ({ className, data }) => {
  return (
    <div style={{ width: '100%', height: 250 }} className={className}>
      <ResponsiveContainer>
        <AreaChart
          data={data}
          margin={{
            top: 20,
            right: 30,
            left: 0,
            bottom: 0,
          }}
        >
          {/* <CartesianGrid strokeDasharray="3 3" /> */}
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip labelClassName="text-[#000000]" />

          <Area
            type="monotone"
            dataKey={
              data && data.length > 0
                ? data[0].amt !== undefined
                  ? 'amt'
                  : 'orderCount'
                : 'orderCount'
            }
            stroke="#9D7100"
            fill="#2b1b00"
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
};

export default ReChartAreaGraph;
