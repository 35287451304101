import React from 'react';
import {
  Container,
  Footer,
  Header,
  SavedFabricRequestSection,
} from '../../components';

function SavedFabricRequests() {
  return (
    <Container>
      <Header />
      <SavedFabricRequestSection />
      <Footer />
    </Container>
  );
}

export default SavedFabricRequests;
