import { Link, useNavigate } from 'react-router-dom';
import {
  Button,
  Section,
  // GoogleSignupButton,
  TermsAndConditions,
} from '../../components';
import { countryList } from '../../utils';
import { forwardRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { useForm, Controller } from 'react-hook-form';
import { Signup } from '../../Redux/Features/Login/signupSlice';

const SignupForm = () => {
  const inputStyles =
    'rounded-[10px] pl-[15px] lg:pl-[25px] w-full h-[47px] bg-[#18181C] text-[#ffffff] flex-stretch-0 focus:outline-none';
  const navigate = useNavigate();

  const {
    control,
    handleSubmit,
    formState: { errors },
    getValues,
    reset,
  } = useForm();

  const passwordMatchValidator = (value) => {
    const password = getValues('password');
    return value === password || 'Passwords do not match';
  };
  const passwordStrengthValidator = (value) => {
    return value.length >= 6 || 'Password must be at least 6 characters long';
  };

  const onSubmit = (data) => {
    dispatch(
      Signup({
        email: data.email,
        password: data.password,
        firstname: data.firstname,
        lastname: data.lastname,
        role: 'designer',
        address: data.address,
        city: data.city,
        country: data.country,
        // vendorBusinessName: data.vendorBusinessName,
        // vendorPaymentNumber: data.vendorPaymentNumber,
        phoneNumber: data.phoneNumber,
      }),
    );
  };

  const status = useSelector((state) => state.signup.status);
  const error = useSelector((state) => state.signup.Error);
  const dispatch = useDispatch();

  useEffect(() => {
    if (status === 'success') {
      toast.success('Account created successfully');
      reset();
      setTimeout(() => {
        navigate('/auth/login');
      }, 5000);
    }
  }, [status, error, navigate]);

  return (
    <form onSubmit={handleSubmit} className="flex flex-col gap-[13px]">
      <ToastContainer />
      <Controller
        name="firstname"
        control={control}
        defaultValue=""
        rules={{ required: 'First name is required' }}
        render={({ field }) => (
          <input
            className={`${
              errors.firstname && 'border border-red-500'
            } ${inputStyles}`}
            placeholder="First name"
            type="text"
            {...field}
          />
        )}
      />
      {errors.firstname && (
        <span className="ml-2 text-sm text-red-500">
          {errors.firstname.message}
        </span>
      )}

      <Controller
        name="lastname"
        control={control}
        defaultValue=""
        rules={{ required: 'Last name is required' }}
        render={({ field }) => (
          <input
            className={`${
              errors.lastname && 'border border-red-500'
            } ${inputStyles}`}
            placeholder="Last name"
            type="text"
            {...field}
          />
        )}
      />
      {errors.lastname && (
        <span className="ml-2 text-sm text-red-500">
          {errors.lastname.message}
        </span>
      )}

      <Controller
        name="email"
        control={control}
        defaultValue=""
        rules={{
          required: 'Email is required',
          pattern: {
            value: /^\S+@\S+$/i,
            message: 'Invalid email address',
          },
        }}
        render={({ field }) => (
          <input
            className={`${
              errors.email && 'border border-red-500'
            } ${inputStyles}`}
            placeholder="Your Email"
            type="email"
            {...field}
          />
        )}
      />
      {errors.email ? (
        <span className="ml-2 text-sm text-red-500">
          {errors.email.message}
        </span>
      ) : (
        error === 'User already exists' && (
          <span className="ml-2 text-sm text-red-500">User already exists</span>
        )
      )}

      <div className="flex lg:flex-row flex-col gap-[13px]">
        <Controller
          name="password"
          control={control}
          defaultValue=""
          rules={{
            required: 'Password is required',
            validate: passwordStrengthValidator,
          }}
          render={({ field }) => (
            <input
              className={`${
                errors.password && 'border border-red-500'
              } ${inputStyles}`}
              placeholder="Enter password"
              type="password"
              {...field}
            />
          )}
        />
        <Controller
          name="repeatPassword"
          control={control}
          defaultValue=""
          rules={{
            required: 'Repeat Password',
            validate: passwordMatchValidator,
          }}
          render={({ field }) => (
            <input
              className={`${
                errors.repeatPassword && 'border border-red-500'
              } ${inputStyles}`}
              placeholder="Repeat password"
              type="password"
              {...field}
            />
          )}
        />
      </div>
      {errors.password && (
        <span className="ml-2 text-sm text-red-500">
          {errors.password.message}
        </span>
      )}
      {errors.repeatPassword && (
        <span className="ml-2 text-sm text-red-500">
          {errors.repeatPassword.message}
        </span>
      )}

      {/* <Input
        className={inputStyles}
        onChange={handleChange}
        value={formData.vendorBusinessName}
        name="vendorBusinessName"
        placeholder="Business Name"
      />
      {errors.vendorBusinessName ? (
        <span className="ml-2 text-sm text-red-500">
          {errors.vendorBusinessName}
        </span>
      ) : (
        error === 'Vendor already exists' && (
          <span className="ml-2 text-sm text-red-500">
            Vendor already exists
          </span>
        )
      )}

      <Input
        className={inputStyles}
        onChange={handleChange}
        value={formData.vendorPaymentNumber}
        name="vendorPaymentNumber"
        placeholder="Payment Number"
      />
      {errors.vendorPaymentNumber && (
        <span className="ml-2 text-sm text-red-500">
          {errors.vendorPaymentNumber}
        </span>
      )} */}

      <Controller
        name="phoneNumber"
        control={control}
        defaultValue=""
        rules={{
          required: 'Phone Number is required. e.g: 07...',
          pattern: {
            value: /^\d+$/,
            message: 'Invalid mobile number',
          },
        }}
        render={({ field }) => (
          <input
            className={`${
              errors.phoneNumber && 'border border-red-500'
            } ${inputStyles}`}
            placeholder="Telephone Number"
            type="phone"
            {...field}
          />
        )}
      />
      {errors.phoneNumber && (
        <span className="ml-2 text-sm text-red-500">
          {errors.phoneNumber.message}
        </span>
      )}

      <Controller
        name="country"
        control={control}
        defaultValue="Rwanda"
        rules={{ required: 'Country is required' }}
        render={({ field }) => (
          <select
            {...field}
            className={`${inputStyles} appearance-none bg-no-repeat bg-right select-none cursor-pointer`}
          >
            <option value="" disabled>
              Select Country
            </option>
            {countryList.map((country) => (
              <option key={country} value={country}>
                {country}
              </option>
            ))}
          </select>
        )}
      />
      {errors.country && (
        <span className="ml-2 text-sm text-red-500">
          {errors.country.message}
        </span>
      )}

      <div className="flex lg:flex-row flex-col gap-[13px]">
        <Controller
          name="city"
          control={control}
          defaultValue=""
          rules={{ required: 'City is required' }}
          render={({ field }) => (
            <input
              className={`${
                errors.city && 'border border-red-500'
              } ${inputStyles}`}
              placeholder="City"
              type="text"
              {...field}
            />
          )}
        />
        <Controller
          name="address"
          control={control}
          defaultValue=""
          rules={{ required: 'Address is required' }}
          render={({ field }) => (
            <input
              className={`${
                errors.address && 'border border-red-500'
              } ${inputStyles}`}
              placeholder="Street Address"
              type="text"
              {...field}
            />
          )}
        />
      </div>
      {errors.city && (
        <span className="ml-2 text-sm text-red-500">{errors.city.message}</span>
      )}
      {errors.address && (
        <span className="ml-2 text-sm text-red-500">
          {errors.address.message}
        </span>
      )}

      <Section>
        <Controller
          name="checked"
          control={control}
          defaultValue={false}
          rules={{ required: 'You must accept our terms and conditions' }}
          render={({ field }) => (
            <TermsAndConditions
              onChange={(e) => field.onChange(e.target.checked)}
              checked={field.value}
            />
          )}
        />
        {errors.checked && (
          <span className="ml-2 text-sm text-red-500">
            {errors.checked.message}
          </span>
        )}

        <div className="flex flex-row items-center justify-center w-auto gap-2">
          <Link to="/auth/login">
            <Button
              onClick={handleSubmit(onSubmit)}
              style={{ width: 'fit-content' }}
              className="px-[20px] h-[40px] text-[14px] mt-2 font-thin bg-gradient-to-r from-[#ffcc00] via-[#e6b608c7] to-[#4c3c03] rounded-[5px]"
            >
              <div className="flex justify-center">
                {status === 'loading' ? (
                  <span>
                    <svg
                      aria-hidden="true"
                      className="w-[20px] h-[20px] mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="currentColor"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentFill"
                      />
                    </svg>
                  </span>
                ) : null}
                <span>Continue to Signup</span>
              </div>
            </Button>
          </Link>
          {/* <p className="text-white mt-2 lg:mr-[-43px]">or</p>
          <GoogleSignupButton /> */}
        </div>
      </Section>
    </form>
  );
};

export default SignupForm;
