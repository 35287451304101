import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { BASE_URL, ADMINTOKEN } from '../../../utils/apiUtils';

// Fetch products from the external API
export const ViewCart = createAsyncThunk(
  'view-cart',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${BASE_URL}api/cart/view`, {
        headers: {
          Authorization: `Bearer ${ADMINTOKEN}`,
          'Content-Type': 'application/json',
        },
      });

      return response.data.cart;
    } catch (error) {
      if (error.response.status === 404) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.response.data.message);
      }
    }
  },
);

export const RemoveProductFromCart = createAsyncThunk(
  'remove-product-from-cart',
  async ({ id }, { rejectWithValue }) => {
    try {
      const response = await axios.delete(`${BASE_URL}api/cart/delete/${id}`, {
        headers: {
          Authorization: `Bearer ${ADMINTOKEN}`,
          'Content-Type': 'application/json',
        },
      });

      if (response.data.removedCartItem) {
        return response.data.removedCartItem;
      } else {
        return;
      }
    } catch (error) {
      if (error.response.status === 404) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.response.data.message);
      }
    }
  },
);

export const AddToCart = createAsyncThunk(
  'add-to-cart',
  async ({ productId }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${BASE_URL}api/cart/add/${productId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${ADMINTOKEN}`,
            'Content-Type': 'application/json',
          },
        },
      );

      return response.data.itemAddedToCart;
    } catch (error) {
      if (error.response.status === 404) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.response.data.message);
      }
    }
  },
);

export const ClearCart = createAsyncThunk(
  'clear-cart',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.delete(`${BASE_URL}api/cart/clear`, {
        headers: {
          Authorization: `Bearer ${ADMINTOKEN}`,
          'Content-Type': 'application/json',
        },
      });

      return response.data;
    } catch (error) {
      if (error.response.status === 404) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.response.data.message);
      }
    }
  },
);

export const updateCartProductQuantity = createAsyncThunk(
  'update-cart',
  async ({ productId, action }, { rejectWithValue }) => {
    try {
      const response = await axios.patch(
        `${BASE_URL}api/cart/update/${productId}`,
        { action },
        {
          headers: {
            Authorization: `Bearer ${ADMINTOKEN}`,
            'Content-Type': 'application/json',
          },
        },
      );
      if (response.data.editedCartItem) {
        return response.data.editedCartItem;
      } else {
        return;
      }
    } catch (error) {
      if (error.response.status === 404) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.response.data.message);
      }
    }
  },
);

const CartSlice = createSlice({
  name: 'Cart',
  initialState: {
    status: null,
    error: null,
    data: [],
    removedCartItem: null,
    editedCartItem: null,
    itemAddedToCart: null,
  },
  extraReducers: {
    [ViewCart.pending]: (state) => {
      state.status = 'loading';
    },
    [ViewCart.fulfilled]: (state, action) => {
      state.data = action.payload;
      state.status = 'success';
    },
    [ViewCart.rejected]: (state, action) => {
      state.status = 'failed';
      state.Error = action.payload;
    },
    [RemoveProductFromCart.pending]: (state) => {
      state.removeCartItemStatus = 'loading';
    },
    [RemoveProductFromCart.fulfilled]: (state, action) => {
      state.removedCartItem = action.payload;
      state.removeCartItemStatus = 'success';
    },
    [RemoveProductFromCart.rejected]: (state, action) => {
      state.removeCartItemStatus = 'failed';
      state.Error = action.payload;
    },
    [updateCartProductQuantity.pending]: (state) => {
      state.updateCartQtyStatus = 'loading';
    },
    [updateCartProductQuantity.fulfilled]: (state, action) => {
      state.editedCartItem = action.payload;
      state.updateCartQtyStatus = 'success';
    },
    [updateCartProductQuantity.rejected]: (state, action) => {
      state.updateCartQtyStatus = 'failed';
      state.Error = action.payload;
    },
    [AddToCart.pending]: (state) => {
      state.addToCartStatus = 'loading';
    },
    [AddToCart.fulfilled]: (state, action) => {
      state.itemAddedToCart = action.payload;
      state.addToCartStatus = 'success';
    },
    [AddToCart.rejected]: (state, action) => {
      state.addToCartStatus = 'failed';
      state.Error = action.payload;
    },
    [ClearCart.pending]: (state) => {
      state.status = 'loading';
    },
    [ClearCart.fulfilled]: (state, action) => {
      state.data = action.payload;
      state.status = 'success';
    },
    [ClearCart.rejected]: (state, action) => {
      state.status = 'failed';
      state.Error = action.payload;
    },
  },
});

export default CartSlice.reducer;
