import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'


const SubcategoriesSkeleton = () => {
    return (
        <div className="ml-[50px] mt-[15px] flex flex-col gap-[10px]">
            
            {Array(8).fill(0).map((item, index) => {
                return (
                <div key={index}>
                    <Skeleton baseColor='#1d1f1e' highlightColor='#292928'/>
                    {/* <input
                      type="checkbox"
                      value={item.name}
                      name='check-box-1'
                      id={item.name}
                      className="appearance checked:bg-[#ca8a23] border-[1px] h-4 w-4 rounded-[4px] border-[#ca8a23] cursor-pointer"
                    />
                    <label htmlFor={item.name} className="pl-3 cursor-pointer text-[#c9c9c9c9] text-[15px] sm:text-[16px] my-auto ml-[5px] lg:ml-0 mt-[5px]">
                      {item.name}
                    </label> */}
                </div>)
              })
              }
        </div>
    )
}

export default SubcategoriesSkeleton;