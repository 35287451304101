import React from 'react';
import { useNavigate } from 'react-router-dom';
import { clearOrderDetails } from '../../utils/orderUtils';

const UnsuccessfulPayment = () => {
  const navigate = useNavigate();

  const handleBackToCart = () => {
    clearOrderDetails();
    navigate('/cart');
  };
  return (
    <div className="min-h-[70vh] flex items-center justify-center bg-gradient-to-b from-[#191919] via-[rgba(53, 47, 47, 0.00)] to-[#040404]">
      <div className="p-8 rounded-lg shadow-md w-full max-w-md bg-[#040404]">
        <h1 className="text-3xl font-semibold text-red-600 mb-4">
          Payment Unsuccessful
        </h1>
        <p className="text-white mb-4">
          We apologize, but there was an issue processing your payment.
        </p>
        <div className="text-center">
          <button
            className="text-[#fff] border hover:bg-[#232323] py-2 px-4 rounded-full focus:outline-none focus:ring focus:ring-blue-300"
            onClick={handleBackToCart}
          >
            Back to Cart
          </button>
        </div>
      </div>
    </div>
  );
};

export default UnsuccessfulPayment;
