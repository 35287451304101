import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Button from '../Buttons/Button';
import Heading from '../Heading';
import Section from '../Section';
import { IoMdAddCircle } from 'react-icons/io';
import { BsTrash, BsCheckCircle, BsCart3 } from 'react-icons/bs';
import { TbTruckDelivery } from 'react-icons/tb';

import Image from '../Image';
import Paragraph from '../Paragraph';
import { useDispatch, useSelector } from 'react-redux';
import {
  DeleteOwnerSample,
  GetOwnerSamples,
} from '../../../Redux/Features/Samples/sampleSlice';

const SavedSampleRequests = () => {
  const [cancel, setCancel] = useState(true);
  const [inDelivery, setInDelivery] = useState(false);
  const [isDelivered, setIsDelivered] = useState(false);
  const samples = useSelector((state) => state?.sampleSlice?.data) || [];

  const dispatch = useDispatch();

  const deletedSampleId =
    useSelector((state) => state?.sampleSlice?.deletedSampleId) || null;

  const [isDeleted, setIsDeleted] = useState(false);

  useEffect(() => {
    if (isDeleted) {
      setIsDeleted(false);
    }
  }, [isDeleted]);

  useEffect(() => {
    dispatch(GetOwnerSamples());
  }, [dispatch, isDeleted]);

  const handleDelete = (sampleId) => {
    dispatch(DeleteOwnerSample(sampleId))
      .unwrap()
      .then((result) => {
        setIsDeleted(true);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const formatDate = (dateString) => {
    const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  useEffect(() => {
    if (deletedSampleId) {
      setIsDeleted(true);
    }
  }, [deletedSampleId]);

  const toggleInDelivery = () => {
    setCancel(!cancel);
    setInDelivery(!inDelivery);
  };

  const toggleIsDelivered = () => {
    setInDelivery(!inDelivery);
    setIsDelivered(!isDelivered);
  };

  const hasSamples = samples.length > 0;
  return (
    <div className="flex flex-col mx-[4%] px-2 lg:px-10 mb-10 mt-5 lg:mt-0 bg-gradient-to-b from-[#292929] via-[rgba(53, 47, 47, 0.00)] to-[#131313]">
      <Section className="flex flex-col lg:flex-row">
        <Heading className="flex-shrink-0 text-xl text-center lg:text-start justify-center lg:justify-start text-white md:text-4xl py-[15px]">
          Saved Sample Requests
        </Heading>
        <Link
          to="/samples/new-request"
          className="flex justify-center lg:justify-start lg:ml-auto lg:mr-[77px] flex-shrink-0"
        >
          <Button
            className="flex gap-1 justify-center lg:justify-start lg:gap-3 mx-auto w-auto h-[40px] text-white my-auto rounded-md bg-gradient-to-br from-yellow-400 to-gray-700"
            type="submit"
          >
            <IoMdAddCircle className="my-auto text-xs lg:text-base" />
            Request sample
          </Button>
        </Link>
      </Section>

      <Section>
        <div className="mb-[20px] px-2 lg:px-0">
          {hasSamples ? (
            samples.map((sample) => (
              <Section
                key={sample._id}
                className="flex flex-col lg:flex-row my-7 lg:mb-7 h-auto lg:h-[130px] lg:w-[94%] bg-[#00000050] rounded-[20px] px-2 lg:px-5 py-4"
              >
                <Section className="flex text-white">
                  <Image
                    src={sample?.product?.imageUrl[0]}
                    alt={sample?.product?.name}
                    className="w-20 h-20 lg:w-[100px] lg:h-[100px] rounded-md object-cover"
                  />
                  <div className="ml-3 pr-1 lg:pr-0 lg:ml-[20px] mt-[5px]">
                    <Heading className="text-[13px] lg:text-[18px]">
                      {sample?.product?.name}
                    </Heading>

                    <div className="flex items-center mt-2">
                      <Paragraph className="text-[#c3c3c3] text-[10px] lg:text-[12px]">
                        Requested On:
                      </Paragraph>
                      <Paragraph className="text-[#c3c3c3] text-[10px] lg:text-[12px] pl-1">
                        {formatDate(sample?.deliveryDate)}
                      </Paragraph>
                    </div>
                  </div>
                </Section>

                <div className="lg:ml-auto mt-3 lg:mt-[2%] cursor-pointer flex gap-3 justify-end">
                  {isDelivered && (
                    <>
                      <Button
                        className="flex gap-1 lg:gap-3 text-sm lg:text-base h-[35px] lg:h-[40px] text-white my-auto rounded-md bg-gradient-to-br from-yellow-400 to-gray-700"
                        type="submit"
                        disabled
                      >
                        <BsCheckCircle className="my-auto" />
                        Delivered
                      </Button>
                      <Button
                        className="flex gap-1 lg:gap-3 text-sm lg:text-base h-[35px] lg:h-[40px] text-white my-auto rounded-md bg-gradient-to-br from-yellow-400 to-gray-700"
                        type="submit"
                      >
                        <BsCart3 className="my-auto" />
                        Add to cart
                      </Button>
                    </>
                  )}
                  {inDelivery && (
                    <Button
                      className="flex gap-1 lg:gap-3 text-sm lg:text-base h-[35px] lg:h-[40px] text-white my-auto rounded-md bg-gradient-to-br from-yellow-400 to-gray-700"
                      type="submit"
                      onClick={toggleIsDelivered}
                    >
                      <TbTruckDelivery className="my-auto" />
                      In delivery
                    </Button>
                  )}
                  {cancel && (
                    <Button
                      className="flex gap-1 lg:gap-3 text-xs lg:text-base h-[35px] lg:h-[40px] text-white my-auto rounded-md bg-gradient-to-br from-yellow-400 to-gray-700"
                      type="submit"
                      onClick={() => handleDelete(sample._id)}
                    >
                      <BsTrash className="my-auto" />
                      Cancel
                    </Button>
                  )}
                </div>
              </Section>
            ))
          ) : (
            <div className="mt-10">
              <Heading className="text-base text-center text-gray-400 lg:text-lg md:text-2xl">
                You have no Saved Samples
              </Heading>
            </div>
          )}
        </div>
      </Section>
    </div>
  );
};

export default SavedSampleRequests;
