import React from 'react';
import {
  Header,
  Container,
  Footer,
  ProductDetails,
  SuggestedProducts,
} from '../../components';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import {
  fetchProduct,
  fetchProductsByCategory,
} from '../../Redux/Features/product/fetchProductSlice';
import { useState } from 'react';
import 'react-loading-skeleton/dist/skeleton.css';
import ProductDetailsSkeleton from '../../components/skeletons/ProductDetailsSkeleton';
import OtherProductsSkeleton from '../../components/skeletons/OtherProductsSkeleton';
import { scrollToTop } from '../../utils';

const ViewProductDetails = () => {
  const dispatch = useDispatch();

  const { id } = useParams();

  let product = useSelector((state) => state.fetchProduct.data);
  const products = useSelector((state) => state.fetchProduct.products);
  const status = useSelector((state) => state.fetchProduct.status);

  const [filteredProducts, setFilteredProducts] = useState([]);

  useEffect(() => {
    if (id) {
      dispatch(fetchProduct(id));
    }
    scrollToTop();
  }, [dispatch, id]);

  useEffect(() => {
    dispatch(fetchProductsByCategory(product?.categoryId));
  }, [dispatch, product?.categoryId]);

  useEffect(() => {
    const filteredArray = products?.filter((item) => item._id !== product?._id);
    setFilteredProducts(filteredArray);
  }, [products, product?._id]);

  return (
    <Container>
      <Header />

      {status !== 'success' ? (
        <ProductDetailsSkeleton />
      ) : (
        <ProductDetails product={product} />
      )}

      {status !== 'success' ? (
        <OtherProductsSkeleton />
      ) : (
        <SuggestedProducts
          maxVisibleProducts={4}
          className="mx-[2%] md:mx-[4%] lg:mx-[6%] xl:mx-[7%]"
          ProductCardsClassName="grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-4"
          products={filteredProducts}
        />
      )}

      <Footer />
    </Container>
  );
};

export default ViewProductDetails;
