const TextArea = ({ placeholder, value, name, onChange, className }) => {
  return (
    <textarea
      placeholder={placeholder}
      value={value}
      name={name}
      onChange={onChange}
      className={className}
    />
  );
};

export default TextArea;
