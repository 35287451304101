import React, { useEffect, useState } from 'react';
import { HiArrowLongDown, HiArrowLongRight } from 'react-icons/hi2';
import { CiCircleInfo, CiSettings } from 'react-icons/ci';
import { BsChevronDown, BsChevronLeft, BsStack } from 'react-icons/bs';
import { SiHomeadvisor } from 'react-icons/si';
import { BsChevronRight } from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCategories } from '../../Redux/Features/product/categorySlice';
import { Heading, Paragraph, Section } from '../common';
import { GiRolledCloth } from 'react-icons/gi';
import { RxSection } from 'react-icons/rx';
import { ViewSubcategory } from '../../Redux/Features/Products/subcategorySlice';
import { fetchSubsubcategories } from '../../Redux/Features/Products/subSubcategorySlice';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { LoginPopup } from '../modal';
import { GetProductsByFilters } from '../../Redux/Features/Products/productSlice';

const HamburgerMenuContent = ({ setIsMobileMenuOpen }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showCategories, setShowCategories] = useState(false);
  const [showFabricRequests, setShowFabricRequests] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedCategoryId, setSelectedCategoryId] = useState('');
  const [showSubcategories, setShowSubcategories] = useState(false);
  const [showLoginPopup, setShowLoginPopup] = useState(false);
  const [isFabricButtonSelected, setIsFabricButtonSelected] = useState(false);
  const [isMob, setIsMob] = useState(false);

  const fetchedCategories = useSelector((state) => state.categories.data);
  let fetchedSubcategories = useSelector((state) => state.subcategory.data);
  let fetchedsubSubcategories = useSelector(
    (state) => state.subSubcategory.data,
  );
  let { currentPage } = useSelector(
    (state) => state?.product?.paginatedProducts,
  );
  let numberOfDisplayedProducts = 21;

  const topSectionStyles = 'flex flex-row text-[#B1ADAD] mt-3';
  const topSectionStyles_left = 'w-[20%] pl-2';
  const topSectionStyles_right =
    'w-[80%] border-b border-[#b1adad50] uppercase text-[15px] pt-[2px] pb-4 cursor-pointer';
  const iconStyles = 'w-6 h-6';

  const middleSectionOptionsStyles =
    'py-3 pl-[10px] text-[14px] text-[#747373]';

  const handleShowCategories = () => {
    setShowCategories((prevShowCategories) => !prevShowCategories);
    setShowSubcategories(false);
    setSelectedCategory(null);
  };

  const handleShowFabricRequests = () => {
    setShowFabricRequests(!showFabricRequests);
  };

  const handleCategoryClick = (category) => {
    if (selectedCategory === category) {
      setSelectedCategory(null);
      setShowSubcategories(false);
    } else {
      setSelectedCategory(category);
      setSelectedCategoryId(category._id);
      setShowSubcategories(true);
    }
  };

  const handleBackClick = () => {
    setShowSubcategories(false);

    setSelectedCategory(null);
    setShowCategories(true);
  };

  const handleSubCategoryClick = (selectedSubcategory) => {
    return new Promise((resolve, reject) => {
      setIsMobileMenuOpen(false);
      setIsMob(true);
      navigate(`/product/browse-products/category/${selectedCategoryId}/true`);
      dispatch(
        GetProductsByFilters({
          categories: [],
          subcategories: [selectedSubcategory],
          subsubcategories: [],
          page: currentPage,
          limit: numberOfDisplayedProducts,
        }),
      );
    });
  };

  const handleSamplesRedirection = () => {
    setIsFabricButtonSelected(false);
    return new Promise((resolve, reject) => {
      const isLoggedIn = localStorage.getItem('token');
      if (!isLoggedIn) {
        setShowLoginPopup(true);
        return;
      }
      navigate('/samples/new-request');
    });
  };

  const handleFabricsRedirection = () => {
    setIsFabricButtonSelected(true);
    return new Promise((resolve, reject) => {
      const isLoggedIn = localStorage.getItem('token');
      if (!isLoggedIn) {
        setShowLoginPopup(true);
        return;
      }
      navigate('/fabric/finder');
    });
  };

  useEffect(() => {
    if (!fetchedCategories.length) {
      dispatch(fetchCategories());
    }
    if (!fetchedSubcategories.length) {
      dispatch(ViewSubcategory());
    }
    if (!fetchedsubSubcategories.length) {
      dispatch(fetchSubsubcategories());
    }
  }, [
    dispatch,
    fetchedCategories.length,
    fetchedSubcategories.length,
    fetchedsubSubcategories.length,
    selectedCategory,
  ]);

  return (
    <div className="grid">
      {/* Top Section */}
      <Section className="">
        <Link to="/home">
          <div className={`${topSectionStyles} mt-0`}>
            <div className={`${topSectionStyles_left}`}>
              <SiHomeadvisor className={`${iconStyles}`} />
            </div>
            <div className={`${topSectionStyles_right}`}>Home</div>
          </div>
        </Link>

        <div className={`${topSectionStyles}`}>
          <div className={`${topSectionStyles_left}`}>
            <BsStack className={`${iconStyles}`} />
          </div>
          <div
            className={`${topSectionStyles_right} flex justify-between`}
            onClick={handleShowCategories}
          >
            <span>Categories</span>
            {showCategories ? (
              <BsChevronDown className="w-4 h-4 mt-1" />
            ) : (
              <BsChevronRight className="w-4 h-4 mt-1" />
            )}
          </div>
        </div>

        <Section className="mt-3 text-[#B1ADAD]">
          {/* Display Categories */}
          {!showSubcategories &&
            Array.isArray(fetchedCategories) &&
            fetchedCategories.map((category) => (
              <div
                key={category._id}
                className={`${middleSectionOptionsStyles} ${
                  showCategories ? 'block' : 'hidden'
                } pl-[20%] hover:text-latestYellow flex justify-between cursor-pointer`}
                onClick={() => handleCategoryClick(category)}
              >
                <span>{category.name}</span>
                {selectedCategory === category ? (
                  <HiArrowLongDown className="w-4 h-4 mt-1" />
                ) : (
                  <HiArrowLongRight className="w-4 h-4 mt-1" />
                )}
              </div>
            ))}

          {/* Display Subcategories */}
          {showSubcategories && selectedCategory && (
            <div className="pl-[15%] mb-10">
              <div
                className={`${middleSectionOptionsStyles} flex justify-between cursor-pointer`}
                onClick={() => handleBackClick()}
              >
                <span className="flex items-center cursor-pointer">
                  <BsChevronLeft className="w-4 h-4 mr-2" />
                  {selectedCategory.name}
                </span>
              </div>
              {fetchedSubcategories
                .filter(
                  (subcategory) =>
                    subcategory.categoryId === selectedCategory._id,
                )
                .map((subcategory) => (
                  <div
                    key={subcategory._id}
                    className="ml-[30px] hover:text-latestYellow cursor-pointer mb-3"
                    onClick={() => handleSubCategoryClick(subcategory._id)}
                  >
                    {subcategory.name}
                  </div>
                ))}
            </div>
          )}
        </Section>

        <div className={`${topSectionStyles}`}>
          <div className={`${topSectionStyles_left}`}>
            <GiRolledCloth className={`${iconStyles}`} />
          </div>
          <div
            className={`${topSectionStyles_right}`}
            onClick={handleShowFabricRequests}
          >
            Request Fabrics
          </div>
          {showFabricRequests ? (
            <BsChevronDown className="w-4 h-4 mt-1" />
          ) : (
            <BsChevronRight className="w-4 h-4 mt-1" />
          )}
        </div>

        <Section className="mt-3">
          {showFabricRequests && (
            <div className={`pl-[15%]`}>
              <div
                className={`${middleSectionOptionsStyles} cursor-pointer hover:text-latestYellow`}
                onClick={handleSamplesRedirection}
              >
                Request Samples
              </div>
              <div
                className={`${middleSectionOptionsStyles} cursor-pointer hover:text-latestYellow`}
                onClick={handleFabricsRedirection}
              >
                Fabric Finder
              </div>
            </div>
          )}
        </Section>
        {/* <div className={`${topSectionStyles}`}>
          <div className={`${topSectionStyles_left}`}>
            <CiSettings className={`${iconStyles}`} />
          </div>
          <div className={`${topSectionStyles_right}`}>Settings</div>
        </div>

        <div className={`${topSectionStyles}`}>
          <div className={`${topSectionStyles_left}`}>
            <CiCircleInfo className={`${iconStyles}`} />
          </div>
          <div className={`${topSectionStyles_right}`}>Help & FAQS</div>
        </div> */}
      </Section>

      {/* Bottom Section */}
      <Section className="mt-[6%] text-[#B1ADAD]">
        <div>
          <Heading className="font-bold pl-[3%] text-[18px] py-2 border-b border-[#b1adad50]">
            TELL US WHAT YOU THINK
          </Heading>
        </div>
        <Link to="/feedback">
          <div>
            <Paragraph
              className={`${middleSectionOptionsStyles} pt-4 hover:text-latestYellow`}
            >
              Help improve the platform
            </Paragraph>
          </div>
        </Link>
      </Section>
      {showLoginPopup && (
        <LoginPopup
          message={`Please login to access the ${
            isFabricButtonSelected ? 'fabric finder' : 'sample request'
          } feature.`}
          onClose={() => setShowLoginPopup(false)}
        />
      )}
    </div>
  );
};

export default HamburgerMenuContent;
