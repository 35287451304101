import React from 'react';
import { BsChevronLeft } from 'react-icons/bs';
import { BiChevronDown } from 'react-icons/bi';

function FilterSidebar({
  allProducts,
  categories,
  clickedCategory,
  isCategoryDropdownOpen,
  handleCategorySelected,
  toggleSortSelection,
  filteredSubcategories,
  clickedSubCategory,
  isDropdownOpen,
  setIsDropdownOpen,
  setIsCategoryDropdownOpen,
  handleSubcategorySelected,
  filteredSubSubcategories,
  handleSubSubCategorySelected,
  selectedCategories,
  selectedSubcategories,
  selectedSubSubcategories,
  toggleDropdown,
  toggleCategoryDropdown,
  className,
  fetchedPaginatedProducts,
  handleBackArrowClick,
}) {
  if (
    fetchedPaginatedProducts &&
    Array.isArray(fetchedPaginatedProducts?.data)
  ) {
    fetchedPaginatedProducts = fetchedPaginatedProducts?.data;
  }

  return (
    <div
      className={`${className}`}
      style={{
        maxHeight: 'calc(100vh - 100px)',
        overflowY: 'auto',
      }}
    >
      {categories.map((item, index) => (
        <div key={index} className="flex flex-col">
          <div
            className={`flex items-center ${
              clickedCategory?._id !== item._id && isCategoryDropdownOpen
                ? 'hidden'
                : 'flex top-0 py-2 lg:py-4'
            }`}
          >
            {clickedCategory?._id === item._id && isCategoryDropdownOpen ? (
              <div
                className="flex border p-2 border-[#363636] rounded-full cursor-pointer"
                onClick={() => handleBackArrowClick(false)}
              >
                <BsChevronLeft className="w-4 h-4 text-white" />
              </div>
            ) : (
              <input
                type="checkbox"
                value={item._id}
                name="category_name"
                id={item.name}
                onChange={(e) => {
                  if (
                    !allProducts.filter((prod) => prod.categoryId === item._id)
                      .length
                  ) {
                    return;
                  }
                  toggleCategoryDropdown();
                  handleCategorySelected(e.target.value);
                  // toggleSortSelection(true, false, false);
                }}
                checked={
                  selectedSubSubcategories.length &&
                  fetchedPaginatedProducts.some(
                    (prod) => prod.categoryId === item._id,
                  )
                }
                disabled={
                  !allProducts.filter((prod) => prod.categoryId === item._id)
                    .length
                }
                className={`appearance-none ${
                  !allProducts.filter((prod) => prod.categoryId === item._id)
                    .length
                    ? 'opacity-40 cursor-not-allowed border-[#6a6a6ac9]'
                    : 'cursor-pointer checked:bg-[#ca8a23] border-[#ca8a23]'
                }  border-[1px] h-4 w-4 rounded-[4px]`}
              />
            )}
            <label
              htmlFor={item.name}
              className={`pl-3 flex items-center justify-between text-[#c9c9c9c9] text-[15px] sm:text-[16px] ml-[5px] lg:ml-0 flex-grow ${
                !isCategoryDropdownOpen && 'cursor-pointer'
              } ${
                !allProducts.filter((prod) => prod.categoryId === item._id)
                  .length &&
                'text-[#6a6a6ac9] cursor-not-allowed border-[#6a6a6ac9]'
              }`}
              // onClick={() => toggleCategoryDropdown}
            >
              <div
                className={`${
                  !allProducts.filter((prod) => prod.categoryId === item._id)
                    .length && 'text-[#6a6a6ac9] cursor-not-allowed'
                } ${
                  !isDropdownOpen &&
                  !isCategoryDropdownOpen &&
                  selectedSubSubcategories.length &&
                  fetchedPaginatedProducts.some(
                    (prod) => prod.categoryId === item._id,
                  ) &&
                  'text-[#ad7f34]'
                }`}
              >
                <span>{item.name}&nbsp;</span>
                {!isCategoryDropdownOpen && (
                  <span>
                    (
                    {
                      allProducts.filter((prod) => prod.categoryId === item._id)
                        .length
                    }
                    )
                  </span>
                )}
              </div>
              <BiChevronDown
                className={`${
                  isCategoryDropdownOpen ||
                  !allProducts.filter((prod) => prod.categoryId === item._id)
                    .length
                    ? 'hidden'
                    : 'block'
                }`}
              />
            </label>
          </div>
        </div>
      ))}

      {isCategoryDropdownOpen && clickedCategory && (
        <div className="px-6">
          <div>
            {filteredSubcategories.map((sub, index) => (
              <div key={index} className="flex flex-col pl-2">
                <div
                  className={`flex items-center ${
                    clickedSubCategory?._id !== sub._id && isDropdownOpen
                      ? 'hidden'
                      : 'flex top-0'
                  }`}
                >
                  {clickedSubCategory?._id === sub._id && isDropdownOpen ? (
                    <div
                      className="flex border p-2 border-[#363636] rounded-full cursor-pointer"
                      onClick={() => handleBackArrowClick(true)}
                    >
                      <BsChevronLeft className="w-4 h-4 text-white" />
                    </div>
                  ) : (
                    <input
                      type="checkbox"
                      value={sub._id}
                      name="subcategory_name"
                      id={sub.name}
                      onChange={(e) => {
                        if (
                          !allProducts.filter(
                            (prod) => prod.subcategoryId === sub._id,
                          ).length
                        ) {
                          return;
                        }
                        toggleDropdown();
                        handleSubcategorySelected(e.target.value);
                        // toggleSortSelection(false, true, false);
                      }}
                      disabled={
                        !allProducts.filter(
                          (prod) => prod.subcategoryId === sub._id,
                        ).length
                      }
                      checked={
                        selectedSubSubcategories.length &&
                        fetchedPaginatedProducts.some(
                          (prod) => prod.subcategoryId === sub._id,
                        )
                      }
                      className={`appearance-none ${
                        !allProducts.filter(
                          (prod) => prod.subcategoryId === sub._id,
                        ).length
                          ? 'opacity-40 cursor-not-allowed border-[#6a6a6ac9]'
                          : 'cursor-pointer checked:bg-[#ca8a23] border-[#ca8a23]'
                      }  border-[1px] h-4 w-4 rounded-[4px]`}
                    />
                  )}
                  <label
                    htmlFor={sub.name}
                    className={`p-2 ${
                      !isDropdownOpen && 'cursor-pointer'
                    } flex items-center justify-between text-[#c9c9c9c9] text-[12px] sm:text-sm ml-[10px] lg:ml-0 flex-grow ${
                      !allProducts.filter(
                        (prod) => prod.subcategoryId === sub._id,
                      ).length && ' cursor-not-allowed border-[#6a6a6ac9]'
                    }`}

                    // onClick={() => toggleDropdown}
                  >
                    <div
                      className={`${
                        !isDropdownOpen &&
                        selectedSubSubcategories.length &&
                        fetchedPaginatedProducts.some(
                          (prod) => prod.subcategoryId === sub._id,
                        ) &&
                        'text-[#ad7f34]'
                      } ${
                        !allProducts.filter(
                          (prod) => prod.subcategoryId === sub._id,
                        ).length &&
                        'opacity-40 cursor-not-allowed border-[#6a6a6ac9]'
                      }`}
                    >
                      <span>{sub.name}&nbsp;</span>
                      {!isDropdownOpen && (
                        <span>
                          (
                          {
                            allProducts.filter(
                              (prod) => prod.subcategoryId === sub._id,
                            ).length
                          }
                          )
                        </span>
                      )}
                    </div>
                    <BiChevronDown
                      className={`${
                        isDropdownOpen ||
                        !allProducts.filter(
                          (prod) => prod.subcategoryId === sub._id,
                        ).length
                          ? 'hidden'
                          : 'block'
                      }`}
                    />
                  </label>
                </div>
                {isDropdownOpen && clickedSubCategory?._id === sub._id ? (
                  <div className="p-5 pt-2">
                    <div className="space-y-5">
                      {filteredSubSubcategories.map((item, index) => (
                        <div key={index} className="flex items-center pl-9">
                          <input
                            type="checkbox"
                            value={item._id}
                            name="subsubcategory_name"
                            id={item.name}
                            onChange={(e) => {
                              handleSubSubCategorySelected(e.target.value);
                              // toggleSortSelection(false, false, true);
                            }}
                            disabled={
                              !allProducts.filter(
                                (prod) => prod.subSubcategoryId === item._id,
                              ).length
                            }
                            checked={selectedSubSubcategories.includes(
                              item._id,
                            )}
                            className={`appearance-none ${
                              !allProducts.filter(
                                (prod) => prod.subSubcategoryId === item._id,
                              ).length
                                ? 'opacity-40 cursor-not-allowed border-[#6a6a6ac9]'
                                : 'cursor-pointer checked:bg-[#ca8a23] border-[#ca8a23]'
                            }  border-[1px] h-4 w-4 rounded-[4px]`}
                          />
                          <label
                            htmlFor={item.name}
                            className={` pl-2 flex items-center justify-between text-[12px] sm:text-sm ml-[10px] lg:ml-0 flex-grow ${
                              selectedSubSubcategories.includes(item._id)
                                ? 'text-[#ad7f34]'
                                : 'text-[#c9c9c9c9]'
                            } ${
                              !allProducts.filter(
                                (prod) => prod.subSubcategoryId === item._id,
                              ).length && 'opacity-40 cursor-not-allowed'
                            }`}
                          >
                            <div>
                              <span>{item.name}&nbsp;</span>
                              {
                                <span className="text-[10px]">
                                  (
                                  {
                                    allProducts.filter(
                                      (prod) =>
                                        prod.subSubcategoryId === item._id,
                                    ).length
                                  }
                                  )
                                </span>
                              }
                            </div>
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>
                ) : null}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

export default FilterSidebar;
