import { useState } from 'react';
import { Input, Paragraph, Section } from '../common';

const PaymentOptions = ({ setIsPaymentNumber }) => {
  const [isMTNOptionClicked, setIsMTNOptionClicked] = useState(false);
  const [isAirtelOptionClicked, setIsAirtelOptionClicked] = useState(false);

  const handleMTNOptionClick = () => {
    setIsMTNOptionClicked((prevState) => !prevState);
    setIsAirtelOptionClicked(false);

    localStorage.setItem('orderPaymentMethod', 'mtn');
  };

  const handleAirtelOptionClick = () => {
    setIsAirtelOptionClicked((prevState) => !prevState);
    setIsMTNOptionClicked(false);

    localStorage.setItem('orderPaymentMethod', 'airtel');
  };

  const handlePaymentNumberChange = (e) => {
    const number = e.target.value;
    JSON.stringify(localStorage.setItem('orderPaymentNumber', number));
    setIsPaymentNumber(number);
  };

  const labelStyles = 'text-[#5e5e5e] mb-[3px]';
  const paymentCardStyles =
    'border border-[#3e3e3e] hover:border-[#ccc] w-[300px] h-[100px] flex justify-center items-center cursor-pointer';
  const paymentLabelStyles = 'mb-[3px] flex justify-center';

  return (
    <div>
      <Section className="flex flex-col pt-5 gap-[10px] md:mt-10 mb-5 md:mb-0 ">
        <Paragraph className={`${labelStyles} mb-5`}>Payment Method</Paragraph>
        <div className="flex flex-row justify-between gap-[10%]">
          <div
            className={`${paymentCardStyles} ${
              isMTNOptionClicked ? 'bg-[#ffcc00]' : ''
            }`}
            onClick={handleMTNOptionClick}
          >
            <div>
              <Paragraph
                className={`${paymentLabelStyles} ${
                  isMTNOptionClicked ? 'text-[#000]' : 'text-[#5e5e5e]'
                } text-[20px]`}
              >
                MTN
              </Paragraph>
              <Paragraph
                className={`${paymentLabelStyles} ${
                  isMTNOptionClicked ? 'text-[#000]' : 'text-[#5e5e5e]'
                } text-[12px]`}
              >
                Mobile Money
              </Paragraph>
            </div>
          </div>
          <div
            className={`${paymentCardStyles} ${
              isAirtelOptionClicked ? 'bg-[#ff0000]' : ''
            }`}
            onClick={handleAirtelOptionClick}
          >
            <div>
              <Paragraph
                className={`${paymentLabelStyles} ${
                  isAirtelOptionClicked ? 'text-[#fff]' : 'text-[#5e5e5e]'
                } text-[20px]`}
              >
                Airtel
              </Paragraph>
              <Paragraph
                className={`${paymentLabelStyles} ${
                  isAirtelOptionClicked ? 'text-[#fff]' : 'text-[#5e5e5e]'
                } text-[12px]`}
              >
                Money
              </Paragraph>
            </div>
          </div>
        </div>
      </Section>

      <Section>
        <Input
          className={`${
            isMTNOptionClicked || isAirtelOptionClicked ? 'block' : 'hidden'
          } bg-[#413F42] text-[#fff] w-[100%] md:w-[45%] focus:outline-none rounded-[2px] my-5`}
          placeholder={`${
            isAirtelOptionClicked ? 'e.g: 250733112233' : 'e.g: 250788112233'
          }`}
          type="number"
          name="mobileMoneyNumber"
          min={1}
          onChange={(e) => handlePaymentNumberChange(e)}
        />
      </Section>
    </div>
  );
};

export default PaymentOptions;
