import React from 'react';

export const TableBody = ({ orders }) => {
  if (!Array.isArray(orders)) {
    return null;
  }
  const formatDate = (dateString) => {
    const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
    return new Date(dateString).toLocaleDateString('en-GB', options);
  };
  return (
    <tbody className="text-sm">
      {orders?.map((order) => (
        <>
          <tr key={order?._id} className="group hover:bg-[#181717]">
            <td className="group-hover:bg-[#181717] rounded-l-md">
              <th
                scope="row"
                className="flex items-center py-4 pl-6 text-white whitespace-nowrap"
              >
                <img
                  className="object-cover w-10 h-10 rounded-full"
                  src={order?.products[0]?.product?.imageUrl[0]}
                  alt="Something"
                />
                <div className="max-w-[200px] pl-8">
                  <div className="font-normal truncate">
                    {order?.customerName}
                  </div>
                </div>
              </th>
            </td>
            <td className="px-8 py-4 group-hover:bg-[#181717] max-w-[350px] truncate text-white">
              <span className="px-4 py-2">
                {order?.products[0]?.product?.name}
              </span>
            </td>
            <td className="px-8 py-4 group-hover:bg-[#181717]">
              <div className="text-white">{formatDate(order?.createdAt)}</div>
            </td>
            <td className="px-8 py-4 group-hover:bg-[#181717] rounded-r-md">
              <span className="px-4 py-2 text-white">
                {order?.totalPrice} Rwf
              </span>
            </td>
          </tr>
        </>
      ))}
    </tbody>
  );
};
