import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { BASE_URL } from '../../../utils/apiUtils';

export const ViewSubcategory = createAsyncThunk(
  'api/products/subcategory',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${BASE_URL}api/products/subcategory/all`,
      );
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

const ViewsubcategorySlice = createSlice({
  name: 'subcategory',
  initialState: {
    subcategory: null,
    status: null,
    error: null,
    data: [],
  },
  extraReducers: {
    [ViewSubcategory.pending]: (state) => {
      state.status = 'loading.....';
    },
    [ViewSubcategory.fulfilled]: (state, action) => {
      state.data = action.payload;
      state.status = 'success';
    },
    [ViewSubcategory.rejected]: (state, action) => {
      state.error = action.payload;
      state.status = 'failed';
    },
  },
});

export default ViewsubcategorySlice.reducer;
