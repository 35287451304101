import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { BASE_URL } from '../../../utils/apiUtils';
import axios from 'axios';

export const requestPasswordReset = createAsyncThunk(
  'request-password-reset',
  async (email, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${BASE_URL}api/users/password/reset`,
        email,
      );
      return response.data;
    } catch (error) {
      if (error.response.status === 404) {
        return rejectWithValue(error.response.data.message);
      }
      if (error.response.status === 500) {
        return rejectWithValue(error.response.data.errors[0].message);
      }
      return rejectWithValue(error);
    }
  },
);

export const resetPassword = createAsyncThunk(
  'reset-password',
  async ({ formData }, { rejectWithValue }) => {
    try {
      const response = await axios.put(
        `${BASE_URL}api/users/password/reset/new-password`,
        {
          password: formData.password,
          token: formData.token,
        },
      );
      return response.data;
    } catch (error) {
      if (error.response.status === 400) {
        return rejectWithValue(error.response.data.message);
      }
      if (error.response.status === 404) {
        return rejectWithValue(error.response.data.message);
      }
      if (error.response.status === 500) {
        return rejectWithValue(error.response.data.errors[0].message);
      }
      return rejectWithValue(error);
    }
  },
);

const forgotPasswordSlice = createSlice({
  name: 'forgotPassword',
  initialState: {
    status: null,
    error: null,
    data: [],
  },
  extraReducers: {
    [requestPasswordReset.pending]: (state) => {
      state.status = 'loading';
    },
    [requestPasswordReset.fulfilled]: (state, action) => {
      state.data = action.payload;
      state.status = 'success';
    },
    [requestPasswordReset.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.payload;
    },
    [resetPassword.pending]: (state) => {
      state.status = 'loading';
    },
    [resetPassword.fulfilled]: (state, action) => {
      state.data = action.payload;
      state.status = 'success';
    },
    [resetPassword.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.payload;
    },
  },
});

export default forgotPasswordSlice.reducer;
