import React from 'react';

const Container = ({ children, className }) => {
  return (
    <div
      className={`bg-[#040404] min-h-screen min-w-screen flex justify-center ${className}`}
      style={{
        width: '100%',
        maxWidth: '100%',
        margin: '0 auto',
        padding: '0 10px',
        boxSizing: 'border-box',
      }}
    >
      <div style={{ maxWidth: '1700px', width: '100%' }}>{children}</div>
    </div>
  );
};

export default Container;
