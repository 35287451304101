import { Link } from 'react-router-dom';
import { Heading, Image } from '../../components';
import { SufuriLogo } from '../../utils';

const AuthNavbar = () => {
  return (
    <div className="p-4 lg:ml-[200px] flex flex-col sm:flex-row gap-4 sm:gap-8 md:gap-12 lg:gap-16">
      <Link to="/" className="flex items-center gap-[3px] lg:gap-2">
        <Image
          className="w-[90px] md:w-[90px] bg-transparent opacity-60"
          style={{ height: 'auto', marginRight: '-10px' }}
          src={SufuriLogo}
          alt="sufuri-logo"
        />

        <Heading className="text-[#8f692c] text-2xl sm:text-3xl logo-text">
          SufuriMart
        </Heading>
      </Link>
    </div>
  );
};

export default AuthNavbar;
