import React, { useEffect } from 'react';
import Button from '../Buttons/Button';
import Heading from '../Heading';
import Paragraph from '../Paragraph';
import Section from '../Section';

import { useDispatch, useSelector } from 'react-redux';
import { ClearCart, ViewCart } from '../../../Redux/Features/Cart/cartSlice';
import EmptyCartContent from '../../EmptyCartContent';
import { useNavigate } from 'react-router-dom';
import { CreateOrder } from '../../../Redux/Features/Orders/orderSlice';
import { Triangle } from 'react-loader-spinner';
import { CartItems } from '../../Cart';
import { selectCartData } from '../../../services';
import { transferFeePercentage } from '../../../utils/checkout';

const ViewCartMiddleSection = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const cart = useSelector(selectCartData) || [];
  const cartProducts = cart.cartProducts || [];

  const status = useSelector((state) => state?.orders?.status);

  const handleClearCart = () => {
    dispatch(ClearCart());
  };

  let deliveryAmount = 2000 || 0;

  let transferFee = (cart?.totalAmount * transferFeePercentage) / 100 || 0;
  let totalAmount = cart?.totalAmount + deliveryAmount || 0;
  let itemsAmount = totalAmount - deliveryAmount || 0;
  let totalCheckoutAmount = totalAmount + transferFee;

  if (cartProducts?.length === 0 || cartProducts?.length === undefined) {
    deliveryAmount = 0;
    totalAmount = 0;
    itemsAmount = 0;
  }

  const handleCheckout = async () => {
    const orderProducts = cartProducts.map((cartProduct) => ({
      quantity: cartProduct?.quantity,
      product: cartProduct?.product?._id,
    }));

    let orderDetails = {
      products: orderProducts,
    };

    if (cartProducts.length > 0) {
      orderDetails.totalPrice = totalCheckoutAmount;
    }
    orderDetails.totalQuantity = cart?.totalQuantity;

    orderDetails.deliveryFee = deliveryAmount;
    orderDetails.transferFeePercentage = transferFeePercentage;

    dispatch(CreateOrder(orderDetails));

    const navigateToCheckout = () => {
      return new Promise((resolve) => {
        const checkNavigation = () => {
          const theOrderId = localStorage.getItem('orderId');
          if (theOrderId) {
            resolve();
          } else {
            setTimeout(checkNavigation, 500);
          }
        };
        checkNavigation();
      });
    };

    try {
      await navigateToCheckout();
      navigate('/checkout');
    } catch (error) {
      console.error('Error while navigating to checkout:', error);
    }
  };

  useEffect(() => {
    dispatch(ViewCart());
  }, [dispatch]);

  const cartNotEmpty = cartProducts.length > 0;

  const currency = 'RWF'; // Should be dynamic

  return (
    <div className="flex flex-col md:flex-row md:mx-[50px] lg:mx-[100px] gap-[15px] md:gap-[15px] lg:gap-[25px]">
      {/* Left */}

      <Section className="pl-3 md:pl-[30px] md:w-[70%] lg:w-[75%] bg-gradient-to-b from-[#040404] via-[rgba(53, 47, 47, 0.00)] to-[#191919]">
        <div className="flex items-center justify-between mr-[6%]">
          <Heading className="text-white pl-[3%] md:pl-0 text-[23px] lg:text-[40px] font-[200] my-7">
            Shopping Cart
          </Heading>

          <Button
            className={`${
              !cartNotEmpty ? 'hidden' : 'block'
            } mt-[15px] lg:mt-[25px] mb-[15px] border border-[#BB2525] lg:px-[50px] rounded-md hover:bg-[#c004041c]`}
            onClick={handleClearCart}
          >
            <span className="text-[#BB2525]">Clear Cart</span>
          </Button>
        </div>
        {cartNotEmpty ? (
          <>
            <CartItems products={cartProducts} />

            <Button
              className={` ${
                !cartNotEmpty ? 'hidden' : 'md:block'
              } text-secondaryYellow hidden lg:block mt-[25px] mb-[15px] mx-auto border border-[#C38D01] md:px-[60px] lg:px-[50px] rounded-md h-[40px] hover:bg-[#2a241599] disabled:opacity-50 disabled:cursor-not-allowed ${
                status === 'loading'
                  ? 'my-auto disabled:opacity-50 disabled:cursor-not-allowed'
                  : ''
              }`}
              onClick={handleCheckout}
              disabled={!cartNotEmpty || status === 'loading'}
            >
              {status === 'loading' ? (
                <p>
                  <Triangle
                    height="20"
                    width="20"
                    color="#fff"
                    ariaLabel="triangle-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={true}
                  />
                </p>
              ) : (
                'Proceed To Checkout'
              )}
            </Button>
          </>
        ) : (
          <EmptyCartContent />
        )}
      </Section>

      {/* Right  */}
      <Section className="lg:w-[25%] md:mx-[5%] align-center rounded-lg h-[150px] bg-gradient-to-b from-[#191919] via-[rgba(53, 47, 47, 0.00)] to-[#040404] px-[15px] py-[12px]">
        <div className="flex text-white mb-1 text-[13px] lg:text-[18px]">
          <Paragraph>Items </Paragraph>
          <Paragraph className="ml-1 mt-0.5 text-secondaryYellow">
            &nbsp;{cart.totalQuantity || 0}
          </Paragraph>
        </div>
        <div className="flex mt-2 mb-3 text-[13px] lg:text-[18px]">
          <Paragraph className="text-white">Items Total</Paragraph>
          <Paragraph className="md:ml-1 text-secondaryYellow font-[300]">
            &nbsp;{itemsAmount.toLocaleString()}
          </Paragraph>
          <Paragraph className="ml-1 text-secondaryYellow font-[300]">
            {currency}
          </Paragraph>
        </div>
        <div className="flex mt-2 mb-3 text-[13px] lg:text-[18px]">
          <Paragraph className="text-white">Transfer Fee</Paragraph>
          <Paragraph className="md:ml-1 text-secondaryYellow font-[300]">
            &nbsp;{transferFee.toLocaleString()}
          </Paragraph>
          <Paragraph className="ml-1 text-secondaryYellow font-[300]">
            {currency}
          </Paragraph>
        </div>
        <div className="flex mt-2 mb-3 text-[13px] lg:text-[18px]">
          <Paragraph className="text-white">Delivery Fee</Paragraph>
          <Paragraph className="md:ml-1 text-secondaryYellow font-[300]">
            &nbsp;{deliveryAmount.toLocaleString()}
          </Paragraph>
          <Paragraph className="ml-1 text-secondaryYellow font-[300]">
            {currency}
          </Paragraph>
        </div>

        <hr className="border border-[#313131] w-full my-4" />

        <div className="flex mt-2 mb-3 text-[13px] lg:text-[18px]">
          <Paragraph className="text-white">Total</Paragraph>
          <Paragraph className="md:ml-1 text-secondaryYellow font-[300]">
            &nbsp;{totalCheckoutAmount.toLocaleString()}
          </Paragraph>
          <Paragraph className="ml-1 text-secondaryYellow font-[300]">
            {currency}
          </Paragraph>
        </div>
        <Button
          className={`${
            status === 'loading' ? '' : ''
          } lg:mx-auto w-[200px] md:text-[12px] lg:h-[40px] text-white my-auto rounded-md bg-gradient-to-br from-yellow-400 to-gray-700 disabled:opacity-50 disabled:cursor-not-allowed`}
          type="submit"
          disabled={!cartNotEmpty || status === 'loading'}
          onClick={handleCheckout}
        >
          {status === 'loading' ? (
            <p className="flex justify-center">
              <Triangle
                height="20"
                width="20"
                color="#fff"
                ariaLabel="triangle-loading"
                wrapperStyle={{}}
                wrapperClassName=""
                visible={true}
              />
            </p>
          ) : (
            'Proceed To Checkout'
          )}
        </Button>
      </Section>
    </div>
  );
};

export default ViewCartMiddleSection;
