import React from 'react';
import { OverviewContent } from './OverviewContent';
import { UsersContent } from './UsersContent';
import { OrdersContent } from './OrdersContent';
import { FeedbackContent } from './FeedbackContent';
import { SettingsContent } from './SettingsContent';

export const DashboardContent = ({
  selectedOption,
  setSelectedOption,
  className,
}) => {
  const optionComponents = {
    overview: <OverviewContent setSelectedOption={setSelectedOption} />,
    users: <UsersContent />,
    orders: <OrdersContent />,
    feedback: <FeedbackContent />,
    settings: <SettingsContent />,
  };
  return (
    <div className={`${className}`}>
      {selectedOption && optionComponents[selectedOption] ? (
        optionComponents[selectedOption]
      ) : (
        <p>Select an option from the sidebar</p>
      )}
    </div>
  );
};
