import React, { useState, useEffect } from 'react';
import { Button, Heading } from '../common';
import SuggestedProductsCard from './SuggestedProductsCard';
import { getRandomProducts } from '../../services';

const SuggestedProducts = ({
  className,
  products,
  maxVisibleProducts,
  HeadingClassName,
  SeeMoreClassName,
  ProductCardsClassName,
}) => {
  const [showMore, setShowMore] = useState(false);
  const [visibleProducts, setVisibleProducts] = useState([]);
  const [manyVisibleProducts, setManyVisibleProducts] = useState([]);
  const [fewVisibleProducts, setFewVisibleProducts] = useState([]);

  useEffect(() => {
    const initialManyVisibleProducts = getRandomProducts(8, products);
    setManyVisibleProducts(initialManyVisibleProducts);
    setFewVisibleProducts(
      initialManyVisibleProducts.slice(0, maxVisibleProducts),
    );
    setVisibleProducts(initialManyVisibleProducts.slice(0, maxVisibleProducts));
  }, [products, maxVisibleProducts]);

  const toggleShowMore = () => {
    if (showMore !== true) {
      setShowMore(true);
      setVisibleProducts(manyVisibleProducts);
    } else {
      setShowMore(false);
      setVisibleProducts(fewVisibleProducts);
    }
  };

  return (
    <div className="mt-[25%] md:mt-[5%]">
      <Heading
        className={`mx-[10%] md:mx-[100px] my-[50px] font-[400] md:font-[500] text-[25px] md:text-[30px] text-white ${HeadingClassName}`}
      >
        You May Also Like
      </Heading>

      <div className={className}>
        <div
          className={`grid grid-cols-2 md:grid-cols-3 gap-2 ${ProductCardsClassName}`}
        >
          {visibleProducts.map((product, index) => (
            <SuggestedProductsCard key={index} product={product} />
          ))}
        </div>
        {products?.length > maxVisibleProducts && (
          <div className={`${SeeMoreClassName} flex justify-center mt-4`}>
            <Button
              className="my-5 border border-[#C38D01] rounded-md md:px-4 py-2 shadow-md md:w-[150px] md:h-[40px]"
              onClick={toggleShowMore}
            >
              <span className="text-[#C38D01]">
                {showMore ? 'See Less' : 'See More'}
              </span>
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default SuggestedProducts;
