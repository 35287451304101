import axios from 'axios';
import { ADMINTOKEN, BASE_URL } from '../../../utils/apiUtils';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

export const ViewWishlist = createAsyncThunk(
  'view-wishlist',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${BASE_URL}api/wishlist/all`, {
        headers: {
          Authorization: `Bearer ${ADMINTOKEN}`,
          'Content-Type': 'application/json',
        },
      });

      return response.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const UpdateWishlist = createAsyncThunk(
  'update-wishlist',
  async ({ wishlistId, quantity }, { rejectWithValue }) => {
    try {
      const response = await axios.patch(
        `${BASE_URL}api/wishlist/update/${wishlistId}`,
        { quantity },
        {
          headers: {
            Authorization: `Bearer ${ADMINTOKEN}`,
            'Content-Type': 'application/json',
          },
        },
      );

      return response.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const AddToWishlist = createAsyncThunk(
  'add-to-wishlist',
  async ({ productId, quantity }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${BASE_URL}api/wishlist/add/${productId}`,
        { quantity },
        {
          headers: {
            Authorization: `Bearer ${ADMINTOKEN}`,
            'Content-Type': 'application/json',
          },
        },
      );

      return response.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const AddWishlistToCart = createAsyncThunk(
  'add-wishlist-to-cart',
  async (wishlistIds, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${BASE_URL}api/wishlist/add-cart`,
        { wishlistIds },
        {
          headers: {
            Authorization: `Bearer ${ADMINTOKEN}`,
            'Content-Type': 'application/json',
          },
        },
      );

      return response.data.itemsAddedToCart;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const DeleteWishlistItem = createAsyncThunk(
  'delete-wishlist-item',
  async (wishlistId, { rejectWithValue }) => {
    try {
      const response = await axios.delete(
        `${BASE_URL}api/wishlist/delete/${wishlistId}`,
        {
          headers: {
            Authorization: `Bearer ${ADMINTOKEN}`,
            'Content-Type': 'application/json',
          },
        },
      );

      return response.data.wishlistId;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const ClearWishlist = createAsyncThunk(
  'clear-wishlist',
  async (wishlistIds, { rejectWithValue }) => {
    try {
      const response = await axios.delete(`${BASE_URL}api/wishlist/delete`, {
        data: {
          wishlistIds,
        },
        headers: {
          Authorization: `Bearer ${ADMINTOKEN}`,
          'Content-Type': 'application/json',
        },
      });
      return response.data.wishlistIds;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

const WishlistSlice = createSlice({
  name: 'Wishlist',
  initialState: {
    status: null,
    error: null,
    data: [],
    deletedWishlistId: null,
    deletedWishlistIds: [],
    addedToCartIds: [],
  },

  extraReducers: {
    [ViewWishlist.pending]: (state) => {
      state.status = 'loading';
    },
    [ViewWishlist.fulfilled]: (state, action) => {
      state.data = action.payload;
      state.status = 'success';
    },
    [ViewWishlist.rejected]: (state, action) => {
      state.status = 'failed';
      state.Error = action.payload;
    },
    [UpdateWishlist.pending]: (state) => {
      state.updateWishlistStatus = 'loading';
    },
    [UpdateWishlist.fulfilled]: (state, action) => {
      state.data = action.payload;
      state.updateWishlistStatus = 'success';
    },
    [UpdateWishlist.rejected]: (state, action) => {
      state.updateWishlistStatus = 'failed';
      state.Error = action.payload;
    },
    [AddToWishlist.pending]: (state) => {
      state.status = 'loading';
    },
    [AddToWishlist.fulfilled]: (state, action) => {
      state.data = action.payload;
      state.status = 'success';
    },
    [AddToWishlist.rejected]: (state, action) => {
      state.status = 'failed';
      state.Error = action.payload;
    },
    [DeleteWishlistItem.pending]: (state) => {
      state.status = 'loading';
    },
    [DeleteWishlistItem.fulfilled]: (state, action) => {
      state.deletedWishlistId = action.payload;
      state.status = 'success';
      state.data = state.data.filter(
        (product) => product._id !== action.payload,
      );
    },
    [DeleteWishlistItem.rejected]: (state, action) => {
      state.status = 'failed';
      state.Error = action.payload;
    },
    [ClearWishlist.pending]: (state) => {
      state.status = 'loading';
    },
    [ClearWishlist.fulfilled]: (state, action) => {
      state.deletedWishlistIds = action.payload;
      state.status = 'success';
      state.data = state.data.filter(
        (product) => !action.payload.includes(product._id),
      );
    },
    [ClearWishlist.rejected]: (state, action) => {
      state.status = 'failed';
      state.Error = action.payload;
    },
    [AddWishlistToCart.pending]: (state) => {
      state.status = 'loading';
    },
    [AddWishlistToCart.fulfilled]: (state, action) => {
      state.addedToCartIds = action.payload;
      state.status = 'success';
      state.data = state.data.filter(
        (product) => !action.payload.includes(product._id),
      );
    },
    [AddWishlistToCart.rejected]: (state, action) => {
      state.status = 'failed';
      state.Error = action.payload;
    },
  },
});

export default WishlistSlice.reducer;
