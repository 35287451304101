import React from 'react';

const PhoneLoader = () => {
  return (
    <div className="flex justify-center items-center h-60 mt-2">
      <div className="border-t-4 border-latestYellow rounded-full w-14 h-14 animate-spin"></div>
    </div>
  );
};

export default PhoneLoader;
