import axios from 'axios';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ADMINTOKEN, BASE_URL } from '../../../utils/apiUtils';

export const GetAdminOverviewStats = createAsyncThunk(
  'adminOverviewStats',
  async ({ week, month, quarter, half }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${BASE_URL}api/analytics/admin/overview`,
        { week, month, quarter, half },
        {
          headers: {
            Authorization: `Bearer ${ADMINTOKEN}`,
            'Content-Type': 'application/json',
          },
        },
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const GetAverageOrderFrequency = createAsyncThunk(
  'averageOrderFrequency',
  async ({ week, month, quarter, half }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${BASE_URL}api/analytics/orders/get-aof`,
        { week, month, quarter, half },
        {
          headers: {
            Authorization: `Bearer ${ADMINTOKEN}`,
            'Content-Type': 'application/json',
          },
        },
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

const AdminDashboardSlice = createSlice({
  name: 'AdminDashboard',
  initialState: {
    status: null,
    error: null,
    data: null,
    aof: null,
  },

  extraReducers: {
    [GetAdminOverviewStats.pending]: (state) => {
      state.status = 'loading';
    },
    [GetAdminOverviewStats.fulfilled]: (state, action) => {
      state.data = action.payload;
      state.status = 'success';
    },
    [GetAdminOverviewStats.rejected]: (state, action) => {
      state.status = 'failed';
      state.Error = action.payload;
    },
    [GetAverageOrderFrequency.pending]: (state) => {
      state.status = 'loading';
    },
    [GetAverageOrderFrequency.fulfilled]: (state, action) => {
      state.aof = action.payload;
      state.status = 'success';
    },
    [GetAverageOrderFrequency.rejected]: (state, action) => {
      state.status = 'failed';
      state.Error = action.payload;
    },
  },
});

export default AdminDashboardSlice.reducer;
