import React, { useEffect, useState } from 'react';
import { Heading, Section } from '../../common';
import { PiBellThin } from 'react-icons/pi';
import { CiSearch } from 'react-icons/ci';
import StatsCard from '../Shared/StatsCard';
import TopXStatsCard from './TopXStatsCard';
import ReChartAreaGraph from './ReChartAreaGraph';
import { TableBody } from './TableBody';
import { GoSidebarExpand, GoSidebarCollapse } from 'react-icons/go';
import { IoOptions } from 'react-icons/io5';
import { useDispatch, useSelector } from 'react-redux';
import { GetVendorOverviewStats } from '../../../Redux/Features/Dashboards/VendorDashboardSlice';
import { IoIosArrowDown } from 'react-icons/io';
import {
  BsCalendar2Day,
  BsCalendar2Month,
  BsCalendar2Week,
} from 'react-icons/bs';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { getLoggedInUserDetails } from '../../../utils/user';

const OverviewContent = ({ setShowSidebar, showSidebar }) => {
  let dashboardData =
    useSelector((state) => state?.vendorDashboard?.data) || [];
  let isLoading = useSelector((state) => state?.vendorDashboard?.status) || [];

  const [totalOrders, setTotalOrders] = useState('--');
  const [revenue, setRevenue] = useState('--');
  const [annualRevenueGraphData, setAnnualRevenueGraphData] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [customerCount, setCustomerCount] = useState('--');
  const [recentOrders, setRecentOrders] = useState([]);
  const [topSellingProducts, setTopSellingProducts] = useState([]);
  const [isOverviewFetched, setIsOverviewFetched] = useState(false);
  const [selectedTime, setSelectedTime] = useState('All time');
  const [isTimeDropdownOpen, setIsTimeDropdownOpen] = useState(false);
  const [percentageChangeOrders, setPercentageChangeOrders] = useState(0);
  const [percentageChangeCustomers, setPercentageChangeCustomers] = useState(0);
  const [percentageChangeRevenue, setPercentageChangeRevenue] = useState(0);

  const dispatch = useDispatch();

  const handleSidebarToggle = () => {
    setShowSidebar((prev) => !prev);
  };

  const toggleTimeDropdown = () => {
    setIsTimeDropdownOpen(!isTimeDropdownOpen);
  };

  useEffect(() => {
    dispatch(GetVendorOverviewStats({}))
      .unwrap()
      .then(() => {
        setIsOverviewFetched(true);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    if (isOverviewFetched) {
      setTotalOrders(dashboardData?.totalOrders);
      setRevenue(dashboardData?.totalRevenue);
      setAnnualRevenueGraphData(dashboardData?.annualRevenueGraphData);
      setCustomers(dashboardData?.customers);
      setCustomerCount(dashboardData?.totalCustomers);
      setRecentOrders(dashboardData?.recentOrders);
      setTopSellingProducts(dashboardData?.topSellingProducts);
      setPercentageChangeOrders(dashboardData?.percentageChangeOrders);
      setPercentageChangeCustomers(dashboardData?.percentageChangeCustomers);
      setPercentageChangeRevenue(dashboardData?.percentageChangeRevenue);
    }
    setIsOverviewFetched(false);
  }, [isOverviewFetched]);

  const handleTimeSelection = (selection) => {
    setSelectedTime(selection);
    setIsTimeDropdownOpen(false);
  };

  const getOverviewStats = async (durationFilter) => {
    await dispatch(GetVendorOverviewStats({ durationFilter: durationFilter }))
      .unwrap()
      .then(() => {
        setIsOverviewFetched(true);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const getTimeString = (selectedTime) => {
    switch (selectedTime) {
      case 'Last 12 Months':
        return 'Last 12 months';
      case 'Last 6 Months':
        return 'Last 6 months';
      case 'Last 3 Months':
        return 'This quarter';
      case 'This Month':
        return 'This month';
      case 'This Week':
        return 'This week';
      default:
        return 'All Time';
    }
  };

  const { firstName, lastName } = getLoggedInUserDetails();

  const toggleSidebarIconStyle =
    'w-5 h-5 cursor-pointer hover:text-latestYellow mt-1 hidden lg:block';
  return (
    <div className={`my-3 ${!showSidebar && 'ml-[2%]'}`}>
      <Section className="w-[100%] flex flex-col-reverse lg:flex-row lg:justify-between mt-[2%]">
        <div className="flex gap-2">
          <Section>
            {showSidebar ? (
              <GoSidebarExpand
                className={toggleSidebarIconStyle}
                onClick={handleSidebarToggle}
              />
            ) : (
              <GoSidebarCollapse
                className={toggleSidebarIconStyle}
                onClick={handleSidebarToggle}
              />
            )}
          </Section>
          <Section>
            <Heading className="text-2xl">
              {' '}
              Welcome Back, {firstName || lastName || 'vendor'}!{' '}
            </Heading>
            <p className="text-[#5f5f5f] text-sm pt-1">
              Here is what's happening in your store today.{' '}
            </p>
          </Section>
        </div>
        <div className="flex justify-between mb-5 lg:mb-0">
          <IoOptions
            className={`text-xl hover:text-latestYellow cursor-pointer block lg:hidden`}
            onClick={handleSidebarToggle}
          />
          <div className="flex flex-row gap-5 my-auto">
            <CiSearch className="text-xl hover:text-latestYellow cursor-pointer" />
            <PiBellThin className="text-xl hover:text-latestYellow cursor-pointer" />

            <vr className="border-[0.5px] border-[#5f5f5f] h-6" />

            <div className="flex gap-2 hover:text-latestYellow cursor-pointer">
              <img
                src={
                  'https://images.unsplash.com/photo-1533711539834-ebcee9ed4975?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTcwfHxwb3J0cmFpdHxlbnwwfHwwfHx8MA%3D%3D' ||
                  ''
                }
                alt="vendor_profile_picture"
                className="w-8 h-8 border-[0.5px] border-[#1d1d1d] object-cover rounded-full mt-[-4px]"
              />
              <p className="max-w-[160px] truncate hidden md:block">
                {firstName} {lastName}
              </p>
            </div>
          </div>
        </div>
      </Section>

      <Section className="flex flex-col xl:flex-row my-3 mt-8 gap-3">
        <Section className="w-full">
          <div className="flex flex-col relative">
            <div className="mb-2 text-sm mr-[3%] lg:mr-0">
              <button
                onClick={toggleTimeDropdown}
                className="ml-auto flex items-center px-2 border-[2px] border-[#1d1d1d] rounded-md gap-x-2 text-sm"
              >
                <span>{selectedTime}</span>
                <IoIosArrowDown />
              </button>

              {isTimeDropdownOpen && (
                <div className="absolute flex flex-col mt-2 w-52 bg-[#404040] right-0 justify-center text-center rounded-xl shadow-lg z-[1000]">
                  <button
                    onClick={async () => {
                      handleTimeSelection('Last 12 Months');
                      await getOverviewStats('last12months');
                    }}
                    className="hover:bg-[#181717] px-8 rounded-t-xl flex items-center gap-x-2 py-1.5"
                  >
                    <BsCalendar2Month />
                    <span>Last 12 Months</span>
                  </button>
                  <button
                    onClick={async () => {
                      handleTimeSelection('Last 6 Months');
                      await getOverviewStats('last6months');
                    }}
                    className="hover:bg-[#181717] px-8 flex items-center gap-x-2 py-1.5"
                  >
                    <BsCalendar2Month />
                    <span>Last 6 Months</span>
                  </button>
                  <button
                    onClick={async () => {
                      handleTimeSelection('Last 3 Months');
                      await getOverviewStats('last3months');
                    }}
                    className="hover:bg-[#181717] px-8 flex items-center gap-x-2 py-1.5"
                  >
                    <AiOutlineLoading3Quarters />

                    <span>Last quarter</span>
                  </button>
                  <button
                    onClick={async () => {
                      handleTimeSelection('This Month');
                      await getOverviewStats('last1month');
                    }}
                    className="hover:bg-[#181717] px-8 flex items-center gap-x-2 py-1.5"
                  >
                    <BsCalendar2Week />
                    <span>Last Month</span>
                  </button>
                  <button
                    onClick={async () => {
                      handleTimeSelection('This Week');
                      await getOverviewStats('last7days');
                    }}
                    className="hover:bg-[#181717] px-8 rounded-b-xl flex items-center gap-x-2 py-1.5"
                  >
                    <BsCalendar2Day />
                    <span>Last Week</span>
                  </button>
                </div>
              )}
            </div>
            <div className="flex flex-col md:flex-row align-center lg:mx-0 gap-3 justify-between">
              <StatsCard
                amount={customerCount}
                subHeading="Total Customers"
                percentage={parseInt(percentageChangeCustomers)}
                durationText={getTimeString(selectedTime)}
                increase={
                  parseInt(percentageChangeCustomers) > 0 ? true : false
                }
              />
              <StatsCard
                amount={revenue}
                currency="RWF"
                subHeading="Total Revenue"
                percentage={parseInt(percentageChangeRevenue)}
                durationText={getTimeString(selectedTime)}
                increase={parseInt(percentageChangeRevenue) > 0 ? true : false}
              />
              <StatsCard
                amount={totalOrders}
                subHeading="Total Orders"
                percentage={parseInt(percentageChangeOrders)}
                durationText={getTimeString(selectedTime)}
                increase={parseInt(percentageChangeOrders) > 0 ? true : false}
              />
            </div>
          </div>

          <div className="flex flex-col my-8">
            <Heading className="text-lg">Earnings(RWF)</Heading>
            {isLoading === 'loading' ? (
              <p className="mt-5 md:mt-2 text-[#5f5f5f]">Loading...</p>
            ) : (
              <ReChartAreaGraph data={annualRevenueGraphData} />
            )}
          </div>

          <div>
            <Heading className={`${!showSidebar && 'mx-auto'} text-lg pb-2`}>
              Top selling products
            </Heading>
            <div>
              {isLoading === 'loading' ? (
                <p className="mt-5 md:mt-2 text-[#5f5f5f]">Loading...</p>
              ) : (
                <TableBody products={topSellingProducts} />
              )}
            </div>
          </div>
        </Section>

        <Section className="mx-1">
          <TopXStatsCard
            title="Top Customers"
            data={customers}
            className="my-[10%] lg:my-0 lg:mb-[5%]"
            isLoading={isLoading === 'loading' ? true : false}
            // handleSeeAllClick={''}
          />
          <TopXStatsCard
            title="Recent Orders"
            data={recentOrders}
            className="mb-[5%] lg:mb-[5%] mt-5"
            // handleSeeAllClick={''}
          />
        </Section>
      </Section>
    </div>
  );
};

export default OverviewContent;
