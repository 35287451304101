import { Link } from 'react-router-dom';
import { SufuriLogo } from '../../utils';
import { Image } from '../common';

const GetStartedNav = () => {
  return (
    <nav className="bg-[#040404]">
      <div className="max-w-7xl mx-auto px-4 mt-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16">
          <div className="flex-shrink-0">
            <Link to="/">
              <div className="flex items-center">
                <Image
                  className="w-[90px] md:w-[90px] bg-transparent opacity-60"
                  style={{ height: 'auto', marginRight: '-10px' }}
                  src={SufuriLogo}
                  alt="sufuri-logo"
                />
                <span className="text-latestYellow text-[18px] md:text-3xl logo-text">
                  SufuriMart
                </span>
              </div>
            </Link>
          </div>
          <div className="flex items-center">
            <div className="ml-4 text-[#c9c9c9ea] hidden md:hidden lg:block xl:block">
              <Link to="/about-us">About Us</Link>
            </div>
            <div className="ml-4">
              <Link to="/auth/login">
                <button
                  type="button"
                  className="border-2 border-[#9d71005f] bg-[#2A2A2A] hover:bg-[#807e7e39] rounded-[60px] w-[100px] md:w-[150px] h-[40px] md:h-[50px] text-base text-[#c9c9c9ea]"
                >
                  Sign in
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default GetStartedNav;
