import axios from 'axios';
import { ADMINTOKEN, BASE_URL } from '../../../utils/apiUtils';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

export const RequestSample = createAsyncThunk(
  'requestSample',
  async ({ formData }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${BASE_URL}api/samples/request/`,
        {
          notes: formData.notes,
          product: formData.product,
          deliveryDate: formData.deliveryDate,
          location: {
            deliveryOption: formData.deliveryOption,
            address: formData.address,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${ADMINTOKEN}`,
            'Content-Type': 'application/json',
          },
        },
      );

      return response.data.newRequest;
    } catch (error) {
      if (error.response.status === 422) {
        return rejectWithValue(error.response.data.errors[0].message);
      } else {
        return rejectWithValue(error.response.data.message);
      }
    }
  },
);

export const GetOwnerSamples = createAsyncThunk(
  'getOwnerSamples',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${BASE_URL}api/samples/request/user`, {
        headers: {
          Authorization: `Bearer ${ADMINTOKEN}`,
          'Content-Type': 'application/json',
        },
      });

      return response.data;
    } catch (error) {
      if (error.response.status === 404) {
        return rejectWithValue(error.response.data.errors[0].message);
      } else {
        return rejectWithValue(error.response.data.message);
      }
    }
  },
);

export const DeleteOwnerSample = createAsyncThunk(
  'deleteOwnerSample',
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.delete(
        `${BASE_URL}api/samples/request/${id}`,
        {
          headers: {
            Authorization: `Bearer ${ADMINTOKEN}`,
            'Content-Type': 'application/json',
          },
        },
      );

      return response.data.fabricRequestId;
    } catch (error) {
      if (error.response.status === 404) {
        return rejectWithValue(error.response.data.errors[0].message);
      } else {
        return rejectWithValue(error.response.data.message);
      }
    }
  },
);

const SampleSlice = createSlice({
  name: 'Sample',
  initialState: {
    status: null,
    error: null,
    data: [],
    deletedSampleId: null,
  },

  extraReducers: {
    [RequestSample.pending]: (state) => {
      state.status = 'loading';
    },
    [RequestSample.fulfilled]: (state, action) => {
      state.data = action.payload;
      state.status = 'success';
    },
    [RequestSample.rejected]: (state, action) => {
      state.status = 'failed';
      state.Error = action.payload;
    },
    [GetOwnerSamples.pending]: (state) => {
      state.status = 'loading';
    },
    [GetOwnerSamples.fulfilled]: (state, action) => {
      state.data = action.payload;
      state.status = 'success';
    },
    [GetOwnerSamples.rejected]: (state, action) => {
      state.status = 'failed';
      state.Error = action.payload;
    },
    [DeleteOwnerSample.pending]: (state) => {
      state.status = 'loading';
    },
    [DeleteOwnerSample.fulfilled]: (state, action) => {
      state.deletedSampleId = action.payload;
      state.status = 'success';
      state.data = state.data.filter((sample) => sample._id !== action.payload);
    },
    [DeleteOwnerSample.rejected]: (state, action) => {
      state.status = 'failed';
      state.Error = action.payload;
    },
  },
});

export default SampleSlice.reducer;
