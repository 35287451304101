import { Container, Header, BodyProduct, Footer } from '../../components';

function AddProduct() {
  return (
    <Container>
      <div className="min-h-screen">
        <Header />
        <BodyProduct />
        <Footer />
      </div>
    </Container>
  );
}
export default AddProduct;
