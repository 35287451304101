import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Container, Heading, Paragraph, Section } from '../common';
import { HomeStitchingDeals } from '../products';
import { useSelector } from 'react-redux';
import HomeSuggestedProductsLoaders from '../skeletons/HomeSuggestedProductsLoaders';

const BuyNowSection = () => {
  const products = useSelector((state) => state.product.data) || [];
  const [fetchedProducts, setFetchedProducts] = useState([products]);

  return (
    <Container>
      <Heading className="text-[#9D7100] text-[25px] mt-[50px] flex justify-center logo-text">
        Stitching Steals & Deals
      </Heading>
      <Section className="flex flex-col text-white md:flex-row">
        <Section className="md:w-[50%]">
          <div className="bg-cover h-[36vh] md:h-[91vh] relative rounded-[30px] md:w-[80%] mx-[10px] md:mx-[50px] my-10 bg-center bg-no-repeat bg-[url('https://res.cloudinary.com/dlw8ohn6p/image/upload/v1689506398/tape-scissors-threads_fkslws.png')] bg-opacity-50">
            <div className="flex flex-col gap-4 mx-8 pt-[5%] md:pt-[50%]">
              <Heading className="text-[25px] font-[500]">
                Simple can be bold.
              </Heading>
              <Paragraph className="text-[13px] font-[200]">
                SufuriMart provides just the right quality.
              </Paragraph>
              <Link to="/product/browse-products">
                <Button className="bg-white max-w-[200px] cursor-pointer rounded-[8px]">
                  <span className="text-[#c1b336] text-[14px] mb-3 uppercase">
                    Browse Products
                  </span>
                </Button>
              </Link>
            </div>
          </div>
        </Section>
        <Section>
          {fetchedProducts?.length === 0 ? (
            <div className="mt-[-40px]">
              {/* <div> */}
              <HomeSuggestedProductsLoaders maxVisibleProducts={4} />
            </div>
          ) : (
            <HomeStitchingDeals
              maxVisibleProducts={4}
              className="mx-2 my-10 md:mx-0"
              products={products}
              SeeMoreClassName="hidden"
              ProductCardsClassName="xl:gap-[50px] gap-4 grid-cols-2 grid-rows-2"
            />
          )}
        </Section>
      </Section>
    </Container>
  );
};

export default BuyNowSection;
