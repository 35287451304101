import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { BASE_URL } from '../../../utils/apiUtils';

export const login = createAsyncThunk(
  'api/users/signin',
  async (login, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${BASE_URL}api/users/signin`, login);
      if (response.data) {
        localStorage.setItem('firstName', response.data.user.firstname);
        localStorage.setItem('lastName', response.data.user.lastname);

        localStorage.setItem('address', response.data.user.address);
        localStorage.setItem('phoneNumber', response.data.user.phoneNumber);
        localStorage.setItem('city', response.data.user.city);
        localStorage.setItem('country', response.data.user.country);

        localStorage.setItem('email', response.data.user.email);
        localStorage.setItem('token', response.data.user.token);
        localStorage.setItem('role', response.data.user.role);
      }
      const { email, password } = login;
      return {
        ...response.data,
        credentials: {
          email,
          password,
        },
      };
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const logout = createAsyncThunk(
  'auth/logout',
  async (_, { dispatch }) => {
    try {
      localStorage.removeItem('email');
      localStorage.removeItem('token');
      localStorage.removeItem('role');
      localStorage.removeItem('firstName');
      localStorage.removeItem('lastName');
      localStorage.removeItem('city');
      localStorage.removeItem('address');
      localStorage.removeItem('country');
      localStorage.removeItem('phoneNumber');

      dispatch({ type: 'auth/logout', payload: null });
    } catch (error) {
      console.error('Logout error:', error);
    }
  },
);

const loginSlice = createSlice({
  name: 'login',
  initialState: {
    users: [],
    status: null,
    Error: null,
  },
  extraReducers: {
    [login.pending]: (state) => {
      state.status = 'loading';
    },
    [login.fulfilled]: (state, action) => {
      state.user = action.payload;
      state.status = 'success';
    },
    [login.rejected]: (state, action) => {
      state.status = 'failed';
      state.Error = action.payload;
    },
    logout: (state) => {
      state.users = [];
      state.status = null;
      state.Error = null;
    },
  },
});

export default loginSlice.reducer;
