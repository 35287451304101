import React from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../components/common/Buttons/Button';
import { Section } from '../components';

export default function Forbidden() {
  const navigate = useNavigate();

  return (
    <Section className="grid min-h-screen pt-20 text-white bg-black place-items-center">
      <h1 className="sm:text-[250px]/[250px] text-8xl font-bold !text-transparent bg-clip-text bg-gradient-to-r from-[#a27c4f] via-[#d6bd8f] to-[#a27c4f]">
        403
      </h1>
      <p className="text-2xl text-center text-white">
        Sorry, you are not authorized to access this page. You can try going:
      </p>
      <div className="flex items-center mt-1 space-x-8">
        <Button
          className="sm:w-40 sm:h-11 w-32 bg-gradient-to-r text-xs sm:text-sm h-7 from-[#ffcc00] via-[#e6b608c7] to-[#4c3c03] rounded-lg"
          onClick={() => navigate('/')}
        >
          GO HOME
        </Button>
      </div>
    </Section>
  );
}
