import React, { useCallback, useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';

import { Navigation, Pagination, FreeMode } from 'swiper/modules';
import { RiShoppingCart2Fill, RiShoppingCart2Line } from 'react-icons/ri';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/free-mode';
import 'swiper/css/scrollbar';
import '../../../src/index.css';

import { Image } from '../common';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import {
  AddToCart,
  RemoveProductFromCart,
  ViewCart,
} from '../../Redux/Features/Cart/cartSlice';
import ProductSliderLoader from '../skeletons/ProductSliderLoader';
import { LoginPopup } from '../modal';
import { PushNotification } from '../Notifications';
import { Triangle } from 'react-loader-spinner';
import { FaHeartCircleCheck } from 'react-icons/fa6';
import { FiHeart } from 'react-icons/fi';
import {
  AddToWishlist,
  DeleteWishlistItem,
  ViewWishlist,
} from '../../Redux/Features/Wishlist/wishlistSlice';

const ProductSlider = ({ className }) => {
  const dispatch = useDispatch();
  const products = useSelector((state) => state.product.data) || [];

  const [isLoading, setIsLoading] = useState({});
  const [showLoginPopup, setShowLoginPopup] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [isAddedToCart, setIsAddedToCart] = useState(false);
  const [isLargeScreen, setIsLargeScreen] = useState(false);
  const [isLoadingWishlist, setIsLoadingWishlist] = useState(false);
  const [isCartSelected, setIsCartSelected] = useState(false);

  const wishlistProducts = useSelector((state) => state?.wishlist?.data) || [];
  const cart = useSelector((state) => state?.cart?.data) || [];
  const cartProducts =
    useSelector((state) => state?.cart?.data?.cartProducts) || [];

  const reducedProducts = products.slice(0, 7);

  useEffect(() => {
    if (cartProducts?.length === 0 || cartProducts?.length === undefined) {
      dispatch(ViewCart());
    }
  }, [cart, cartProducts, dispatch]);

  useEffect(() => {
    if (window.innerWidth > 768) {
      setIsLargeScreen(true);
    }
  }, []);

  const handleAddToCart = async (productId) => {
    setIsCartSelected(true);
    const isLoggedIn = localStorage.getItem('token');
    if (!isLoggedIn) {
      setShowLoginPopup(true);
      return;
    }

    setIsLoading((prevLoading) => ({ ...prevLoading, [productId]: true }));

    try {
      const isProductInCart = cartProducts.some(
        (cartProduct) => cartProduct.product._id === productId,
      );

      if (isProductInCart) {
        setIsAddedToCart(false);
        await dispatch(RemoveProductFromCart({ id: productId }));
      } else {
        setIsAddedToCart(true);
        await dispatch(AddToCart({ productId }));
      }

      await dispatch(ViewWishlist());
      await dispatch(ViewCart());
      setShowNotification(true);
    } catch (error) {
      console.error('Error adding/removing from cart:', error);
    } finally {
      setIsLoading((prevLoading) => ({ ...prevLoading, [productId]: false }));
    }
  };

  const handleCloseNotification = () => {
    setShowNotification(false);
  };

  const handleAddToWishlist = async (productId) => {
    setIsCartSelected(false);
    const isLoggedIn = localStorage.getItem('token');
    if (!isLoggedIn) {
      setShowLoginPopup(true);
      return;
    }

    setIsLoadingWishlist((prevLoading) => ({
      ...prevLoading,
      [productId]: true,
    }));

    try {
      const isProductInWishlist = wishlistProducts.find(
        (wishlistProduct) => wishlistProduct?.product === productId,
      );

      if (isProductInWishlist) {
        await dispatch(DeleteWishlistItem(isProductInWishlist._id));
      } else {
        await dispatch(AddToWishlist({ productId, quantity: 1 }));
      }

      await dispatch(ViewCart());
      await dispatch(ViewWishlist());
    } catch (error) {
      console.error('Error adding/removing from wishlist:', error);
    } finally {
      setIsLoadingWishlist((prevLoading) => ({
        ...prevLoading,
        [productId]: false,
      }));
    }
  };

  return (
    <div className={className}>
      {products?.length === 0 ? (
        <ProductSliderLoader isLargeScreen={isLargeScreen} />
      ) : (
        <Swiper
          breakpoints={{
            320: {
              slidesPerView: 2,
              spaceBetween: 0,
            },

            500: {
              slidesPerView: 2,
              spaceBetween: 0,
            },

            760: {
              slidesPerView: 2,
              spaceBetween: 10,
            },

            1024: {
              slidesPerView: 2,
              spaceBetween: 15,
            },
          }}
          modules={[Navigation, Pagination, FreeMode, Autoplay]}
          autoplay={{
            delay: 3000,
            stopOnLastSlide: false,
            disableOnInteraction: false,
          }}
          // navigation={isLargeScreen ? { clickable: true } : false}

          pagination={{ clickable: true }}
          centeredSlides={true}
          autoplayStopOnInteraction={false}
          navigationStopOnInteraction={false}
          overflow="hidden"
          className="cursor-pointer"
        >
          {reducedProducts.map((product, index) => (
            <SwiperSlide key={index} className="gap-5">
              <div className="border border-primaryYellow mx-auto w-[95%] md:max-w-[280px] rounded-md mb-8 md:mb-10 flex flex-col pb-3 bg-[#252523]">
                <div className="relative">
                  <Link to={`/product/view/${product._id}`}>
                    <Image
                      src={product?.imageUrl[0]}
                      className="object-cover w-full h-64 rounded-md rounded-b-none"
                      alt={`slide_image${index}`}
                    />
                    <p className="text-[15px] px-2 md:text-[18px] pt-2 text-[#A09431] items-center justify-center text-center truncate">
                      {product?.name}
                    </p>
                  </Link>
                  <div
                    className="absolute flex items-center justify-center p-2 rounded-full right-2 bottom-10"
                    style={{ boxShadow: '0px 0px 10px' }}
                  >
                    <button onClick={() => handleAddToWishlist(product?._id)}>
                      {isLoadingWishlist[product?._id] ? (
                        <p>
                          <Triangle
                            height="20"
                            width="20"
                            color="#F0B00A"
                            ariaLabel="triangle-loading"
                            wrapperStyle={{}}
                            wrapperClassName=""
                            visible={true}
                          />
                        </p>
                      ) : (
                        <>
                          {Array.isArray(wishlistProducts) &&
                          wishlistProducts.find(
                            (wishlistProduct) =>
                              wishlistProduct?.product === product?._id,
                          ) ? (
                            <FaHeartCircleCheck className="w-5 h-5 text-latestYellow" />
                          ) : (
                            <FiHeart className="w-5 h-5 text-latestYellow" />
                          )}
                        </>
                      )}
                    </button>
                  </div>
                </div>

                <div className="flex pt-[6px] justify-between">
                  <p className="md:text-[14px] ml-[10%] md:ml-[26%] xl:ml-[15%] my-auto text-[12px] text-white">
                    {product?.unitPriceBeforeProfit?.toLocaleString()} FRW /
                    meter
                  </p>
                  <div
                    className="my-auto mr-[10%] cursor-pointer text-[#F0B00A]"
                    onClick={() => handleAddToCart(product._id)}
                  >
                    {isLoading[product._id] ? (
                      <p>
                        <Triangle
                          height="20"
                          width="20"
                          color="#F0B00A"
                          ariaLabel="triangle-loading"
                          wrapperStyle={{}}
                          wrapperClassName=""
                          visible={true}
                        />
                      </p>
                    ) : (
                      <>
                        {cartProducts.find(
                          (cartProduct) =>
                            cartProduct.product._id === product._id,
                        ) ? (
                          <RiShoppingCart2Fill className="w-5 h-5 md:w-6 md:h-6" />
                        ) : (
                          <RiShoppingCart2Line className="w-5 h-5 md:w-6 md:h-6" />
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      )}
      {showLoginPopup && (
        <LoginPopup
          message={`Please login first to add items to the ${
            isCartSelected ? 'cart' : 'wishlist'
          }`}
          onClose={() => setShowLoginPopup(false)}
        />
      )}
      {showNotification && (
        <PushNotification
          message={`${
            isAddedToCart ? 'Added to the' : 'Removed from the'
          }   cart!`}
          onClose={handleCloseNotification}
        />
      )}
    </div>
  );
};

export default ProductSlider;
