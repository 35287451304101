import {
  FaHome,
  FaChartBar,
  FaClipboardList,
  FaUsers,
  FaShoppingCart,
} from 'react-icons/fa';
import {
  RiSettings3Line,
  RiQuestionLine,
  RiUserSettingsLine,
} from 'react-icons/ri';
import { MdDashboard } from 'react-icons/md';

const SidebarUpperOptions = {
  overview: {
    title: 'Overview',
    // icon: <FaHome />,
    icon: <MdDashboard />,
  },
  analytics: {
    title: 'Analytics',
    icon: <FaChartBar />,
  },
  orders: {
    title: 'Orders',
    icon: <FaClipboardList />,
  },
  customers: {
    title: 'Customers',
    icon: <FaUsers />,
  },
  shop: {
    title: 'Shop',
    icon: <FaShoppingCart />,
  },
};

const SidebarToolsOptions = {
  // Not being used
  settings: {
    title: 'Settings',
    icon: <RiSettings3Line />,
  },
  help: {
    title: 'Help',
    icon: <RiQuestionLine />,
  },
  //   manageUser: {
  //     title: 'Manage User',
  //     icon: <RiUserSettingsLine />,
  //   },
};

const TopCustomers = [
  {
    name: 'Robert Mugabo',
    imageUrl:
      'https://images.unsplash.com/photo-1531384441138-2736e62e0919?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NzV8fHBvcnRyYWl0fGVufDB8fDB8fHww',
    purchasesCount: 26,
    totalPurchasesAmount: 576000,
  },
  {
    name: 'Nicole Taylor',
    imageUrl:
      'https://images.unsplash.com/photo-1534528741775-53994a69daeb?q=80&w=2864&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    purchasesCount: 24,
    totalPurchasesAmount: 454600,
  },
  {
    name: 'Daniel',
    imageUrl:
      'https://plus.unsplash.com/premium_photo-1674777843175-fa95bed1f577?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTc3fHxwb3J0cmFpdHxlbnwwfHwwfHx8MA%3D%3D',
    purchasesCount: 20,
    totalPurchasesAmount: 421500,
  },
  {
    name: 'James Green',
    imageUrl:
      'https://images.unsplash.com/photo-1506794778202-cad84cf45f1d?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8cG9ydHJhaXR8ZW58MHx8MHx8fDA%3D',
    purchasesCount: 16,
    totalPurchasesAmount: 210900,
  },
  {
    name: 'Tricia',
    imageUrl:
      'https://images.unsplash.com/photo-1632765854612-9b02b6ec2b15?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NDR8fHBvcnRyYWl0fGVufDB8fDB8fHww',
    purchasesCount: 7,
    totalPurchasesAmount: 90000,
  },
  {
    name: 'John Doe',
    imageUrl:
      'https://images.unsplash.com/photo-1500648767791-00dcc994a43e?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTZ8fHBvcnRyYWl0fGVufDB8fDB8fHww',
    purchasesCount: 6,
    totalPurchasesAmount: 65800,
  },
];

const RecentOrders = [
  {
    name: 'Sewing Threads',
    imageUrl:
      'https://res.cloudinary.com/dlw8ohn6p/image/upload/v1690564400/julia-maior-y1ae88IA1X4-unsplash_mqy810.jpg',
    purchasesCount: 2,
    totalPurchasesAmount: 32000,
  },
  {
    name: 'Large Fabric Scissors',
    imageUrl:
      'https://res.cloudinary.com/dlw8ohn6p/image/upload/v1690626646/behnam-norouzi-zvmZjYt5Z9s-unsplash_nv5lu4.jpg',
    purchasesCount: 1,
    totalPurchasesAmount: 18600,
  },
  {
    name: 'Navy Blue Fabric with Sequins',
    imageUrl:
      'https://res.cloudinary.com/dlw8ohn6p/image/upload/v1690626770/julissa-santana-wm972l0GUxE-unsplash_szdzoy.jpg',
    purchasesCount: 5,
    totalPurchasesAmount: 72000,
  },
  {
    name: 'Large Fabric Scissors',
    imageUrl:
      'https://res.cloudinary.com/dlw8ohn6p/image/upload/v1690626646/behnam-norouzi-zvmZjYt5Z9s-unsplash_nv5lu4.jpg',
    purchasesCount: 1,
    totalPurchasesAmount: 18600,
  },
];

const ReChartData = [
  {
    name: 'JAN',
    uv: 1000 + Math.floor(Math.random() * 5000),
    pv: 2000 + Math.floor(Math.random() * 4000),
    amt: 7000 + Math.floor(Math.random() * 5000),
  },
  {
    name: 'FEB',
    uv: 1000 + Math.floor(Math.random() * 5000),
    pv: 2000 + Math.floor(Math.random() * 4000),
    amt: 7000 + Math.floor(Math.random() * 5000),
  },
  {
    name: 'MAR',
    uv: 1000 + Math.floor(Math.random() * 5000),
    pv: 2000 + Math.floor(Math.random() * 4000),
    amt: 7000 + Math.floor(Math.random() * 5000),
  },
  {
    name: 'APR',
    uv: 1000 + Math.floor(Math.random() * 5000),
    pv: 2000 + Math.floor(Math.random() * 4000),
    amt: 7000 + Math.floor(Math.random() * 5000),
  },
  {
    name: 'MAY',
    uv: 1000 + Math.floor(Math.random() * 5000),
    pv: 2000 + Math.floor(Math.random() * 4000),
    amt: 7000 + Math.floor(Math.random() * 5000),
  },
  {
    name: 'JUN',
    uv: 1000 + Math.floor(Math.random() * 5000),
    pv: 2000 + Math.floor(Math.random() * 4000),
    amt: 7000 + Math.floor(Math.random() * 5000),
  },
  {
    name: 'JUL',
    uv: 1000 + Math.floor(Math.random() * 5000),
    pv: 2000 + Math.floor(Math.random() * 4000),
    amt: 7000 + Math.floor(Math.random() * 5000),
  },
  {
    name: 'AUG',
    uv: 1000 + Math.floor(Math.random() * 5000),
    pv: 2000 + Math.floor(Math.random() * 4000),
    amt: 7000 + Math.floor(Math.random() * 5000),
  },
  {
    name: 'SEP',
    uv: 1000 + Math.floor(Math.random() * 5000),
    pv: 2000 + Math.floor(Math.random() * 4000),
    amt: 7000 + Math.floor(Math.random() * 5000),
  },
  {
    name: 'OCT',
    uv: 1000 + Math.floor(Math.random() * 5000),
    pv: 2000 + Math.floor(Math.random() * 4000),
    amt: 7000 + Math.floor(Math.random() * 5000),
  },
  {
    name: 'NOV',
    uv: 1000 + Math.floor(Math.random() * 5000),
    pv: 2000 + Math.floor(Math.random() * 4000),
    amt: 7000 + Math.floor(Math.random() * 5000),
  },
  {
    name: 'DEC',
    uv: 1000 + Math.floor(Math.random() * 5000),
    pv: 2000 + Math.floor(Math.random() * 4000),
    amt: 7000 + Math.floor(Math.random() * 5000),
  },
];

const TopSellingProducts = [
  {
    product_name: 'Sewing Threads',
    product_image:
      'https://res.cloudinary.com/dlw8ohn6p/image/upload/v1690564400/julia-maior-y1ae88IA1X4-unsplash_mqy810.jpg',
  },
  {
    product_name: 'Large Fabric Scissors',
    product_image:
      'https://res.cloudinary.com/dlw8ohn6p/image/upload/v1690626646/behnam-norouzi-zvmZjYt5Z9s-unsplash_nv5lu4.jpg',
  },
  {
    product_name: 'Navy Blue Fabric with Sequins',
    product_image:
      'https://res.cloudinary.com/dlw8ohn6p/image/upload/v1690626770/julissa-santana-wm972l0GUxE-unsplash_szdzoy.jpg',
  },
  {
    product_name: 'Large Fabric Scissors',
    product_image:
      'https://res.cloudinary.com/dlw8ohn6p/image/upload/v1690626646/behnam-norouzi-zvmZjYt5Z9s-unsplash_nv5lu4.jpg',
  },
];

export {
  SidebarUpperOptions,
  SidebarToolsOptions,
  TopCustomers,
  RecentOrders,
  ReChartData,
  TopSellingProducts,
};
