import React from 'react';

export const StockReportBar = ({ name, percentage, stock }) => {
  return (
    <div className="flex items-center justify-between gap-x-6">
      <div className="font-medium text-white truncate w-[40%]">{name}</div>
      <div className="w-[50%] rounded-full h-3 bg-[#181717]">
        <div
          className={`h-3 rounded-full bg-latestYellow w-[${percentage}]`}
        ></div>
      </div>
      <div className="font-medium text-white w-[10%]">{stock}</div>
    </div>
  );
};
