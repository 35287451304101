import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { BASE_URL, ADMINTOKEN } from '../../../utils/apiUtils';

export const Payment = createAsyncThunk(
  'payment',
  async (_, { rejectWithValue }) => {
    try {
      const orderId = localStorage.getItem('orderId');
      const response = await axios.get(
        `${BASE_URL}api/orders/payment/verify-status/${orderId}`,
        {
          headers: {
            Authorization: `Bearer ${ADMINTOKEN}`,
            'Content-Type': 'application/json',
          },
        },
      );

      return response.data;
    } catch (error) {
      if (error.response.status === 422) {
        return rejectWithValue(error.response.data.errors[0].message);
      }
      if (error.response.status === 404) {
        return rejectWithValue(error.response.data.message);
      }
      if (error.response.status === 400) {
        return rejectWithValue(error.response.data.message);
      }
    }
  },
);

const PaymentSlice = createSlice({
  name: 'payment',
  initialState: {
    status: null,
    error: null,
    data: [],
  },
  extraReducers: {
    [Payment.pending]: (state) => {
      state.status = 'loading';
    },
    [Payment.fulfilled]: (state, action) => {
      state.data = action.payload;
      state.status = 'success';
    },
    [Payment.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.payload;
    },
  },
});

export default PaymentSlice.reducer;
