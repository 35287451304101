import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { BASE_URL, ADMINTOKEN } from '../../../utils/apiUtils';

// getting vendors
export const getVendors = createAsyncThunk(
  'api/users/vendors',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${BASE_URL}api/users/role/vendor`, {
        headers: {
          Authorization: `Bearer ${ADMINTOKEN}`,
        },
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

const vendorSlice = createSlice({
  name: 'vendors',
  initialState: {
    vendors: null,
    data: null,
    status: null,
    Error: null,
  },
  extraReducers: {
    [getVendors.pending]: (state) => {
      state.status = 'loading.....';
    },
    [getVendors.fulfilled]: (state, action) => {
      state.data = action.payload;
      state.status = 'success';
    },
    [getVendors.rejected]: (state, action) => {
      state.status = 'failed';
      state.Error = action.payload;
    },
  },
});

export default vendorSlice.reducer;
