import React from 'react';
import {
  Container,
  Footer,
  Header,
  Heading,
  Paragraph,
  ProductCard,
  Section,
} from '../../components';
import { BsFillCheckCircleFill, BsFilterLeft } from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import {
  GetProductsByFilters,
  PaginateProvidedProducts,
} from '../../Redux/Features/Products/productSlice';
import 'react-loading-skeleton/dist/skeleton.css';
import ProductsSkeleton from '../../components/skeletons/ProductsSkeleton';
import Skeleton from 'react-loading-skeleton';
import SubcategoriesSkeleton from '../../components/skeletons/SubcategoriesSkeleton';
import Pagination from '../../components/Pagination';
import { useLocation } from 'react-router-dom';
import { FilterSidebar } from '../../components';
import { scrollToTop } from '../../utils';
import { GiSettingsKnobs } from 'react-icons/gi';

function BrowseProducts() {
  const dispatch = useDispatch();

  let numberOfDisplayedProducts = 21;

  let products =
    useSelector((state) => state?.product?.productsByFilters) || [];
  let allProducts = useSelector((state) => state.product.data) || [];

  let { totalPages, currentPage, totalCount } = useSelector(
    (state) => state?.product?.paginatedProducts,
  );

  let fetchedPaginatedProducts =
    useSelector((state) => state?.product?.paginatedProducts) || [];

  useEffect(() => {
    dispatch(
      PaginateProvidedProducts({
        productsToPaginate: products,
        page: 1,
        limit: numberOfDisplayedProducts,
      }),
    );
  }, [
    dispatch,
    fetchedPaginatedProducts.length,
    numberOfDisplayedProducts,
    products,
  ]);

  const [selectedSubcategories, setSelectedSubcategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [theCurrentPage, setTheCurrentPage] = useState(1);
  const [isFilterSidebarOpen, setIsFilterSidebarOpen] = useState(false);
  const [isFiltersButtonClicked, setIsFiltersButtonClicked] = useState(false);

  const [isSortBy, setIsSortBy] = useState('');
  const [isOrder, setIsOrder] = useState(1);

  let categories = useSelector((state) => state.categories.data);
  let subSubcategories = useSelector((state) => state.subSubcategory.data);
  let subcategories = useSelector((state) => state.subcategory.data);
  let subcatLoadingStatus = useSelector((state) => state.subcategory.status);

  const loadingStatus = useSelector(
    (state) => state.product.productsByFiltersStatus,
  );
  const fetchError = useSelector(
    (state) => state.product.productsByFiltersError,
  );

  const [selectedSubSubcategories, setSelectedSubSubcategories] = useState([]);
  const [clickedSubCategoryIndex, setClickedSubCategoryIndex] = useState(null);
  const [filteredSubcategories, setFilteredSubcategories] = useState([]);
  const [filteredSubSubcategories, setFilteredSubSubcategories] = useState([]);
  const [clickedCategory, setClickedCategory] = useState(null);
  const [clickedSubCategory, setClickedSubCategory] = useState(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isCategoryDropdownOpen, setIsCategoryDropdownOpen] = useState(false);
  const [isCategorySelected, setIsCategorySelected] = useState(false);
  const [isSubCategorySelected, setIsSubCategorySelected] = useState(false);
  const [isSubSubCategorySelected, setIsSubSubCategorySelected] =
    useState(false);
  const [isInitialRender, setIsInitialRender] = useState(true);

  const [selectedSortOption, setSelectedSortOption] = useState('');

  useEffect(() => {
    currentPage === undefined || null
      ? setTheCurrentPage(1)
      : setTheCurrentPage(currentPage);
  }, [currentPage]);

  const toggleCategoryDropdown = () => {
    setIsCategoryDropdownOpen(!isCategoryDropdownOpen);
  };

  const handleButtonClick = () => {
    setSelectedCategories([]);
    setSelectedSubcategories([]);
    setSelectedSubSubcategories([]);

    if (isCategoryDropdownOpen) {
      setIsCategoryDropdownOpen(!isCategoryDropdownOpen);
    }

    if (isDropdownOpen) {
      setIsDropdownOpen(!isDropdownOpen);
    }

    setSelectedSubSubcategories((prevSelectedSubSubcategories) => {
      setSelectedSubcategories((prevSelectedSubcategories) => {
        setSelectedCategories((prevSelectedCategories) => {
          if (
            !prevSelectedCategories.length &&
            !prevSelectedSubcategories.length &&
            !prevSelectedSubSubcategories.length
          ) {
            dispatch(
              GetProductsByFilters({
                categories: prevSelectedCategories,
                subcategories: prevSelectedSubcategories,
                subsubcategories: prevSelectedSubSubcategories,
                page: currentPage,
                limit: numberOfDisplayedProducts,
              }),
            )
              .then(() => {
                dispatch(
                  PaginateProvidedProducts({
                    productsToPaginate: [],
                    page: 1,
                    limit: numberOfDisplayedProducts,
                  }),
                );
              })
              .catch((error) => {
                console.log(error.message);
              });
          }
          return [];
        });
        return [];
      });
      return [];
    });
  };

  const handleApplyButtonClick = () => {
    if (isCategoryDropdownOpen) {
      setIsCategoryDropdownOpen(!isCategoryDropdownOpen);
    }

    if (isDropdownOpen) {
      setIsDropdownOpen(!isDropdownOpen);
    }
    setSelectedCategories([]);
    setSelectedSubcategories([]);

    dispatch(
      GetProductsByFilters({
        categories: [],
        subcategories: [],
        subsubcategories: selectedSubSubcategories,
        page: currentPage,
        limit: numberOfDisplayedProducts,
      }),
    );
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const toggleSortSelection = (isCategory, isSubCategory, isSubSubCategory) => {
    if (isCategory) {
      setIsCategorySelected(true);
      setIsSubCategorySelected(false);
      setIsSubSubCategorySelected(false);
    }
    if (isSubCategory) {
      setIsSubCategorySelected(true);
      setIsCategorySelected(false);
      setIsSubSubCategorySelected(false);
    }
    if (isSubSubCategory) {
      setIsSubSubCategorySelected(true);
      setIsSubCategorySelected(false);
      setIsCategorySelected(false);
    }
  };

  const handlePageChange = (newPage) => {
    dispatch(
      PaginateProvidedProducts({
        productsToPaginate: products,
        page: newPage,
        limit: numberOfDisplayedProducts,
      }),
    );
    scrollToTop();
  };

  const handleCategorySelected = (catId) => {
    setSelectedCategories((prevSelectedCategories) => {
      let updatedSelectedCategories;
      if (prevSelectedCategories.includes(catId)) {
        updatedSelectedCategories = prevSelectedCategories.filter(
          (cat) => cat !== catId,
        );

        if (selectedSubcategories && selectedSubSubcategories) {
          handleButtonClick();
        }

        if (isDropdownOpen) {
          setIsDropdownOpen(!isDropdownOpen);
        }
      } else {
        updatedSelectedCategories = [...prevSelectedCategories, catId];
      }

      setSelectedCategories(updatedSelectedCategories);
      setSelectedSortOption('');

      const filteredSubs = subcategories.filter(
        (sub) => sub.categoryId === catId,
      );
      setFilteredSubcategories(filteredSubs);

      const clickedCat = categories.find((cat) => cat._id === catId);
      setClickedCategory(clickedCat);

      return updatedSelectedCategories;
    });
  };

  const handleSubcategorySelected = (subcatId) => {
    setSelectedSubcategories((prevSelectedSubcategories) => {
      let updatedSelectedSubcategories;
      if (prevSelectedSubcategories.includes(subcatId)) {
        updatedSelectedSubcategories = prevSelectedSubcategories.filter(
          (subcat) => subcat !== subcatId,
        );
      } else {
        updatedSelectedSubcategories = [...prevSelectedSubcategories, subcatId];
      }

      setSelectedSortOption('');

      const filteredSubSubs = subSubcategories.filter(
        (subSub) => subSub.subCategoryId === subcatId,
      );
      setFilteredSubSubcategories(filteredSubSubs);

      const clickedSubCat = subcategories.find(
        (subcat) => subcat._id === subcatId,
      );
      setClickedSubCategory(clickedSubCat);

      const clickedSubCategoryIndex = subcategories.indexOf(clickedSubCat);

      setClickedSubCategoryIndex(clickedSubCategoryIndex);

      return updatedSelectedSubcategories;
    });
  };

  const handleSubSubCategorySelected = (subCatId) => {
    setSelectedSubSubcategories((prevSelectedSubSubcategories) => {
      let updatedSelectedSubSubcategories;
      if (prevSelectedSubSubcategories.includes(subCatId)) {
        updatedSelectedSubSubcategories = prevSelectedSubSubcategories.filter(
          (subCat) => subCat !== subCatId,
        );
      } else {
        updatedSelectedSubSubcategories = [
          ...prevSelectedSubSubcategories,
          subCatId,
        ];
      }

      setSelectedSortOption('');

      return updatedSelectedSubSubcategories;
    });
  };

  const handleOnSortChange = (e) => {
    const newSortOption = e;
    setSelectedSortOption(newSortOption);

    try {
      const { sortBy, order } = JSON.parse(e);

      setIsSortBy(sortBy);
      setIsOrder(order);

      dispatch(
        GetProductsByFilters({
          categories: selectedCategories,
          subcategories: selectedSubcategories,
          subsubcategories: selectedSubSubcategories,
          page: currentPage,
          limit: numberOfDisplayedProducts,
          sortBy: sortBy,
          order: order,
        }),
      );
    } catch (error) {
      console.log('Encountered error: ', error.message);
    }
  };

  useEffect(() => {
    dispatch(
      GetProductsByFilters({
        categories: selectedCategories,
        subcategories: selectedSubcategories,
        subsubcategories: selectedSubSubcategories,
        page: currentPage,
        limit: numberOfDisplayedProducts,
      }),
    );

    scrollToTop();
  }, []);

  useEffect(() => {
    if (!selectedSubSubcategories.length) {
      dispatch(
        GetProductsByFilters({
          categories: [],
          subcategories: [],
          subsubcategories: selectedSubSubcategories,
          page: currentPage,
          limit: numberOfDisplayedProducts,
        }),
      );
    }

    scrollToTop();
  }, [
    selectedSubSubcategories,
    currentPage,
    dispatch,
    numberOfDisplayedProducts,
  ]);

  const openFilterSidebar = () => {
    setIsFilterSidebarOpen((prevState) => !prevState);
    setIsFiltersButtonClicked(true);

    if (isDropdownOpen || isCategoryDropdownOpen) {
      setIsDropdownOpen(false);
      setIsCategoryDropdownOpen(false);
    }
  };

  useEffect(() => {
    if (isFilterSidebarOpen) {
      document.body.classList.add('disable-scroll');
    } else {
      document.body.classList.remove('disable-scroll');
    }

    return () => {
      document.body.classList.remove('disable-scroll');
    };
  }, [isFilterSidebarOpen]);

  const handleBackArrowClick = (isBackFromSubCategory) => {
    setSelectedSubcategories([]);

    if (!isBackFromSubCategory) {
      setSelectedCategories([]);
    }

    if (isCategoryDropdownOpen && !isBackFromSubCategory) {
      setIsCategoryDropdownOpen(!isCategoryDropdownOpen);
    }

    if (isDropdownOpen) {
      setIsDropdownOpen(!isDropdownOpen);
    }
  };

  return (
    <Container>
      <Header />
      {/* Top Section with the Page Title and Sorting dropdown  */}
      <Section className="flex flex-col text-white md:flex-row md:justify-between md:mr-[9%]">
        <div className="flex flex-col md:flex-row md:items-center">
          <div className="text-[#AAAAAA] text-[28px] font-[500] ml-[20px] pt-[5px] md:py-[20px] md:ml-[50px]">
            {loadingStatus === 'loading' ? (
              <Skeleton
                width={200}
                baseColor="#1d1f1e"
                highlightColor="#292928"
              />
            ) : loadingStatus === 'failed' ? (
              <></>
            ) : (
              <Heading className="text-[#AAAAAA] text-[28px] text-center font-[500] ">
                Browse Products
              </Heading>
            )}
          </div>
        </div>
        <div className="justify-center flex flex-row md:items-center font-[200] mb-5 md:my-auto gap-[20px]">
          {loadingStatus === 'loading' ? (
            <Skeleton
              width={300}
              height={40}
              baseColor="#1d1f1e"
              highlightColor="#292928"
            />
          ) : loadingStatus === 'failed' ? (
            <></>
          ) : (
            <div className="flex md:flex-row items-center gap-[10px]">
              <label htmlFor="sortBy">Sort By:</label>
              <select
                value={selectedSortOption}
                onChange={(e) => handleOnSortChange(e.target.value)}
                className="text-gray-300 text-[13px] md:text-[15px] border bg-black flex gap-4 md:gap-[80px] border-primaryYellow my-auto px-3 py-1 w-[130px] md:w-[190px] rounded-lg"
              >
                <option className="text-center text-gray-300" value={''}>
                  &nbsp;&nbsp;&nbsp;-- Select --
                </option>
                <option
                  value={JSON.stringify({ sortBy: 'createdAt', order: 1 })}
                >
                  Date: Old to New
                </option>
                <option
                  value={JSON.stringify({ sortBy: 'createdAt', order: -1 })}
                >
                  Date: New to Old
                </option>
                <option
                  value={JSON.stringify({
                    sortBy: 'unitPriceBeforeProfit',
                    order: 1,
                  })}
                >
                  Price : Low to High
                </option>
                <option
                  value={JSON.stringify({
                    sortBy: 'unitPriceBeforeProfit',
                    order: -1,
                  })}
                >
                  Price : High to Low
                </option>
              </select>
            </div>
          )}
          <div className="flex flex-row md:flex-row items-center text-[12px]">
            {loadingStatus === 'loading' ? (
              <Skeleton
                width={100}
                height={40}
                baseColor="#1d1f1e"
                highlightColor="#292928"
              />
            ) : loadingStatus === 'failed' ? (
              <></>
            ) : (
              <div className="flex flex-row w-[30%] pt-3 md:pt-0">
                <Paragraph className="text-[#c2c2c2e9] mb-2 md:mb-0">
                  {totalCount === undefined ? products.length : totalCount}
                </Paragraph>
                <Paragraph className="text-[#c2c2c2e9]">
                  &nbsp;results
                </Paragraph>{' '}
              </div>
            )}
          </div>
        </div>
      </Section>

      <Section className="flex flex-col lg:flex-row">
        {/* Left part */}
        <Section className="lg:w-[28%]  hidden lg:flex flex-col mb-2 lg:mb-0">
          <div className="flex justify-between w-full">
            <Heading className="text-primaryYellow font-[500] text-[30px] ml-[50px] mt-3 lg:mt-[20px]">
              {subcatLoadingStatus !== 'success' ? (
                <Skeleton baseColor="#1d1f1e" highlightColor="#292928" />
              ) : (
                'Filters'
              )}
            </Heading>
            <div className="flex justify-between gap-2 mt--4">
              <button
                className={`hidden lg:mt-[30px] lg:flex lg:text-sm lg:px-2 py-1.5 disabled:cursor-not-allowed lg:rounded-[10px] lg:z-10 lg:border ${
                  !selectedSubSubcategories.length || !isDropdownOpen
                    ? 'text-gray-600 border-gray-600'
                    : 'text-[#6bbf50] border-[#6bbf50] hover:bg-[#04c0261c]'
                }`}
                onClick={handleApplyButtonClick}
                disabled={!selectedSubSubcategories.length || !isDropdownOpen}
              >
                Apply{' '}
                <BsFillCheckCircleFill className="ml-[5%] w-5 h-5 pt-0.5 pl-1" />
              </button>

              <button
                className={`hidden lg:mt-[30px] lg:flex lg:text-sm lg:px-2 py-1.5 disabled:cursor-not-allowed lg:rounded-[10px] lg:z-10 lg:border ${
                  !selectedSubSubcategories.length
                    ? 'text-gray-600 border-gray-600'
                    : 'text-[#BB2525] border-[#BB2525] hover:bg-[#c004041c]'
                }`}
                onClick={handleButtonClick}
                disabled={!selectedSubSubcategories.length}
              >
                Clear <GiSettingsKnobs className="w-5 h-5 pt-0.5 pl-1" />
              </button>
            </div>
          </div>

          <Heading className="hidden lg:block text-white font-[500] text-[25px] ml-[50px] pt-4">
            {subcatLoadingStatus !== 'success' ? (
              <Skeleton baseColor="#1d1f1e" highlightColor="#292928" />
            ) : (
              'Select Category'
            )}
          </Heading>

          {subcatLoadingStatus !== 'success' ? (
            <SubcategoriesSkeleton
              baseColor="#1d1f1e"
              highlightColor="#292928"
            />
          ) : (
            <Section className="ml-[50px] mt-[15px] space-y-10 mr-2 md:mr-10 md:ml-[50px] lg:mr-0">
              <FilterSidebar
                allProducts={allProducts}
                products={products}
                categories={categories}
                clickedCategory={clickedCategory}
                subcategories={subcategories}
                isCategoryDropdownOpen={isCategoryDropdownOpen}
                handleCategorySelected={handleCategorySelected}
                toggleSortSelection={toggleSortSelection}
                filteredSubcategories={filteredSubcategories}
                clickedSubCategory={clickedSubCategory}
                isDropdownOpen={isDropdownOpen}
                handleSubcategorySelected={handleSubcategorySelected}
                filteredSubSubcategories={filteredSubSubcategories}
                handleSubSubCategorySelected={handleSubSubCategorySelected}
                selectedCategories={selectedCategories}
                selectedSubcategories={selectedSubcategories}
                selectedSubSubcategories={selectedSubSubcategories}
                toggleDropdown={toggleDropdown}
                toggleCategoryDropdown={toggleCategoryDropdown}
                isSubCategorySelected={isSubCategorySelected}
                isCategorySelected={isCategorySelected}
                handleBackArrowClick={handleBackArrowClick}
                fetchedPaginatedProducts={fetchedPaginatedProducts}
                className="bg-black mt-[-5px] overflow-y-auto z-[9998]"
              />
            </Section>
          )}
        </Section>

        {/* Right part */}
        <Section className="md:w-[74%] my-5 mx-[7%] md:mx-auto">
          <div className="flex justify-between w-full">
            <button
              className={`lg:hidden mt-[-30px] mb-4 flex ${
                isFilterSidebarOpen
                  ? 'text-black bg-primaryYellow'
                  : 'border border-primaryYellow text-white'
              }  px-4 py-1.5 rounded-[10px] z-10`}
              onClick={() => {
                openFilterSidebar();
              }}
            >
              Filters{' '}
              <BsFilterLeft className="ml-[5%] my-auto w-5 h-5 pt-0.5 pl-1" />
            </button>
            <button
              className={`lg:hidden mt-[-30px] mb-4 flex px-4 py-1.5 disabled:cursor-not-allowed rounded-[10px] z-10 border ${
                !selectedSubSubcategories.length || !isDropdownOpen
                  ? 'text-gray-600 border-gray-600'
                  : 'text-[#6bbf50] border-[#6bbf50] hover:bg-[#04c0261c]'
              }`}
              onClick={handleApplyButtonClick}
              disabled={!selectedSubSubcategories.length || !isDropdownOpen}
            >
              Apply{' '}
              <BsFillCheckCircleFill className="ml-[5%] my-auto w-5 h-5 pt-0.5 pl-1" />
            </button>
            <button
              className={`lg:hidden mt-[-30px] mb-4 flex px-4 py-1.5 disabled:cursor-not-allowed rounded-[10px] z-10 border ${
                !selectedSubSubcategories.length
                  ? 'text-gray-600 border-gray-600'
                  : 'text-[#BB2525] border-[#BB2525] hover:bg-[#c004041c]'
              }`}
              onClick={handleButtonClick}
              disabled={!selectedSubSubcategories.length}
            >
              Clear{' '}
              <GiSettingsKnobs className="ml-[5%] my-auto w-5 h-5 pt-0.5 pl-1" />
            </button>
          </div>

          <FilterSidebar
            allProducts={allProducts}
            products={products}
            categories={categories}
            clickedCategory={clickedCategory}
            subcategories={subcategories}
            isCategoryDropdownOpen={isCategoryDropdownOpen}
            handleCategorySelected={handleCategorySelected}
            toggleSortSelection={toggleSortSelection}
            filteredSubcategories={filteredSubcategories}
            clickedSubCategory={clickedSubCategory}
            isDropdownOpen={isDropdownOpen}
            setIsDropdownOpen={setIsDropdownOpen}
            setIsCategoryDropdownOpen={setIsCategoryDropdownOpen}
            handleSubcategorySelected={handleSubcategorySelected}
            filteredSubSubcategories={filteredSubSubcategories}
            handleSubSubCategorySelected={handleSubSubCategorySelected}
            selectedCategories={selectedCategories}
            selectedSubcategories={selectedSubcategories}
            selectedSubSubcategories={selectedSubSubcategories}
            toggleDropdown={toggleDropdown}
            toggleCategoryDropdown={toggleCategoryDropdown}
            isSubCategorySelected={isSubCategorySelected}
            isCategorySelected={isCategorySelected}
            handleBackArrowClick={handleBackArrowClick}
            fetchedPaginatedProducts={fetchedPaginatedProducts}
            className={`${
              isFilterSidebarOpen
                ? 'block bg-black mt-[-5px] mb-[25px] h-[55vh] p-3 overflow-y-auto z-[9998] border border-[#292929] transition-all ease-in-out duration-500'
                : 'hidden'
            } lg:hidden`}
          />
          {loadingStatus === 'loading' ? (
            <ProductsSkeleton cards={12} />
          ) : loadingStatus === 'failed' ? (
            <h1 className="text-center text-gray-500 mt-[20%] mx-auto justify-center">
              {fetchError}
            </h1>
          ) : (
            <>
              <ProductCard
                className="grid grid-cols-2 grid-rows-2 md:flex md:flex-wrap justify-between md:justify-center md:items-center gap-[20px] md:gap-[30px]"
                products={fetchedPaginatedProducts}
              />
            </>
          )}
        </Section>
      </Section>
      <Pagination
        currentPage={theCurrentPage}
        currentPageChange={currentPage}
        totalPages={totalPages}
        onPageChange={handlePageChange}
        className="flex justify-center md:px-5 mx-auto my-12"
      />
      <Footer />
    </Container>
  );
}

export default BrowseProducts;
