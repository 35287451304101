import { Container, Header, Footer, BodyCategory } from '../../components';

function Addcategory() {
  return (
    <Container>
      <div className="min-h-screen">
        <Header />
        <BodyCategory />
        <Footer />
      </div>
    </Container>
  );
}
export default Addcategory;
