import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { ADMINTOKEN, BASE_URL } from '../../../utils/apiUtils';

export const fetchProduct = createAsyncThunk(
  'fetchProduct', // Action type
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${BASE_URL}api/products/${id}`, {
        headers: {
          Authorization: `Bearer ${ADMINTOKEN}`,
          'Content-Type': 'application/json',
        },
      });

      return response.data.productExist; // Return the actual data
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const fetchProductsByCategory = createAsyncThunk(
  'fetchProductBYCategory', // Action type
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${BASE_URL}api/products/cat/${id}`, {
        headers: {
          Authorization: `Bearer ${ADMINTOKEN}`,
          'Content-Type': 'application/json',
        },
      });
      return response.data.data; // Return the actual data
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.response.data.message);
    }
  },
);

const FetchProductSlice = createSlice({
  name: 'product',
  initialState: {
    data: null,
    status: null,
    error: null,
    products: null,
  },
  extraReducers: {
    [fetchProduct.pending]: (state) => {
      state.status = 'loading';
    },
    [fetchProduct.fulfilled]: (state, action) => {
      state.data = action.payload;
      state.status = 'success';
    },
    [fetchProduct.rejected]: (state, action) => {
      state.error = action.payload;
      state.status = 'failed';
    },
    [fetchProductsByCategory.pending]: (state) => {
      state.status = 'loading';
    },
    [fetchProductsByCategory.fulfilled]: (state, action) => {
      state.products = action.payload;
      state.status = 'success';
    },
    [fetchProductsByCategory.rejected]: (state, action) => {
      state.error = action.payload;
      state.status = 'failed';
    },
  },
});

export default FetchProductSlice.reducer;
