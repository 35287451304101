import React, { useState } from 'react';
import { Section } from '../../components';
import { SidebarContent } from '../../components/Dashboard/admin/SidebarContent';
import { DashboardContent } from '../../components/Dashboard/admin/DashboardContent';

export default function AdminDashboard() {
  const [selectedOption, setSelectedOption] = useState('overview');

  return (
    <div className="bg-[#040404] min-h-screen min-w-screen flex w-full overflow-hidden">
      <Section className={'z-10'}>
        <SidebarContent
          selectedOption={selectedOption}
          setSelectedOption={setSelectedOption}
        />
      </Section>
      <Section>
        <DashboardContent
          selectedOption={selectedOption}
          setSelectedOption={setSelectedOption}
        />
      </Section>
    </div>
  );
}
