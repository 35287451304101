import React, { useEffect, useState } from 'react';
import { IoIosArrowDown, IoIosArrowForward } from 'react-icons/io';
import { IoNotificationsOutline } from 'react-icons/io5';
import StatsCard from '../Shared/StatsCard';
import { TableBody } from './TableBody';
import { StockReportBar } from './StockReportBar';
import { dummyOrders } from '../../../data/adminDashboardData';
import { FaSortAmountDown, FaSortAmountUpAlt } from 'react-icons/fa';
import {
  BsCalendar2Day,
  BsCalendar2Month,
  BsCalendar2Week,
} from 'react-icons/bs';
import { RecentOrderCard } from './RecentOrderCard';
import { useDispatch, useSelector } from 'react-redux';
import { GetAdminOverviewStats } from '../../../Redux/Features/Dashboards/AdminDashboardSlice';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { TbLoaderQuarter } from 'react-icons/tb';
import { getLoggedInUserDetails } from '../../../utils/user';

export const OverviewContent = ({ setSelectedOption }) => {
  let dashboardData = useSelector((state) => state?.adminDashboard?.data) || [];
  const [totalOrders, setTotalOrders] = useState('__');
  const [soldProducts, setSoldProducts] = useState('__');
  const [revenue, setRevenue] = useState('__');
  const [newUsers, setNewUsers] = useState('__');
  const [totalOrdersGrowth, setTotalOrdersGrowth] = useState('_');
  const [soldProductsGrowth, setSoldProductsGrowth] = useState('_');
  const [revenueGrowth, setRevenueGrowth] = useState('_');
  const [newUsersGrowth, setNewUsersGrowth] = useState('_');
  const [recentOrders, setRecentOrders] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isTimeDropdownOpen, setIsTimeDropdownOpen] = useState(false);
  const [selectedStock, setSelectedStock] = useState('Ltd Stock');
  const [selectedTime, setSelectedTime] = useState('Last Month');
  const [isOverviewFetched, setIsOverviewFetched] = useState(false);

  const dispatch = useDispatch();

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };
  const handleStockSelection = (selection) => {
    setSelectedStock(selection);
    setIsDropdownOpen(false);
  };

  const toggleTimeDropdown = () => {
    setIsTimeDropdownOpen(!isTimeDropdownOpen);
  };
  const handleTimeSelection = (selection) => {
    setSelectedTime(selection);
    setIsTimeDropdownOpen(false);
  };
  const handleItemClick = () => {
    setSelectedOption('orders');
  };

  useEffect(() => {
    if (isOverviewFetched) {
      setTotalOrders(dashboardData.currentAdminStats.totalOrders);
      setSoldProducts(dashboardData.currentAdminStats.soldProducts);
      setRevenue(dashboardData.currentAdminStats.revenue);
      setNewUsers(dashboardData.currentAdminStats.users);
      setTotalOrdersGrowth(dashboardData.growthRate.totalOrders);
      setSoldProductsGrowth(dashboardData.growthRate.soldProducts);
      setRevenueGrowth(dashboardData.growthRate.revenue);
      setNewUsersGrowth(dashboardData.growthRate.users);
      setRecentOrders(dashboardData.recentOrders);
      setIsOverviewFetched(false);
    }
  }, [isOverviewFetched]);

  const getOverviewStats = async ({ week, month, quarter, half }) => {
    await dispatch(GetAdminOverviewStats({ week, month, quarter, half }))
      .unwrap()
      .then(() => {
        setIsOverviewFetched(true);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    dispatch(GetAdminOverviewStats({ week: 0, month: 30, quarter: 0, half: 0 }))
      .unwrap()
      .then(() => {
        setIsOverviewFetched(true);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const getTimeString = (selectedTime) => {
    switch (selectedTime) {
      case 'Last 6 Months':
        return 'These 6 months';
      case 'Last 3 Months':
        return 'This quarter';
      case 'Last Month':
        return 'This month';
      case 'Last Week':
        return 'This week';
      default:
        return 'This month';
    }
  };
  const { firstName, lastName } = getLoggedInUserDetails();

  return (
    <div className="grid w-full h-full grid-cols-1 py-3 lg:py-0">
      <div className="h-auto lg:h-[20%] lg:pl-[8%] items-center lg:items-stretch w-full lg:fixed top-0 flex flex-col text-white">
        <div className="flex justify-between h-[50%] mx-8 lg:mt-5">
          <h3 className="hidden text-3xl text-center lg:block">
            Welcome Back, {firstName || lastName || 'Admin'}!
          </h3>
          <div className="flex items-center justify-end gap-x-5">
            <IoNotificationsOutline
              className="w-6 h-6 text-gray-500 !cursor-not-allowed"
              title="Notifications Coming Soon"
            />
            {/* <VscAccount className="w-6 h-6" /> */}
            <h3 className="text-2xl text-center lg:hidden">
              Welcome Back, Serge!
            </h3>

            <vr className="border-[0.5px] border-[#5f5f5f] h-6 hidden md:block" />
            <div className="flex gap-2 cursor-pointer hover:text-latestYellow">
              <img
                className="object-cover w-10 h-10 rounded-full"
                src="https://www.indiewire.com/wp-content/uploads/2021/06/kevin-hart.png?w=600&h=337&crop=1"
                alt="kevin hart"
              />
              <div className="flex items-center">
                <p className="max-w-[160px] truncate hidden md:block">
                  {firstName} {lastName}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="flex mx-8 text-lg gap-x-10 lg:h-[50%] mt-5">
          <div className="relative">
            <span>Summary</span>
            <hr className="absolute w-full border rounded-md" />
          </div>
          <div
            className="relative cursor-pointer"
            onClick={() => handleItemClick()}
          >
            <span>Statistics</span>
            {/* <hr className="absolute w-full border border-gray-300 rounded-md" /> */}
          </div>
        </div>
      </div>
      <div className="lg:pl-[7%] lg:top-[20%] w-full lg:fixed lg:h-[80%] mt-4">
        <div className="lg:h-[45%] justify-center items-start lg:items-center gap-y-[10%] flex flex-col w-full overflow-x-auto custom-scrollbar">
          <div className="flex justify-between items-center w-full text-white px-[7%] mb-5 lg:mb-0">
            <div className="flex text-white bg-[#181717] p-1 gap-x-2 rounded-md text-sm">
              <button className="p-2 bg-gray-600 rounded-md">Table</button>
              <button
                className="p-2 rounded-md"
                onClick={() => handleItemClick()}
              >
                Chart
              </button>
            </div>
            <div className="relative">
              <button
                onClick={toggleTimeDropdown}
                className="flex items-center px-2 border-[2px] border-gray-800 rounded-md gap-x-2"
              >
                <span>{selectedTime}</span>
                <IoIosArrowDown />
              </button>

              {isTimeDropdownOpen && (
                <div className="absolute flex flex-col mt-2 w-52 bg-stone-700 right-0 justify-center text-center rounded-xl shadow-lg z-[1000]">
                  <button
                    onClick={async () => {
                      handleTimeSelection('Last 6 Months');
                      await getOverviewStats({ half: 180 });
                    }}
                    className="hover:bg-[#181717] px-8 rounded-xl flex items-center gap-x-2 py-1.5"
                  >
                    <BsCalendar2Month />
                    <span>Last 6 Months</span>
                  </button>
                  <button
                    onClick={async () => {
                      handleTimeSelection('Last 3 Months');
                      await getOverviewStats({ quarter: 90 });
                    }}
                    className="hover:bg-[#181717] px-8 rounded-xl flex items-center gap-x-2 py-1.5"
                  >
                    <AiOutlineLoading3Quarters />
                    {/* <TbLoaderQuarter /> */}
                    <span>Last Quarter</span>
                  </button>
                  <button
                    onClick={async () => {
                      handleTimeSelection('Last Month');
                      await getOverviewStats({ month: 30 });
                    }}
                    className="hover:bg-[#181717] px-8 rounded-xl flex items-center gap-x-2 py-1.5"
                  >
                    <BsCalendar2Week />
                    <span>Last Month</span>
                  </button>
                  <button
                    onClick={async () => {
                      handleTimeSelection('Last Week');
                      await getOverviewStats({ week: 7 });
                    }}
                    className="hover:bg-[#181717] px-8 rounded-xl flex items-center gap-x-2 py-1.5"
                  >
                    <BsCalendar2Day />
                    <span>Last Week</span>
                  </button>
                </div>
              )}
            </div>
          </div>
          <div className="flex gap-x-[5%] lg:gap-x-10">
            <StatsCard
              amount={totalOrders}
              subHeading="Total Orders"
              percentage={parseFloat(totalOrdersGrowth).toFixed(1)}
              durationText={getTimeString(selectedTime)}
              increase={totalOrdersGrowth > 0 ? true : false}
              className={'!w-[230px]'}
            />
            <StatsCard
              amount={soldProducts}
              subHeading="Products Sold"
              percentage={parseFloat(soldProductsGrowth).toFixed(1)}
              durationText={getTimeString(selectedTime)}
              increase={soldProductsGrowth > 0 ? true : false}
              className={'!w-[230px]'}
            />
            <StatsCard
              amount={revenue}
              subHeading="Revenue"
              percentage={parseFloat(revenueGrowth).toFixed(1)}
              durationText={getTimeString(selectedTime)}
              increase={revenueGrowth > 0 ? true : false}
              currency="RWF"
              className={'!w-[230px]'}
            />
            <StatsCard
              amount={newUsers}
              subHeading="New Users"
              percentage={parseFloat(newUsersGrowth).toFixed(1)}
              durationText={getTimeString(selectedTime)}
              increase={newUsersGrowth > 0 ? true : false}
              className={'!w-[230px]'}
            />
            {/* <StatsCard
              amount="1K"
              subHeading="New Users"
              percentage="80"
              durationText="This month"
              increase={true}
            /> */}
          </div>
        </div>
        <div className="lg:h-[55%] w-full flex flex-col lg:flex-row my-5 lg:mt-0">
          <div className="lg:w-[60%] h-full overflow-y-auto custom-scrollbar">
            <div className="flex justify-between text-white lg:my-2 px-7">
              <h5 className="text-2xl">Recent Orders</h5>
              <button
                className="flex items-center text-gray-400 gap-x-2"
                onClick={() => handleItemClick()}
              >
                <span>View All</span>
                <IoIosArrowForward />
              </button>
            </div>
            <div className="justify-center hidden w-full py-2 lg:flex">
              {recentOrders.length > 0 ? (
                <TableBody orders={recentOrders} />
              ) : (
                <span className="text-center text-gray-500">
                  No recent orders
                </span>
              )}
            </div>
            <div className="flex flex-col justify-center w-full py-2 lg:hidden">
              {recentOrders.length > 0 ? (
                <RecentOrderCard orders={recentOrders} />
              ) : (
                <span className="text-center text-gray-500">
                  No recent orders
                </span>
              )}
            </div>
          </div>
          <div className="lg:w-[40%] h-full overflow-y-auto custom-scrollbar mb-5">
            <div className="flex justify-between my-2 text-white px-7">
              <h5 className="text-2xl">Stock Report</h5>
              <div className="relative">
                <button
                  onClick={toggleDropdown}
                  className="flex items-center px-2 border-[2px] border-gray-800 rounded-md gap-x-2"
                >
                  <span>{selectedStock}</span>
                  <IoIosArrowDown />
                </button>

                {isDropdownOpen && (
                  <div className="absolute flex flex-col mt-2 w-44 bg-stone-700 right-0 justify-center text-center rounded-xl shadow-lg z-[1000]">
                    <button
                      onClick={() => handleStockSelection('Ltd Stock')}
                      className="hover:bg-[#181717] px-8 rounded-xl flex items-center gap-x-2 py-1.5"
                    >
                      <FaSortAmountUpAlt />
                      <span>Ltd Stock</span>
                    </button>
                    <button
                      onClick={() => handleStockSelection('Most Stock')}
                      className="hover:bg-[#181717] px-8 rounded-xl flex items-center gap-x-2 py-1.5"
                    >
                      <FaSortAmountDown />
                      <span>Most Stock</span>
                    </button>
                  </div>
                )}
              </div>
            </div>

            <div
              className="flex flex-col justify-center w-full px-12 py-6 space-y-8 !cursor-not-allowed"
              title="Feature Coming Soon"
            >
              <StockReportBar name="Gold Satin" percentage="70%" stock="70" />
              <StockReportBar
                name="Dark Green Satin"
                percentage="90%"
                stock="96"
              />
              <StockReportBar
                name="Light Turquoise Satin"
                percentage="30%"
                stock="30"
              />
              <StockReportBar
                name="Dark Brown Satin"
                percentage="65%"
                stock="65"
              />
              <StockReportBar
                name="Black Silk with Gold Abstract Lines"
                percentage="50%"
                stock="50"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
