import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'

const ProductsSkeleton = ({ cards }) => {
  return (
    <div className={`flex flex-wrap justify-between`}>
      {Array(cards).fill(0).map((item, index) => (
        <Skeleton
          style={{ borderRadius: '15px', width: '250px', height: '310px', display: 'flex', flexFlow: 'row' }}
          key={index}
          baseColor='#1d1f1e'
          highlightColor='#292928'></Skeleton>
      ))}
    </div>
  );
};

export default ProductsSkeleton;
