import React from 'react';
import { Container, Footer, Header } from '../../components';
import SampleRequestForm from '../../components/Forms/SampleRequestForm';

function NewSamplesRequest() {
  return (
    <Container>
      <Header />

      <SampleRequestForm />

      <Footer />
    </Container>
  );
}

export default NewSamplesRequest;
