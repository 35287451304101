import { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  RemoveProductFromCart,
  updateCartProductQuantity,
  ViewCart,
} from '../Redux/Features/Cart/cartSlice';

export function useCartOperations() {
  const dispatch = useDispatch();
  const [productToRemove, setProductToRemove] = useState('');
  const [productToUpdateQuantity, setProductToUpdateQuantity] = useState('');
  const [action, setAction] = useState('');
  const [isUpdateQtyLoading, setIsUpdateQtyLoading] = useState({});

  const [showNotification, setShowNotification] = useState(false);

  const handleRemoveFromCart = (id) => {
    setProductToRemove(id);
  };

  const handleCloseNotification = () => {
    setShowNotification(false);
  };

  useEffect(() => {
    if (productToRemove !== '') {
      dispatch(RemoveProductFromCart({ id: productToRemove }))
        .then(() => {
          dispatch(ViewCart());
          setShowNotification(true);
        })
        .catch((error) => {
          console.error('Error removing product from cart:', error);
        });
    }
  }, [productToRemove, dispatch]);

  useEffect(() => {
    if (productToUpdateQuantity !== '') {
      dispatch(
        updateCartProductQuantity({
          productId: productToUpdateQuantity,
          action: action,
        }),
      )
        .then(() => {
          dispatch(ViewCart());
        })
        .catch((error) => {
          console.error('Error editing the cart item quantity:', error);
        });
    }
  }, [
    productToRemove,
    action,
    productToUpdateQuantity,

    isUpdateQtyLoading,
    setIsUpdateQtyLoading,
    dispatch,
  ]);

  return {
    handleRemoveFromCart,
    showNotification,
    handleCloseNotification,
  };
}
