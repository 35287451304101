import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import logger from 'redux-logger';
import thunk from 'redux-thunk';
import loginSlice from '../Features/Login/loginSlice';
import ViewCategory from '../Features/Products/categorySlice';
import vendorSlice from '../Features/Login/vendorSlice';
import addProduct from '../Features/product/addProductSlice';
import categorySlice from '../Features/product/categorySlice';
import subcategorySlice from '../Features/product/subcategorySlice';
import ViewsubcategorySlice from '../Features/Products/subcategorySlice';
import subSubcategorySlice from '../Features/Products/subSubcategorySlice';
import productSlice from '../Features/Products/productSlice';
import signupSlice from '../Features/Login/signupSlice';
import cartSlice from '../Features/Cart/cartSlice';
import sampleSlice from '../Features/Samples/sampleSlice';
import fabricFinderSlice from '../Features/Fabrics/fabricFinderSlice';
import fetchProductSlice from '../Features/product/fetchProductSlice';
import orderSlice from '../Features/Orders/orderSlice';
import checkoutSlice from '../Features/Orders/checkoutSlice';
import paymentSlice from '../Features/Orders/paymentSlice';
import forgotPasswordSlice from '../Features/Login/forgotPasswordSlice';
import feedbackSlice from '../Features/Feedback/feedbackSlice';
import wishlistSlice from '../Features/Wishlist/wishlistSlice';
import AdminDashboardSlice from '../Features/Dashboards/AdminDashboardSlice';
import VendorDashboardSlice from '../Features/Dashboards/VendorDashboardSlice';

const middleware = [...getDefaultMiddleware(), thunk];

if (process.env.NODE_ENV === 'development') {
  middleware.push(logger);
}

const store = configureStore({
  reducer: {
    login: loginSlice,
    signup: signupSlice,
    vendors: vendorSlice,
    category: ViewCategory,
    subSubcategory: subSubcategorySlice,
    subcategory: ViewsubcategorySlice,
    categories: categorySlice,
    subcategories: subcategorySlice,
    addProduct: addProduct,
    product: productSlice,
    cart: cartSlice,
    sampleSlice: sampleSlice,
    fabric: fabricFinderSlice,
    fetchProduct: fetchProductSlice,
    orders: orderSlice,
    checkout: checkoutSlice,
    payment: paymentSlice,
    resetPassword: forgotPasswordSlice,
    feedback: feedbackSlice,
    wishlist: wishlistSlice,
    adminDashboard: AdminDashboardSlice,
    vendorDashboard: VendorDashboardSlice,
  },
  middleware,
});

export default store;
