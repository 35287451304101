import React, { useEffect, useState } from 'react';
import { PiUserCircleThin } from 'react-icons/pi';
import Heading from '../Heading';
import Section from '../Section';

const ProfileMiddleSection = () => {
  const spacingStyles = 'mx-3 md:mx-10';
  const labelStyles = 'text-[#999999]';
  const cardStyles =
    'mx-2 md:mx-12 my-4 md:my-6 text-[#999999] border border-latestYellow rounded-md md:w-[70%] lg:w-[50%] xl:w-[40%] bg-[#292929] min-h-[100px] p-5 gap-3 flex flex-col';
  const userDetailsStyles = 'text-[#fff] cursor-pointer';

  const [loggedInUser, setLoggedInUser] = useState({});

  useEffect(() => {
    const userFromLocalStorage = {
      firstName: localStorage.getItem('firstName') || '',
      lastName: localStorage.getItem('lastName') || '',
      email: localStorage.getItem('email') || '',
      address: localStorage.getItem('address') || '',
      phoneNumber: localStorage.getItem('phoneNumber') || '',
      city: localStorage.getItem('city') || '',
      country: localStorage.getItem('country') || '',
    };

    setLoggedInUser(userFromLocalStorage);
  }, []);

  return (
    <div className="bg-gradient-to-b from-[#191919] via-[rgba(53, 47, 47, 0.00)] to-[#040404] mt-5 mx-5 md:mx-10 pt-2 pb-5">
      <Section className="flex items-center">
        <div className={`text-center flex gap-1.5 md:gap-3 ${spacingStyles}`}>
          <PiUserCircleThin className="h-[65px] w-[65px] md:h-24 md:w-24 text-latestYellow mx-auto cursor-pointer" />
          <Heading className="text-[#fff] text-[20px] md:text-[30px] justify-center items-center flex">
            {loggedInUser.firstName} {loggedInUser.lastName}'s Profile
          </Heading>
        </div>
      </Section>

      <Section className={cardStyles}>
        <label className={labelStyles}>Account Holder</label>
        <Heading className={userDetailsStyles}>
          {loggedInUser.firstName} {loggedInUser.lastName}
        </Heading>
      </Section>

      <Section className={cardStyles}>
        <label className={`${labelStyles} text-latestYellow`}>
          CONTACT DETAILS
        </label>
        <div>
          <label className={labelStyles}>Email</label>
          <Heading className={userDetailsStyles}>{loggedInUser.email}</Heading>
        </div>
        <div>
          <label className={labelStyles}>Telephone number</label>
          <Heading className={userDetailsStyles}>
            {loggedInUser.phoneNumber}
          </Heading>
        </div>
      </Section>

      <Section className={cardStyles}>
        <label className={`${labelStyles} text-latestYellow`}>LOCATION</label>
        <div>
          <label className={labelStyles}>Address</label>
          <Heading className={userDetailsStyles}>
            {loggedInUser.address}
          </Heading>
        </div>
        <div>
          <label className={labelStyles}>City</label>
          <Heading className={userDetailsStyles}>{loggedInUser.city}</Heading>
        </div>
      </Section>
    </div>
  );
};

export default ProfileMiddleSection;
