import Section from '../Section';
import { imageUrlsHomePage } from '../../../utils/images';

const HomeDiscoverSection = () => {
  const rightSideDivStyles =
    'h-[80px] md:h-[120px] md:w-[120px] w-[80px] border border-[#272727] rounded-[10px] md:rounded-[20px] bg-[#18181C]';

  const backgroundImageStyles = 'bg-center bg-no-repeat bg-cover';

  return (
    <Section className="justify-center md:justify-normal container md:ml-[0px] flex flex-row gap-[15px] md:gap-[20px] mt-[50px] md:mt-[180px] relative md:w-5">
      {/* Left */}
      <Section className="flex flex-col flex-shrink-0 gap-[15px] md:gap-[20px] md:ml-[140px]">
        <div className={rightSideDivStyles}></div>
        <div
          style={{
            backgroundImage: `url(${imageUrlsHomePage.left1})`,
          }}
          className={`border border-[#27de67] ${rightSideDivStyles} ${backgroundImageStyles}`}
        ></div>
        <div className={rightSideDivStyles}></div>
        <div
          style={{ backgroundImage: `url(${imageUrlsHomePage.left2})` }}
          className={`border border-[#27f8c7] ${rightSideDivStyles} ${backgroundImageStyles}`}
        ></div>
      </Section>

      {/* Middle */}
      <Section className="flex flex-col flex-shrink-0 gap-[15px] md:gap-[20px] mt-[-90px]">
        <div
          style={{ backgroundImage: `url(${imageUrlsHomePage.middle1})` }}
          className={`${rightSideDivStyles} ${backgroundImageStyles} border border-[#FFC700]`}
        ></div>
        <div className={rightSideDivStyles}></div>
        <div
          style={{ backgroundImage: `url(${imageUrlsHomePage.middle2})` }}
          className={`${rightSideDivStyles} ${backgroundImageStyles} border border-[#E209A6]`}
        ></div>
        <div className={rightSideDivStyles}></div>
      </Section>

      {/* Right */}
      <Section className="flex flex-col flex-shrink-0 gap-[15px] md:gap-[20px]">
        <div className={rightSideDivStyles}></div>
        <div
          style={{ backgroundImage: `url(${imageUrlsHomePage.right})` }}
          className={`${rightSideDivStyles} ${backgroundImageStyles} border border-[#86EF00]`}
        ></div>
        <div className={rightSideDivStyles}></div>
        <div className={rightSideDivStyles}></div>
      </Section>
    </Section>
  );
};

export default HomeDiscoverSection;
