import React, { useState } from 'react';
import { IoIosArrowDown } from 'react-icons/io';

export const DashboardDropdown = ({
  options,
  onSelect,
  selectedOption,
  className,
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  return (
    <div className={`relative ${className}`}>
      <button
        onClick={toggleDropdown}
        className="flex items-center px-2 border-[2px] border-gray-800 rounded-md gap-x-2"
      >
        <span>{selectedOption}</span>
        <IoIosArrowDown />
      </button>
      {isDropdownOpen && (
        <div className="absolute flex flex-col mt-2 w-52 bg-stone-700 right-0 justify-center text-center rounded-xl shadow-lg z-[1000]">
          {options.map((option) => (
            <button
              key={option.value}
              onClick={() => {
                onSelect(option.value);
                setIsDropdownOpen(false);
              }}
              className="hover:bg-[#181717] px-8 rounded-xl flex items-center gap-x-2 py-1.5"
            >
              {option.icon && <option.icon />}
              <span>{option.label}</span>
            </button>
          ))}
        </div>
      )}
    </div>
  );
};
