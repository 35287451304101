import React from 'react';
import { RiShoppingCart2Line } from 'react-icons/ri';

const EmptyCartContent = () => {
  return (
    <div className="mx-5 rounded-lg md:h-[50vh] flex flex-col items-center justify-center py-10 lg:pt-0 ">
      <RiShoppingCart2Line className="text-5xl text-latestYellow" />
      <p className="text-gray-400 text-[19px] md:text-[30px] font-[200] my-2">
        Oops, your cart is empty...
      </p>
    </div>
  );
};

export default EmptyCartContent;
