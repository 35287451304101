import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Sidebar } from './Sidebar';
import { GetAllProducts } from '../../../Redux/Features/Products/productSlice';
import { Image, Section } from '../../common';
import { SufuriLogo } from '../../../utils';

export const SidebarContent = ({ selectedOption, setSelectedOption }) => {
  const sortedProducts = useSelector((state) => state.product.data.data) || [];

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleGoHome = () => {
    if (sortedProducts.length > 0) {
      dispatch(GetAllProducts());
      setTimeout(() => {
        navigate('/home');
      }, 2000);
    } else {
      navigate('/home');
    }
  };
  return (
    <div>
      <div className="hidden lg:block w-[7%] left-0 fixed h-full border-r-2 border-gray-700">
        <div
          className="flex flex-col items-center w-full cursor-pointer mb-[70%] mt-[30%]"
          onClick={handleGoHome}
        >
          <Image
            className="w-[5.5rem] h-auto bg-transparent opacity-60"
            src={SufuriLogo}
            alt="sufuri-logo"
          />
        </div>
        <Sidebar
          selectedOption={selectedOption}
          setSelectedOption={setSelectedOption}
        />
      </div>
      <Section className="fixed flex justify-center w-full bottom-5 lg:hidden">
        <div className="w-[80%]">
          <div className="rounded-full bg-[#181717] py-3 px-3 gap-x-1 flex justify-around">
            <Sidebar
              selectedOption={selectedOption}
              setSelectedOption={setSelectedOption}
            />
          </div>
        </div>
      </Section>
    </div>
  );
};
