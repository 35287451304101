import { useState } from 'react';
import Categories from './Categories';
import { Link } from 'react-router-dom';

const NavbarCategories = ({ className }) => {
  const [dropdownIndex, setDropdownIndex] = useState(null);

  const toggleDropdown = (index) => {
    if (dropdownIndex === index) {
      setDropdownIndex(null);
    } else {
      setDropdownIndex(index);
    }
  };
  return (
    <div
      className={`hidden md:flex justify-center items-center md:gap-[10px] ${className}`}
    >
      <Link to="/product/new-arrivals">
        <div className="text-[13px] mb-2 text-[white] py-1 px-2 cursor-pointer hover:text-[#ad7f34]">
          New Arrivals
        </div>
      </Link>
      <Categories
        toggleDropdown={toggleDropdown}
        dropdownIndex={dropdownIndex}
      />
      <div className="text-sm px-2 mt-[-8px]  py-[2px] text-[#fff] border border-[#5b5b5b] bg-[#2a2a2a] rounded-full hover:text-[#ffffff7c]">
        Request Fabrics
      </div>
    </div>
  );
};

export default NavbarCategories;
