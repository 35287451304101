import { Link } from 'react-router-dom';
import StaticSlidingImages from '../../assets/sliderImages/staticScrollingProducts.png';
import {
  GetStartedNav,
  Container,
  Section,
  Heading,
  Paragraph,
  Button,
  Image,
} from '../../components';

function LandingPage() {
  return (
    <Container className="bg-[#040404]">
      <GetStartedNav />
      <Section className="flex flex-col md:flex-row">
        <div className="md:w-[57%] md:ml-[33px] ml-[25px] mr-[15px] xl:ml-[140px] mb-[30px] relative z-10">
          <Heading className="text-[#ffffffff] text-[25px] lg:text-[28px] w-full lg:w-[750px] lg:mt-[150px] mt-[50px] md:mt-[150px] xl:mt-[150px]">
            Upgrade your designs with our curated{' '}
            <br className="hidden lg:block md:block xl:block" />
            collection of tailoring essentials.
          </Heading>

          <Paragraph className="text-[#c9c9c9] text-[18px] font-thin pt-10">
            Shop smarter, sew better - your one-stop destination for
            <br className="hidden lg:block md:block xl:block" /> tailor-approved
            supplies.{' '}
            <Link
              to="/auth/signup"
              className="text-yellow-500 underline underline-offset-1"
            >
              Learn more.
            </Link>
          </Paragraph>

          <Link to="/auth/signup">
            <Button
              className="w-[270px] h-[50px] text-[20px] tracking-[3px] font-medium bg-gradient-to-r from-[#ffcc00] via-[#e6b608c7] to-[#4c3c03] rounded-[10px] mt-10 mx-auto md:mx-0"
              style={{ display: 'block' }}
            >
              Get started
            </Button>
          </Link>
        </div>

        <div className="absolute top-[140px] right-0 z-0">
          {/* Vertical Image Slider 👇🏽 */}
          <Section className="hidden lg:block xl:block">
            <Image
              src={StaticSlidingImages}
              alt="Static-Sliding-Images"
              className="min-h-[600px] w-[930px]"
            />
          </Section>
        </div>
      </Section>
    </Container>
  );
}

export default LandingPage;
