import { Container, Header, Footer } from '../../components';
import { BodySubcategory } from '../../components/addSubcategory';

function AddSubcategory() {
  return (
    <Container>
      <div className="min-h-screen">
        <Header />
        <BodySubcategory />
        <Footer />
      </div>
    </Container>
  );
}
export default AddSubcategory;
