import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DashboardDropdown } from '../Shared/DashboardDropdown';
import { OrdersTableMenu } from '../Shared/OrdersTableMenu';
import { OrdersTableBody } from '../Shared/OrdersTableBody';
import { OrdersTableHead } from '../Shared/OrdersTableHead';
import { RiSearchLine } from 'react-icons/ri';
import { IoIosArrowDown } from 'react-icons/io';
import ReChartAreaGraph from '../Vendor/ReChartAreaGraph';
import { ViewOrders } from '../../../Redux/Features/Orders/orderSlice';
import { BsCalendar2Month, BsCalendar2Week } from 'react-icons/bs';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { OrdersStatCard } from '../Shared/OrdersStatCard';
import { GetAverageOrderFrequency } from '../../../Redux/Features/Dashboards/AdminDashboardSlice';
import { GetVendorOverviewStats } from '../../../Redux/Features/Dashboards/VendorDashboardSlice';

export const OrdersContent = () => {
  const [isOptionDropdownOpen, setIsOptionDropdownOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState('Last Month');
  const [selectedOrderOption, setSelectedOrderOption] = useState('All');
  const [isOrdersFetched, setIsOrdersFetched] = useState(false);
  const [isFetchingDone, setIsFetchingDone] = useState(false);
  const [isAOFFetchingDone, setIsAOFFetchingDone] = useState(false);
  const [fetchedOrders, setFetchedOrders] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [filteredPendingOrders, setFilteredPendingOrders] = useState([]);
  const [filteredNewOrders, setFilteredNewOrders] = useState([]);
  const [filteredPaidOrders, setFilteredPaidOrders] = useState([]);
  const [filteredFailedOrders, setFilteredFailedOrders] = useState([]);
  const [filteredShippedOrders, setFilteredShippedOrders] = useState([]);
  const [filteredDelivered, setFilteredDelivered] = useState([]);
  const [filteredProcessing, setFilteredProcessing] = useState([]);
  const [averageOrderFrequency, setAverageOrderFrequency] = useState('__');
  const [ordersGraphData, setOrdersGraphData] = useState([]);
  const [isChartDataFetched, setIsChartDataFetched] = useState(false);

  let ordersData = useSelector((state) => state?.orders?.data) || [];
  let aofData = useSelector((state) => state?.adminDashboard?.aof) || [];
  let isChartDataLoading =
    useSelector((state) => state?.vendorDashboard?.status) || [];
  let orderChartData =
    useSelector((state) => state?.vendorDashboard?.data) || [];
  const dispatch = useDispatch();

  const handleOptionSelection = (selection) => {
    setSelectedOption(selection);
    setIsOptionDropdownOpen(false);

    switch (selection) {
      case 'Last 6 Months':
        return getAOF({ half: 180 });
      case 'Last 3 Months':
        return getAOF({ quarter: 90 });
      case 'Last Month':
        return getAOF({ month: 30 });

      default:
        getAOF({ month: 30 });
        break;
    }
  };

  const options = [
    { label: 'Last 6 Months', value: 'Last 6 Months', icon: BsCalendar2Month },
    {
      label: 'Last Quarter',
      value: 'Last 3 Months',
      icon: AiOutlineLoading3Quarters,
    },
    { label: 'Last Month', value: 'Last Month', icon: BsCalendar2Week },
    //* Add more options
  ];

  useEffect(() => {
    if (isOrdersFetched) {
      setFetchedOrders(ordersData);
      setFilteredOrders(ordersData);
      setFilteredPendingOrders(
        ordersData.filter((order) => order.paymentStatus === 'pending'),
      );
      setFilteredPaidOrders(
        ordersData.filter((order) => order.paymentStatus === 'paid'),
      );
      setFilteredFailedOrders(
        ordersData.filter((order) => order.paymentStatus === 'failed'),
      );

      setFilteredNewOrders(
        [...ordersData]
          .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
          .slice(0, 10), //!FOR TEMPORARY CASES
      );
      setIsFetchingDone(true);
      setIsOrdersFetched(false);
    }
  }, [isOrdersFetched]);

  useEffect(() => {
    if (isAOFFetchingDone) {
      setAverageOrderFrequency(aofData.averageOrderFrequency);
      setIsAOFFetchingDone(false);
    }
    if (isChartDataFetched) {
      setOrdersGraphData(orderChartData.monthlyOrderGraphData);
    }
  }, [isAOFFetchingDone, isChartDataFetched]);

  useEffect(() => {
    switch (selectedOrderOption) {
      case 'All':
        return setFilteredOrders(fetchedOrders);
      case 'New':
        return setFilteredOrders(filteredNewOrders);
      case 'Pending':
        return setFilteredOrders(filteredPendingOrders);
      case 'Paid':
        return setFilteredOrders(filteredPaidOrders);
      case 'Failed':
        return setFilteredOrders(filteredFailedOrders);
      default:
        return setFilteredOrders(fetchedOrders);
    }
  }, [selectedOrderOption]);

  // const provideShippedOrders = () => {
  //   const filteredOrders = fetchedOrders.filter(
  //     (order) => order.deliveryStatus === 'shipped',
  //   );
  //   setFilteredShippedOrders(filteredOrders);
  //   return;
  // };
  // const provideDeliveredOrders = () => {
  //   const filteredOrders = fetchedOrders.filter(
  //     (order) => order.deliveryStatus === 'delivered',
  //   );
  //   setFilteredDelivered(filteredOrders);
  //   return;
  // };
  // const provideProcessingOrders = () => {
  //   const filteredOrders = fetchedOrders.filter(
  //     (order) => order.deliveryStatus === 'processing',
  //   );
  //   setFilteredProcessing(filteredOrders);
  //   return;
  // };

  useEffect(() => {
    dispatch(ViewOrders())
      .unwrap()
      .then(() => {
        setIsOrdersFetched(true);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    dispatch(GetVendorOverviewStats({}))
      .unwrap()
      .then(() => {
        setIsChartDataFetched(true);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    dispatch(
      GetAverageOrderFrequency({ week: 0, month: 30, quarter: 0, half: 0 }),
    )
      .unwrap()
      .then(() => {
        setIsAOFFetchingDone(true);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const getAOF = async ({ week, month, quarter, half }) => {
    await dispatch(GetAverageOrderFrequency({ week, month, quarter, half }))
      .unwrap()
      .then(() => {
        setIsAOFFetchingDone(true);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <div className="text-white lg:pl-[7%] w-[100vw] min-h-screen py-4 mb-14 lg:mb-0">
      <div className="ml-[2%] mr-[3.5%] flex flex-col">
        <h4 className="text-2xl">Orders</h4>
        <div className="flex gap-x-4 my-[3%] lg:my-[2%] overflow-x-auto custom-scrollbar">
          <OrdersStatCard
            header="New Orders"
            value={isFetchingDone ? filteredNewOrders.length : 0}
          />
          <OrdersStatCard
            header="Pending Orders"
            value={isFetchingDone ? filteredPendingOrders.length : 0}
          />
          <OrdersStatCard
            header="Paid Orders"
            value={isFetchingDone ? filteredPaidOrders.length : 0}
          />
          <OrdersStatCard
            header="Failed Orders"
            value={isFetchingDone ? filteredFailedOrders.length : 0}
          />
          <OrdersStatCard
            header="Avg. Order Frequency"
            value={averageOrderFrequency === null ? 0 : averageOrderFrequency}
          />
          <OrdersStatCard
            header="Avg. Order Value"
            value={isFetchingDone ? filteredFailedOrders.length : 0}
          />
        </div>

        <div className="flex flex-col h-[40%]">
          <div className="flex justify-between">
            <span className="text-lg">Orders Overview</span>
            <DashboardDropdown
              options={options}
              onSelect={(selectedOption) =>
                handleOptionSelection(selectedOption)
              }
              selectedOption={selectedOption}
            />
          </div>
          {isChartDataLoading === 'loading' ? (
            <p className="mt-10 md:mt-2 text-[#5f5f5f]">Loading...</p>
          ) : (
            <div className="my-7">
              <ReChartAreaGraph data={ordersGraphData} />
            </div>
          )}
        </div>

        <div className="flex flex-col gap-y-4">
          <OrdersTableMenu
            setSelectedOrderOption={setSelectedOrderOption}
            selectedOrderOption={selectedOrderOption}
          />
          <div className="flex justify-center">
            <div className="relative w-[90%]" title="Search Coming Soon">
              <input
                type="text"
                placeholder="Search order name..."
                // value={searchTerm}
                // onChange={handleInputChange}
                className="!cursor-not-allowed py-2 xl:px-7 px-[7%] pr-5 md:pr-8 w-full h-8 text-[13.5px] border border-[#1515143a] text-white rounded-full focus:outline-none focus:ring focus:border-[#100e0e30] bg-[#181717]"
                disabled
              />
              <RiSearchLine className="!cursor-not-allowed absolute w-5 h-5 text-gray-500 transform top-2/4 -translate-y-2/4 right-3" />
            </div>
          </div>

          <div
            className={`${
              window.innerWidth >= 1024
                ? ''
                : 'overflow-x-auto custom-scrollbar'
            }`}
          >
            <table className="w-full">
              <OrdersTableHead />
              {filteredOrders.length > 0 ? (
                <OrdersTableBody orders={filteredOrders} />
              ) : (
                <span className="text-gray-500">
                  No orders for the selected option
                </span>
              )}
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
