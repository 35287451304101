const homeFinalSectionDetails = [
  {
    svg: 'https://res.cloudinary.com/dlw8ohn6p/image/upload/v1692021215/Free_Sample_Icon_o9gtfc.png',
    title: 'Rich Fabric Selection',
    description: 'Discover diverse, Locally-sourced fabrics',
  },
  {
    svg: 'https://res.cloudinary.com/dlw8ohn6p/image/upload/v1692021527/Group_1251_k5gjir.png',
    title: 'Tailoring Supplies:',
    description: 'All essentials at your fingertips.',
  },
  {
    svg: 'https://res.cloudinary.com/dlw8ohn6p/image/upload/v1692021215/Free_Sample_Icon_o9gtfc.png',
    title: 'Free Sample Delivery:',
    description: 'Convenient and unlimited samples.',
  },
  {
    svg: 'https://res.cloudinary.com/dlw8ohn6p/image/upload/v1692021346/Moodboard_Icon_qartqj.png',
    title: 'Moodboard Creation:',
    description: 'Inspire and refine your designs, then add to cart.',
  },
];

export default homeFinalSectionDetails;
