import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { BASE_URL, ADMINTOKEN } from '../../../utils/apiUtils';

// Fetch products from the external API
export const fetchCategories = createAsyncThunk(
  'fetch-category',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${BASE_URL}api/products/category/all`,
        {
          headers: {
            Authorization: `Bearer ${ADMINTOKEN}`,
            'Content-Type': 'application/json',
          },
        },
      );
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  },
);
export const Createcat = createAsyncThunk(
  'add-category',
  async (createcat, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${BASE_URL}api/products/category/`,
        createcat,
        {
          headers: {
            Authorization: `Bearer ${ADMINTOKEN}`,
            'Content-Type': 'application/json',
          },
        },
      );
      return response.data;
    } catch (error) {
      if (error.response.status === 422) {
        return rejectWithValue(error.response.data.errors[0].message);
      } else {
        return rejectWithValue(error.response.data.message);
      }
    }
  },
);

const CategorySlice = createSlice({
  name: 'categories',
  initialState: {
    createcat: null,
    status: null,
    error: null,
    data: [],
    emailVerified: false,
  },
  extraReducers: {
    [Createcat.pending]: (state) => {
      state.status = 'loading';
    },
    [Createcat.fulfilled]: (state, action) => {
      state.data = action.payload;
      state.status = 'success';
    },
    [Createcat.rejected]: (state, action) => {
      state.status = 'failed';
      state.Error = action.payload;
    },
    [fetchCategories.pending]: (state) => {
      state.status = 'loading.....';
    },
    [fetchCategories.fulfilled]: (state, action) => {
      state.data = action.payload;
      state.status = 'success';
    },
    [fetchCategories.rejected]: (state, action) => {
      state.status = 'failed';
      state.Error = action.payload;
    },
  },
});

export default CategorySlice.reducer;
