import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

const TermsAndConditions = ({ onChange, checked }) => {
  return (
    <div className="flex flex-grid gap-[10px] items-center justify-center">
      <label htmlFor="check-box-1" className="ml-[5px] lg:ml-0 mt-[5px]">
        <input
          type="checkbox"
          name="checked" // Add name attribute
          id="check-box-1"
          className="appearance-none border-[1px] h-4 w-4 rounded-[4px] border-[#ca8a23] cursor-pointer"
          checked={checked} // Set checked based on the prop
          onChange={onChange}
        />
        <FontAwesomeIcon
          icon={faCheck}
          className="text-2xl text-[#ca8a23] h-3 w-3 ml-[-14px] mb-[5px] cursor-pointer text-opacity-0 check-1"
        />
      </label>
      <label
        htmlFor="termsCheckbox"
        className="text-[#c9c9c9c9] text-[13px] sm:text-[12px]"
      >
        I have read and accept the{' '}
        <span className="text-[#3131ffdd]">Terms and Conditions</span>
      </label>
    </div>
  );
};

export default TermsAndConditions;
