const parseProductInfo = (inputProductString) => {
  if (inputProductString) {
    const productString = inputProductString.split('\n');

    const productInfo = [
      { key: 'Color', value: null },
      { key: 'Pattern', value: null },
      { key: 'Length', value: null },
      { key: 'Width', value: null },
      { key: 'Recommended Use', value: null },
    ];

    productString.forEach((line, index) => {
      const [key, value] = line.split(':').map((item) => item.trim());
      if (key && value) {
        productInfo[index].value = value;
      }
    });

    return productInfo;
  }

  return [];
};
export default parseProductInfo;

// const parseProductInfo = (inputProductString) => {
//   const productString = inputProductString.split('\n');

//   const productInfo = [
//     { key: 'Color', value: null },
//     { key: 'Pattern', value: null },
//     { key: 'Length', value: null },
//     { key: 'Width', value: null },
//     { key: 'Recommended Use', value: null },
//   ];

//   productString.forEach((line, index) => {
//     const [key, value] = line.split(':').map((item) => item.trim());
//     if (key && value) {
//       productInfo[index].value = value;
//     }
//   });

//   return productInfo;
// };

// export default parseProductInfo;
