import {
  AuthNavbar,
  Container,
  Heading,
  Paragraph,
  Section,
  LoginForm,
  AuthRightSection,
} from '../../components';

function Login() {
  return (
    <Container className="bg-black">
      <AuthNavbar />
      <Section className="flex flex-col lg:flex-row ml-5 mr-5 lg:ml-[220px]">
        <Section className="flex flex-col mb-[30px] mx-auto lg:mx-0 mt-8 lg:mt-90px p-7 rounded-[15px] max-w-[426px] lg:w-[426px] lg:mb-10 bg-gradient-to-b from-[#191919] via-[#191919] to-[#040404]">
          <Heading className="mt-8 text-3xl font-medium text-white lg:text-4xl">
            Sign in
          </Heading>

          <Paragraph className="text-[#888888] text-base lg:text-lg pt-6">
            Fill in the login form to shop tailoring
            <br /> essentials and access SufuriMart services.
          </Paragraph>

          <LoginForm />
        </Section>

        <AuthRightSection />
      </Section>
    </Container>
  );
}

export default Login;
