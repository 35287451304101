import {
  Routes,
  Route,
  createBrowserRouter,
  createRoutesFromElements,
} from 'react-router-dom';
import {
  AboutUs,
  ForgotPassword,
  HomePage,
  LandingPage,
  Login,
  Signup,
  AddProduct,
  BrowseProducts,
  Addcategory,
  AddSubcategory,
  ViewProductDetails,
  ViewCart,
  NewSamplesRequest,
  SavedSamples,
  Forbidden,
  NotFound,
  CheckoutPage,
} from '../pages';
import AddSubsubcategory from '../pages/AddSubsubcategory';
import { FabricFinder, SavedFabricRequests } from '../pages/Fabric';
import { RootLayout } from '../layouts';
import { RequireAuth } from '../components/RequireAuth';
import BrowseCategoryProducts from '../pages/Products/BrowseCategoryProducts';
import ResetPassword from '../pages/Auth/ResetPassword';
import { NewArrivals } from '../pages/Products';
import { Feedback } from '../pages/Feedback';
import { ViewProfile } from '../pages/Profile';
import ComingSoon from '../pages/ComingSoon';
import Wishlist from '../pages/Wishlist/Wishlist';
import { SearchResultsPage } from '../pages/Search';
import VendorDashboard from '../pages/Dashboard/VendorDashboard';
import AdminDashboard from '../pages/Dashboard/AdminDashboard';

export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<RootLayout />}>
      <Route index element={<LandingPage />} />
      <Route path="home" element={<HomePage />} />

      <Route path="auth">
        <Route path="signup" element={<Signup />} />
        <Route path="login" element={<Login />} />
        <Route path="forgot-password" element={<ForgotPassword />} />
        <Route path="*" element={<NotFound />} />
      </Route>
      <Route path="reset-password/redirect" element={<ResetPassword />} />

      <Route path="about-us" element={<AboutUs />} />
      <Route path="forbidden" element={<Forbidden />} />
      <Route path="*" element={<NotFound />} />
      <Route path="404" element={<NotFound />} />

      <Route path="product">
        <Route path="admin" element={<RequireAuth allowedRoles={['admin']} />}>
          <Route path="add-product" element={<AddProduct />} />
          <Route path="add-category" element={<Addcategory />} />
          <Route path="add-subcategory" element={<AddSubcategory />} />
          <Route path="add-Subsubcategory" element={<AddSubsubcategory />} />
          <Route path="*" element={<NotFound />} />
        </Route>

        <Route path="browse-products">
          <Route index element={<BrowseProducts />} />
          <Route
            path="category/:id/:isMob"
            element={<BrowseCategoryProducts />}
          />
          <Route path="*" element={<NotFound />} />
        </Route>

        <Route path="new-arrivals" element={<NewArrivals />} />
        <Route path="view/:id" element={<ViewProductDetails />} />
        <Route path="*" element={<NotFound />} />
      </Route>

      <Route path="cart" element={<ViewCart />} />
      <Route path="wishlist" element={<Wishlist />} />

      <Route path="samples">
        <Route path="saved" element={<SavedSamples />} />
        <Route path="new-request" element={<NewSamplesRequest />} />
        <Route path="*" element={<NotFound />} />
      </Route>

      <Route path="fabric">
        <Route path="finder" element={<FabricFinder />} />
        <Route path="saved" element={<SavedFabricRequests />} />
        <Route path="*" element={<NotFound />} />
      </Route>

      <Route path="checkout" element={<CheckoutPage />} />
      <Route path="coming-soon" element={<ComingSoon />} />

      <Route path="feedback" element={<Feedback />} />
      <Route path="profile" element={<ViewProfile />} />

      <Route path="search/:theSearchTerm" element={<SearchResultsPage />} />

      <Route path="dashboard">
        <Route
          path="vendor"
          element={<RequireAuth allowedRoles={['vendor']} />}
        >
          <Route index element={<VendorDashboard />} />
          <Route path="*" element={<NotFound />} />
        </Route>
        <Route path="admin" element={<RequireAuth allowedRoles={['admin']} />}>
          <Route index element={<AdminDashboard />} />
        </Route>
      </Route>
    </Route>,
  ),
);

//  For every new route added, remember to check if it has to be added in the root layout or not
