import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './Redux/Store';
import './index.css';
import 'react-toastify/dist/ReactToastify.css';
import App from './App';
import { inject } from '@vercel/analytics';

const rootElement = document.getElementById('root');
inject();
createRoot(rootElement).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
