import React from 'react';
import { Container, Footer, Header } from '../../components';
import { FeedbackForm } from '../../components/Forms';

const Feedback = () => {
  return (
    <Container>
      <Header />

      <FeedbackForm />

      <Footer />
    </Container>
  );
};

export default Feedback;
