import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCategories } from '../../Redux/Features/product/categorySlice';

const Categories = () => {
  let categories = useSelector((state) => state.categories.data);
  const [categoriesFetched, setCategoriesFetched] = useState(false);
  const dispatch = useDispatch();

  const { id: navigatedCategoryId } = useParams();

  useEffect(() => {
    if (!categoriesFetched) {
      dispatch(fetchCategories());
      setCategoriesFetched(true);
    }
  }, [dispatch]);

  const navigate = useNavigate();

  const handleNavigate = (id) => {
    navigate(`/product/browse-products/category/${id}/false`);
  };
  return (
    <ul className="flex flex-wrap list-none">
      {Array.isArray(categories) &&
        categories.map((category, index) => (
          <li
            key={index}
            className={`md:flex md:flex-row text-[13px] mb-2 py-1 px-2 rounded gap-[3px] cursor-pointer hover:text-[#ad7f34] ${
              navigatedCategoryId === category._id
                ? 'text-[#ad7f34]'
                : 'text-[white]'
            }`}
            onClick={() => handleNavigate(category._id)}
          >
            {category.name}
          </li>
        ))}
    </ul>
  );
};

export default Categories;
