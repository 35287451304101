import React, { useState, useEffect } from 'react';

const PushNotification = ({ message, onClose, className }) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(true);

    const timer = setTimeout(() => {
      setIsVisible(false);
      onClose();
    }, 2500);

    return () => clearTimeout(timer);
  }, [onClose]);

  return (
    <div
      className={`fixed z-[9999] bg-gradient-to-b from-[#040404] to-[#18181C] border-[1px] border-latestYellow top-1.5 md:top-2 left-0 right-0 flex justify-center rounded-[15px] w-[60%] md:w-[25%] mx-auto text-white p-3 transition-transform duration-500 ${
        isVisible ? 'slide-in' : 'slide-out'
      }`}
    >
      <p className="text-[13px]">{message}</p>
    </div>
  );
};

export default PushNotification;
