import React from 'react';
import { Provider } from 'react-redux';
import { router } from './routes';
import store from './Redux/Store';
import { RouterProvider } from 'react-router-dom';

const App = () => {
  return (
    <Provider store={store}>
      <RouterProvider router={router} />
    </Provider>
  );
};

export default App;
