import axios from 'axios';
import { ADMINTOKEN, BASE_URL } from '../../../utils/apiUtils';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

export const FindFabric = createAsyncThunk(
  'findFabric',
  async ({ formData }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${BASE_URL}api/fabric/request/`,
        {
          name: formData?.name,
          notes: formData?.notes,
          imageUrl: formData?.imageUrl,
          deadlineDate: formData?.deadlineDate,
        },
        {
          headers: {
            Authorization: `Bearer ${ADMINTOKEN}`,
            'Content-Type': 'application/json',
          },
        },
      );

      return response.data.newRequest;
    } catch (error) {
      if (error.response.status === 422) {
        return rejectWithValue(error.response.data.errors[0].message);
      } else {
        return rejectWithValue(error.response.data.message);
      }
    }
  },
);

export const GetOwnerFabrics = createAsyncThunk(
  'getOwnerFabrics',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${BASE_URL}api/fabric/request/user`, {
        headers: {
          Authorization: `Bearer ${ADMINTOKEN}`,
          'Content-Type': 'application/json',
        },
      });

      return response.data;
    } catch (error) {
      if (error.response.status === 404) {
        return rejectWithValue(error.response.data.errors[0].message);
      } else {
        return rejectWithValue(error.response.data.message);
      }
    }
  },
);

export const DeleteOwnerFabric = createAsyncThunk(
  'deleteOwnerFabric',
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.delete(
        `${BASE_URL}api/fabric/request/${id}`,
        {
          headers: {
            Authorization: `Bearer ${ADMINTOKEN}`,
            'Content-Type': 'application/json',
          },
        },
      );

      return response.data.fabricRequestId;
    } catch (error) {
      if (error.response.status === 404) {
        return rejectWithValue(error.response.data.errors[0].message);
      } else {
        return rejectWithValue(error.response.data.message);
      }
    }
  },
);

export const UpdateOwnerFabric = createAsyncThunk(
  'updateOwnerFabric',
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.put(`${BASE_URL}api/fabric/request/${id}`, {
        headers: {
          Authorization: `Bearer ${ADMINTOKEN}`,
          'Content-Type': 'application/json',
        },
      });

      return response.data.updatedProductCat;
    } catch (error) {
      if (error.response.status === 404) {
        return rejectWithValue(error.response.data.errors[0].message);
      } else {
        return rejectWithValue(error.response.data.message);
      }
    }
  },
);

const FabricFinderSlice = createSlice({
  name: 'Sample',
  initialState: {
    status: null,
    error: null,
    data: [],
    deletedFabricId: null,
  },

  extraReducers: {
    [FindFabric.pending]: (state) => {
      state.status = 'loading';
    },
    [FindFabric.fulfilled]: (state, action) => {
      state.data = action.payload;
      state.status = 'success';
    },
    [FindFabric.rejected]: (state, action) => {
      state.status = 'failed';
      state.Error = action.payload;
    },
    [GetOwnerFabrics.pending]: (state) => {
      state.status = 'loading';
    },
    [GetOwnerFabrics.fulfilled]: (state, action) => {
      state.data = action.payload;
      state.status = 'success';
    },
    [GetOwnerFabrics.rejected]: (state, action) => {
      state.status = 'failed';
      state.Error = action.payload;
    },
    [DeleteOwnerFabric.pending]: (state) => {
      state.status = 'loading';
    },
    [DeleteOwnerFabric.fulfilled]: (state, action) => {
      state.deletedFabricId = action.payload;
      state.status = 'success';
      state.data = state.data.filter((fabric) => fabric._id !== action.payload);
    },
    [DeleteOwnerFabric.rejected]: (state, action) => {
      state.status = 'failed';
      state.Error = action.payload;
    },
  },
});

export default FabricFinderSlice.reducer;
