import React from 'react';
import {
  Container,
  Footer,
  Header,
  Heading,
  Paragraph,
  ProductCard,
  Section,
} from '../../components';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { GetAllProductsWithPagination } from '../../Redux/Features/Products/productSlice';
import 'react-loading-skeleton/dist/skeleton.css';
import ProductsSkeleton from '../../components/skeletons/ProductsSkeleton';
import Skeleton from 'react-loading-skeleton';
import Pagination from '../../components/Pagination';
import { useLocation } from 'react-router-dom';
import { SearchProduct } from '../../Redux/Features/Products/productSlice';

function NewArrivals() {
  // Number of products that will be displayed per page
  const dispatch = useDispatch();
  let numberOfDisplayedProducts = 16;
  let products = useSelector((state) => state.product.data);
  let { totalPages, currentPage, totalCount } = useSelector(
    (state) => state.product.data,
  );
  const [theCurrentPage, setTheCurrentPage] = useState(1);

  const loadingStatus = useSelector((state) => state.product.status);
  const fetchError = useSelector((state) => state.product.Error);

  const location = useLocation();
  // Getting the search term from the URL
  const searchTerm = new URLSearchParams(location.search).get('search');

  useEffect(() => {
    currentPage === undefined || null
      ? setTheCurrentPage(1)
      : setTheCurrentPage(currentPage);
  }, [currentPage]);

  useEffect(() => {
    dispatch(
      GetAllProductsWithPagination({
        page: currentPage,
        sortBy: 'createdAt',
        order: -1,
        limit: numberOfDisplayedProducts,
      }),
    );
    if (searchTerm) dispatch(SearchProduct(searchTerm));
  }, [dispatch]);

  // This function will run when the user changes the page <Pagination>
  const handlePageChange = (newPage) => {
    // Fetch or update data for the new page here
    dispatch(
      GetAllProductsWithPagination({
        page: currentPage,
        sortBy: 'createdAt',
        order: -1,
        limit: numberOfDisplayedProducts,
      }),
    );
  };

  return (
    <Container>
      <Header />
      {/* Top Section with the Page Title and Sorting dropdown  */}
      <Section className="flex flex-col text-white md:flex-row md:justify-between md:mr-[9%]">
        <div className="flex-col hidden md:flex md:flex-row md:items-center">
          <div className="text-[#AAAAAA] text-[28px] font-[500] mb-4 md:mb-0 ml-[20px] py-[5px] md:py-[20px] md:ml-[50px]">
            {loadingStatus === 'loading' ? (
              <Skeleton
                width={200}
                baseColor="#1d1f1e"
                highlightColor="#292928"
              />
            ) : loadingStatus === 'failed' ? (
              <></>
            ) : (
              <Heading className="text-[#AAAAAA] text-[28px] hidden md:block font-[500] sm:mb-4 md:mb-0">
                New Arrivals
              </Heading>
            )}
          </div>
        </div>
        <div className="justify-center flex flex-row md:items-center font-[200] my-2 md:my-auto gap-[20px]">
          <div className="flex flex-row md:flex-row items-center text-[12px]">
            {loadingStatus === 'loading' ? (
              <Skeleton
                width={100}
                height={40}
                baseColor="#1d1f1e"
                highlightColor="#292928"
              />
            ) : loadingStatus === 'failed' ? (
              <></>
            ) : (
              <div className="flex flex-row w-[30%] pt-3 md:pt-0">
                <Paragraph className="text-[#c2c2c2e9] mb-2 md:mb-0">
                  {totalCount === undefined ? products.length : totalCount}
                </Paragraph>
                <Paragraph className="text-[#c2c2c2e9]">
                  &nbsp;results
                </Paragraph>{' '}
              </div>
            )}
          </div>
        </div>
      </Section>

      <Section className="flex flex-col lg:flex-row">
        {/* Right part */}
        <Section className="md:w-[90%] my-5 mx-[7%] md:mx-auto">
          {loadingStatus === 'loading' ? (
            <ProductsSkeleton cards={12} />
          ) : loadingStatus === 'failed' ? (
            <h1 className="text-center text-gray-500 mt-[20%] mx-auto justify-center">
              {fetchError}
            </h1>
          ) : (
            <ProductCard
              className="grid grid-cols-2 grid-rows-2 md:flex md:flex-wrap justify-between md:justify-center md:items-center gap-[20px] md:gap-[30px]"
              products={products}
            />
          )}
        </Section>
      </Section>
      <Pagination
        currentPage={theCurrentPage}
        currentPageChange={currentPage}
        totalPages={totalPages}
        onPageChange={handlePageChange}
        className="flex justify-center px-5 mx-auto my-12 md-12"
      />
      <Footer />
    </Container>
  );
}

export default NewArrivals;
