import HomePageImage1 from '../assets/images/home-page-img1.png';
import HomePageImage2 from '../assets/images/home-page-img1.1.png';
import redTuxedo from '../assets/images/red-tuxedo.png';

const imageUrlsHomePage = {
  left1:
    'https://res.cloudinary.com/dlw8ohn6p/image/upload/v1689506410/classic-hero_w8gdkw.jpg',
  left2:
    'https://res.cloudinary.com/dlw8ohn6p/image/upload/v1689506411/sewing-machine-bgWhite_thsa2g.avif',
  middle1:
    'https://res.cloudinary.com/dlw8ohn6p/image/upload/v1689506410/black-buttons-bgWhite_morqnh.jpg',
  middle2:
    'https://res.cloudinary.com/dlw8ohn6p/image/upload/v1689506410/rose-suit-material_ym3vsv.webp',
  right:
    'https://res.cloudinary.com/dlw8ohn6p/image/upload/v1689506411/white-tapeMeasure_k00m3o.avif',
};

export { HomePageImage1, HomePageImage2, redTuxedo, imageUrlsHomePage };
