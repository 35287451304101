import { BsChatText } from 'react-icons/bs';
import { FiSettings } from 'react-icons/fi';
import { HiOutlineViewGrid } from 'react-icons/hi';
import { HiMiniUsers } from 'react-icons/hi2';
import { TbClipboardText } from 'react-icons/tb';

const SidebarOptions = {
  overview: {
    title: 'Overview',
    icon: <HiOutlineViewGrid />,
  },
  users: {
    title: 'Users',
    icon: <HiMiniUsers />,
  },
  orders: {
    title: 'Orders',
    icon: <TbClipboardText />,
  },
  feedback: {
    title: 'Feedback',
    icon: <BsChatText />,
  },
  settings: {
    title: 'Settings',
    icon: <FiSettings />,
  },
};

export const EmptyOptions = [
  'users',
  'feedback',
  'settings',
  'customers',
  'shop',
  'analytics',
];

const orderImage1 =
  'https://images.jacobinmag.com/wp-content/uploads/2016/06/28201917/muhammad_ali_photo_by_stanley_weston_archive_photos_getty_482857506.jpg';
const orderImage2 =
  'https://upload.wikimedia.org/wikipedia/commons/thumb/5/5c/Kanye_West_at_the_2009_Tribeca_Film_Festival_%28crop_2%29.jpg/640px-Kanye_West_at_the_2009_Tribeca_Film_Festival_%28crop_2%29.jpg';
const orderImage3 =
  'https://i.pinimg.com/474x/3e/62/21/3e6221a9f082ef2bb372e20f18fd7f22.jpg';
const orderImage4 =
  'https://lionsgate.brightspotcdn.com/1d/90/8fc75de5411e985f3a2def98358d/johnwick4-section-promo-double-home-03.jpg';
const orderImage5 =
  'https://image-cdn.essentiallysports.com/wp-content/uploads/Mike-Tyson-blunt.jpg?width=600';
const orderImage6 =
  'https://res.cloudinary.com/dlw8ohn6p/image/upload/v1691477056/43a_lslqip.jpg';

const dummyOrders = [
  {
    user_name: 'M Ali',
    product_image: orderImage1,
    product_name: 'Light Turquoise Satin',
    order_date: '12-01-2023',
    price: '60,000',
  },
  {
    user_name: 'Ye',
    product_image: orderImage2,
    product_name: 'Gold & Black Satin',
    order_date: '20-04-2023',
    price: '10,000',
  },
  {
    user_name: 'Agent Smith',
    product_image: orderImage3,
    product_name: 'Gold Satin',
    order_date: '05-06-2023',
    price: '20,000',
  },
  {
    user_name: 'John Wick',
    product_image: orderImage4,
    product_name: 'Dark Brown Satin',
    order_date: '10-08-2023',
    price: '30,000',
  },
  {
    user_name: 'Mike Tyson',
    product_image: orderImage5,
    product_name: 'Dark Green Satin',
    order_date: '27-10-2023',
    price: '40,000',
  },
  {
    user_name: 'John Doe',
    product_image: orderImage6,
    product_name: 'Blue & Black Sweater',
    order_date: '15-11-2019',
    price: '5,000',
  },
];

export { SidebarOptions, dummyOrders };
