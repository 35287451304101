import React from 'react';
import {
  Container,
  Footer,
  Header,
  SavedSampleRequests,
} from '../../components';

function SavedSamples() {
  return (
    <Container>
      <Header />
      <SavedSampleRequests />
      <Footer />
    </Container>
  );
}

export default SavedSamples;
