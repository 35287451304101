import React from 'react';
import {
  AuthNavbar,
  AuthRightSection,
  Container,
  Heading,
  Paragraph,
  Section,
} from '../../components';
import ResetPasswordForm from '../../components/Forms/ResetPasswordForm';

function ResetPassword() {
  return (
    <Container className="bg-black">
      <AuthNavbar />
      <Section className="flex flex-col lg:flex-row lg:items-center ml-5 mr-5 lg:ml-[220px]">
        <Section className="flex flex-col mb-[30px] bg-[#1D1D1D] mx-auto lg:mx-0 mt-8 lg:mt-90px p-7 rounded-[15px] max-w-[426px] lg:w-[426px] lg:mb-0">
          <Heading className="mt-8 text-3xl font-medium text-white lg:text-4xl">
            Reset Password
          </Heading>

          <Paragraph className="text-[#888888] text-base lg:text-lg pt-6">
            Fill in your new password
          </Paragraph>

          <ResetPasswordForm />
        </Section>

        <AuthRightSection />
      </Section>
    </Container>
  );
}

export default ResetPassword;
