import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { SufuriLogo } from '../../utils';
import {
  RiSearchLine,
  RiShoppingCartLine,
  RiUserLine,
  RiCloseLine,
  RiMenuLine,
  RiShoppingCartFill,
} from 'react-icons/ri';
import { MdOutlineDashboardCustomize } from 'react-icons/md';
import { Heading, Image, Input, Section } from '../common';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../Redux/Features/Login/loginSlice';
import HamburgerMenuContent from './HamburgerMenuContent';
import { GetAllProducts } from '../../Redux/Features/Products/productSlice';
import { fetchCategories } from '../../Redux/Features/product/categorySlice';
import { LoginPopup } from '../modal';
import { ViewCart } from '../../Redux/Features/Cart/cartSlice';
import { FiHeart } from 'react-icons/fi';
import MobileViewDropdown from './MobileViewDropdown';
import { TbHeartPlus } from 'react-icons/tb';
import { BiCategoryAlt } from 'react-icons/bi';

const Navbar = () => {
  let products = useSelector((state) => state.product.data);
  let categories = useSelector((state) => state.categories.data);
  const wishlist = useSelector((state) => state?.wishlist?.data) || [];

  const cartProducts =
    useSelector((state) => state?.cart?.data?.cartProducts) || [];

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const sortedProducts = useSelector((state) => state.product.data.data) || [];
  const [filteredOptions, setFilteredOptions] = useState([products]);
  const [searchTerm, setSearchTerm] = useState('');
  const [categoriesFetched, setCategoriesFetched] = useState(false);
  const [showLoginPopup, setShowLoginPopup] = useState(false);
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
  const [showAllProducts, setShowAllProducts] = useState(false);
  const [isCartSelected, setIsCartSelected] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isVendor, setIsVendor] = useState(false);

  const navigate = useNavigate();
  const dropdownRef = useRef(null);
  const dispatch = useDispatch();

  const toggleDropdown = () => {
    return new Promise((resolve, reject) => {
      const isLoggedIn = localStorage.getItem('token');
      if (!isLoggedIn) {
        setIsUserLoggedIn(false);
        setIsDropdownOpen((prevDropdown) => !prevDropdown);
        return;
      }
      setIsDropdownOpen((prevDropdown) => !prevDropdown);
      setIsUserLoggedIn(true);
    });
  };

  const toggleDropdownWeb = () => {
    return new Promise((resolve, reject) => {
      const isLoggedIn = localStorage.getItem('token');
      if (!isLoggedIn) {
        setIsUserLoggedIn(false);
        setIsDropdownOpen((prevDropdown) => !prevDropdown);
        return;
      }

      setIsDropdownOpen((prevDropdown) => !prevDropdown);
      setIsUserLoggedIn(true);
    });
  };

  const dropDownStyles =
    'px-4 py-2 cursor-pointer hover:text-[#000000] hover:bg-[#5b5b5b]';

  const handleOutsideClick = (event) => {
    if (dropdownRef.current && dropdownRef.current.contains(event.target)) {
      setIsDropdownOpen(false);
    }
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (dropdownRef.current && dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    const handleMobileMenuToggle = () => {
      if (isMobileMenuOpen) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = 'auto';
      }
    };

    window.addEventListener('click', handleOutsideClick);
    handleMobileMenuToggle();
    return () => {
      window.removeEventListener('click', handleOutsideClick);
      document.body.style.overflow = 'auto';
    };
  }, [isMobileMenuOpen]);

  const handleInputChange = (event) => {
    if (sortedProducts.length > 0) {
      dispatch(GetAllProducts());
    } else {
      const newSearchTerm = event.target.value;
      setSearchTerm(newSearchTerm);

      const filteredOptions = products.filter((product) =>
        product.name.toLowerCase().includes(newSearchTerm.toLowerCase()),
      );
      setFilteredOptions(filteredOptions);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      navigate(`/search/${searchTerm}`);
      setSearchTerm('');
    }
  };

  const handleSearchPageRedirect = () => {
    if (searchTerm) {
      navigate(`/search/${searchTerm}`);
      setSearchTerm('');
    }
  };

  const handleLogout = () => {
    dispatch(logout());
    navigate('/auth/login');
  };

  const handleGoToCart = () => {
    setIsCartSelected(true);
    return new Promise((resolve, reject) => {
      const isLoggedIn = localStorage.getItem('token');
      if (!isLoggedIn) {
        setShowLoginPopup(true);
        return;
      }

      setTimeout(() => {
        dispatch(ViewCart());
      }, 100);

      navigate('/cart');
    });
  };

  const handleGoToWishlist = () => {
    setIsCartSelected(false);
    return new Promise(() => {
      const isLoggedIn = localStorage.getItem('token');
      if (!isLoggedIn) {
        setShowLoginPopup(true);
        return;
      }

      navigate('/wishlist');
    });
  };

  const handleGoHome = () => {
    if (sortedProducts.length > 0) {
      dispatch(GetAllProducts());
      setTimeout(() => {
        navigate('/home');
      }, 2000);
    } else {
      navigate('/home');
    }
  };

  useEffect(() => {
    if (!categoriesFetched) {
      dispatch(fetchCategories());
      setCategoriesFetched(true);
    }
  }, [dispatch]);

  useEffect(() => {
    const role = localStorage.getItem('role');
    if (role === 'admin') {
      setIsAdmin(true);
    } else if (role === 'vendor') {
      setIsVendor(true);
    }
  }, []);

  const loggedInUser = {
    firstName: localStorage.getItem('firstName') || '',
    lastName: localStorage.getItem('lastName') || '',
  };

  const displayedProducts = showAllProducts
    ? filteredOptions
    : filteredOptions.slice(0, 10);

  const location = useLocation();

  return (
    <nav>
      {/* Mobile menu */}
      <div className="flex justify-between px-2 md:hidden">
        {/* <Section className="flex items-center justify-center"> */}
        <Section className="flex mt-4">
          {isMobileMenuOpen ? (
            // Hamburger menu icon when mobile menu is open
            <RiCloseLine
              className="items-center w-[25px] h-[25px] mr-2 text-white cursor-pointer"
              onClick={toggleMobileMenu}
            />
          ) : (
            // Hamburger menu icon when mobile menu is closed
            <RiMenuLine
              className="items-center w-6 h-6 mr-2 text-white cursor-pointer"
              onClick={toggleMobileMenu}
            />
          )}
        </Section>

        <Section>
          {/* <Link> */}
          <div className="flex mt-4 cursor-pointer" onClick={handleGoHome}>
            <Image
              className="w-[90px] md:w-[90px] bg-transparent opacity-60 mr-[-20px] mt-[-27px]"
              src={SufuriLogo}
              alt="sufuri-logo"
            />
            <span className="text-[#9D7100] text-[18px] md:text-3xl logo-text">
              SufuriMart
            </span>
          </div>
          {/* </Link> */}
        </Section>

        {/* Mobile view icons 👇🏽 */}

        <div className="flex relative gap-2" ref={dropdownRef}>
          <div>
            {/* Profile icon */}
            <button
              className="flex items-center gap-1 ml-2 mt-[18px]"
              onClick={toggleDropdown}
              aria-label="Profile"
            >
              <div>
                <RiUserLine
                  className={`h-6 w-6 text-[#B1ADAD] ${
                    isDropdownOpen ? 'text-yellow-500' : 'text-[#B1ADAD]'
                  }`}
                />
              </div>
            </button>
            {isDropdownOpen && (
              <div className="absolute right-0 mt-2 w-72 bg-[#040404] rounded-[20px] shadow-lg z-[1000]">
                <ul className="py-2 text-white">
                  {isUserLoggedIn ? (
                    <li className="px-4 py-2 flex gap-1 border-b border-[#434343]">
                      <span>
                        <RiUserLine className="h-6 w-6 text-[#FFD704]" />
                      </span>
                      {loggedInUser.firstName} {loggedInUser.lastName}
                    </li>
                  ) : (
                    <></>
                  )}

                  <Link to="/product/browse-products">
                    <li className={dropDownStyles}>Browse Products</li>
                  </Link>
                  <Link to="/profile">
                    {isUserLoggedIn ? (
                      <li className={dropDownStyles}>User Profile</li>
                    ) : (
                      <></>
                    )}
                  </Link>
                  {/* <li className={dropDownStyles}>Order History</li> */}
                  <Link to="/samples/saved">
                    {isUserLoggedIn ? (
                      <li className={dropDownStyles}>Sample Requests</li>
                    ) : (
                      <></>
                    )}
                  </Link>
                  <Link to="/fabric/saved">
                    {isUserLoggedIn ? (
                      <li className={dropDownStyles}>Saved Fabrics</li>
                    ) : (
                      <></>
                    )}
                  </Link>
                  {isAdmin ? (
                    <Link to="/dashboard/admin">
                      {isUserLoggedIn ? (
                        <li className={dropDownStyles}>Dashboard</li>
                      ) : (
                        <></>
                      )}
                    </Link>
                  ) : isVendor ? (
                    <Link to="/dashboard/vendor">
                      {isUserLoggedIn ? (
                        <li className={dropDownStyles}>Dashboard</li>
                      ) : (
                        <></>
                      )}
                    </Link>
                  ) : (
                    <></>
                  )}
                  {/* <li className={dropDownStyles}>My Moodboards</li> */}
                  {/* <li className={dropDownStyles}>Settings</li> */}
                  <li className="px-4 py-2 cursor-pointer hover:text-[#79691c] border-t border-[#434343]">
                    <button onClick={handleLogout}>
                      {isUserLoggedIn ? 'Logout' : 'Login'}
                    </button>
                  </li>
                </ul>
              </div>
            )}
          </div>

          <MobileViewDropdown
            handleGoToWishlist={handleGoToWishlist}
            handleGoToCart={handleGoToCart}
            cartProducts={cartProducts}
            location={location}
            dropdownRef={dropdownRef}
            toggleDropdown={toggleDropdown}
            isDropdownOpen={isDropdownOpen}
            isUserLoggedIn={isUserLoggedIn}
            dropDownStyles={dropDownStyles}
            isAdmin={isAdmin}
            isVendor={isVendor}
            handleLogout={handleLogout}
            wishlist={wishlist}
          />
        </div>

        <div
          className={`${
            isMobileMenuOpen ? 'block z-[9999]' : 'hidden'
          }  fixed top-[70px] mr-5 bg-[#040404] left-0 h-[100vh] overflow-y-scroll rounded-[10px] w-[65%] border border-[#323232] bg-brown bg-opacity-95 p-4`}
        >
          <HamburgerMenuContent setIsMobileMenuOpen={setIsMobileMenuOpen} />
        </div>
      </div>

      {/* Regular desktop navigation */}
      <div className="hidden md:flex items-center justify-between bg-brown p-4 md:gap-[40px] lg:gap-[100px] xl:gap-[200px] md:mx-[20px]">
        {/* <Link> */}
        <div
          className="flex items-center cursor-pointer md:gap-1"
          onClick={handleGoHome}
        >
          <Image
            className="w-[90px] md:w-[90px] bg-transparent opacity-60"
            style={{ height: 'auto', marginRight: '-10px' }}
            src={SufuriLogo}
            alt="sufuri-logo"
          />
          <span className="text-[#8f692c] text-[18px] md:text-[26px] pt-1 logo-text">
            SufuriMart
          </span>
        </div>
        {/* </Link> */}

        {/* Search */}
        <form
          onSubmit={handleSearchPageRedirect}
          className="hidden mx-4 md:block md:relative md:flex-grow md:mx-0"
        >
          <div>
            <Input
              type="text"
              placeholder="Search fabrics, supplies, colors, patterns, and more!"
              value={searchTerm}
              onChange={handleInputChange}
              onKeyPress={(e) => handleKeyPress(e)}
              className={`py-2 xl:px-9 md:px-[7%] pr-5 md:pr-8 w-full h-8 text-[13.5px] border border-[#1515143a] text-white rounded-[16px] ${
                searchTerm
                  ? 'rounded-b-[0px] rounded-t-[16px] border-b py-10'
                  : ''
              } focus:outline-none focus:border-[#100e0e30] bg-[#100E0E]`}
            />
            <RiSearchLine
              onClick={handleSearchPageRedirect}
              onKeyPress={handleKeyPress}
              className="absolute w-5 h-5 text-gray-500 transform cursor-pointer top-2/4 -translate-y-2/4 right-3"
            />

            {searchTerm !== '' && filteredOptions.length > 0 && (
              <Section className="w-[100%] absolute z-[3000] lg:justify-center custom-scrollbar rounded-bl-lg rounded-b-lg bg-[#100E0E] max-h-64 overflow-y-auto">
                <div className="mb-[20px] px-2">
                  {/* {filteredOptions.map((product) => ( */}
                  {displayedProducts.map((product) => (
                    <Link to={`/product/view/${product._id}`} key={product._id}>
                      <Section className="flex justify-between lg:justify-center flex-row my-4 h-auto lg:w-[90%] rounded-[10px] p-2 lg:px-5 lg:mx-6  hover:bg-[#191919]">
                        <img
                          src={product?.imageUrl[0]}
                          alt={product?.name}
                          className="rounded-md w-9 h-9"
                        />
                        <Section className="flex flex-col ml-2 text-black w-[78%]">
                          <div>
                            <Heading className="text-[12px] lg:text-[15px] text-white truncate">
                              {product?.name}
                            </Heading>
                          </div>
                          <span className="text-xs text-[#9aa0a6]">
                            {
                              (
                                categories.find(
                                  (cat) => cat._id === product?.categoryId,
                                ) || {}
                              ).name
                            }
                          </span>
                        </Section>

                        <div className="flex gap-3 cursor-pointer lg:justify-center lg:ml-auto">
                          {/* <button className="flex justify-center cursor-pointer lg:justify-center gap-1 w-14 lg:w-6 lg:gap-2 mx-auto lg:text-base h-5 lg:h-[20px] text-white my-auto rounded-md">
                          <RiSearchLine className="w-5 h-5 text-gray-500" />
                        </button>{' '} */}
                        </div>
                      </Section>
                    </Link>
                  ))}
                  {!showAllProducts && filteredOptions.length > 10 && (
                    <p
                      onClick={() => {
                        navigate(`/search/${searchTerm}`);
                        setSearchTerm('');
                      }}
                      className="flex justify-center ml-auto text-sm cursor-pointer text-latestYellow hover:text-latestYellow/70"
                    >
                      See all {filteredOptions.length} results
                    </p>
                  )}
                </div>
              </Section>
            )}
          </div>
        </form>

        <div className="flex items-center gap-[20px] md:gap-[20px]">
          {/* Wishlist */}
          <div onClick={handleGoToWishlist} className="cursor-pointer">
            {location.pathname === '/wishlist' ? (
              <FiHeart className="w-5 h-5 text-latestYellow fill-latestYellow" />
            ) : wishlist.length > 0 ? (
              <TbHeartPlus className="w-5 h-5 text-latestYellow" />
            ) : (
              <FiHeart className="w-5 h-5 text-[#B1ADAD]" />
            )}
          </div>

          {/* Moodboard */}
          <Link to="/coming-soon">
            <MdOutlineDashboardCustomize className="h-5 w-5 text-[#B1ADAD] mr-1" />
          </Link>

          {/* Cart icon */}
          {/* <Link to="/cart"> */}
          <div
            className="flex items-center gap-[10px] cursor-pointer"
            onClick={handleGoToCart}
          >
            {cartProducts.length === 0 ? (
              <div className="relative flex">
                <RiShoppingCartLine className="h-6 w-6 text-[#B1ADAD]" />

                <span className="text-black absolute ml-[18px] mt-[-2px] text-[8px] bg-[#B1ADAD] rounded-full text-center items-center h-[12px] w-[12px] pt-[0.5px]">
                  {0}
                </span>
              </div>
            ) : (
              <div className="relative flex">
                <RiShoppingCartFill className="w-6 h-6 text-latestYellow" />

                <span
                  className={`text-black absolute ml-[18px] mt-[-2px] text-[8px] bg-latestYellow rounded-full text-center items-center  
                    ${
                      cartProducts.length > 9
                        ? 'h-[14px] w-[14px] pt-[1.5px]'
                        : 'h-[12px] w-[12px] pt-[0.5px]'
                    }
                    ${
                      cartProducts.length > 99
                        ? 'h-[16px] w-[16px] pt-[2.5px]'
                        : 'h-[12px] w-[12px] pt-[0.5px]'
                    }
                    `}
                >
                  {cartProducts.length || 0}
                </span>
              </div>
            )}
          </div>
          {/* </Link> */}

          <div className="relative" ref={dropdownRef}>
            {/* Profile icon */}
            <button
              className="flex items-center gap-1"
              onMouseEnter={toggleDropdownWeb}
              aria-label="Profile"
            >
              <div>
                <RiUserLine
                  className={`h-6 w-6 text-[#B1ADAD] ${
                    isDropdownOpen ? 'text-latestYellow' : 'text-[#B1ADAD]'
                  }`}
                />
              </div>
            </button>
            {isDropdownOpen && (
              <div className="absolute right-0 mt-2 w-72 bg-[#040404] rounded-[20px] shadow-lg z-[1000]">
                <ul className="py-2 text-white">
                  {isUserLoggedIn ? (
                    <li className="px-4 py-2 flex gap-1 border-b border-[#434343]">
                      <span>
                        <RiUserLine className="w-6 h-6 text-latestYellow" />
                      </span>
                      {loggedInUser.firstName} {loggedInUser.lastName}
                    </li>
                  ) : (
                    <></>
                  )}
                  <Link to="/product/browse-products">
                    <li className={dropDownStyles}>Browse Products</li>
                  </Link>
                  <Link to="/profile">
                    {isUserLoggedIn ? (
                      <li className={dropDownStyles}>User profile</li>
                    ) : (
                      <></>
                    )}
                  </Link>
                  {/* <li className={dropDownStyles}>Order History</li> */}
                  <Link to="/samples/saved">
                    {isUserLoggedIn ? (
                      <li className={dropDownStyles}>Sample Requests</li>
                    ) : (
                      <></>
                    )}
                  </Link>
                  <Link to="/fabric/saved">
                    {isUserLoggedIn ? (
                      <li className={dropDownStyles}>Saved Fabrics</li>
                    ) : (
                      <></>
                    )}
                  </Link>

                  {isAdmin ? (
                    <Link to="/dashboard/admin">
                      {isUserLoggedIn ? (
                        <li className={dropDownStyles}>Dashboard</li>
                      ) : (
                        <></>
                      )}
                    </Link>
                  ) : isVendor ? (
                    <Link to="/dashboard/vendor">
                      {isUserLoggedIn ? (
                        <li className={dropDownStyles}>Dashboard</li>
                      ) : (
                        <></>
                      )}
                    </Link>
                  ) : (
                    <></>
                  )}
                  {/* <li className={dropDownStyles}>My Moodboards</li> */}
                  {/* <li className={dropDownStyles}>Settings</li> */}
                  <li className="px-4 py-2 cursor-pointer hover:text-[#79691c] border-t border-[#434343]">
                    <button onClick={handleLogout}>
                      {isUserLoggedIn ? 'Logout' : 'Login'}
                    </button>
                  </li>
                </ul>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Search input on mobile devices */}
      <div className="flex justify-between mx-1">
        <form
          onSubmit={handleSearchPageRedirect}
          className="relative mb-4 flex-grow mr-1 md:hidden md:mx-0"
        >
          <Input
            type="text"
            placeholder="Search fabrics, supplies, patterns..."
            className={`py-2 pl-4 pr-10 w-full h-8 text-[13.5px] border-[#1b1b1a3a] text-white rounded-[16px] ${
              searchTerm
                ? 'rounded-b-[0px] rounded-t-[16px] border-b py-10'
                : ''
            } focus:outline-none focus:border-[#100e0e30] bg-[#100E0E]`}
            value={searchTerm}
            onChange={handleInputChange}
          />
          <RiSearchLine
            className={`${
              isMobileMenuOpen && 'hidden'
            } h-5 w-5 absolute mt-[-16px] transform -translate-y-2/4 right-3 text-gray-500`}
            onKeyPress={handleKeyPress}
            onClick={handleSearchPageRedirect}
          />

          {searchTerm !== '' && filteredOptions.length > 0 && (
            <Section className="w-[100%] absolute z-[3000] rounded-bl-lg rounded-b-lg bg-[#100E0E] backdrop-blur-none max-h-56 overflow-y-auto custom-scrollbar">
              <div className="mb-[20px] px-2">
                {/* {filteredOptions.map((product) => ( */}
                {displayedProducts.map((product) => (
                  <Link to={`/product/view/${product._id}`} key={product._id}>
                    <Section
                      key={product._id}
                      className="flex justify-center flex-row my-2 h-auto lg:w-[90%] rounded-[10px] p-2 lg:px-5 lg:mx-6 hover:bg-[#191919]"
                    >
                      <img
                        src={product?.imageUrl[0]}
                        alt={product?.name}
                        className="w-8 h-8 rounded-md"
                      />
                      <Section className="flex flex-col ml-2 text-black w-[70%]">
                        <div>
                          <Heading className="text-[12px] lg:text-[15px] text-white truncate">
                            {product?.name}
                          </Heading>
                        </div>

                        <span className="text-[10px] text-[#9aa0a6]">
                          {
                            (
                              categories.find(
                                (cat) => cat._id === product.categoryId,
                              ) || {}
                            ).name
                          }
                        </span>
                      </Section>

                      <div className="flex justify-center gap-3 ml-auto cursor-pointer">
                        {/* <button className="flex justify-center cursor-pointer gap-1 w-5 lg:gap-2 mx-auto text-xs lg:text-base h-4 lg:h-[20px] text-white my-auto rounded-md">
                        <RiSearchLine className="w-4 h-4 text-gray-500" />
                      </button> */}
                      </div>
                    </Section>
                  </Link>
                ))}
                {!showAllProducts && filteredOptions.length > 10 && (
                  <p
                    onClick={() => {
                      navigate(`/search/${searchTerm}`);
                      setSearchTerm('');
                    }}
                    className="flex justify-center ml-auto text-sm cursor-pointer text-latestYellow hover:text-latestYellow/70"
                  >
                    See all {filteredOptions.length} results
                  </p>
                )}
              </div>
            </Section>
          )}
        </form>
        <div className="my-auto mb-5 md:hidden">
          <Link to="/product/browse-products">
            <BiCategoryAlt
              className="text-[#6b6969] h-6 w-6"
              onKeyPress={handleKeyPress}
              onClick={handleSearchPageRedirect}
            />
          </Link>
        </div>
      </div>
      {showLoginPopup && (
        <LoginPopup
          message={`Please login to access the ${
            isCartSelected ? 'cart' : 'wishlist'
          }`}
          onClose={() => setShowLoginPopup(false)}
        />
      )}
    </nav>
  );
};

export default Navbar;
