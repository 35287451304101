import React from 'react';
import { useSelector } from 'react-redux';
import {
  Container,
  Footer,
  Header,
  SuggestedProducts,
  ViewCartMiddleSection,
} from '../../components';

function ViewCart() {
  const products = useSelector((state) => state?.product?.data) || [];

  return (
    <Container>
      <Header />

      <ViewCartMiddleSection />
      <div>
        <SuggestedProducts
          maxVisibleProducts={3}
          className="lg:mx-[5%] mx-[4%]"
          products={products}
        />
      </div>

      <Footer />
    </Container>
  );
}

export default ViewCart;
