import React from 'react';

export const OrdersTableHead = ({}) => {
  return (
    <thead className="text-xs text-white">
      <tr>
        <th scope="col" className="pr-4 lg:pr-6 py-3">
          Date
        </th>
        <th scope="col" className="pr-4 lg:pr-6 py-3">
          Product Name
        </th>
        <th scope="col" className="pr-4 lg:pr-6 py-3">
          Product Cost
        </th>
        <th scope="col" className="pr-4 lg:pr-6 py-3">
          Customer
        </th>
        <th scope="col" className="pr-4 lg:pr-6 py-3">
          Email Address
        </th>
        <th scope="col" className="pr-4 lg:pr-6 py-3">
          Status
        </th>
        <th scope="col" className="pr-4 lg:pr-6 py-3">
          Payment Method
        </th>
      </tr>
      <tr className="relative">
        <hr className="absolute w-full border border-gray-800 rounded-md" />
      </tr>
    </thead>
  );
};
