import { Link, useNavigate } from 'react-router-dom';
import { Categories } from '../home';
import { Navbar } from '../navbar';
import { useEffect, useRef, useState } from 'react';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import Button from './Buttons/Button';
import { LoginPopup } from '../modal';

const Header = () => {
  const [openModal, setOpenModal] = useState(false);
  const [showLoginPopup, setShowLoginPopup] = useState(false);
  const [isFabricButtonSelected, setIsFabricButtonSelected] = useState(false);

  const navigate = useNavigate();
  const modalRef = useRef();

  const toggleModal = () => {
    setOpenModal(!openModal);
  };

  const handleOutsideClick = (event) => {
    if (
      modalRef.current &&
      !modalRef.current.contains(event.target) &&
      event.target !== modalOpenButtonRef.current
    ) {
      setOpenModal(false);
    }
  };

  const modalOpenButtonRef = useRef();

  useEffect(() => {
    window.addEventListener('click', handleOutsideClick);

    return () => {
      window.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  const handleSamplesRedirect = () => {
    return new Promise((resolve, reject) => {
      setIsFabricButtonSelected(false);
      const isLoggedIn = localStorage.getItem('token');
      if (!isLoggedIn) {
        setOpenModal(false);
        setShowLoginPopup(true);
        return;
      }
      navigate('/samples/new-request');
    });
  };

  const handleFabricsRedirect = () => {
    return new Promise((resolve, reject) => {
      setIsFabricButtonSelected(true);
      const isLoggedIn = localStorage.getItem('token');
      if (!isLoggedIn) {
        setOpenModal(false);
        setShowLoginPopup(true);
        return;
      }
      navigate('/fabric/finder');
    });
  };

  return (
    <>
      <Navbar />
      <div className="hidden md:flex md:flex-wrap md:justify-center md:items-center gap-[10px] p-1">
        <Link to="/product/new-arrivals">
          <div className="text-[13px] mb-2 text-[white] py-1 px-2 cursor-pointer hover:text-[#ad7f34]">
            New Arrivals
          </div>
        </Link>
        <Categories />
        <button
          ref={modalOpenButtonRef}
          onClick={toggleModal}
          className="text-sm px-3 mt-[-8px] py-[6px] text-[#fff] border border-[#262525] bg-[#111010] rounded-full hover:text-[#ffffff7c]"
        >
          Request Fabrics
        </button>
      </div>

      {openModal && (
        <div
          ref={modalRef}
          className={`hidden ${
            showLoginPopup ? 'hidden' : ''
          } fixed top-[20vh] md:mx-[10vw] md:h-auto lg:mx-[15vw] md:w-[80vw] lg:w-[70vw] backdrop-blur-2xl h-[50vh] justify-items-center rounded-2xl z-[5000] md:flex md:flex-col no-scrollbar overflow-y-auto shadow-2xl`}
        >
          <div className="flex justify-end mt-8 mb-1 mr-10 lg:mb-0">
            <button className="w-6 h-6 rounded-full" onClick={toggleModal}>
              <AiOutlineCloseCircle className="w-6 h-6 fill-primaryYellow" />
            </button>
          </div>

          <div className="relative block mx-4 lg:mx-32">
            <h1 className="justify-center text-center text-white md:text-3xl lg:text-4xl">
              Find the Perfect Fabric for Your Project!
            </h1>
          </div>

          <div className="grid grid-cols-2 mt-20 text-white md:mb-14 md:mr-4 md:ml-8 lg:mx-10 place-items-center">
            <div className="flex flex-col mr-2">
              <h4>
                The Request Samples feature enables designers (client) to
                express interest in a particular product
              </h4>
              <div className="flex justify-center mt-14">
                <Button
                  onClick={handleSamplesRedirect}
                  className="mt-[5px] md:w-[70%] lg:w-[60%] border justify-center border-primaryYellow px-auto rounded-md h-[42px] hover:bg-[#2a241599] flex gap-4"
                >
                  <span className="justify-center text-primaryYellow">
                    Request Samples
                  </span>
                </Button>
              </div>
            </div>
            <div className="flex flex-col ml-2">
              <h4>
                The Fabric Finder feature helps designers find rare fabrics not
                easily available online or elsewhere
              </h4>
              <div className="flex justify-center mt-14">
                <Button
                  onClick={handleFabricsRedirect}
                  className="mt-[5px] md:w-[70%] lg:w-[60%] border justify-center border-primaryYellow px-auto rounded-md h-[42px] hover:bg-[#2a241599] flex gap-4"
                >
                  <span className="justify-center text-primaryYellow">
                    Fabric Finder
                  </span>
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
      {showLoginPopup && (
        <LoginPopup
          message={`Please login to access the ${
            isFabricButtonSelected ? 'fabric finder' : 'sample request'
          } feature.`}
          onClose={() => setShowLoginPopup(false)}
        />
      )}
    </>
  );
};
export default Header;
