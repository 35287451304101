import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { BASE_URL, ADMINTOKEN } from '../../../utils/apiUtils';

// Fetch products from the external API
export const GetAllProducts = createAsyncThunk(
  'browseProduct',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${BASE_URL}api/products/all/items`, {
        headers: {
          Authorization: `Bearer ${ADMINTOKEN}`,
          'Content-Type': 'application/json',
        },
      });
      return response.data.data;
    } catch (error) {
      if (error.response.status === 500) {
        return rejectWithValue(error.response.data.errors[0].message);
      } else {
        return rejectWithValue(error.response.data.message);
      }
    }
  },
);
export const GetAllProductsWithPagination = createAsyncThunk(
  'browse-Products-with-pagination',
  async ({ page, limit, sortBy, order }, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${BASE_URL}api/products?page=${page}&limit=${limit}&sortBy=${sortBy}&order=${order}`,
        {
          headers: {
            Authorization: `Bearer ${ADMINTOKEN}`,
            'Content-Type': 'application/json',
          },
        },
      );
      return response?.data?.data;
    } catch (error) {
      if (error.response.status === 500) {
        return rejectWithValue(error.response.data.errors[0].message);
      } else {
        return rejectWithValue(error.response.data.message);
      }
    }
  },
);
export const PaginateProvidedProducts = createAsyncThunk(
  'paginate-given-products',
  async (
    { page, limit, sortBy, order, productsToPaginate },
    { rejectWithValue },
  ) => {
    try {
      if (
        Array.isArray(productsToPaginate) &&
        productsToPaginate.length === 1 &&
        productsToPaginate[0].length === 0
      ) {
        productsToPaginate = [];
      }

      let endpoint = '';
      if (sortBy || order === undefined) {
        endpoint = `${BASE_URL}api/products/paginate/custom-products?page=${page}&limit=${limit}`;
      } else {
        endpoint = `${BASE_URL}api/products/paginate/custom-products?page=${page}&limit=${limit}&sortBy=${sortBy}&order=${order}`;
      }

      const response = await axios.post(
        `${endpoint}`,
        {
          productsToPaginate,
        },
        {
          headers: {
            Authorization: `Bearer ${ADMINTOKEN}`,
            'Content-Type': 'application/json',
          },
        },
      );

      return response.data;
    } catch (error) {
      if (error.response.status === 500) {
        return rejectWithValue(error.response.data.errors[0].message);
      } else {
        return rejectWithValue(error.response.data.message);
      }
    }
  },
);
export const SearchProduct = createAsyncThunk(
  'search-product',
  async (searchTerm, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${BASE_URL}api/products/all/items`, {
        headers: {
          Authorization: `Bearer ${ADMINTOKEN}`,
          'Content-Type': 'application/json',
        },
      });
      return response.data.data.filter((product) =>
        product.name.toLowerCase().includes(searchTerm.toLowerCase()),
      );
    } catch (error) {
      if (error.response.status === 500) {
        return rejectWithValue(error.response.data.errors[0].message);
      } else {
        return rejectWithValue(error.response.data.message);
      }
    }
  },
);
export const SortProduct = createAsyncThunk(
  'sortProduct',
  async (sortBy, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${BASE_URL}api/products?sortBy=${sortBy}`,
        {
          headers: {
            Authorization: `Bearer ${ADMINTOKEN}`,
            'Content-Type': 'application/json',
          },
        },
      );
      return response.data;
    } catch (error) {
      if (error.response.status === 500) {
        return rejectWithValue(error.response.data.errors[0].message);
      } else {
        return rejectWithValue(error.response.data.message);
      }
    }
  },
);
export const GetProductBySubcat = createAsyncThunk(
  'get-Product-by-subcat',
  async (subcatId, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${BASE_URL}api/products/subcat/${subcatId}`,
        {
          headers: {
            Authorization: `Bearer ${ADMINTOKEN}`,
            'Content-Type': 'application/json',
          },
        },
      );
      return response.data;
    } catch (error) {
      if (error.response.status === 500) {
        return rejectWithValue(error.response.data.errors[0].message);
      } else {
        return rejectWithValue(error.response.data.message);
      }
    }
  },
);

export const GetProductBySubcategories = createAsyncThunk(
  'get-Product-by-subcategories',
  async (
    { subcategories, page, limit, sortBy, order },
    { rejectWithValue },
  ) => {
    try {
      const response = await axios.post(
        `${BASE_URL}api/products/subcats?page=${page}&limit=${limit}&sortBy=${sortBy}&order=${order}`,
        { subcategories: subcategories },
        {
          headers: {
            Authorization: `Bearer ${ADMINTOKEN}`,
            'Content-Type': 'application/json',
          },
        },
      );
      return response.data;
    } catch (error) {
      if (error.response.status === 500) {
        return rejectWithValue(error.response.data.errors[0].message);
      } else {
        return rejectWithValue(error.response.data.message);
      }
    }
  },
);

export const GetProductsBySubSubcategories = createAsyncThunk(
  'get-Products-by-subSubcategories',
  async (
    { subSubcategories, page, limit, sortBy, order },
    { rejectWithValue },
  ) => {
    try {
      const response = await axios.post(
        `${BASE_URL}api/products/subsubcats?page=${page}&limit=${limit}&sortBy=${sortBy}&order=${order}`,
        { subSubcategories: subSubcategories },
        {
          headers: {
            Authorization: `Bearer ${ADMINTOKEN}`,
            'Content-Type': 'application/json',
          },
        },
      );
      return response.data;
    } catch (error) {
      if (error.response.status === 500) {
        return rejectWithValue(error.response.data.errors[0].message);
      } else {
        return rejectWithValue(error.response.data.message);
      }
    }
  },
);

export const GetProductByCategories = createAsyncThunk(
  'get-Product-by-categories',
  async ({ categories, page, limit, sortBy, order }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${BASE_URL}api/products/categories?page=${page}&limit=${limit}&sortBy=${sortBy}&order=${order}`,
        { categories: categories },
        {
          headers: {
            Authorization: `Bearer ${ADMINTOKEN}`,
            'Content-Type': 'application/json',
          },
        },
      );
      return response.data.data;
    } catch (error) {
      if (error.response.status === 500) {
        return rejectWithValue(error.response.data.errors[0].message);
      } else {
        return rejectWithValue(error.response.data.message);
      }
    }
  },
);

export const GetProductsByFilters = createAsyncThunk(
  'get-Product-by-filters',
  async (
    { categories, subcategories, subsubcategories, sortBy, order },
    { rejectWithValue },
  ) => {
    try {
      const response = await axios.post(
        `${BASE_URL}api/products/all/filters?sortBy=${sortBy}&order=${order}`,
        { categories, subcategories, subsubcategories },
        {
          headers: {
            Authorization: `Bearer ${ADMINTOKEN}`,
            'Content-Type': 'application/json',
          },
        },
      );

      return response.data.data;
    } catch (error) {
      if (error.response.status === 500) {
        return rejectWithValue(error.response.data.errors[0].message);
      } else {
        return rejectWithValue(error.response.data.message);
      }
    }
  },
);

const ProductSlice = createSlice({
  name: 'Product',
  initialState: {
    status: null,
    error: null,
    data: [],
    emailVerified: false,
    productsByFilters: [],
    paginatedProducts: [],
    paginatedProductsStatus: null,
  },
  extraReducers: {
    [GetAllProducts.pending]: (state) => {
      state.status = 'loading';
    },
    [GetAllProducts.fulfilled]: (state, action) => {
      state.data = action.payload;
      state.status = 'success';
    },
    [GetAllProducts.rejected]: (state, action) => {
      state.Error = action.payload;
      state.status = 'failed';
    },
    [SortProduct.pending]: (state) => {
      state.status = 'loading';
    },
    [SortProduct.fulfilled]: (state, action) => {
      state.data = action.payload;
      state.status = 'success';
    },
    [SortProduct.rejected]: (state, action) => {
      state.status = 'failed';
      state.Error = action.payload;
    },
    [GetProductBySubcat.pending]: (state) => {
      state.status = 'loading';
    },
    [GetProductBySubcat.fulfilled]: (state, action) => {
      state.data = action.payload;
      state.status = 'success';
    },
    [GetProductBySubcat.rejected]: (state, action) => {
      state.status = 'failed';
      state.Error = action.payload;
    },
    [GetAllProductsWithPagination.pending]: (state) => {
      state.status = 'loading';
    },
    [GetAllProductsWithPagination.fulfilled]: (state, action) => {
      state.data = action.payload;
      state.status = 'success';
    },
    [GetAllProductsWithPagination.rejected]: (state, action) => {
      state.status = 'failed';
      state.Error = action.payload;
    },

    [PaginateProvidedProducts.pending]: (state) => {
      state.paginatedProductsStatus = 'loading';
    },
    [PaginateProvidedProducts.fulfilled]: (state, action) => {
      state.paginatedProducts = action.payload;
      state.paginatedProductsStatus = 'success';
    },
    [PaginateProvidedProducts.rejected]: (state, action) => {
      state.paginatedProductsStatus = 'failed';
      state.paginatedProductsError = action.payload;
    },

    [GetProductBySubcategories.pending]: (state) => {
      state.status = 'loading';
    },
    [GetProductBySubcategories.fulfilled]: (state, action) => {
      state.data = action.payload;
      state.status = 'success';
    },
    [GetProductBySubcategories.rejected]: (state, action) => {
      state.status = 'failed';
      state.Error = action.payload;
    },
    [SearchProduct.pending]: (state) => {
      state.status = 'loading';
    },
    [SearchProduct.fulfilled]: (state, action) => {
      state.data = action.payload;
      state.status = 'success';
    },
    [SearchProduct.rejected]: (state, action) => {
      state.status = 'failed';
      state.Error = action.payload;
    },
    [GetProductsBySubSubcategories.pending]: (state) => {
      state.status = 'loading';
    },
    [GetProductsBySubSubcategories.fulfilled]: (state, action) => {
      state.data = action.payload;
      state.status = 'success';
    },
    [GetProductsBySubSubcategories.rejected]: (state, action) => {
      state.status = 'failed';
      state.Error = action.payload;
    },
    [GetProductByCategories.pending]: (state) => {
      state.status = 'loading';
    },
    [GetProductByCategories.fulfilled]: (state, action) => {
      state.data = action.payload;
      state.status = 'success';
    },
    [GetProductByCategories.rejected]: (state, action) => {
      state.error = action.payload;
      state.status = 'failed';
    },
    [GetProductsByFilters.pending]: (state) => {
      state.productsByFiltersStatus = 'loading';
    },
    [GetProductsByFilters.fulfilled]: (state, action) => {
      state.productsByFilters = action.payload;
      state.productsByFiltersStatus = 'success';
    },
    [GetProductsByFilters.rejected]: (state, action) => {
      state.productsByFiltersError = action.payload;
      state.productsByFiltersStatus = 'failed';
    },
  },
});

export default ProductSlice.reducer;
