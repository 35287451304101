import { createSelector } from 'reselect';

// Create a selector to get the cart data from the Redux store
const getCartData = (state) => state?.cart?.data;

// Create a memoized selector to retrieve the cart data
export const selectCartData = createSelector(
  [getCartData],
  (cartData) => cartData,
);
