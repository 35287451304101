import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import {
  GetAllProducts,
  PaginateProvidedProducts,
} from '../../Redux/Features/Products/productSlice';
import { parseProductInfo, scrollToTop } from '../../utils';
import { Heading, Section } from '../common';
import Pagination from '../Pagination';
import NoSearchResults from '../../components/svg/NoSearchResults';
import { ProductCard } from '../products';
import { debounce } from 'lodash';
import '../../index.css';

const SearchResults = () => {
  let categories = useSelector((state) => state?.categories?.data);
  let allProducts = useSelector((state) => state?.product?.data);

  const [isFetching, setIsFetching] = useState(false);

  let fetchedPaginatedProducts = useSelector(
    (state) => state?.product?.paginatedProducts?.data || [],
  );

  let paginatedProductsStatus = useSelector(
    (state) => state?.product?.paginatedProductsStatus,
  );

  const numberOfDisplayedProducts = 10;

  let { totalPages, currentPage, totalCount } = useSelector(
    (state) => state?.product?.paginatedProducts,
  );

  const [filteredOptions, setFilteredOptions] = useState([allProducts]);
  const [theCurrentPage, setTheCurrentPage] = useState(1);

  const dispatch = useDispatch();
  const { theSearchTerm } = useParams();

  useEffect(() => {
    if (!fetchedPaginatedProducts.length) {
      setIsFetching(true);
      return;
    }

    if (!fetchedPaginatedProducts.length && allProducts.length) {
      setIsFetching(false);
      return;
    }

    if (
      paginatedProductsStatus === 'loading' &&
      !fetchedPaginatedProducts.length === undefined
    ) {
      setIsFetching(true);
      return;
    } else if (
      fetchedPaginatedProducts.length === 0 &&
      paginatedProductsStatus === 'success'
    ) {
      setIsFetching(false);
      return;
    } else {
      setIsFetching(false);
      return;
    }
  }, [fetchedPaginatedProducts, paginatedProductsStatus, allProducts]);

  useEffect(() => {
    currentPage === undefined || null
      ? setTheCurrentPage(1)
      : setTheCurrentPage(currentPage);
  }, [currentPage]);

  let fetchedSearchTerm = theSearchTerm;

  useEffect(() => {
    setIsFetching(true);

    const filterProductsDebounced = debounce(async () => {
      const theFilteredOptions = await allProducts?.filter((product) =>
        product.name.toLowerCase().includes(theSearchTerm.toLowerCase()),
      );

      setFilteredOptions(theFilteredOptions || []);
    }, 300);

    filterProductsDebounced();

    return () => {
      filterProductsDebounced.cancel();
    };
  }, [theSearchTerm, allProducts]);

  useEffect(() => {
    // eslint-disable-next-line no-undef
    const throttleDispatch = _.throttle(() => {
      dispatch(
        PaginateProvidedProducts({
          productsToPaginate: filteredOptions,
          limit: numberOfDisplayedProducts,
          page: 1,
          // sortBy: isSortBy,
          // order: isOrder,
        }),
      );
    }, 500);

    throttleDispatch();

    return () => {
      throttleDispatch.cancel();
    };
  }, [dispatch, filteredOptions, numberOfDisplayedProducts]);

  const handlePageChange = (newPage) => {
    dispatch(
      PaginateProvidedProducts({
        productsToPaginate: filteredOptions,
        page: newPage,
        limit: numberOfDisplayedProducts,
      }),
    );
    scrollToTop();
  };

  return (
    <div
      className={`my-4 mx-3 md:mx-7 bg-gradient-to-b from-[#040404] to-[#18181C] rounded-[20px] pb-0.5 ${
        !fetchedPaginatedProducts.length && 'bg-none'
      }`}
    >
      {isFetching || !allProducts.length ? (
        <div className="flex justify-center items-center h-screen">
          <div className="spinner"></div>
        </div>
      ) : (
        <Section
          className={`
        ${
          fetchedPaginatedProducts.length && allProducts.length
            ? 'justify-between'
            : 'justify-normal'
        }
         flex flex-col justify-between m-3 md:mx-10 max-w-[100%]`}
        >
          <div className="flex justify-between mt-2 mb-4">
            <div
              className={`text-white text-xl md:text-2xl flex flex-col md:flex-row  ${
                fetchedPaginatedProducts.length &&
                window.innerWidth < 768 &&
                'justify-center w-[90%] md:w-[100%]'
              }`}
            >
              <span className="md:w-[100%] flex flex-col md:flex-row">
                {fetchedPaginatedProducts.length && allProducts.length
                  ? `${totalCount} Search ${
                      fetchedPaginatedProducts.length === 1
                        ? 'result'
                        : 'results'
                    }`
                  : 'No search results'}
                &nbsp;for&nbsp;
                <br />
                <p className="text-latestYellow truncate">
                  {fetchedSearchTerm}
                </p>
              </span>
            </div>

            <div
              className={`text-white pt-1 mr-3 ${
                fetchedPaginatedProducts.length
                  ? 'flex my-auto justify-end'
                  : 'hidden'
              }`}
            >
              <p>
                <span className="text-latestYellow">{currentPage}</span>
                &nbsp;of&nbsp;
                {totalPages}
              </p>
            </div>
          </div>

          {theSearchTerm !== '' &&
          !fetchedPaginatedProducts.length &&
          !isFetching ? (
            <Section className="w-[100%] mx-1 my-4 lg:justify-center custom-scrollbar rounded-[10px] rounded-b-lg bg-[#040404] overflow-y-auto">
              <div className="mb-[20px] px-2 pt-2">
                <NoSearchResults />
                <p className="text-white text-center pt-0 md:pt-5">
                  No products found. Keep
                  <Link to="/product/browse-products">
                    <span className="text-latestYellow hover:text-latestYellow/70">
                      &nbsp;browsing.
                    </span>
                  </Link>
                </p>
              </div>
            </Section>
          ) : (
            <div>
              {fetchedPaginatedProducts?.map((product, index) => (
                <Link
                  to={`/product/view/${product?._id}`}
                  key={`${index}_${product?._id}`}
                >
                  <Section
                    className={` 
                 flex justify-between lg:justify-center flex-row my-4 h-auto lg:w-[90%] rounded-[10px] p-2 lg:px-5 lg:mx-6  hover:bg-[#191919]`}
                  >
                    {product?.imageUrl &&
                      Array.isArray(product?.imageUrl) &&
                      product?.imageUrl?.length > 0 && (
                        <div className="w-[30%] md:w-[20%] rounded-md">
                          <img
                            src={product?.imageUrl[0]}
                            alt={product?.name}
                            className="rounded-md min-w-[100%] max-w-[100%] h-[12vh] md:h-[20vh] object-cover"
                          />
                        </div>
                      )}
                    <Section className="flex flex-col ml-2 text-black w-[70%] md:w-[80%]">
                      <div>
                        <Heading className="text-[16px] lg:text-[15px] text-white max-w-[90%] truncate">
                          {product?.name}
                        </Heading>
                      </div>
                      <span className="text-md text-[#9aa0a6]">
                        {
                          (
                            categories.find(
                              (cat) => cat?._id === product?.categoryId,
                            ) || {}
                          ).name
                        }
                      </span>
                      <div>
                        <Heading className="text-md text-[#9aa0a6]">
                          <span className="text-latestYellow font-semibold">
                            {product?.unitPriceBeforeProfit?.toLocaleString()}{' '}
                            RWF
                          </span>
                        </Heading>
                      </div>
                      {product?.description && (
                        <div className="text-[14px] pt-3 w-[90%] flex">
                          <span className="font-bold text-white/50">
                            Color:
                          </span>
                          &nbsp;
                          <span className="font-bold text-latestYellow/50 truncate max-w-[90%]">
                            {parseProductInfo(product?.description)[0].value ||
                              'N/A'}
                          </span>
                        </div>
                      )}
                    </Section>

                    <div className="flex gap-3 cursor-pointer lg:justify-center lg:ml-auto"></div>
                  </Section>
                </Link>
              ))}
              {/* <ProductCard
                className={`hidden grid-cols-2 grid-rows-2 md:flex md:flex-wrap justify-between md:justify-center md:items-center gap-[20px] md:gap-[30px]`}
                products={
                  // fetchedPaginatedProducts.length > 1
                  //   ? fetchedPaginatedProducts
                  //   : []
                  fetchedPaginatedProducts
                }
              /> */}
            </div>
          )}
        </Section>
      )}
      <Pagination
        currentPage={theCurrentPage}
        currentPageChange={currentPage}
        totalPages={totalPages}
        onPageChange={handlePageChange}
        className={`flex justify-center md:px-5 mx-auto mt-12 mb-5 ${
          fetchedPaginatedProducts.length === 0 && 'hidden'
        }`}
      />
    </div>
  );
};

export default SearchResults;
