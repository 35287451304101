export const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  });
};

export const handleKeyPress = ({ e, handleSubmit }) => {
  if (e.key === 'Enter') {
    e.preventDefault();
    handleSubmit(e);
  }
};
