import React from 'react';
import { useNavigate } from 'react-router';
import { Button, Section } from '../components';

function ComingSoon() {
  const navigate = useNavigate();
  return (
    <Section className="flex flex-col items-center justify-center min-h-screen px-0.5 sm:px-3 space-y-10 text-center text-white bg-black">
      <h2 className="text-5xl font-bold text-secondaryYellow sm:text-7xl">
        Coming Soon...
      </h2>
      <p className="w-4/5 text-sm sm:w-2/5 sm:text-base">
        We will be launching the{' '}
        <span className="font-medium text-secondaryYellow">Moodboard</span>{' '}
        feature soon, where you can better visualize your designs with
        SufuriMart products!
      </p>

      <Button
        className="w-32 sm:h-11 bg-gradient-to-r text-xs sm:text-sm h-7 from-[#ffcc00] via-[#e6b608c7] to-[#4c3c03] rounded-lg"
        onClick={() => navigate('/')}
      >
        GO HOME
      </Button>
    </Section>
  );
}

export default ComingSoon;
