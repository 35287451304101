import React from 'react';
import { BsCheck, BsPlus } from 'react-icons/bs';
import { HiOutlineMinusSmall } from 'react-icons/hi2';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { Triangle } from 'react-loader-spinner';

export const TableBody = ({
  products,
  handleDelete,
  handleUpdateQuantity,
  isUpdateQtyLoading,
  updateWishlistStatus,
  toggleProductSelection,
  selectedProductIds,
  handleOneWishlistToCart,
}) => {
  if (!Array.isArray(products)) {
    return null;
  }
  return (
    <tbody>
      {products?.map((product) => (
        <>
          <tr key={product?.name}>
            <td className="w-4 p-4">
              <div className="flex items-center">
                <label htmlFor="checkbox-table-search-1">
                  <input
                    id="checkbox-table-search-1"
                    type="checkbox"
                    className="appearance-none checked:bg-[#ca8a23] w-[14px] h-[14px] rounded bg-gray-700"
                    onChange={() => toggleProductSelection(product._id)}
                    checked={selectedProductIds.includes(product._id)}
                  />
                  <span className="sr-only">checkbox</span>
                </label>
              </div>
            </td>
            <th
              scope="row"
              className="flex items-center pl-6 text-white py-7 whitespace-nowrap"
            >
              <img
                className="object-cover w-12 h-12 rounded"
                src={product?.imageUrl[0]}
                alt="Something"
              />
              <div className="max-w-[200px] pl-5">
                <div className="text-sm underline truncate underline-offset-4">
                  {product.name}
                </div>
              </div>
            </th>
            <td className="px-8 py-7">
              <span className="px-4 py-2 font-medium text-white">
                {product?.price}
              </span>
            </td>
            <td className="px-8 py-7">
              <div className="text-sm text-white">12-11-2019</div>
            </td>
            <td className="px-8 py-7">
              <div className="flex items-center">
                <BsCheck className="mr-2 text-green-500" />
                <div className="text-sm text-green-500">In stock</div>
              </div>
            </td>
            <td className="px-8 py-7">
              <div className="flex items-center pl-1">
                {updateWishlistStatus === 'loading' &&
                isUpdateQtyLoading[product._id] ? (
                  <span className="px-1.5">
                    <Triangle
                      height="20"
                      width="20"
                      color="#fff"
                      ariaLabel="triangle-loading"
                      wrapperStyle={{ display: 'inline-block' }}
                      wrapperClassName=""
                      visible={true}
                    />
                  </span>
                ) : (
                  <span className="px-3 text-sm text-white">
                    {product.quantity}
                  </span>
                )}
                <div className="grid">
                  <button
                    onClick={() =>
                      handleUpdateQuantity(product._id, 'increase')
                    }
                  >
                    <BsPlus className="text-primaryYellow" />
                  </button>
                  <button
                    onClick={() =>
                      handleUpdateQuantity(product._id, 'decrease')
                    }
                  >
                    <HiOutlineMinusSmall className="text-primaryYellow" />
                  </button>
                </div>
              </div>
              <hr className="border border-gray-600 rounded-md" />
            </td>
            <td className="px-8 py-7">
              <div className="flex items-center space-x-3">
                <button
                  onClick={() => handleOneWishlistToCart(product._id)}
                  className="grid px-3 py-2 text-xs text-center text-white bg-latestYellow rounded-3xl"
                >
                  <span>Add to cart</span>
                </button>
                <button onClick={() => handleDelete(product._id)}>
                  <AiOutlineCloseCircle className="w-5 h-5 text-white hover:text-red-500" />
                </button>
              </div>
            </td>
          </tr>
          <tr className="relative">
            <hr className="absolute w-full border border-gray-800 rounded-md" />
          </tr>
        </>
      ))}
    </tbody>
  );
};
