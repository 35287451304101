import React from 'react';

const MenuItem = ({ onClick, selected, label }) => (
  <div className="relative">
    <button
      onClick={onClick}
      className={`text-gray-500 ${selected && '!text-[#ad7f34]'}`}
    >
      {label}
    </button>
    <hr
      className={`${selected && 'border border-[#ad7f34] w-full'} absolute`}
    />
  </div>
);

export const OrdersTableMenu = ({
  setSelectedOrderOption,
  selectedOrderOption,
}) => {
  const handleItemClick = (selection) => {
    setSelectedOrderOption(selection);
  };

  return (
    <div className="flex gap-x-[3%] text-xs md:text-sm">
      <MenuItem
        onClick={() => handleItemClick('All')}
        selected={selectedOrderOption === 'All'}
        label="All Orders"
      />
      <MenuItem
        onClick={() => handleItemClick('New')}
        selected={selectedOrderOption === 'New'}
        label="New Orders"
      />
      <MenuItem
        onClick={() => handleItemClick('Pending')}
        selected={selectedOrderOption === 'Pending'}
        label="Pending Orders"
      />
      <MenuItem
        onClick={() => handleItemClick('Paid')}
        selected={selectedOrderOption === 'Paid'}
        label="Paid Orders"
      />
      <MenuItem
        onClick={() => handleItemClick('Failed')}
        selected={selectedOrderOption === 'Failed'}
        label="Failed Orders"
      />
    </div>
  );
};
