import React, { useState, forwardRef } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Paragraph, Section, Button } from '../common';
import { PaymentOptions } from '../checkout';

const Input = forwardRef(({ className, ...rest }, ref) => (
  <input ref={ref} className={className} {...rest} />
));

const BillingAddressForm = ({
  updateFormData,
  isFormSubmitted,
  isPaymentNumber,
  setIsPaymentNumber,
}) => {
  const [dropdownValue, setDropdownValue] = useState('');

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    if (isPaymentNumber) {
      updateFormData(data);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="bg-gradient-to-b from-[#191919] via-[rgba(53, 47, 47, 0.00)] to-[#040404] px-2 md:px-7 rounded md:rounded-[0px]">
        <Section className="flex flex-col md:flex-row md:justify-between pt-2 md:pt-5 gap-[1%] md:gap-[5%] w-[100%]">
          <div className="flex flex-col">
            <Paragraph className="text-[#5e5e5e] mb-[3px]">Name</Paragraph>
            <Controller
              name="name"
              control={control}
              defaultValue=""
              rules={{ required: 'Name is required' }}
              render={({ field }) => (
                <Input
                  className={`${
                    errors.name && 'border border-red-500'
                  } bg-[#474747] text-[#ffffff] mb-2 w-[100%] md:w-[190px] lg:w-[265px] xl:w-[340px] focus:outline-none py-2 px-3 placeholder-[#A3A3A3] rounded-[4px] transition-all duration-300 ease-in-out
                  `}
                  placeholder="John Doe"
                  type="text"
                  {...field}
                />
              )}
            />
            {errors.name && (
              <span className="text-sm text-red-500">
                {errors.name.message}
              </span>
            )}
          </div>
          <div className="flex flex-col">
            <Paragraph className="text-[#5e5e5e] mb-[3px]">
              Mobile Number
            </Paragraph>
            <Controller
              name="mobileNumber"
              control={control}
              defaultValue=""
              rules={{
                required: 'Mobile Number is required',
                pattern: {
                  value: /^\d+$/,
                  message: 'Invalid mobile number',
                },
              }}
              render={({ field }) => (
                <Input
                  className={`${
                    errors.mobileNumber && 'border border-red-500'
                  } bg-[#474747] text-[#ffffff] mb-2 w-[100%] md:w-[190px] lg:w-[265px] xl:w-[340px] focus:outline-none py-2 px-3 placeholder-[#A3A3A3] rounded-[4px] transition-all duration-300 ease-in-out`}
                  placeholder="250788112233"
                  type="text"
                  {...field}
                />
              )}
            />
            {errors.mobileNumber && (
              <span className="text-sm text-red-500">
                {errors.mobileNumber.message}
              </span>
            )}
          </div>
        </Section>

        <Section className="flex flex-col md:flex-row md:justify-between pt-2 md:pt-5 gap-[1%] md:gap-[5%] w-[100%]">
          <div className="flex flex-col">
            <Paragraph className="text-[#5e5e5e] mb-[3px]">Email</Paragraph>
            <Controller
              name="email"
              control={control}
              defaultValue=""
              rules={{
                required: 'Email is required',
                pattern: {
                  value: /^\S+@\S+$/i,
                  message: 'Invalid email address',
                },
              }}
              render={({ field }) => (
                <Input
                  className={`${
                    errors.email && 'border border-red-500'
                  } bg-[#474747] text-[#ffffff] mb-2 w-[100%] md:w-[190px] lg:w-[265px] xl:w-[340px] focus:outline-none py-2 px-3 placeholder-[#A3A3A3] rounded-[4px] transition-all duration-300 ease-in-out`}
                  placeholder="john@example.com"
                  type="text"
                  {...field}
                />
              )}
            />
            {errors.email && (
              <span className="text-sm text-red-500">
                {errors.email.message}
              </span>
            )}
          </div>
          <div className="flex flex-col">
            <Controller
              name="location"
              control={control}
              defaultValue=""
              rules={{ required: 'Location is required' }}
              render={({ field }) => (
                <select
                  id="underline_select"
                  className={`${
                    errors.location && 'border border-red-500'
                  } bg-[#413F42] text-[#ffffff] mb-2 w-[100%] md:w-[190px] lg:w-[265px] xl:w-[340px] focus:outline-none rounded-[2px] py-3 md:mt-5 px-0 text-sm  bg-transparent border-0 border-b-2 border-gray-200 appearance-none dark:text-gray-400 dark:border-gray-700 focus:ring-0 focus:border-gray-200 peer`}
                  onChange={(e) => {
                    field.onChange(e);
                    setDropdownValue(e.target.value);
                  }}
                  value={field.value}
                >
                  <option value="">Choose a location</option>
                  <option value="tailor">Tailor's location</option>
                  <option value="designer">Designer's location</option>
                </select>
              )}
            />
            {errors.location && (
              <span className="text-sm text-red-500">
                {errors.location.message}
              </span>
            )}
          </div>
        </Section>

        {dropdownValue === 'tailor' || dropdownValue === 'designer' ? (
          <Section className="flex flex-col md:flex-row md:justify-between pt-2 md:pt-5 gap-[1%] md:gap-[5%] w-[100%]">
            <div className="flex flex-col">
              <Paragraph className="text-[#5e5e5e] mb-[3px]">
                {dropdownValue === 'tailor'
                  ? `Tailor's District`
                  : dropdownValue === 'designer'
                  ? `Designer's District`
                  : ''}
              </Paragraph>
              <Controller
                name="district"
                control={control}
                defaultValue=""
                rules={{ required: 'District is required' }}
                render={({ field }) => (
                  <Input
                    className={`${
                      errors.district && 'border border-red-500'
                    } bg-[#413F42] text-[#ffffff] mb-2 w-[100%] md:w-[190px] lg:w-[265px] xl:w-[340px] focus:outline-none py-2 px-3 placeholder-[#A3A3A3] border border-[#5D5D5D] rounded-[4px] transition-all duration-300 ease-in-out`}
                    placeholder="Kicukiro"
                    type="text"
                    {...field}
                  />
                )}
              />
              {errors.district && (
                <span className="text-sm text-red-500">
                  {errors.district.message}
                </span>
              )}
            </div>
            <div className="flex flex-col">
              <Paragraph className="text-[#5e5e5e] mb-[3px]">
                {dropdownValue === 'tailor'
                  ? `Tailor's Street Address`
                  : dropdownValue === 'designer'
                  ? `Designer's Street Address`
                  : ''}
              </Paragraph>
              <Controller
                name="streetAddress"
                control={control}
                defaultValue=""
                rules={{ required: 'Street Address is required' }}
                render={({ field }) => (
                  <Input
                    className={`${
                      errors.streetAddress && 'border border-red-500'
                    } bg-[#413F42] text-[#ffffff] mb-2 w-[100%] md:w-[190px] lg:w-[265px] xl:w-[340px] focus:outline-none py-2 px-3 placeholder-[#A3A3A3] border border-[#5D5D5D] rounded-[4px] transition-all duration-300 ease-in-out`}
                    placeholder="KN 123 Ave"
                    type="text"
                    {...field}
                  />
                )}
              />
              {errors.streetAddress && (
                <span className="text-sm text-red-500">
                  {errors.streetAddress.message}
                </span>
              )}
            </div>
          </Section>
        ) : (
          ''
        )}

        <PaymentOptions setIsPaymentNumber={setIsPaymentNumber} />

        <Button
          type="submit"
          className="bg-[#000] border rounded-lg text-[#0000] my-4 disabled:opacity-50 disabled:cursor-not-allowed"
          // disabled={isFormSubmitted}
        >
          <span className="text-[#fff]">Save</span>
        </Button>
      </div>
    </form>
  );
};

export default BillingAddressForm;
