import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { BASE_URL } from '../../../utils/apiUtils';

export const Signup = createAsyncThunk(
  'signup',
  async (signup, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${BASE_URL}api/users/register`,
        signup,
      );
      return response.data;
    } catch (error) {
      if(error.response.status===409){
        return rejectWithValue(error.response.data.message);
      }
      if(error.response.status===422){
        return rejectWithValue(error.response.data.errors[0].message);
      }
      console.log(error)
      return rejectWithValue(error);
    }
  },
);

const SignupSlice = createSlice({
  name: 'signup',
  initialState: {
    signup: null,
    status: null,
    error: null,
    emailVerified: false,
  },
  extraReducers: {
    [Signup.pending]: (state) => {
      state.status = 'loading';
    },
    [Signup.fulfilled]: (state, action) => {
      state.user = action.payload;
      state.status = 'success';
    },
    [Signup.rejected]: (state, action) => {
      state.status = 'failed';
      state.Error = action.payload;
    },
  },
});

export default SignupSlice.reducer;
