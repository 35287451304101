import {
  AuthNavbar,
  Container,
  Heading,
  Paragraph,
  Section,
  SignupForm,
  AuthRightSection,
} from '../../components';

function Signup() {
  return (
    <Container className="bg-black">
      <AuthNavbar />
      <Section className="flex mb-[25px]">
        <Section className="flex flex-col ml-3 mr-3 lg:mr-0 lg:ml-0">
          <Heading className="text-[#ffffff] font-medium text-[35px] lg:text-[45px] lg:ml-[220px] mt-[20px]">
            Register Today
          </Heading>

          <Paragraph className="text-[#888888] text-[14px] lg:ml-[220px] lg:text-[15px] pt-[15px]">
            The first step in gaining access to a curated selection of{' '}
            <br className="hidden lg:block" /> fabrics and tailoring supplies to
            deliver quality outfits to <br className="hidden lg:block" /> your
            clients.{' '}
          </Paragraph>

          <div className="lg:ml-[220px] mt-[30px] lg:w-[426px]">
            <SignupForm />
          </div>
        </Section>

        <AuthRightSection />
      </Section>
    </Container>
  );
}

export default Signup;
