import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  CheckoutOrderPreview,
  UnsuccessfulPayment,
  SuccessfulPayment,
} from '../../checkout';
import { BillingAddressForm } from '../../Forms';
import Heading from '../Heading';
import Section from '../Section';
import PhoneLoader from '../../skeletons/PhoneLoader';

const CheckoutMiddleSection = () => {
  const [formData, setFormData] = useState({});
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const navigate = useNavigate();
  // const [thePaymentStatus, setThePaymentStatus] = useState('');
  const [isPaymentNumber, setIsPaymentNumber] = useState();

  const checkoutStatus = useSelector((state) => state?.checkout?.status);
  const paymentStatus = useSelector((state) => state?.payment?.status);

  useEffect(() => {
    setTimeout(() => {
      const orderId = localStorage.getItem('orderId');
      if (!orderId) {
        navigate('/cart');
      }
    }, 2000);
  }, [navigate]);

  if (!localStorage.getItem('orderId')) {
    return (
      <div className="my-12 text-2xl text-center text-red-600">
        <p>Invalid access. ...redirecting back to the cart.</p>
      </div>
    );
  }

  const updateFormData = (data) => {
    setFormData(data);
    setIsFormSubmitted(true);
  };

  let sectionContent = null;

  if (paymentStatus === 'success') {
    sectionContent = <SuccessfulPayment />;
  } else if (paymentStatus === 'failed') {
    sectionContent = <UnsuccessfulPayment />;
  } else if (checkoutStatus === 'success') {
    sectionContent = (
      <>
        <Heading className="items-center justify-center text-center text-white mt-14">
          Please check the popup on the Payment Number you provided...
        </Heading>
        <PhoneLoader />
      </>
    );
  } else {
    sectionContent = (
      <>
        <Heading className="mb-4 text-white">Delivery Information</Heading>
        <BillingAddressForm
          updateFormData={updateFormData}
          isFormSubmitted={isFormSubmitted}
          isPaymentNumber={isPaymentNumber}
          setIsPaymentNumber={setIsPaymentNumber}
        />
      </>
    );
  }

  return (
    <div className="flex flex-col mx-5 my-8 md:flex-row md:justify-around md:gap-10 md:mx-12">
      <Section className="md:w-[65%]">{sectionContent}</Section>

      <Section className="md:w-[35%]">
        <Heading className="text-white">Order Summary</Heading>
        <CheckoutOrderPreview
          isFormSubmitted={isFormSubmitted}
          updatedFormData={formData}
          paymentStatus={paymentStatus}
        />
      </Section>
    </div>
  );
};

export default CheckoutMiddleSection;
