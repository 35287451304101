import { useEffect, useState } from 'react';
import { Container, Section } from '../common';
import { ProductSlider } from '../products';

const DontMissOutSection = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(
    localStorage.getItem('mobileMenuOpen') === 'true',
  );

  useEffect(() => {
    const handleLocalStorageChange = ({ key }) => {
      if (key === 'mobileMenuOpen') {
        setIsMobileMenuOpen(localStorage.getItem('mobileMenuOpen') === 'true');
      }
    };

    window.addEventListener('storage', handleLocalStorageChange);

    return () => {
      window.removeEventListener('storage', handleLocalStorageChange);
    };
  }, []);

  return (
    <Container className="bg-cover min-h-[19vh] h-[1/2] md:h-0 mx-4 md:mx-6 mt-5 md:mt-0 rounded-[20px] bg-center bg-no-repeat bg-[url('https://res.cloudinary.com/dlw8ohn6p/image/upload/v1692012344/IMG_4702_aceryx.jpg')] bg-opacity-50">
      <div className="inset-0 bg-black opacity-50"></div>
      <Section className="flex flex-col md:flex-row justify-around md:gap-[50px]">
        <Section className="px-7 text-[25px] md:text-[34px] font-[500] text-white flex flex-col pt-[30px] md:pt-[250px]">
          <span className="text-secondaryYellow">Don't Miss Out!</span>
          <span>
            Check Out Our New <br className="hidden md:block" /> Arrivals.
          </span>
        </Section>
        <Section
          className={`w-full md:w-[60%] lg:w-[50%] xl:w-[35%] h-[80%] mt-[10px] md:mt-7`}
          style={{ zIndex: isMobileMenuOpen ? -10 : 1 }}
        >
          <ProductSlider className={`mt-[10px] md:mt-[150px] md:mr-[2px]`} />
        </Section>
      </Section>
    </Container>
  );
};

export default DontMissOutSection;
