import { Container, Footer, Header } from '../../components';
import { ProfileMiddleSection } from '../../components/common';

const ViewProfile = () => {
  return (
    <Container>
      <Header />
      <ProfileMiddleSection />
      <Footer />
    </Container>
  );
};

export default ViewProfile;
