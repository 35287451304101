import React from 'react';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { MdAdd } from 'react-icons/md';

import { Button, Heading, Input, Section } from '../common';

const AddToCartModal = ({
  setShowModal,
  quantity,
  setQuantity,
  handleAddToCart,
  productTitle,
}) => {
  return (
    <Section className="fixed inset-0 flex items-center justify-center z-10">
      <div className="absolute inset-0 bg-black opacity-70"></div>
      <div
        className="md:px-[40px] mt-[-100px] md:mt-[-350px] w-[80%] lg:w-[40%] p-4 rounded-[20px] z-10"
        style={{
          borderRadius: '30px',
          background:
            'linear-gradient(183deg, rgba(104, 104, 104, 0.37) 0%, rgba(67, 67, 67, 0.44) 100%)',
          boxShadow: '0px 4px 25px 28px rgba(0, 0, 0, 0.25)',
          backdropFilter: 'blur(20px)',
        }}
      >
        <div className="flex my-[15px] justify-between">
          <Heading className="text-[20px] lg:text-[20px] mb-4 text-white font-[300]">
            {productTitle}
          </Heading>
          <AiOutlineCloseCircle
            className="text-primaryYellow w-8 h-8 cursor-pointer"
            onClick={() => {
              setShowModal(false);
            }}
          />
        </div>
        <div className="flex gap-4 my-[20px]">
          <Heading className="my-auto text-[#c3c3c3] text-[13px]">
            Quantity
          </Heading>
          <Input
            type="number"
            className="rounded-md px-4 py-2 w-[100px] text-white bg-[#393a3a] border border-[#343434] focus:outline-none focus:ring "
            min={1}
            defaultValue={1}
            value={quantity}
            onChange={(e) => setQuantity(e.target.value)}
          />
        </div>

        <div className="flex justify-between my-[30px]">
          <Button
            className="border border-[#C38D01] rounded-md w-[200px] h-[40px] flex gap-[34px] hover:bg-[#2a2415]"
            onClick={handleAddToCart}
          >
            <span className="ml-[9px] my-auto bg-[#393a3a] rounded-full">
              <MdAdd className="text-[#C38D01]" />
            </span>
            <span className="text-[#C38D01]">Add to Cart</span>
          </Button>
        </div>
      </div>
    </Section>
  );
};

export default AddToCartModal;
