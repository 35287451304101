import React from 'react';
import Skeleton from 'react-loading-skeleton';

const ProductSliderLoader = ({ isLargeScreen }) => {
  const loaderCards = Array.from(
    { length: isLargeScreen ? 1 : 1 },
    (_, index) => (
      <>
        <div
          className="mx-auto md:mx-1 rounded-[25px] w-[70%] md:w-[70%] lg:h-[250px] xl:h-[280px] lg:w-[250px] xl:w-[300px] md:mt-5"
          key={index}
        >
          <Skeleton
            baseColor="#1d1f1e"
            highlightColor="#292928"
            className="rounded-[25px] md:ml-[77px] mt-[-20px] h-[320px] md:w-[230px]"
          />
        </div>
      </>
    ),
  );

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 md:gap-4 xl:gap-0">
      {loaderCards}
    </div>
  );
};

export default ProductSliderLoader;
