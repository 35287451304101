import axios from 'axios';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ADMINTOKEN, BASE_URL } from '../../../utils/apiUtils';

export const GetVendorOverviewStats = createAsyncThunk(
  'vendorOverviewStats',
  async ({ durationFilter }, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${BASE_URL}api/analytics/vendor/overview?durationFilter=${durationFilter}`,
        {
          headers: {
            Authorization: `Bearer ${ADMINTOKEN}`,
            'Content-Type': 'application/json',
          },
        },
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

const VendorDashboardSlice = createSlice({
  name: 'VendorDashboard',
  initialState: {
    status: null,
    error: null,
    data: null,
  },

  extraReducers: {
    [GetVendorOverviewStats.pending]: (state) => {
      state.status = 'loading';
    },
    [GetVendorOverviewStats.fulfilled]: (state, action) => {
      state.data = action.payload;
      state.status = 'success';
    },
    [GetVendorOverviewStats.rejected]: (state, action) => {
      state.status = 'failed';
      state.Error = action.payload;
    },
  },
});

export default VendorDashboardSlice.reducer;
