import {
  Container,
  Footer,
  DontMissOutSection,
  BuyNowSection,
  DiscoverSection,
  Header,
  HomeFinalSection,
} from '../../components';

function HomePage() {
  return (
    <Container>
      {/* 👇🏽 Sections of the Home page from the navbar upto the footer */}
      <Header />

      <DontMissOutSection />
      <DiscoverSection />
      <BuyNowSection />
      <HomeFinalSection />

      <Footer />
    </Container>
  );
}

export default HomePage;
