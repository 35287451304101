import React from 'react';
import { Link } from 'react-router-dom';
import { Image, Section } from '../../common';
import SufuriLogo from '../../../assets/images/sufuri-tree-logo.png';
import { CiLogout } from 'react-icons/ci';
import { GoSidebarCollapse, GoSidebarExpand } from 'react-icons/go';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { logout } from '../../../Redux/Features/Login/loginSlice';
import { EmptyOptions } from '../../../data/adminDashboardData';

const Sidebar = ({
  className,
  sidebarUpperOptions,
  sidebarToolsOptions,
  selectedOption,
  setSelectedOption,
  showSidebar,
  setShowSidebar,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleItemClick = (key) => {
    setSelectedOption(key === selectedOption ? null : key);
  };

  const renderIcon = (icon) => {
    return icon ? (
      <span className="flex justify-center my-auto text-xl">{icon}</span>
    ) : null;
  };

  const handleSidebarToggle = () => {
    setShowSidebar((prev) => !prev);
  };

  const toggleSidebarIconStyle =
    'w-5 h-5 cursor-pointer hover:text-latestYellow mt-1 block lg:hidden';

  const renderSidebarItems = (options, isTools = false) => {
    return (
      <div className={`flex flex-col px-[15%] py-7 ${!isTools && ''}`}>
        {options &&
          Object.entries(options).map(([key, value]) => (
            <li
              key={key}
              className={`flex p-4 mt-2 gap-1 align-middle text-center cursor-pointer font-thin ${
                selectedOption === key &&
                'border border-[#8f692c] text-[#8f692c] rounded-lg bg-[#080500]'
              } ${
                EmptyOptions.includes(key)
                  ? 'text-gray-500 !cursor-not-allowed'
                  : ''
              }`}
              title={`${
                EmptyOptions.includes(key) ? 'Feature Coming Soon' : ''
              }`}
              onClick={() => handleItemClick(key)}
            >
              {renderIcon(value.icon)}
              {showSidebar ? value.title : ''}
            </li>
          ))}
      </div>
    );
  };

  const handleLogout = () => {
    dispatch(logout());
    navigate('/auth/login');
  };

  return (
    <div
      className={`flex flex-col justify-between border-r-[#5f5f5f] border-r-[0.5px] text-[#ffffff] ${className}`}
    >
      <Section className="py-2">
        <div className="flex my-auto">
          {/* <div className="flex justify-end my-auto mr-2"> */}
          <GoSidebarExpand
            className={toggleSidebarIconStyle}
            onClick={handleSidebarToggle}
          />
        </div>
        <Link to="/home">
          <div className="flex items-center px-[4%]">
            <Image
              className="w-[90px] md:w-[80px] bg-transparent opacity-60 mx-auto xl:mx-0"
              src={SufuriLogo}
              alt="sufuri-logo"
            />
            {showSidebar && (
              <span className="text-[#8f692c] ml-[-15px] pt-2 text-[25px] md:text-2xl logo-text hidden xl:block">
                SufuriMart
              </span>
            )}
          </div>
        </Link>
        {/* <div className="flex my-auto">
          {showSidebar ? (
            <GoSidebarExpand
              className={toggleSidebarIconStyle}
              onClick={handleSidebarToggle}
            />
          ) : (
            <GoSidebarCollapse
              className={toggleSidebarIconStyle}
              onClick={handleSidebarToggle}
            />
          )}
        </div> */}
        {/* {showSidebar ? (
          <GoSidebarExpand
            className={toggleSidebarIconStyle}
            onClick={handleSidebarToggle}
          />
        ) : (
          <GoSidebarCollapse
            className={toggleSidebarIconStyle}
            onClick={handleSidebarToggle}
          />
        )} */}
      </Section>

      <hr className="border-[0.5px] border-[#5f5f5f]" />

      <Section className="mb-auto">
        <ul>{renderSidebarItems(sidebarUpperOptions)}</ul>
      </Section>

      {/* <Section>
        <ul>{renderSidebarItems(sidebarToolsOptions, true)}</ul>
      </Section> */}

      <hr className="border-[0.5px] border-[#5f5f5f]" />

      <div onClick={handleLogout}>
        <Section
          className={`flex mx-auto ${
            showSidebar ? 'px-[20%]' : 'ml-[35%]'
          } py-4 mt-2 gap-1 align-middle text-center cursor-pointer text-[#828282]`}
        >
          <CiLogout className="my-auto mx-auto lg:mx-0" />
          {showSidebar && <p className="hidden lg:block">Logout</p>}
        </Section>
      </div>
    </div>
  );
};

export default Sidebar;
