import React, { useEffect, useState } from 'react';
import { LoginPopup } from '../modal';
import { useNavigate } from 'react-router';

import { Button, Heading, Image, Paragraph, Section } from '../common';
import { parseProductInfo } from '../../utils';

import {
  AddToCart,
  RemoveProductFromCart,
  ViewCart,
} from '../../Redux/Features/Cart/cartSlice';
import { useDispatch, useSelector } from 'react-redux';
import { BsCartPlus, BsCartXFill } from 'react-icons/bs';
import { CreateOrder } from '../../Redux/Features/Orders/orderSlice';
import { Triangle } from 'react-loader-spinner';
import { FiHeart } from 'react-icons/fi';
import {
  AddToWishlist,
  DeleteWishlistItem,
  ViewWishlist,
} from '../../Redux/Features/Wishlist/wishlistSlice';
import { FaHeartCircleCheck } from 'react-icons/fa6';
import { transferFeePercentage } from '../../utils/checkout';

const ProductDetails = ({ product }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showLoginPopup, setShowLoginPopup] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingWishlist, setIsLoadingWishlist] = useState(false);
  const [isCheckoutButtonClicked, setIsCheckoutButtonClicked] = useState(false);
  const orderStatus = useSelector((state) => state?.orders?.status);
  const wishlistProducts = useSelector((state) => state?.wishlist?.data) || [];
  const [isCartSelected, setIsCartSelected] = useState(false);

  const [selectedImage, setSelectedImage] = useState(
    `${product?.imageUrl[0]}` || '',
  );

  const cartProducts =
    useSelector((state) => state?.cart?.data?.cartProducts) || [];

  const handleAddToCart = async (productId) => {
    setIsCheckoutButtonClicked(false);
    setIsCartSelected(true);
    const isLoggedIn = localStorage.getItem('token');
    if (!isLoggedIn) {
      setShowLoginPopup(true);
      return;
    }

    setIsLoading({ ...isLoading, [productId]: true });

    try {
      const isProductInCart = cartProducts.some(
        (cartProduct) => cartProduct.product._id === productId,
      );

      if (isProductInCart) {
        await dispatch(RemoveProductFromCart({ id: productId }));
      } else {
        await dispatch(AddToCart({ productId }));
      }

      await dispatch(ViewCart());
    } catch (error) {
      console.error('Error adding/removing from cart:', error);
    } finally {
      setIsLoading({ ...isLoading, [productId]: false });
    }
  };

  const handleCheckoutNow = async ({ productId }) => {
    setIsCheckoutButtonClicked(true);
    return new Promise(async (resolve) => {
      const isLoggedIn = localStorage.getItem('token');
      if (!isLoggedIn) {
        setShowLoginPopup(true);
        return;
      }

      let orderDetails = {
        products: [
          {
            quantity: 1,
            product: productId,
          },
        ],
      };

      const deliveryAmount = 2000 || 0;
      let transferFee =
        (product?.unitPriceBeforeProfit * transferFeePercentage) / 100 || 0;
      let totalAmount = product?.unitPriceBeforeProfit + deliveryAmount || 0;
      let totalCheckoutAmount = totalAmount + transferFee;

      if (product) {
        orderDetails.totalPrice = totalCheckoutAmount;
      }

      orderDetails.totalQuantity = orderDetails?.products.length;
      orderDetails.deliveryFee = deliveryAmount;

      orderDetails.transferFeePercentage = transferFeePercentage;

      dispatch(CreateOrder(orderDetails)).then(() => {
        navigate('/checkout');
      });

      const navigateToCheckout = () => {
        return new Promise((resolve) => {
          const checkNavigation = () => {
            const theOrderId = localStorage.getItem('orderId');
            if (theOrderId) {
              resolve();
            } else {
              setTimeout(checkNavigation, 500);
            }
          };
          checkNavigation();
        });
      };

      try {
        await navigateToCheckout();
        // navigate('/checkout');
      } catch (error) {
        console.error('Error while navigating to checkout:', error);
      }
    });
  };

  const handleSelectImage = ({ image }) => {
    setSelectedImage(image);
  };

  const handleAddToWishlist = async (productId) => {
    setIsCartSelected(false);
    setIsCheckoutButtonClicked(false);
    const isLoggedIn = localStorage.getItem('token');
    if (!isLoggedIn) {
      setShowLoginPopup(true);
      return;
    }

    setIsLoadingWishlist((prevLoading) => ({
      ...prevLoading,
      [productId]: true,
    }));

    try {
      const isProductInWishlist = wishlistProducts.find(
        (wishlistProduct) => wishlistProduct?.product === productId,
      );

      if (isProductInWishlist) {
        await dispatch(DeleteWishlistItem(isProductInWishlist._id));
      } else {
        await dispatch(AddToWishlist({ productId, quantity: 1 }));
      }

      await dispatch(ViewWishlist());
    } catch (error) {
      console.error('Error adding/removing from wishlist:', error);
    } finally {
      setIsLoadingWishlist((prevLoading) => ({
        ...prevLoading,
        [productId]: false,
      }));
    }
  };

  return (
    <Section className="flex flex-col md:flex-row gap-[15px] md:gap-[30px] mx-[3%] md:mx-[2%] my-[20px] md:my-[40px] flex-shrink-0">
      <Heading className="text-white items-center text-[25px] block md:hidden truncate">
        {product?.name}
      </Heading>
      {/* Left */}
      <Section className="rounded-[25px] md:w-[55%] h-[550px] flex flex-col-reverse md:flex-row gap-2">
        <div className="flex flex-row justify-center gap-3 mt-3 md:flex-col md:justify-normal">
          {product?.imageUrl?.map((image, index) => (
            <div
              onMouseEnter={() => handleSelectImage({ image })}
              onClick={() => handleSelectImage({ image })}
            >
              <Image
                key={index}
                className={`w-[60px] h-[60px] rounded-[5px] object-cover hover:border hover:border-[#2f2f2f] cursor-pointer ${
                  selectedImage === image
                    ? 'border border-secondaryYellow hover:border-secondaryYellow'
                    : ''
                }`}
                src={image}
                alt="product-1 details"
              />
            </div>
          ))}
        </div>
        <Image
          className="w-[100%] md:w-[90%] h-[100%] mx-auto rounded-[25px] object-cover"
          src={selectedImage}
          alt="product-1 details"
        />
      </Section>

      {/* Right */}
      <Section className="px-5 py-2 md:w-[45%] flex flex-col rounded-md bg-gradient-to-b from-[#191919] via-[#191919] to-[#040404]">
        <Heading className="text-white text-[40px]">{product?.name}</Heading>

        <Section className="justify-between text-[#C38D01] flex gap-2 mb-2">
          <Paragraph className="text-[40px] md:text-[50px] font-[600]">
            {product?.unitPriceBeforeProfit?.toLocaleString()} RWF
          </Paragraph>

          <button onClick={() => handleAddToWishlist(product?._id)}>
            {isLoadingWishlist[product._id] ? (
              <p>
                <Triangle
                  height="20"
                  width="20"
                  color="#F0B00A"
                  ariaLabel="triangle-loading"
                  wrapperStyle={{}}
                  wrapperClassName=""
                  visible={true}
                />
              </p>
            ) : (
              <>
                {Array.isArray(wishlistProducts) &&
                wishlistProducts.find(
                  (wishlistProduct) =>
                    wishlistProduct?.product === product?._id,
                ) ? (
                  <FaHeartCircleCheck className="my-2 w-7 h-7 text-latestYellow" />
                ) : (
                  <FiHeart className="my-2 w-7 h-7 text-latestYellow" />
                )}
              </>
            )}
          </button>
        </Section>

        <Section className="flex gap-[20px] md:gap-[50px]">
          <Button
            className={`rounded-md w-[200px] h-[40px] flex ${
              cartProducts.find(
                (cartProduct) => cartProduct?.product?._id === product?._id,
              )
                ? 'bg-[#C38D01] hover:bg-[#9b6f02] justify-between'
                : 'border border-[#C38D01] hover:bg-[#222] justify-around px-2'
            }`}
            onClick={() => handleAddToCart(product?._id)}
          >
            <span className="md:ml-[9px] my-auto rounded-full">
              {cartProducts.find(
                (cartProduct) => cartProduct?.product?._id === product?._id,
              ) ? (
                <BsCartXFill className="text-[#000]" />
              ) : (
                <BsCartPlus className="text-[#C38D01]" />
              )}
            </span>
            <span
              className={`text-[11px] lg:text-[13px] my-auto ${
                cartProducts.find(
                  (cartProduct) => cartProduct?.product?._id === product?._id,
                )
                  ? 'text-[#000]'
                  : 'text-[#C38D01]'
              } `}
            >
              {cartProducts.find(
                (cartProduct) => cartProduct.product._id === product._id,
              ) ? (
                <span className={`${isLoading[product._id] ? '' : ''}`}>
                  {isLoading[product._id] ? (
                    <p className="pr-10">Loading...</p>
                  ) : (
                    'Remove from Cart'
                  )}
                </span>
              ) : (
                <>{isLoading[product._id] ? 'Loading...' : 'Add to Cart'}</>
              )}
            </span>
          </Button>
          <Button
            className="disabled:opacity-50 disabled:cursor-not-allowed w-[200px] h-[40px] text-white my-auto rounded-md bg-gradient-to-br from-yellow-400 to-gray-700 text-[11px] lg:text-[13px]"
            type="submit"
            onClick={() => handleCheckoutNow({ productId: product?._id })}
            disabled={orderStatus === 'loading'}
          >
            {orderStatus === 'loading' ? (
              <p className="flex justify-center">
                <Triangle
                  height="20"
                  width="20"
                  color="#fff"
                  ariaLabel="triangle-loading"
                  wrapperStyle={{}}
                  wrapperClassName=""
                  visible={true}
                />
              </p>
            ) : (
              'Proceed To Checkout'
            )}
          </Button>
        </Section>

        {/* Product Description 👇🏽 */}
        <Paragraph className="text-[#c9c9c9e8] mt-[25px] text-[15px]">
          In stock
          <br />
          <br />
          {product?.unitPriceBeforeProfit?.toLocaleString()} RWF /{' '}
          {product?.unitMeasurement}
          <br /> <br />
          <span className="font-medium text-latestYellow">Description:</span>
          <br />
          {parseProductInfo(product?.description).map((item, index) => (
            <div key={index}>
              <span className="font-bold">{item.key}:</span> {item.value}
            </div>
          ))}{' '}
          <br /> <br />
        </Paragraph>
      </Section>
      {showLoginPopup && (
        <LoginPopup
          message={`Please login first to ${
            isCheckoutButtonClicked
              ? 'buy this product.'
              : isCartSelected
              ? 'add items to the cart.'
              : 'add items to the wishlist.'
          } 
          `}
          onClose={() => setShowLoginPopup(false)}
        />
      )}
    </Section>
  );
};

export default ProductDetails;
