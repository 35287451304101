import React from 'react';
import { Heading, Section } from '../../common';

const TopXStatsCard = ({
  title,
  data,
  handleSeeAllClick,
  className,
  isLoading,
}) => {
  const firstFiveResults = data?.slice(0, 4);

  return (
    <div className={className}>
      <Section className="flex justify-between pb-3">
        <Heading>{title}</Heading>
        <p
          className="text-xs text-[#8c8c8c] my-auto cursor-pointer"
          onClick={handleSeeAllClick}
        >
          See all
        </p>
      </Section>

      <hr className="border-[0.5px] border-[#5f5f5fb5]" />

      <Section>
        {isLoading && <p className="mt-5 md:mt-2 text-[#5f5f5f]">Loading...</p>}
        {data && data.length > 0 && !isLoading ? (
          firstFiveResults?.map((item, index) => (
            <div key={index} className="flex justify-between">
              <div className="flex my-3 gap-4">
                {item?.imageUrls ? (
                  item?.imageUrls.map((imageUrl, idx) => (
                    <img
                      key={idx}
                      src={imageUrl}
                      alt={`Product ${idx + 1}`}
                      className="w-10 h-10 object-cover rounded-full"
                      style={{ marginLeft: idx !== 0 ? '-8px' : '0' }}
                    />
                  ))
                ) : (
                  <img
                    src={item?.imageUrl}
                    alt={item?.name}
                    className="w-10 h-10 object-cover rounded-full"
                  />
                )}

                <div>
                  {item?.name ? (
                    <p className="text-sm max-w-[150px] truncate">
                      {item?.name}
                    </p>
                  ) : (
                    <p className="text-sm max-w-[90%] truncate">
                      {item.names &&
                        item.names.map((name, index) => (
                          <span key={index}>
                            {name}
                            {index < item.names.length - 1 && ', '}
                          </span>
                        ))}
                    </p>
                  )}

                  {item.purchasesCount && (
                    <p className="text-xs text-[#5f5f5f]">{`${
                      item.purchasesCount
                    } ${
                      item.purchasesCount < 1 ? 'purchase' : 'purchases'
                    }`}</p>
                  )}
                  {item.totalPrice && (
                    <p className="text-xs text-[#5f5f5f]">{`${
                      item.names.length
                    } ${item.names.length > 1 ? 'products' : 'product'}`}</p>
                  )}
                </div>
              </div>

              <p className="my-auto text-sm text-[#5f5f5f]">
                RWF&nbsp;
                {item?.totalPurchasesAmount?.toLocaleString() ||
                  item?.totalPrice?.toLocaleString()}
              </p>
            </div>
          ))
        ) : (
          <div className="min-w-[350px]">
            <p className="text-sm text-[#5f5f5f]">No data available</p>
          </div>
        )}
      </Section>
    </div>
  );
};

export default TopXStatsCard;
