import React from 'react';
import { AiOutlineLeft, AiOutlineRight } from 'react-icons/ai';

const Pagination = ({
  currentPage,
  currentPageChange,
  totalPages,
  onPageChange,
  className,
}) => {
  const renderPageNumbers = () => {
    const pages = [];
    let startPage = 1;
    let endPage = totalPages;

    if (window.innerWidth < 768) {
      if (totalPages > 4) {
        startPage = Math.max(currentPage - 1, 1);
        endPage = Math.min(startPage + 3, totalPages);
        if (endPage < totalPages) {
          endPage--;
        }
      }
    } else if (window.innerWidth < 1024) {
      if (totalPages > 4) {
        startPage = Math.max(currentPage - 2, 1);
        endPage = Math.min(startPage + 4, totalPages);
        if (endPage < totalPages) {
          endPage--;
        }
      }
    } else {
      if (totalPages > 4) {
        startPage = Math.max(currentPage - 2, 1);
        endPage = Math.min(startPage + 4, totalPages);
        if (endPage < totalPages) {
          endPage--;
        }
      }
    }

    for (let i = startPage; i <= endPage; i++) {
      pages.push(
        <button
          key={i}
          onClick={() => onPageChange(i)}
          className={`mx-1 px-3 py-1 rounded-md border-[1px] border-yellow-300 text-white ${
            currentPage === i
              ? 'bg-yellow-600 text-white'
              : 'bg-black hover-bg-gray-800'
          }`}
        >
          {i}
        </button>,
      );
    }

    if (startPage > 1) {
      pages.unshift(
        <button key="startEllipsis" disabled className="mx-1 text-latestYellow">
          ...
        </button>,
      );
    }

    if (endPage < totalPages) {
      pages.push(
        <button key="endEllipsis" disabled className="mx-1 text-latestYellow">
          ...
        </button>,
      );
    }

    if (startPage > 1) {
      pages.unshift(
        <button
          key={1}
          onClick={() => onPageChange(1)}
          className={`mx-1 px-3 py-1 rounded-md border-[1px] border-yellow-300 text-white ${
            currentPage === 1
              ? 'bg-yellow-600 text-white'
              : 'bg-black hover-bg-gray-800'
          }`}
        >
          1
        </button>,
      );
    }

    if (endPage < totalPages) {
      pages.push(
        <button
          key={totalPages}
          onClick={() => onPageChange(totalPages)}
          className={`mx-1 px-3 py-1 rounded-md border-[1px] border-yellow-300 text-white ${
            currentPage === totalPages
              ? 'bg-yellow-600 text-white'
              : 'bg-black hover-bg-gray-800'
          }`}
        >
          {totalPages}
        </button>,
      );
    }

    return pages;
  };

  return (
    <div className={`flex flex-wrap items-center ${className}`}>
      <div onClick={() => onPageChange(currentPageChange - 1)} className="mr-1">
        <button
          disabled={currentPage === 1}
          className="hidden md:block mx-1 px-2 py-1 border-[0.5px] text-white border-yellow-300 rounded-md bg-gray-800 hover-bg-gray-700 disabled:cursor-not-allowed disabled:bg-black disabled:border-[#676767] disabled:text-[#707070]"
        >
          Previous
        </button>
        <AiOutlineLeft
          className={`${
            currentPage === 1 && 'hidden'
          } w-7 h-7 md:hidden text-yellow-300`}
        />
      </div>
      {renderPageNumbers()}
      <div onClick={() => onPageChange(currentPageChange + 1)} className="ml-1">
        <button
          disabled={currentPage === totalPages}
          className="hidden md:block mx-1 px-3 py-1 border-[0.5px] text-white border-yellow-300 rounded-md bg-gray-800 hover-bg-gray-700 disabled:cursor-not-allowed disabled:bg-black disabled:border-[#676767] disabled:text-[#707070]"
        >
          Next
        </button>
        <AiOutlineRight
          className={`w-7 h-7 md:hidden ${
            currentPage === totalPages && 'hidden'
          } text-yellow-300`}
        />
      </div>
    </div>
  );
};

export default Pagination;
