import React, { useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { HomePage, Login } from '../pages';
import { GetAllProducts } from '../Redux/Features/Products/productSlice';
import { useDispatch } from 'react-redux';
import { ViewCart } from '../Redux/Features/Cart/cartSlice';
import { LoginPopup } from '../components';
import { ViewWishlist } from '../Redux/Features/Wishlist/wishlistSlice';

export function RootLayout() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [showLoginPopup, setShowLoginPopup] = useState(false);
  const isLoggedIn = localStorage.getItem('token');

  useEffect(() => {
    dispatch(GetAllProducts());
    dispatch(ViewCart());
    dispatch(ViewWishlist());
  }, []);

  useEffect(() => {
    if (location.pathname === '/home' && isLoggedIn) {
      dispatch(ViewCart());
    }
    if (location.pathname === '/cart' && isLoggedIn) {
      dispatch(ViewCart());
    }
    if (location.pathname === '/wishlist' && isLoggedIn) {
      dispatch(ViewWishlist());
    }
  }, [dispatch, location.pathname, isLoggedIn]);

  const isLandingPage = location.pathname === '/';

  const isAddProductPage = location.pathname === '/product/admin/add-product';
  const isAddCategoryPage = location.pathname === '/product/admin/add-category';
  const isAddSubCategoryPage =
    location.pathname === '/product/admin/add-subcategory';
  const isAddSubsubcategoryPage =
    location.pathname === '/product/admin/add-Subsubcategory';
  const isCartPage = location.pathname === '/cart';
  const isSavedSamplesPage = location.pathname === '/samples/saved';
  const isSamplesRequestPage = location.pathname === '/samples/new-request';
  const isFabricFinderPage = location.pathname === '/fabric/finder';
  const isSavedFabricPage = location.pathname === '/fabric/saved';
  const isChekoutPage = location.pathname === '/checkout';
  const isFeedbackPage = location.pathname === '/feedback';
  const isProfilePage = location.pathname === '/profile';
  const isWishlistPage = location.pathname === '/wishlist';

  const shouldRenderContent =
    isAddProductPage ||
    isAddCategoryPage ||
    isAddSubCategoryPage ||
    isAddSubsubcategoryPage ||
    isCartPage ||
    isSavedSamplesPage ||
    isSamplesRequestPage ||
    isFabricFinderPage ||
    isSavedFabricPage ||
    isChekoutPage ||
    isFeedbackPage ||
    isProfilePage ||
    isWishlistPage;

  if (isLandingPage) {
    return <HomePage />;
  }

  return (
    <div>
      <main>
        <Outlet />
        {shouldRenderContent && !isLoggedIn ? (
          <LoginPopup
            message="Please login first to access the Page."
            onClose={() => {
              setShowLoginPopup(false);
              navigate('/home');
            }}
            closeButtonStyle="hidden"
            homeButtonStyle="block"
          />
        ) : (
          <></>
        )}
      </main>
    </div>
  );
}
