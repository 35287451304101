import React, { useState } from 'react';
import { Section } from '../common';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const OtherProductsSkeleton = () => {
  return (
    <>
      <Section className="flex gap-[15px] md:gap-[40px] mx-[10px] md:mx-[100px] my-[40px] justify-center">
        {/* Left */}
        <Section className="rounded-[25px] w-[50%] h-[200px] md:h-[310px] md:w-[250px]">
          <Skeleton
            baseColor="#1d1f1e"
            highlightColor="#292928"
            className="rounded-[25px] h-[200px] md:h-[310px] md:w-[250px]"
          />
        </Section>
        <Section className="mt-1 md:mt-0 rounded-[25px] bg-[#1d1f1e] w-[50%] h-[200px] flex flex-col md:h-[310px] md:w-[250px]">
          <Skeleton
            baseColor="#1d1f1e"
            highlightColor="#292928"
            className="px-5 py-2 bg-[#1E2125] h-[200px] flex flex-col rounded-[25px] md:h-[310px] md:w-[250px]"
          />
        </Section>
        <Section className="bg-[#1d1f1e] w-[30%] h-[310px] hidden md:flex flex-col rounded-[25px] md:h-[310px] md:w-[250px]">
          <Skeleton
            baseColor="#1d1f1e"
            highlightColor="#292928"
            className="px-5 py-2 bg-[#1E2125] w-[50%] h-[310px] flex flex-col rounded-[25px] md:h-[310px] md:w-[250px]"
          />
        </Section>
        <Section className="hidden bg-[#1d1f1e] w-[30%] h-[310px] md:flex flex-col rounded-[25px] md:h-[310px] md:w-[250px]">
          <Skeleton
            baseColor="#1d1f1e"
            highlightColor="#292928"
            className="px-5 py-2 bg-[#1E2125] w-[50%] h-[310px] flex flex-col rounded-[25px] md:h-[310px] md:w-[250px]"
          />
        </Section>
      </Section>
    </>
  );
};

export default OtherProductsSkeleton;
