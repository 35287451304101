import React from 'react';
import Skeleton from 'react-loading-skeleton';

const HomeSuggestedProductsLoaders = ({ maxVisibleProducts }) => {
  const loaderCards = Array.from({ length: maxVisibleProducts }, (_, index) => (
    <>
      <div
        className="mx-3 md:mx-0 rounded-[25px] h-[200px] md:h-[310px] md:w-[250px] md:mt-10"
        key={index}
      >
        <Skeleton
          baseColor="#1d1f1e"
          highlightColor="#292928"
          className="rounded-[25px] h-[200px] md:h-[310px] md:w-[250px]"
        />
      </div>
    </>
  ));

  return <div className="grid grid-cols-2 gap-4">{loaderCards}</div>;
};

export default HomeSuggestedProductsLoaders;
