import React from 'react';
import {
  Container,
  FabricFinderSection,
  Footer,
  Header,
} from '../../components';

function FabricFinder() {
  return (
    <Container>
      <Header />

      <FabricFinderSection />

      <Footer />
    </Container>
  );
}

export default FabricFinder;
