import React, { useState } from 'react';
import { RiShoppingCart2Fill, RiShoppingCart2Line } from 'react-icons/ri';
import { Button, Image, Paragraph } from '../common';
import { useDispatch, useSelector } from 'react-redux';
import {
  AddToCart,
  RemoveProductFromCart,
  ViewCart,
} from '../../Redux/Features/Cart/cartSlice';
import { Link } from 'react-router-dom';
import { LoginPopup } from '../modal';
import { Triangle } from 'react-loader-spinner';
import { PushNotification } from '../Notifications';
import { FaHeartCircleCheck } from 'react-icons/fa6';
import { FiHeart } from 'react-icons/fi';
import {
  AddToWishlist,
  DeleteWishlistItem,
  ViewWishlist,
} from '../../Redux/Features/Wishlist/wishlistSlice';

const SuggestedProductsCard = ({ className, product }) => {
  const dispatch = useDispatch();
  const [showLoginPopup, setShowLoginPopup] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [isAddedToCart, setIsAddedToCart] = useState(false);
  const [isLoadingWishlist, setIsLoadingWishlist] = useState(false);
  const [isCartSelected, setIsCartSelected] = useState(false);

  const wishlistProducts = useSelector((state) => state?.wishlist?.data) || [];
  const cartProducts =
    useSelector((state) => state?.cart?.data?.cartProducts) || [];

  const handleAddToCart = async (productId) => {
    setIsCartSelected(true);
    const isLoggedIn = localStorage.getItem('token');
    if (!isLoggedIn) {
      setShowLoginPopup(true);
      return;
    }

    setIsLoading((prevLoading) => ({ ...prevLoading, [productId]: true }));

    try {
      const isProductInCart = cartProducts.some(
        (cartProduct) => cartProduct.product._id === productId,
      );

      if (isProductInCart) {
        setIsAddedToCart(false);
        await dispatch(RemoveProductFromCart({ id: productId }));
      } else {
        setIsAddedToCart(true);
        await dispatch(AddToCart({ productId }));
      }

      await dispatch(ViewCart());
      setShowNotification(true);
    } catch (error) {
      console.error('Error adding/removing from cart:', error);
    } finally {
      setIsLoading((prevLoading) => ({ ...prevLoading, [productId]: false }));
    }
  };
  const handleCloseNotification = () => {
    setShowNotification(false);
  };

  const handleAddToWishlist = async (productId) => {
    setIsCartSelected(false);
    const isLoggedIn = localStorage.getItem('token');
    if (!isLoggedIn) {
      setShowLoginPopup(true);
      return;
    }

    setIsLoadingWishlist((prevLoading) => ({
      ...prevLoading,
      [productId]: true,
    }));

    try {
      const isProductInWishlist = wishlistProducts.find(
        (wishlistProduct) => wishlistProduct?.product === productId,
      );

      if (isProductInWishlist) {
        await dispatch(DeleteWishlistItem(isProductInWishlist._id));
      } else {
        await dispatch(AddToWishlist({ productId, quantity: 1 }));
      }

      await dispatch(ViewWishlist());
    } catch (error) {
      console.error('Error adding/removing from wishlist:', error);
    } finally {
      setIsLoadingWishlist((prevLoading) => ({
        ...prevLoading,
        [productId]: false,
      }));
    }
  };

  return (
    <div className={className}>
      <div className="cursor-pointer max-w-[230px] md:min-w-[230px]">
        <div className="relative">
          <Link to={`/product/view/${product._id}`}>
            <Image
              src={product?.imageUrl[0]}
              alt={product?.name}
              className="object-cover rounded-t-[10px] w-[230px] h-[200px] md:h-[230px] hover:border hover:border-[#F0B00A]"
            />
          </Link>
          <div
            className="absolute flex items-center justify-center p-2 rounded-full right-2 bottom-2"
            style={{ boxShadow: '0px 0px 10px' }}
          >
            <button onClick={() => handleAddToWishlist(product?._id)}>
              {isLoadingWishlist[product?._id] ? (
                <p>
                  <Triangle
                    height="20"
                    width="20"
                    color="#F0B00A"
                    ariaLabel="triangle-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={true}
                  />
                </p>
              ) : (
                <>
                  {Array.isArray(wishlistProducts) &&
                  wishlistProducts.find(
                    (wishlistProduct) =>
                      wishlistProduct?.product === product?._id,
                  ) ? (
                    <FaHeartCircleCheck className="w-5 h-5 text-latestYellow" />
                  ) : (
                    <FiHeart className="w-5 h-5 text-latestYellow" />
                  )}
                </>
              )}
            </button>
          </div>
        </div>
      </div>
      <div className="max-w-[230px] border-b border-[#141414] p-2 md:py-2 md:px-2 rounded-bl-[14px] md:rounded-bl-[24px] rounded-br-[14px] md:rounded-br-[24px]">
        <Paragraph className="text-[#ffffff] text-[15px] py-1 hover:cursor-pointer truncate">
          <Link to={`/product/view/${product._id}`}>{product.name}</Link>
        </Paragraph>

        <div className="flex justify-between flex-row md:flex-row md:gap-[0%]">
          <Paragraph className="pl-2 md:pl-0 md:text-[13px] text-[15px] my-auto w-auto flex text-[#ffffff] truncate">
            {product?.unitPriceBeforeProfit?.toLocaleString()}
            <span>&nbsp;FRW</span>
          </Paragraph>
          {cartProducts.find(
            (cartProduct) => cartProduct.product._id === product._id,
          ) ? (
            <Button
              className={`hidden md:flex border bg-[#F0B00A] border-[#000000] rounded-[3px] h-4 md:h-6 md:rounded-[5px]`}
              onClick={() => handleAddToCart(product._id)}
            >
              <span className="text-[9.7px] md:text-[10px] mt-[-8px] md:mt-[-5px] text-[#000] truncate">
                {isLoading[product._id] ? 'Loading...' : 'Remove from Cart'}
              </span>
            </Button>
          ) : (
            <Button
              className={`hidden md:flex border ${
                cartProducts.find(
                  (cartProduct) => cartProduct.product._id === product._id,
                )
                  ? 'bg-[#F0B00A] border-[#000000]'
                  : 'border-[#F0B00A]'
              } rounded-[3px] h-4 md:h-6 md:rounded-[5px]`}
              onClick={() => handleAddToCart(product._id)}
            >
              <span
                className={`text-[9.7px] md:text-[10px] mt-[-8px] md:mt-[-5px] ${
                  cartProducts.find(
                    (cartProduct) => cartProduct.product._id === product._id,
                  )
                    ? 'text-[#000]'
                    : 'text-[#F0B00A]'
                } truncate`}
              >
                {cartProducts.find(
                  (cartProduct) => cartProduct.product._id === product._id,
                ) ? (
                  <>
                    {isLoading[product._id] ? 'Loading...' : 'Remove from Cart'}
                  </>
                ) : (
                  <>{isLoading[product._id] ? 'Loading...' : 'Add to Cart'}</>
                )}
              </span>
            </Button>
          )}
          <div
            className="cursor-pointer md:hidden text-[#F0B00A]"
            onClick={() => handleAddToCart(product._id)}
          >
            {isLoading[product._id] ? (
              <p>
                <Triangle
                  height="20"
                  width="20"
                  color="#F0B00A"
                  ariaLabel="triangle-loading"
                  wrapperStyle={{}}
                  wrapperClassName=""
                  visible={true}
                />
              </p>
            ) : (
              <>
                {cartProducts.find(
                  (cartProduct) => cartProduct.product._id === product._id,
                ) ? (
                  <RiShoppingCart2Fill className="w-5 h-5 md:w-6 md:h-6 md:hidden" />
                ) : (
                  <RiShoppingCart2Line className="w-5 h-5 md:w-6 md:h-6 md:hidden" />
                )}
              </>
            )}
          </div>
        </div>
      </div>
      {showLoginPopup && (
        <LoginPopup
          message={`Please login first to add items to the ${
            isCartSelected ? 'cart' : 'wishlist'
          }`}
          onClose={() => setShowLoginPopup(false)}
        />
      )}
      {showNotification && (
        <PushNotification
          message={`${
            isAddedToCart ? 'Added to the' : 'Removed from the'
          }   cart!`}
          onClose={handleCloseNotification}
        />
      )}
    </div>
  );
};

export default SuggestedProductsCard;
