import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { BASE_URL, ADMINTOKEN } from '../../../utils/apiUtils';

export const CreateSubcat = createAsyncThunk(
  'add-category',
  async (createsubcat, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${BASE_URL}api/products/subcategory/`,
        createsubcat,
        {
          headers: {
            Authorization: `Bearer ${ADMINTOKEN}`,
            'Content-Type': 'application/json',
          },
        },
      );
      return response.data;
    } catch (error) {
      if (error.response.status === 422) {
        return rejectWithValue(error.response.data.errors[0].message);
      } else {
        return rejectWithValue(error.response.data.message);
      }
    }
  },
);

const SubcategorySlice = createSlice({
  name: 'createsubcat',
  initialState: {
    createsubcat: null,
    status: null,
    error: null,
    emailVerified: false,
  },
  extraReducers: {
    [CreateSubcat.pending]: (state) => {
      state.status = 'loading';
    },
    [CreateSubcat.fulfilled]: (state, action) => {
      state.user = action.payload;
      state.status = 'success';
    },
    [CreateSubcat.rejected]: (state, action) => {
      state.status = 'failed';
      state.Error = action.payload;
    },
  },
});

export default SubcategorySlice.reducer;
