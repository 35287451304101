import React from 'react';

export const OrdersStatCard = ({ className, header, value }) => {
  return (
    <div
      className={`border border-gray-500 flex flex-col text-sm w-auto whitespace-nowrap lg:w-[24%] h-auto px-3 py-2 lg:px-0 lg:pl-5 rounded-md gap-y-1 ${className}`}
    >
      <span className="text-gray-500">{header}</span>
      <span className="text-white text-2xl font-medium">{value}</span>
    </div>
  );
};
