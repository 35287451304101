import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import { SubmitFeedback } from '../../Redux/Features/Feedback/feedbackSlice';
import { useForm, Controller } from 'react-hook-form';
import { scrollToTop } from '../../utils';

const FeedbackForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const status = useSelector((state) => state.feedback.status);

  useEffect(() => {
    scrollToTop();
  }, []);

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const onSubmit = (data) => {
    const { name, email, feedback } = data;

    dispatch(SubmitFeedback({ name, email, feedback }));
  };

  useEffect(() => {
    if (status === 'success') {
      reset();

      toast.success('Feedback submitted successfully');
      setTimeout(() => {
        navigate('/');
        dispatch(SubmitFeedback()); //* This was left here intentionally to clear the feedback state
      }, 5000);
    }
  }, [status, navigate, dispatch]);

  return (
    <div className="flex justify-center mx-3 my-8 md:flex-row md:mx-0 md:my-10">
      <ToastContainer />
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="bg-gradient-to-b from-[#18181C] to-[#040404] w-full max-w-md p-4 rounded-lg shadow-md"
      >
        <div className="mb-4">
          <h1 className="text-2xl text-center uppercase text-latestYellow logo-text">
            Feedback Form
          </h1>
          <label
            htmlFor="name"
            className="block text-[#5e5e5e] font-semibold mb-2"
          >
            Name
          </label>
          <Controller
            name="name"
            control={control}
            defaultValue=""
            rules={{ required: 'Please enter your name.' }}
            render={({ field }) => (
              <input
                className={`${
                  errors.name && 'border border-red-500'
                } w-full px-3 py-2 rounded-lg focus:outline-none focus:ring bg-[#474747] text-[#ffffff]`}
                placeholder="Enter your name"
                type="text"
                id="name"
                {...field}
              />
            )}
          />
          {errors.name && (
            <p className="mt-1 text-red-500">{errors.name.message}</p>
          )}
        </div>
        <div className="mb-4">
          <label
            htmlFor="email"
            className="block text-[#5e5e5e] font-semibold mb-2"
          >
            Email
          </label>
          <Controller
            name="email"
            control={control}
            defaultValue=""
            rules={{
              required: 'Email is required',
              pattern: {
                value: /^\S+@\S+$/i,
                message: 'Invalid email address',
              },
            }}
            render={({ field }) => (
              <input
                className={`${
                  errors.email && 'border border-red-500'
                } w-full px-3 py-2 rounded-lg focus:outline-none focus:ring bg-[#474747] text-[#ffffff]`}
                type="email"
                placeholder="Enter your email"
                id="email"
                {...field}
              />
            )}
          />

          {errors.email && (
            <p className="mt-1 text-red-500">{errors.email.message}</p>
          )}
        </div>
        <div className="mb-4">
          <label
            htmlFor="feedback"
            className="block text-[#5e5e5e] font-semibold mb-2"
          >
            Feedback
          </label>
          <Controller
            name="feedback"
            control={control}
            defaultValue=""
            rules={{
              required: 'Please provide your feedback.',
            }}
            render={({ field }) => (
              <textarea
                placeholder="Provide feedback"
                className="w-full px-3 py-2 rounded-lg focus:outline-none focus:ring bg-[#474747] text-[#ffffff]"
                name="feedback"
                id="feedback"
                rows="4"
                {...field}
              />
            )}
          />

          {errors.feedback && (
            <p className="mt-1 text-red-500">{errors.feedback.message}</p>
          )}
        </div>
        <div className="text-center">
          <button
            type="submit"
            className="px-4 py-2 text-white transition duration-300 rounded-lg bg-gradient-to-br from-latestYellow to-gray-900 hover:cursor-pointer"
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default FeedbackForm;
