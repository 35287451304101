import { Section, Image } from '../../../components';
import image1 from '../../../assets/images/image1-signupPage.jpeg';
import image2 from '../../../assets/images/image2-signupPage.jpeg';
import image3 from '../../../assets/images/image3-signupPage.jpeg';

const AuthRightSection = () => {
  return (
    <Section className="hidden lg:block xl:block flex-col ml-[20px] lg:ml-[180px] lg:mt-[150px] lg:h-[370px]">
      <div className="flex flex-col lg:flex-row gap-6">
        <Image
          src={image1}
          alt="Image with stacked needle-threads"
          className="hidden lg:block h-auto lg:w-[180px] lg:h-[200px] rounded-[23px]"
        />
        <div className="hidden lg:block">
          <Image
            src={image2}
            alt="Piece of cloth made from wool"
            className="h-auto lg:w-[140px] lg:h-[160px] rounded-[23px] lg:mt-10 "
          />
        </div>
      </div>
      <div className="hidden lg:block lg:mt-5">
        <Image
          src={image3}
          alt="Piece of cloth made from wool"
          className="h-auto lg:w-[160px] lg:h-[130px] md:mt-0 lg:rounded-[23px] lg:ml-[90px] sm:w-[20px] sm:h-[20px]"
        />
      </div>
    </Section>
  );
};

export default AuthRightSection;
