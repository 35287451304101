import React from 'react';
import {
  CheckoutMiddleSection,
  Container,
  Footer,
  Header,
} from '../../components';

function CheckoutPage() {
  return (
    <Container>
      <Header />

      <CheckoutMiddleSection />

      <Footer />
    </Container>
  );
}

export default CheckoutPage;
