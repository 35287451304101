import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { BASE_URL, ADMINTOKEN } from '../../../utils/apiUtils';
import { toast } from 'react-toastify';

export const Checkout = createAsyncThunk(
  'checkout',
  async (checkoutDetails, { rejectWithValue }) => {
    try {
      const orderId = localStorage.getItem('orderId');
      const response = await axios.put(
        `${BASE_URL}api/orders/checkout/${orderId}`,
        checkoutDetails,
        {
          headers: {
            Authorization: `Bearer ${ADMINTOKEN}`,
            'Content-Type': 'application/json',
          },
        },
      );

      toast.success('Please wait as payment is being processed');

      return response.data;
    } catch (error) {
      if (error.response.status === 422) {
        return rejectWithValue(error.response.data.errors[0].message);
      }
      if (error.response.status === 404) {
        return rejectWithValue(error.response.data.message);
      }
      if (error.response.status === 400) {
        return rejectWithValue(error.response.data.message);
      }
    }
  },
);

const CheckoutSlice = createSlice({
  name: 'checkout',
  initialState: {
    status: null,
    error: null,
    data: [],
  },
  extraReducers: {
    [Checkout.pending]: (state) => {
      state.status = 'loading';
    },
    [Checkout.fulfilled]: (state, action) => {
      state.data = action.payload;
      state.status = 'success';
    },
    [Checkout.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.payload;
    },
  },
});

export default CheckoutSlice.reducer;
