import React from 'react';
import AnalyticsContent from './AnalyticsContent';
import CustomersContent from './CustomersContent';
import OrdersContent from './OrdersContent';
import OverviewContent from './OverviewContent';
import ShopContent from './ShopContent';

const DashboardContent = ({
  className,
  selectedOption,
  setShowSidebar,
  showSidebar,
}) => {
  const optionComponents = {
    overview: (
      <OverviewContent
        setShowSidebar={setShowSidebar}
        showSidebar={showSidebar}
      />
    ),
    analytics: <AnalyticsContent />,
    orders: (
      <OrdersContent
        setShowSidebar={setShowSidebar}
        showSidebar={showSidebar}
      />
    ),
    customers: <CustomersContent />,
    shop: <ShopContent />,
  };

  return (
    <div className={`text-[#ffffff] ${className}`}>
      {selectedOption && optionComponents[selectedOption] ? (
        optionComponents[selectedOption]
      ) : (
        <p>Select an option from the sidebar</p>
      )}
    </div>
  );
};

export default DashboardContent;
