export function clearOrderDetails() {
  const orderId = localStorage.getItem('orderId');
  const orderPaymentNumber = localStorage.getItem('orderPaymentNumber');
  const orderPaymentMethod = localStorage.getItem('orderPaymentMethod');

  if (orderId) {
    localStorage.removeItem('orderId');
  }

  if (orderPaymentNumber) {
    localStorage.removeItem('orderPaymentNumber');
  }

  if (orderPaymentMethod) {
    localStorage.removeItem('orderPaymentMethod');
  }
}
