import React from 'react';
import { EmptyOptions, SidebarOptions } from '../../../data/adminDashboardData';

export const Sidebar = ({ selectedOption, setSelectedOption }) => {
  const handleItemClick = (key) => {
    setSelectedOption(key === selectedOption ? null : key);
  };

  const renderIcon = (icon) => {
    return icon ? <span className="text-lg lg:text-2xl">{icon}</span> : null;
  };

  const renderSidebarItems = (options) => {
    return (
      <>
        <div
          className={`flex lg:flex-col items-center w-full h-full space-x-9 lg:space-x-0 lg:space-y-16`}
        >
          {options &&
            Object.entries(options).map(([key, value]) => (
              <li
                key={key}
                className={`cursor-pointer ${
                  selectedOption === key && ' text-latestYellow'
                } ${
                  EmptyOptions.includes(key)
                    ? 'text-gray-500 !cursor-not-allowed'
                    : ''
                }`}
                title={`${
                  EmptyOptions.includes(key) ? 'Feature Coming Soon' : ''
                }`}
                onClick={() => handleItemClick(key)}
              >
                {renderIcon(value.icon)}
              </li>
            ))}
        </div>
      </>
    );
  };
  return (
    <aside className="text-white">
      <ul>{renderSidebarItems(SidebarOptions)}</ul>
    </aside>
  );
};
