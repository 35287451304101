import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Button from '../Buttons/Button';
import Heading from '../Heading';
import Section from '../Section';
import { IoMdAddCircle } from 'react-icons/io';
import { BsCheckCircle, BsTrash } from 'react-icons/bs';

import Image from '../Image';
import Paragraph from '../Paragraph';
import { useDispatch, useSelector } from 'react-redux';
import {
  DeleteOwnerFabric,
  GetOwnerFabrics,
} from '../../../Redux/Features/Fabrics/fabricFinderSlice';

const SavedFabricRequestSection = () => {
  const [cancel, setIsCancel] = useState(true);
  const [isFound, setIsFound] = useState(false);
  const [isSelected, setIsSelected] = useState('');
  const fabrics = useSelector((state) => state?.fabric?.data) || [];
  const deletedFabricId =
    useSelector((state) => state?.fabric?.deletedFabricId) || null;

  const [isDeleted, setIsDeleted] = useState(false);

  useEffect(() => {
    if (isDeleted) {
      setIsDeleted(false);
    }
  }, [isDeleted]);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(GetOwnerFabrics());
  }, [dispatch, isDeleted]);

  const handleDelete = (fabricId) => {
    dispatch(DeleteOwnerFabric(fabricId))
      .unwrap()
      .then((result) => {
        setIsDeleted(true);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    if (deletedFabricId) {
      setIsDeleted(true);
    }
  }, [deletedFabricId]);

  const toggleIsFound = () => {
    setIsFound(!isFound);
    setIsCancel(!cancel);
  };

  const formatDate = (dateString) => {
    const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const hasFabrics = fabrics.length > 0;
  return (
    <div className="flex flex-col mx-[4%] px-2 mt-5 lg:mt-0 lg:px-10 mb-10 bg-gradient-to-b from-[#292929] via-[rgba(53, 47, 47, 0.00)] to-[#131313]">
      <Section className="flex flex-col lg:flex-row">
        <Heading className="flex-shrink-0 text-white text-xl md:text-4xl text-center lg:text-start py-[15px]">
          Saved Fabric Finder Requests
        </Heading>
        <Link
          to="/fabric/finder"
          className="flex justify-center lg:justify-start lg:ml-auto lg:mr-[77px] flex-shrink-0"
        >
          <Button
            className="flex gap-1 justify-center lg:justify-start lg:gap-3 mx-auto w-auto h-[40px] text-white my-auto rounded-md bg-gradient-to-br from-yellow-400 to-gray-700"
            type="submit"
          >
            <IoMdAddCircle className="my-auto text-xs lg:text-base" />
            Request Fabric
          </Button>
        </Link>
      </Section>

      <Section>
        <div className="mb-[20px] px-2 lg:px-0">
          {hasFabrics ? (
            fabrics.map((fabric) => (
              <Section
                key={fabric._id}
                className="flex flex-col lg:flex-row my-7 lg:mb-7 h-auto lg:h-[130px] lg:w-[94%] bg-[#00000050] rounded-[20px] px-2 lg:px-5 py-4"
              >
                <Section className="flex text-white">
                  <Image
                    src={fabric?.imageUrl}
                    alt={fabric?.name}
                    className="w-20 h-20 lg:w-[100px] lg:h-[100px] rounded-md object-cover"
                  />
                  <div className="ml-[20px] mt-[5px]">
                    <Heading className="text-[13px] lg:text-[18px]">
                      {fabric?.name}
                    </Heading>

                    <div className="flex flex-col mt-2 space-y-2">
                      <div className="flex items-center">
                        <Paragraph className="text-[#c3c3c3] text-[10px] lg:text-[12px]">
                          Requested On:
                        </Paragraph>
                        <Paragraph className="text-[#c3c3c3] text-[10px] lg:text-[12px] pl-1">
                          {formatDate(fabric?.createdAt)}
                        </Paragraph>
                      </div>

                      {isFound && (
                        <div className="flex items-center">
                          <Paragraph className="text-[#c3c3c3] text-[10px] lg:text-[12px]">
                            Quantity:
                          </Paragraph>
                          <Paragraph className="text-secondaryYellow ml-2 font-bold text-xs md:text-[14px]">
                            Available
                          </Paragraph>
                        </div>
                      )}
                    </div>
                  </div>
                </Section>

                <div className="lg:ml-auto mt-3 lg:mt-[2%] cursor-pointer flex gap-3 justify-end">
                  {isFound && (
                    <Button
                      className="flex gap-1 lg:gap-3 text-xs lg:text-base h-[35px] lg:h-[40px] text-white my-auto rounded-md bg-gradient-to-br from-yellow-400 to-gray-700"
                      type="submit"
                      disabled
                    >
                      <BsCheckCircle className="my-auto" />
                      Found
                    </Button>
                  )}
                  {cancel && (
                    <Button
                      className="flex gap-1 lg:gap-3 text-xs lg:text-base h-[35px] lg:h-[40px] text-white my-auto rounded-md bg-gradient-to-br from-yellow-400 to-gray-700"
                      type="submit"
                      onClick={() => handleDelete(fabric._id)}
                    >
                      <BsTrash className="my-auto" />
                      Cancel
                    </Button>
                  )}
                </div>
              </Section>
            ))
          ) : (
            <div className="mt-10">
              <Heading className="text-base text-center text-gray-400 lg:text-lg md:text-2xl">
                You have no Saved Fabrics
              </Heading>
            </div>
          )}
        </div>
      </Section>
    </div>
  );
};

export default SavedFabricRequestSection;
