import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import {
  FiPhone,
  FiMail,
  FiMapPin,
  FiFacebook,
  FiInstagram,
  FiYoutube,
} from 'react-icons/fi';
import { FaLocationArrow } from 'react-icons/fa';
import { FaXTwitter } from 'react-icons/fa6';

import { currentDate } from '../../utils';
import Image from './Image';
import SufuriLogo from '../../assets/images/sufuri-tree-logo.png';
import { LoginPopup } from '../modal';

const Footer = () => {
  const navigate = useNavigate();
  const [showLoginPopup, setShowLoginPopup] = useState(false);

  const handleFeedbackRedirect = () => {
    return new Promise((resolve, reject) => {
      const isLoggedIn = localStorage.getItem('token');
      if (!isLoggedIn) {
        setShowLoginPopup(true);
        return;
      }

      navigate('/feedback');
    });
  };

  return (
    <footer className="bg-[#111111] text-white font-extralight">
      <Link to="/home">
        <div className="flex items-center px-[4%] pt-4">
          <Image
            className="w-[90px] md:w-[100px] bg-transparent opacity-60"
            // style={{ height: 'auto' }}
            src={SufuriLogo}
            alt="sufuri-logo"
          />
          <span className="text-[#8f692c] ml-[-20px] pt-2 text-[25px] md:text-3xl logo-text">
            SufuriMart
          </span>
        </div>
      </Link>
      <div className="container py-8 mx-auto md:px-8">
        <div className="grid grid-cols-1 gap-8 md:grid-cols-3">
          {/* About Us part */}
          <div>
            <Link to="/about-us">
              <h3 className="mb-4 text-lg font-semibold">About Us</h3>
            </Link>
            <ul className="space-y-2">
              <Link to="/get-in-touch">
                <li>Get in touch</li>
              </Link>

              <div onClick={handleFeedbackRedirect} className="cursor-pointer">
                <li>Provide feedback</li>
              </div>
            </ul>
          </div>

          {/* More Information part */}
          <div>
            <h3 className="mb-4 text-lg font-semibold">More Information</h3>
            <ul className="space-y-2">
              <li>
                <a href="/privacy-policy">Privacy Policy</a>
              </li>
              <li>
                <a href="/refund-return">Refund & Return/Exchange</a>
              </li>
              <li>
                <a href="/shipping-payment">Shipping & Payment Policy</a>
              </li>

              <li>
                <a href="/terms-conditions">Terms and Conditions</a>
              </li>
            </ul>
          </div>

          {/* Contact Us part*/}
          <div>
            <h3 className="mb-4 text-lg font-semibold">Contact Us</h3>
            <ul className="space-y-2">
              <li>
                <FiPhone className="inline-block w-4 h-4 mr-2 text-yellow-500" />
                +250 785 869 913
              </li>
              <li>
                <FaLocationArrow className="inline-block w-4 h-4 mr-2 text-yellow-500" />
                +250 791 345 798
              </li>
              <li>
                <FiMail className="inline-block w-4 h-4 mr-2 text-yellow-500" />
                info@sufuri.org
              </li>
              <li>
                <FiMapPin className="inline-block w-4 h-4 mr-2 text-yellow-500" />
                Kigali, Rwanda
              </li>
            </ul>
          </div>
        </div>
      </div>

      {/* Bottom Part */}
      <div className="h-[70px] w-full text-white bg-black flex flex-col md:flex-row items-center md:justify-between px-4 md:px-8">
        <p className="md:pl-[70px] text-center md:text-left">
          Project Sufuri &copy; {currentDate.year}
        </p>
        <div className="flex space-x-4 text-yellow-500 my-[4px] md:mr-[280px]">
          {/* <a
            href="https://www.facebook.com"
            className="rounded-full p-2 bg-[#111111]"
          >
            <FiFacebook className="w-5 h-5" />
          </a> */}
          <a
            href="https://twitter.com/ProjectSufuri"
            className="rounded-full p-2 bg-[#111111]"
          >
            {/* <FaTwitter className="w-5 h-5" /> */}
            <FaXTwitter className="w-5 h-5" />
          </a>
          <a
            href="https://www.instagram.com/project.sufuri"
            className="rounded-full p-2 bg-[#111111]"
          >
            <FiInstagram className="w-5 h-5" />
          </a>
          {/* <a
            href="https://www.youtube.com"
            className="rounded-full p-2 bg-[#111111]"
          >
            <FiYoutube className="w-5 h-5" />
          </a> */}
        </div>
      </div>
      {showLoginPopup && (
        <LoginPopup
          message="Please login first to send feedback."
          onClose={() => setShowLoginPopup(false)}
        />
      )}
    </footer>
  );
};

export default Footer;
