import { Container, Header, Footer } from '../../components';
import { BodySubsubcategory } from '../../components/addSubsubcategory';

function AddSubsubcategory() {
  return (
    <Container>
      <div className="min-h-screen">
        <Header />
        <BodySubsubcategory />
        <Footer />
      </div>
    </Container>
  );
}
export default AddSubsubcategory;
