import React, { useState } from 'react';
import {
  Button,
  Heading,
  Image,
  Paragraph,
  Section,
  TextArea,
} from '../../common';
import { IoIosSend } from 'react-icons/io';
import { ToastContainer, toast } from 'react-toastify';
import { cloudinaryCloudName, uploadPreset } from '../../../utils/apiUtils';
import { FindFabric } from '../../../Redux/Features/Fabrics/fabricFinderSlice';
import { useDispatch } from 'react-redux';
import { MdAdd } from 'react-icons/md';
import { useForm, Controller } from 'react-hook-form';

const FabricFinderSection = () => {
  const [uploadProgress, setUploadProgress] = useState(0);
  const dispatch = useDispatch();

  const [imagePreview, setImagePreview] = useState('');

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    getValues,
    setValue,
  } = useForm();

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
        setValue('imageUrl', reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const onSubmit = async (data) => {
    const formData1 = new FormData();

    formData1.append('file', data.imageUrl);
    formData1.append('upload_preset', `${uploadPreset}`);

    const xhr = new XMLHttpRequest();
    xhr.open(
      'POST',
      `https://api.cloudinary.com/v1_1/${cloudinaryCloudName}/image/upload`,
      true,
    );
    xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
    xhr.upload.addEventListener('progress', (event) => {
      const progress = Math.round((event.loaded / event.total) * 100);
      setUploadProgress(progress);
    });
    xhr.onreadystatechange = () => {
      if (xhr.readyState === XMLHttpRequest.DONE) {
        if (xhr.status === 200) {
          const imageData = JSON.parse(xhr.responseText);
          const uploadedImage = imageData.url;

          const { name, notes, deadlineDate, imageUrl = uploadedImage } = data;

          dispatch(
            FindFabric({ formData: { name, notes, deadlineDate, imageUrl } }),
          );
          reset();
          setImagePreview('');

          setUploadProgress(0);

          return toast.success('Request successful');
        } else {
          console.error('Error uploading image:', xhr.statusText);
          console.log('Cloudinary response:', xhr.responseText);
        }
      }
    };
    xhr.send(formData1);
  };

  return (
    <div className="pt-5 lg:pt-0  mt-5 lg:mt-0 mx-[4%] px-10 mb-10 bg-gradient-to-b from-[#040404] to-[#18181C]">
      <ToastContainer />
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-col-reverse lg:flex lg:flex-row"
      >
        <Section className="mb-10">
          <Heading className="text-white text-[40px] py-[15px]">
            Fabric Finder
          </Heading>
          <Paragraph className="text-[#c3c3c379] text-[14px]">
            Experience personalized fabric sourcing by uploading an image and
            describing <br />
            the fabric you're looking for, and let us hunt down the perfect
            match for you.
          </Paragraph>

          {/* Search Button */}
          <div className="mt-[30px]">
            <Paragraph className="text-white">Name</Paragraph>
            <Controller
              name="name"
              control={control}
              defaultValue=""
              rules={{ required: 'Name is required' }}
              render={({ field }) => (
                <input
                  type="text"
                  placeholder="Silk Fabric"
                  className="mt-[5px] text-white text-[12px] w-[100%] rounded-[3px] h-[40px] px-4 py-2  focus:outline-none bg-[#474747]"
                  {...field}
                />
              )}
            />

            <div className="flex flex-col">
              <i className="text-[#c3c3c379] text-sm">
                N.B: You can give it any relevant name
              </i>
              {errors.name && (
                <span className="ml-2 text-sm text-red-500">
                  {errors.name.message}
                </span>
              )}
            </div>
          </div>

          {/* Notes */}
          <div className="mt-[20px]">
            <Paragraph className="text-white">{'Notes (optional)'}</Paragraph>
            <Controller
              name="notes"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextArea
                  className="mt-[5px] text-[#c3c3c379] text-[12px] w-[100%] rounded-[3px] h-[70px] px-4 py-2 bg-[#18181C] focus:outline-none focus:ring"
                  name="notes"
                  {...field}
                />
              )}
            />
          </div>

          {/* Image Upload */}
          <div className="mt-[20px]">
            <Paragraph className="text-white">
              Upload a reference photo for the desired fabric.
            </Paragraph>
            <Controller
              name="imageUrl"
              control={control}
              defaultValue=""
              rules={{ required: 'Image is required' }}
              render={({ field }) => (
                <>
                  <label className="mt-[5px] cursor-pointer border border-[#C38D01] rounded-md max-w-[280px] h-[40px] flex gap-[30px] hover:bg-[#2a2415]">
                    <span className="ml-[20px] my-auto bg-[#393a3a] rounded-full">
                      <MdAdd className="text-[#C38D01]" />
                    </span>
                    <span className="text-[#C38D01] text-sm lg:text-base my-auto flex">
                      Upload Reference Photo
                    </span>
                    <input
                      type="file"
                      className="hidden"
                      onChange={(e) => {
                        field.onChange(e.target.files);
                        handleImageChange(e);
                      }}
                    />
                  </label>
                  {errors.imageUrl && (
                    <span className="ml-2 text-sm text-red-500">
                      {errors.imageUrl.message}
                    </span>
                  )}
                </>
              )}
            />
            {errors.imageUrl && (
              <span className="ml-2 text-sm text-red-500">
                {errors.imageUrl.message}
              </span>
            )}
          </div>

          <Section className="flex flex-col justify-center my-auto lg:hidden mt-[10px]">
            <Paragraph className="text-sm text-center text-white">
              {watch('name') ? watch('name') : 'Uploaded image'}
            </Paragraph>
            {imagePreview && (
              <Image
                src={imagePreview}
                alt="uploaded-image"
                className="w-[300px] max-h-[400px] h-auto rounded-[20px] object-cover mt-[15px]"
              />
            )}
          </Section>

          {/* Delivery Date */}
          <div className="mt-[20px]">
            <Paragraph className="text-white">Date Needed</Paragraph>
            <Controller
              name="deadlineDate"
              control={control}
              defaultValue=""
              rules={{
                required: 'Deadline Date is required',
              }}
              render={({ field }) => (
                <input
                  type="date"
                  className="mt-[5px] text-[12px] text-[#9E9E9E] w-[100%] rounded-[3px] h-[40px] px-4 py-2 focus:outline-none bg-[#474747]"
                  name="deadlineDate"
                  {...field}
                />
              )}
            />

            {errors.deadlineDate && (
              <span className="ml-2 text-sm text-red-500">
                {errors.deadlineDate.message}
              </span>
            )}
          </div>

          <Button
            className="mt-[50px] flex gap-5 w-[130px] h-[40px] text-white my-auto rounded-md bg-gradient-to-br from-yellow-400 to-gray-700"
            type="submit"
            onClick={() => {
              const formValues = getValues();
              console.log('***form***', formValues);
            }}
          >
            <IoIosSend className="w-5 h-5 text-white" />
            <span className="text-white">Submit</span>
          </Button>
        </Section>

        {/* Right side */}
        <Section className="hidden lg:justify-center lg:my-auto lg:mx-auto lg:flex lg:flex-col">
          <Paragraph className="text-center text-white">
            {watch('name') ? watch('name') : 'Uploaded image'}
          </Paragraph>
          {imagePreview && (
            <Image
              src={imagePreview}
              alt="uploaded-image"
              className="w-[300px] max-h-[400px] h-auto rounded-[20px] object-cover mt-[15px]"
            />
          )}
        </Section>
      </form>
    </div>
  );
};

export default FabricFinderSection;
