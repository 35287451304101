import React from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../components/common/Buttons/Button';
import { Section } from '../components';

export default function NotFound() {
  const navigate = useNavigate();
  const handleGoBack = () => navigate(-1);

  return (
    <Section className="grid min-h-screen place-items-center pt-20 bg-black text-white">
      <strong className="sm:text-3xl text-2xl">Oops, that page is gone.</strong>
      <p className="text-center sm:text-base text-sm">
        The page you're looking for may have been moved, deleted <br /> or
        possibly never existed
      </p>
      <h1 className="sm:text-[250px]/[250px] text-8xl font-bold !text-transparent bg-clip-text bg-gradient-to-r from-[#a27c4f] via-[#d6bd8f] to-[#a27c4f]">
        404
      </h1>

      <div className="flex items-center space-x-8 mt-1 sm:mt-5">
        <Button
          className="sm:w-40 sm:h-11 w-32 bg-gradient-to-r text-xs sm:text-sm h-7 from-[#ffcc00] via-[#e6b608c7] to-[#4c3c03] rounded-lg"
          onClick={handleGoBack}
        >
          GO BACK
        </Button>
        <Button
          className="sm:w-40 sm:h-11 w-32 bg-gradient-to-r text-xs sm:text-sm h-7 from-[#ffcc00] via-[#e6b608c7] to-[#4c3c03] rounded-lg"
          onClick={() => navigate('/')}
        >
          GO HOME
        </Button>
      </div>
    </Section>
  );
}
