import React from 'react';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { BiSolidCart } from 'react-icons/bi';
import { BsCheck, BsPlus } from 'react-icons/bs';
import { HiOutlineMinusSmall } from 'react-icons/hi2';
import { Triangle } from 'react-loader-spinner';

export const WishlistProductCard = ({
  products,
  handleDelete,
  handleUpdateQuantity,
  isUpdateQtyLoading,
  updateWishlistStatus,
  toggleProductSelection,
  selectedProductIds,
  handleOneWishlistToCart,
}) => {
  if (!Array.isArray(products)) {
    return null;
  }
  return (
    <>
      {products?.map((product) => (
        <div
          className="flex px-[1.5%] py-[2%] md:px-2 rounded-md md:py-2 my-[3%] md:my-3 mx-[1%] md:mx-3 bg-[#202020]"
          key={product?.name}
        >
          <div className="flex items-center justify-center gap-x-2 md:pl-0 pl-[1%]">
            <input
              id="checkbox-table-search-1"
              type="checkbox"
              className="appearance-none checked:bg-[#ca8a23] w-[14px] h-[14px] rounded bg-gray-700"
              onChange={() => toggleProductSelection(product._id)}
              checked={selectedProductIds.includes(product._id)}
            />

            <img
              className="object-cover w-16 h-16 rounded"
              src={product?.imageUrl[0]}
              alt="Something"
            />
          </div>

          <div className="flex ml-[3%] md:ml-4 justify-end">
            <div className="flex flex-col w-[55vw] gap-y-1">
              <div className="text-sm font-medium text-white">
                {product.name}
              </div>
              <span className="text-sm font-medium text-white">
                <span className="font-normal">Price: </span>
                {product?.price}
              </span>
              <div className="flex items-center">
                <BsCheck className="mr-1 text-green-500" />
                <div className="text-xs text-green-500">In stock</div>
              </div>
              <div className="flex items-center">
                <button
                  onClick={() => handleUpdateQuantity(product._id, 'decrease')}
                >
                  <HiOutlineMinusSmall className="text-primaryYellow" />
                </button>
                {updateWishlistStatus === 'loading' &&
                isUpdateQtyLoading[product._id] ? (
                  <span className="px-1.5">
                    <Triangle
                      height="20"
                      width="20"
                      color="#fff"
                      ariaLabel="triangle-loading"
                      wrapperStyle={{ display: 'inline-block' }}
                      wrapperClassName=""
                      visible={true}
                    />
                  </span>
                ) : (
                  <span className="px-3 text-sm text-white">
                    {product?.quantity}
                  </span>
                )}
                <button
                  onClick={() => handleUpdateQuantity(product._id, 'increase')}
                >
                  <BsPlus className="text-primaryYellow" />
                </button>
              </div>
            </div>

            <div className="flex flex-col items-end justify-center px-1 gap-y-3">
              <button onClick={() => handleOneWishlistToCart(product._id)}>
                <BiSolidCart className="w-6 h-6 text-secondaryYellow" />
              </button>
              <button onClick={() => handleDelete(product._id)}>
                <AiOutlineCloseCircle className="w-5 h-5 text-white hover:text-red-500" />
              </button>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};
