import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { BASE_URL } from '../../../utils/apiUtils';
import { toast } from 'react-toastify';

// Fetch products from the external API
export const AddProduct = createAsyncThunk(
  'addProduct',
  async ({ formData }, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${BASE_URL}api/products`, formData);

      toast.success('Product added successfully');
      return response.data.data;
    } catch (error) {
      if (error.response.status === 422) {
        toast.error(error.response.data.errors[0].message);
        return rejectWithValue(error.response.data.errors[0].message);
      } else {
        toast.error(error.response.data.message);
        return rejectWithValue(error.response.data.message);
      }
    }
  },
);

const AddProductSlice = createSlice({
  name: 'addProduct',
  initialState: {
    status: null,
    error: null,
    data: [],
    emailVerified: false,
  },
  extraReducers: {
    [AddProduct.pending]: (state) => {
      state.status = 'loading';
    },
    [AddProduct.fulfilled]: (state, action) => {
      state.data = action.payload;
      state.status = 'success';
    },
    [AddProduct.rejected]: (state, action) => {
      state.status = 'failed';
      state.Error = action.payload;
    },
  },
});

export default AddProductSlice.reducer;
