import FormProduct from '../Forms/formProduct';
import { Paragraph, Heading } from '../../components';

const BodyProduct = () => {
  return (
    <div className="flex rounded-md md:rounded items-center w-[95vw] mr-[2.5vw] ml-[2.5vw] gap-[30px] pt-[15px] md:pt-[60px] pb-[40px] pl-[10px] md:pl-[50px] pr-[50px] my-4 md:mt-0 bg-gradient-to-b from-[#040404] to-[#18181C]">
      <div className="md:mr-[10%]">
        <div className="mb-[20px] md:mb-[60px]">
          <Heading className="capitalize font-sans text-white font-medium text-3xl lg:text-4xl md:mt-8">
            create product
          </Heading>
          <Paragraph className="text-[#9E9E9E] font-sans pt-2 text-[13px] md:text-[16px]">
            Unleash the Power of sufurimart: Create and Manage Your Product with
            Ease!
          </Paragraph>
        </div>
        <FormProduct />
      </div>
    </div>
  );
};
export default BodyProduct;
