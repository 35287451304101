import React from 'react';
import { Container, Footer, Header, SearchResults } from '../../components';

const SearchResultsPage = () => {
  return (
    <Container>
      <Header />
      <SearchResults />
      <Footer />
    </Container>
  );
};

export default SearchResultsPage;
