import React from 'react';
import { Section } from '../common';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const ProductDetailsSkeleton = () => {
  return (
    <Section className="flex flex-col md:flex-row gap-[12px] md:gap-[40px] mx-[7%] md:mx-[100px] my-[40px]">
      {/* Left */}
      <Section className="rounded-[25px] md:w-[50%] h-[550px]">
        <Skeleton
          baseColor="#1d1f1e"
          highlightColor="#292928"
          className=" rounded-[25px] w-[50%] h-[550px]"
        />
      </Section>
      <Section className="bg-[#1d1f1e] md:w-[50%] h-[550px] hidden md:flex flex-col rounded-md">
        <Skeleton
          baseColor="#1d1f1e"
          highlightColor="#292928"
          className="px-5 py-2 bg-[#1E2125] md:w-[50%] h-[550px] flex flex-col rounded-md"
        />
      </Section>
    </Section>
  );
};

export default ProductDetailsSkeleton;
