import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { fetchCategories } from '../../../Redux/Features/product/categorySlice';

export const TableBody = ({ products }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchCategories());
  }, []);

  let categories = useSelector((state) => state.categories.data);

  if (!Array.isArray(products) || !products.length) {
    return (
      <div className="flex text-center align-middle justify-center">
        <p className="text-[#5f5f5f]">No products to display.</p>
      </div>
    );
  }

  return (
    <tbody className="text-sm">
      {products?.map((product, index) => (
        <tr key={product?.name} className="group hover:bg-[#181717]">
          <Link
            to={`/product/view/${product?.id}`}
            style={{ width: '100%', display: 'contents' }}
          >
            <td className="px-2 py-4 group-hover:bg-[#181717] rounded-l-md align-middle">
              <span className="px-4 py-2 text-[#8c8c8c]">{index + 1}</span>
            </td>
            <td className="group-hover:bg-[#181717]" style={{ width: '50%' }}>
              <th
                scope="row"
                className="flex items-center py-4 pl-3 text-white whitespace-nowrap"
                style={{ width: '100%' }}
              >
                <img
                  className="object-cover w-10 h-10 rounded-full"
                  src={product?.imageUrl}
                  alt={product?.name}
                />
                <div className="pl-4 flex-grow">
                  <div className="font-normal truncate">{product?.name}</div>
                  {categories.map((category, index) => (
                    <div
                      key={index}
                      className="hidden lg:flex align-middle items-center justify-center text-center font-normal truncate text-[#8c8c8c]"
                    >
                      {category._id === product?.categoryId && category?.name}
                    </div>
                  ))}
                </div>
              </th>
            </td>

            <td
              className="py-4 group-hover:bg-[#181717] align-middle"
              style={{ width: '25%' }}
            >
              <span className="px-4 py-2 text-[#8c8c8c]">
                {product?.salesCount.toLocaleString()} sales
              </span>
            </td>

            <td
              className="py-4 group-hover:bg-[#181717] rounded-r-md align-middle"
              style={{ width: '25%' }}
            >
              <span className="py-2 text-[#8c8c8c]">
                RWF &nbsp;{product?.totalRevenue.toLocaleString()}
              </span>
            </td>
          </Link>
        </tr>
      ))}
    </tbody>
  );
};
