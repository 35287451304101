import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

const LoginPopup = ({
  message,
  onClose,
  closeButtonStyle,
  homeButtonStyle,
}) => {
  useEffect(() => {
    document.body.classList.add('disable-scroll');
    return () => {
      document.body.classList.remove('disable-scroll');
    };
  }, []);

  return (
    <div className="fixed inset-0 flex items-center justify-center z-[9999] popup-overlay disable-scroll">
      <div className="backdrop-blur-md border border-[#2d2109] md:gap-4 rounded-lg flex flex-col shadow-lg p-4 md:w-[30%] bg-gradient-to-b from-[#040404] via-[rgba(53, 47, 47, 0.00)] to-[#191919] mx-4 md:mx-0">
        <p className="text-white text-3xl border-b border-b-[#2d2109] pt-1 pb-5">
          Not Logged In
        </p>
        <p className="text-[#9f9f9f] text-md mt-3 md:mt-0">{message}</p>
        <div className="flex gap-2 ml-auto py-3 md:py-0">
          <Link to="/auth/login">
            <button className="mt-2 px-4 py-2 bg-latestYellow hover:bg-secondaryYellow hover:text-[#e2e2e2] text-white rounded-md">
              Login
            </button>
          </Link>

          <button
            onClick={onClose}
            className={`${closeButtonStyle} mt-2 px-4 py-2 bg-[#ded3bc] hover:bg-[#fff4de] text-latestYellow rounded-md`}
          >
            Close
          </button>

          <button
            onClick={onClose}
            className={`${homeButtonStyle} ${
              !homeButtonStyle ? 'hidden' : ''
            } mt-2 px-4 py-2 bg-[#ded3bc] hover:bg-[#fff4de] text-latestYellow rounded-md`}
          >
            Home
          </button>
        </div>
      </div>
    </div>
  );
};

export default LoginPopup;
