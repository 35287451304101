import React from 'react';
import { homeFinalSectionDetails } from '../../../utils';
import Heading from '../Heading';
import Image from '../Image';
import Section from '../Section';

const HomeFinalSection = () => {
  return (
    <Section>
      <div className="grid md:grid-cols-2 md:grid-rows-2 mx-[10%] md:mx-[15%] my-12 gap-12">
        {homeFinalSectionDetails.map((section, index) => (
          <div className="flex gap-[20px] my-[20px]" key={index}>
            <div>
              <Image
                src={section.svg}
                alt={section.title}
                className="w-[45px] md:w-[90px] h-[45px] md:h-[90px] rounded-lg object-contain"
              />
            </div>
            <div className="mt-[15px]">
              <Heading className="text-latestYellow md:text-[25px] font-[600]">
                {section.title}
              </Heading>
              <Heading className="text-white font-[100]">
                {section.description}
              </Heading>
            </div>
          </div>
        ))}
      </div>
    </Section>
  );
};

export default HomeFinalSection;
