import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { BASE_URL, ADMINTOKEN } from '../../../utils/apiUtils';

export const fetchSubsubcategories = createAsyncThunk(
  'fetch-subSubCategory',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${BASE_URL}api/products/subSubCategory/all`,
        {
          headers: {
            Authorization: `Bearer ${ADMINTOKEN}`,
            'Content-Type': 'application/json',
          },
        },
      );
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  },
);
export const CreateSubsubcategory = createAsyncThunk(
  'create-subSubCategory',
  async (formData, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${BASE_URL}api/products/subSubcategory`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${ADMINTOKEN}`,
            'Content-Type': 'application/json',
          },
        },
      );
      return response.data;
    } catch (error) {
      if (error.response.status === 422) {
        return rejectWithValue(error.response.data.errors[0].message);
      } else {
        return rejectWithValue(error.response.data.message);
      }
    }
  },
);

const SubsubcategorySlice = createSlice({
  name: 'subSubcategories',
  initialState: {
    subSubcategories: null,
    status: null,
    error: null,
    data: [],
  },
  extraReducers: {
    [CreateSubsubcategory.pending]: (state) => {
      state.status = 'loading';
    },
    [CreateSubsubcategory.fulfilled]: (state, action) => {
      state.data = action.payload;
      state.status = 'success';
    },
    [CreateSubsubcategory.rejected]: (state, action) => {
      state.status = 'failed';
      state.Error = action.payload;
    },
    [fetchSubsubcategories.pending]: (state) => {
      state.status = 'loading.....';
    },
    [fetchSubsubcategories.fulfilled]: (state, action) => {
      state.data = action.payload;
      state.status = 'success';
    },
    [fetchSubsubcategories.rejected]: (state, action) => {
      state.status = 'failed';
      state.Error = action.payload;
    },
  },
});

export default SubsubcategorySlice.reducer;
