import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ViewCategory } from '../../Redux/Features/Products/categorySlice';
import { ViewSubcategory } from '../../Redux/Features/Products/subcategorySlice';
import {
  Button,
  Input,
  ImageInput,
  TextArea,
  Paragraph,
  Image,
} from '../../components';
import { getVendors } from '../../Redux/Features/Login/vendorSlice';
import { fetchCategories } from '../../Redux/Features/product/categorySlice';
import { ToastContainer, toast } from 'react-toastify';
import { cloudinaryCloudName, uploadPreset } from '../../utils/apiUtils';
import { AddProduct } from '../../Redux/Features/product/addProductSlice';
import { fetchSubsubcategories } from '../../Redux/Features/Products/subSubcategorySlice';
import { IoAddCircleOutline } from 'react-icons/io5';
import { useForm, Controller } from 'react-hook-form';

const FormProduct = () => {
  const dispatch = useDispatch();

  const inputStyles =
    'bg-[#474747] w-[88vw] md:w-[100%] pl-[20px] pr-[10px] py-[5px] rounded-sm text-[#ffffff]';

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    getValues,
    setValue,
    setError,
  } = useForm();

  const [categories, setCategories] = useState([]);
  const [uploadProgress, setUploadProgress] = useState(0);
  const categories1 = useSelector((state) => state.categories.data);
  const subcategories = useSelector((state) => state.subcategory.data);
  const subSubcategories = useSelector((state) => state.subSubcategory.data);

  const status = useSelector((state) => state.addProduct.status);
  const error = useSelector((state) => state.addProduct.Error);

  useEffect(() => {
    dispatch(fetchCategories());
    dispatch(fetchSubsubcategories());
  }, [dispatch]);

  useEffect(() => {
    dispatch(ViewSubcategory());
  }, [dispatch]);

  const MAX_UPLOADED_IMAGES = 4;

  const vendors = useSelector((state) => state.vendors.data);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredOptions, setFilteredOptions] = useState(vendors);
  const handleInputChange = (event) => {
    const newSearchTerm = event.target.value;
    setSearchTerm(newSearchTerm);

    // Filter the options based on the search term
    const filteredOptions = vendors.filter((vendor) =>
      vendor.vendorBusinessName
        .toLowerCase()
        .includes(newSearchTerm.toLowerCase()),
    );
    setFilteredOptions(filteredOptions);
  };

  const getVendorId = (vendorName) => {
    const elementWithVendorBusinessName = vendors.find(
      (element) => element.vendorBusinessName === vendorName,
    );
    if (elementWithVendorBusinessName) {
      setValue('vendorId', elementWithVendorBusinessName._id);
      setError('vendorId', {
        type: 'manual',
        message: '',
      });
    }
  };
  useEffect(() => {
    dispatch(getVendors());
  }, [dispatch]);

  const handleImageChange = (e) => {
    const files = e.target.files;
    const newImages = [];

    if (files && files.length > 0 && files.length < 5) {
      const promises = Array.from(files).map((file) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => {
            newImages.push(reader.result);
            resolve();
          };
          reader.readAsDataURL(file);
        });
      });
      Promise.all(promises).then(() => {
        if (files.length <= MAX_UPLOADED_IMAGES) {
          setValue('imageUrl', newImages);
        }
      });
    }
  };

  useEffect(() => {
    if (status === 'success') {
      reset();

      toast.success('Product created successfully');
    } else if (status === 'failed') {
      toast.error(error);
    }
  }, [status, error]);

  const onSubmit = async (data) => {
    const uploadedImages = [];

    const uploadPromises = data?.imageUrl.map((imageData) => {
      const formData1 = new FormData();
      formData1.append('file', imageData);
      formData1.append('upload_preset', `${uploadPreset}`);

      return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.open(
          'POST',
          `https://api.cloudinary.com/v1_1/${cloudinaryCloudName}/image/upload`,
          true,
        );
        xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
        xhr.upload.addEventListener('progress', (event) => {
          const progress = Math.round((event.loaded / event.total) * 100);
          setUploadProgress(progress);
        });
        xhr.onreadystatechange = () => {
          if (xhr.readyState === XMLHttpRequest.DONE) {
            if (xhr.status === 200) {
              const imageData = JSON.parse(xhr.responseText);
              uploadedImages.push(imageData.url);
              resolve();
            } else {
              console.error('Error uploading image:', xhr.statusText);
              console.log('Cloudinary response:', xhr.responseText);
              reject();
            }
          }
        };
        xhr.send(formData1);
      });
    });

    Promise.all(uploadPromises)
      .then(async () => {
        const {
          name,
          categoryId,
          subcategoryId,
          subSubcategoryId,
          description,
          imageUrl = uploadedImages,
          unitMeasurement,
          minimumOrderQuantity,
          vendorId,
          unitPrice,
        } = data;
        await dispatch(
          AddProduct({
            formData: {
              name,
              categoryId,
              subcategoryId,
              subSubcategoryId,
              description,
              imageUrl,
              unitMeasurement,
              minimumOrderQuantity,
              vendorId,
              unitPrice,
            },
          }),
        );
        reset();
        setUploadProgress(0);
        setSearchTerm('');
      })
      .catch((error) => {
        console.error('Image upload error:', error);
      });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await dispatch(ViewCategory());
        const fetchedCategories = response.payload.data.data;

        setCategories(fetchedCategories);

        await dispatch(getVendors());
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [dispatch]);

  return (
    <div className="md:flex items-center md:w-[87vw]">
      <ToastContainer />
      <form onSubmit={handleSubmit(onSubmit)} className="w-[50%]">
        <div className="block mb-[20px] md:mb-[50px]">
          <div className="mb-[10px] md:mb-[20px]">
            <label className="text-white" htmlFor="productName">
              Product Name
            </label>
          </div>
          <div className="md:w-[100%] m-0">
            <Controller
              name="name"
              control={control}
              defaultValue=""
              rules={{ required: 'Product Name is required' }}
              render={({ field }) => (
                <input
                  type="text"
                  placeholder="e.g. Fabric Sheet"
                  className={`${inputStyles}`}
                  {...field}
                />
              )}
            />
            {errors.name && (
              <span className="ml-2 text-sm text-red-500">
                {errors.name.message}
              </span>
            )}
          </div>
        </div>

        <div className="block mb-[20px] md:mb-[50px]">
          <div className="mb-[10px] md:mb-[20px]">
            <label className="text-white" htmlFor="categoryId">
              Category Name
            </label>
          </div>
          <div className="w-[88vw] md:w-[100%]  m-0">
            <Controller
              name="categoryId"
              control={control}
              defaultValue=""
              rules={{
                required: 'Category is required',
              }}
              render={({ field }) => (
                <select
                  className="text-[#ffffff] bg-[#474747] w-[100%] pl-[20px] pr-[10px] py-[5px] rounded-sm"
                  id="categoryName"
                  name="categoryId"
                  {...field}
                >
                  <option value="" disabled>
                    Select Category Name
                  </option>
                  {categories1 &&
                    categories1.length > 0 &&
                    categories1.map((category) => {
                      return (
                        <option key={category._id} value={category._id}>
                          {category.name}
                        </option>
                      );
                    })}
                </select>
              )}
            />
            {errors.categoryId && (
              <span className="ml-2 text-sm text-red-500">
                {errors.categoryId.message}
              </span>
            )}
          </div>
        </div>

        <div
          className={`${!watch('categoryId') ? 'hidden' : 'block'} mb-[50px]`}
        >
          <div className="mb-[10px] md:mb-[20px]">
            <label className="text-white" htmlFor="categoryId">
              Subcategory Name
            </label>
          </div>
          <div className="w-[88vw] md:w-[100%] m-0">
            <Controller
              name="subcategoryId"
              control={control}
              defaultValue=""
              rules={{
                required: 'Subcategory is required',
              }}
              render={({ field }) => (
                <select
                  className="text-[#ffffff] bg-[#474747] w-[100%] pl-[20px] pr-[10px] py-[5px] rounded-sm"
                  id="subcategoryName"
                  name="subcategoryId"
                  {...field}
                >
                  <option value="" disabled>
                    Select Subcategory
                  </option>
                  {subcategories &&
                    subcategories.length > 0 &&
                    subcategories.map((subcategory) => {
                      if (subcategory.categoryId !== watch('categoryId')) {
                        return null;
                      }
                      return (
                        <option key={subcategory._id} value={subcategory._id}>
                          {subcategory.name}
                        </option>
                      );
                    })}
                </select>
              )}
            />
            {errors.subcategoryId && (
              <span className="ml-2 text-sm text-red-500">
                {errors.subcategoryId.message}
              </span>
            )}
          </div>
        </div>

        <div
          className={`${
            !watch('subcategoryId') ? 'hidden' : 'block'
          } mb-[50px] w-[88vw] md:w-[100%]`}
        >
          <div className="mb-[10px] md:mb-[20px]">
            <label className="text-white" htmlFor="subSubcategoryId">
              Sub-subcategory Name
            </label>
          </div>
          <div className="w-[100%] m-0">
            <Controller
              name="subSubcategoryId"
              control={control}
              defaultValue=""
              rules={{
                required: 'Sub-subcategory is required',
              }}
              render={({ field }) => (
                <select
                  className="text-[#ffffff] bg-[#474747] w-[100%] pl-[20px] pr-[10px] py-[5px] rounded-sm"
                  id="subsubcategoryName"
                  name="subSubcategoryId"
                  {...field}
                >
                  <option value="" disabled>
                    Select Subsubcategory
                  </option>
                  {subSubcategories &&
                    subSubcategories.length > 0 &&
                    subSubcategories.map((subSubcategory) => {
                      if (
                        subSubcategory.subCategoryId !== watch('subcategoryId')
                      ) {
                        return null;
                      }
                      return (
                        <option
                          key={subSubcategory._id}
                          value={subSubcategory._id}
                        >
                          {subSubcategory.name}
                        </option>
                      );
                    })}
                </select>
              )}
            />
            {errors.subSubcategoryId && (
              <span className="ml-2 text-sm text-red-500">
                {errors.subSubcategoryId.message}
              </span>
            )}
          </div>
        </div>

        <div className="block mb-[50px]">
          <div className="mb-[20px]">
            <label className="text-white" htmlFor="description">
              Description
            </label>
          </div>
          <div className="w-[88vw] md:w-[100%]  m-0">
            <Controller
              name="description"
              control={control}
              defaultValue=""
              rules={{
                required: 'Please provide your description.',
              }}
              render={({ field }) => (
                <TextArea
                  placeholder="Type Text Here..."
                  className="bg-[#18181C] text-[#9E9E9E] w-[100%] h-[100px] md:h-[150px] pl-[20px] pr-[10px] py-[5px] rounded-sm"
                  id="description"
                  name="description"
                  {...field}
                />
              )}
            />
            {errors.description && (
              <p className="mt-1 text-red-500">{errors.description.message}</p>
            )}
          </div>
        </div>

        <div className="block mb-[20px] md:mb-[50px]">
          <div className="mb-[10px] md:mb-[20px]">
            <label className="text-white" htmlFor="unitPrice">
              Unit Price
            </label>
          </div>
          <div className="w-[88vw] md:w-[100%] m-0">
            <Controller
              name="unitPrice"
              control={control}
              defaultValue=""
              rules={{ required: 'Unit Price is required' }}
              render={({ field }) => (
                <Input
                  className={`${inputStyles}`}
                  placeholder="e.g. 1200"
                  type="number"
                  id="unitPrice"
                  min="1"
                  {...field}
                />
              )}
            />
            {errors.unitPrice && (
              <p className="mt-1 text-red-500">{errors.unitPrice.message}</p>
            )}
          </div>
        </div>

        <div className="block mb-[50px] w-[88vw] md:w-[100%]">
          <div className="mb-[20px]">
            <label className="text-white" htmlFor="unitMeasurement">
              Unit of Measurement
            </label>
          </div>
          <div className="w-[100%]  m-0">
            <Controller
              name="unitMeasurement"
              control={control}
              defaultValue=""
              rules={{
                required: 'Unit Measurement is required',
              }}
              render={({ field }) => (
                <select
                  className="text-[#ffffff] bg-[#474747] w-[100%] pl-[20px] pr-[10px] py-[5px] rounded-sm"
                  id="unitMeasurement"
                  name="unitMeasurement"
                  {...field}
                >
                  <option value="" disabled>
                    Select a Unit
                  </option>
                  <option value="meter">Meter</option>
                  <option value="piece">Piece</option>
                  <option value="pack">Pack</option>
                  <option value="packet">Packet</option>
                  <option value="circularRound">Circular Round</option>
                </select>
              )}
            />
            {errors.unitMeasurement && (
              <span className="ml-2 text-sm text-red-500">
                {errors.unitMeasurement.message}
              </span>
            )}
          </div>
        </div>

        <div className="mb-[50px] w-[88vw] md:w-[100%]">
          <div className="mb-[20px]">
            <label className="text-white" htmlFor="minimumOrderQuantity">
              Minimum Order Quantity
            </label>
          </div>
          <div className="w-[100%] m-0">
            <Controller
              name="minimumOrderQuantity"
              control={control}
              defaultValue=""
              rules={{ required: 'Minimum Order Quantity is required' }}
              render={({ field }) => (
                <Input
                  className={`${inputStyles}`}
                  placeholder="e.g. 15"
                  type="number"
                  id="minimumOrderQuantity"
                  defaultValue="1"
                  min="1"
                  {...field}
                />
              )}
            />
            {errors.minimumOrderQuantity && (
              <p className="mt-1 text-red-500">
                {errors.minimumOrderQuantity.message}
              </p>
            )}
          </div>
        </div>

        {/* Select Product Owner */}
        <div className="block mb-[50px]">
          <div className="mb-[20px]">
            <label className="text-white" htmlFor="vendor">
              Select vendor
            </label>
          </div>
          <Controller
            name="vendorId"
            control={control}
            defaultValue=""
            rules={{ required: 'Vendor is required' }}
            render={({ field }) => (
              <>
                <input
                  type="text"
                  className={inputStyles}
                  value={searchTerm}
                  onChange={(e) => {
                    handleInputChange(e);
                  }}
                  placeholder="Search owner..."
                />
                {searchTerm !== '' && filteredOptions.length > 0 && (
                  <ul className="z-10 w-full mt-2 bg-[#474747] border border-gray-300 rounded-lg shadow-lg">
                    {filteredOptions.map((vendor) => (
                      <li
                        key={vendor._id}
                        className="px-4 py-2 text-white cursor-pointer hover:text-black hover:bg-gray-300"
                        onClick={() => {
                          setSearchTerm(vendor.vendorBusinessName);
                          setFilteredOptions([]);
                          getVendorId(vendor.vendorBusinessName);
                        }}
                      >
                        {vendor.vendorBusinessName}
                      </li>
                    ))}
                  </ul>
                )}
              </>
            )}
          />
          {errors.vendorId && (
            <span className="ml-2 text-sm text-red-500">
              {errors.vendorId.message}
            </span>
          )}
        </div>

        <div className="block mb-[50px]">
          <div className="mb-[20px]">
            <label className="text-white" htmlFor="image">
              Choose Images of Product (4)
            </label>
          </div>
          <div className="md:w-[40%] relative flex justify-around max-w-[200px] m-0 border-[1px] border-[#F0B00A] rounded-md items-center py-1">
            <div className="absolute flex justify-around w-[100%] cursor-pointer">
              <span className="my-auto">
                <IoAddCircleOutline className="h-5 w-5 md:h-4 md:w-4 text-[#F0B00A]" />
              </span>

              <div className="ml-[10px] pt-[2px]">
                <Paragraph className="text-[#F0B00A] text-[12px] md:text-[12px] lg:text-[16px]">
                  Choose Images
                </Paragraph>
              </div>
            </div>
            {/* Multiple Images */}
            <ImageInput
              className="opacity-[0%] cursor-pointer"
              type="file"
              id="image"
              name="image"
              accept="image/*"
              multiple
              onChange={handleImageChange}
            />
          </div>
          {uploadProgress > 0 && (
            <div className="text-[#F0B00A]">
              <span className="text-[#ffffff]">
                Uploading {watch('imageUrl').length} images:
              </span>{' '}
              {`${uploadProgress}%`}
            </div>
          )}
        </div>

        <Button
          className="md:px-[70px] prod-btn text-white md:py-[13px] rounded-md"
          style={{
            width: 'fit-content',
            background: 'linear-gradient(45deg, #C2B21E, #5F5750)',
          }}
          type="submit"
        >
          <div className="flex items-center">
            {status === 'loading' ? (
              <span>
                <svg
                  aria-hidden="true"
                  class="w-4 md:w-8 h-4 md:h-8 mr-2 md:mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="currentColor"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentFill"
                  />
                </svg>
              </span>
            ) : null}
            <span>Create Product</span>
          </div>
        </Button>
      </form>
      <div className="block md:ml-[7%] mt-10">
        <div className="mb-[20px]">
          <Paragraph className="text-[#FFF] font-sans">
            Uploaded Images:{' '}
            <span className="text-[12px] text-primaryYellow font-[500]">
              {watch('imageUrl') ? watch('imageUrl').length : 0}/4
            </span>
          </Paragraph>
        </div>
        <div className="grid grid-cols-2 gap-3 md:gap-4">
          {watch('imageUrl') &&
            watch('imageUrl').map((image, index) => (
              <Image
                key={index}
                src={image}
                alt={`image${index + 1}`}
                className="border border-[#7b7b7b] w-[110px] md:w-[200px] h-[110px] md:h-[200px] object-cover rounded-md md:rounded-lg"
              />
            ))}
        </div>
      </div>
    </div>
  );
};
export default FormProduct;
