import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { BASE_URL, ADMINTOKEN } from '../../../utils/apiUtils';

export const ViewOrders = createAsyncThunk(
  'view-all-orders',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${BASE_URL}api/orders`, {
        headers: {
          Authorization: `Bearer ${ADMINTOKEN}`,
          'Content-Type': 'application/json',
        },
      });

      return response.data;
    } catch (error) {
      if (error.response.status === 404) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.response.data.message);
      }
    }
  },
);

export const ViewOrdersByLoggedInUser = createAsyncThunk(
  'view-all-orders-by-loggedInUser',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${BASE_URL}api/analytics/filtered-orders`,
        {
          headers: {
            Authorization: `Bearer ${ADMINTOKEN}`,
            'Content-Type': 'application/json',
          },
        },
      );

      return response.data.filteredOrders;
    } catch (error) {
      if (error.response.status === 404) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.response.data.message);
      }
    }
  },
);

export const CreateOrder = createAsyncThunk(
  'create-order',
  async (orderDetails, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${BASE_URL}api/orders/place-order`,
        orderDetails,
        {
          headers: {
            Authorization: `Bearer ${ADMINTOKEN}`,
            'Content-Type': 'application/json',
          },
        },
      );

      if (response) {
        const orderId = response?.data?.newOrder?._id || '';
        try {
          localStorage.setItem('orderId', orderId);
        } catch (error) {
          console.error(
            'Error while storing orderItems in localStorage:',
            error,
          );
        }
      }
      return response.data;
    } catch (error) {
      if (error.response.status === 422) {
        return rejectWithValue(error.response.data.errors[0].message);
      }
      if (error.response.status === 404) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.response.data.message);
      }
    }
  },
);

export const FetchOrder = createAsyncThunk(
  'fetch-order',
  async (orderId, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${BASE_URL}api/orders/${orderId}`, {
        headers: {
          Authorization: `Bearer ${ADMINTOKEN}`,
          'Content-Type': 'application/json',
        },
      });

      return response.data;
    } catch (error) {
      if (error.response.status === 404) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.response.data.message);
      }
    }
  },
);

const OrderSlice = createSlice({
  name: 'order',
  initialState: {
    status: null,
    error: null,
    data: [],
  },
  extraReducers: {
    [ViewOrders.pending]: (state) => {
      state.status = 'loading';
    },
    [ViewOrders.fulfilled]: (state, action) => {
      state.data = action.payload;
      state.status = 'success';
    },
    [ViewOrders.rejected]: (state, action) => {
      state.status = 'failed';
      state.Error = action.payload;
    },
    [ViewOrdersByLoggedInUser.pending]: (state) => {
      state.status = 'loading';
    },
    [ViewOrdersByLoggedInUser.fulfilled]: (state, action) => {
      state.dataByLoggedInUser = action.payload;
      state.status = 'success';
    },
    [ViewOrdersByLoggedInUser.rejected]: (state, action) => {
      state.status = 'failed';
      state.Error = action.payload;
    },
    [FetchOrder.pending]: (state) => {
      state.status = 'loading';
    },
    [FetchOrder.fulfilled]: (state, action) => {
      state.data = action.payload;
      state.status = 'success';
    },
    [FetchOrder.rejected]: (state, action) => {
      state.status = 'failed';
      state.Error = action.payload;
    },
    [CreateOrder.pending]: (state) => {
      state.status = 'loading';
    },
    [CreateOrder.fulfilled]: (state, action) => {
      state.data = action.payload;
      state.status = 'success';
    },
    [CreateOrder.rejected]: (state, action) => {
      state.status = 'failed';
      state.Error = action.payload;
    },
  },
});

export default OrderSlice.reducer;
