import React from 'react';

const Button = ({ children, onClick, className, style, disabled }) => {
  return (
    <button
      className={`lg:px-4 lg:py-2 px-2 py-2 text-white ${className}`}
      style={style}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  );
};

export default Button;
