import React, { useEffect, useRef, useState } from 'react';
import {
  Button,
  Heading,
  Image,
  Input,
  Paragraph,
  Section,
  TextArea,
} from '../common';
import { CiSearch } from 'react-icons/ci';
import { IoIosSend } from 'react-icons/io';
import { RiSearchLine } from 'react-icons/ri';
import { useDispatch, useSelector } from 'react-redux';
import {
  GetOwnerSamples,
  RequestSample,
} from '../../Redux/Features/Samples/sampleSlice';
import { ToastContainer, toast } from 'react-toastify';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { useForm, Controller } from 'react-hook-form';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const SampleRequestForm = () => {
  const [openModal, setOpenModal] = useState(false);
  const dropdownRef = useRef(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchImage, setSearchImage] = useState('');
  let productSamples = useSelector((state) => state.product.data);
  const [filteredOptions, setFilteredOptions] = useState(productSamples);
  const [productError, setProductError] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    getValues,
    setValue,
  } = useForm();

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(GetOwnerSamples());
  }, [dispatch]);

  const handleInputChange = (event) => {
    const newSearchTerm = event.target.value;
    setSearchTerm(newSearchTerm);

    const filteredOptions = productSamples.filter((productSample) =>
      productSample.name.toLowerCase().includes(newSearchTerm.toLowerCase()),
    );
    setFilteredOptions(filteredOptions);
  };

  const onSubmit = async (data) => {
    const { notes, deliveryDate, deliveryOption, address, product } = data;
    if (!product) {
      setProductError(true);
      return;
    }

    await dispatch(
      RequestSample({
        formData: { notes, deliveryDate, deliveryOption, address, product },
      }),
    );
    reset();
    setSearchTerm('');
    setProductError(false);

    return toast.success('Request successful');
  };

  const toggleModal = () => {
    setOpenModal(!openModal);
  };

  const handleOutsideClick = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setOpenModal(false);
    }
  };

  useEffect(() => {
    window.addEventListener('click', handleOutsideClick);

    return () => {
      window.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  const filterDatePickerDates = (date) => {
    const isFutureDate = date >= new Date();
    // const isWednesdayOrFriday = date.getDay() === 3 || date.getDay() === 5;

    // return isFutureDate && isWednesdayOrFriday;
    return isFutureDate;
  };
  return (
    <div className=" pt-5 lg:pt-0  mx-[4%] mt-5 lg:mt-0 px-10 mb-10 bg-gradient-to-b from-[#040404] to-[#18181C]">
      <ToastContainer />
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-col-reverse lg:flex lg:flex-row"
      >
        <Section className="mb-10">
          <Heading className="text-white text-[40px] py-[15px]">
            Request Samples
          </Heading>
          <Paragraph className="text-[#c3c3c379] text-[14px]">
            Experience our products firsthand by requesting a sample and
            discover the <br /> exceptional quality and value we offer.
          </Paragraph>

          {/* Search Button */}
          <div className="mt-[30px]">
            <Paragraph className="text-white">Select Product</Paragraph>
            <button
              type="button"
              onClick={toggleModal}
              className="lg:px-4 lg:py-2 px-2 py-2 text-white mt-[5px] w-[100%] border justify-between border-primaryYellow px-auto rounded-md h-[42px] hover:bg-[#2a241599] flex gap-4"
            >
              <span className="justify-start text-primaryYellow">Search</span>
              <CiSearch className="w-5 h-5 mt-[2px] text-primaryYellow justify-end" />
            </button>
            {productError && !watch('product') && (
              <span className="ml-2 text-sm text-red-500">
                Product is required
              </span>
            )}
          </div>
          {/* Notes */}
          <div className="mt-[20px]">
            <Paragraph className="text-white">Notes</Paragraph>

            <Controller
              name="notes"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextArea
                  // placeholder="For example Gray Texture curtain having 3 meter long and 2 meter height"
                  className="mt-[5px] text-[#c3c3c379] text-[12px] w-[100%] rounded-[3px] h-[70px] px-4 py-2 bg-[#18181C] focus:outline-none focus:ring"
                  name="notes"
                  // value={formData.notes}
                  // onChange={handleChange}
                  {...field}
                />
              )}
            />
            {errors.notes && (
              <span className="ml-2 text-sm text-red-500">
                {errors.notes.message}
              </span>
            )}
          </div>

          <Section className="flex flex-col justify-center mx-auto my-auto lg:hidden mt-[10px]">
            <Paragraph className="text-sm text-center text-white">
              {searchTerm ? searchTerm : 'Uploaded image'}
            </Paragraph>
            {searchImage && (
              <Image
                src={searchImage}
                alt="productSample.imgUrl"
                className="w-[300px] max-h-[400px] h-auto rounded-[20px] object-cover mt-[15px]"
              />
            )}
          </Section>

          {/* Delivery Date */}
          <div className="mt-[20px] flex flex-col">
            <Paragraph className="text-white">Delivery Date</Paragraph>
            <Controller
              name="deliveryDate"
              control={control}
              defaultValue=""
              rules={{
                required: 'Delivery Date is required',
              }}
              render={({ field }) => (
                <DatePicker
                  selected={selectedDate}
                  filterDate={(date) => filterDatePickerDates(date)}
                  onChange={(date) => {
                    setSelectedDate(date);
                    field.onChange(date);
                  }}
                  showYearDropdown
                  className="mt-[5px] text-[12px] w-[100%] rounded-[3px] h-[40px] px-4 py-2 text-[#9E9E9E] focus:outline-none bg-[#474747]"
                  dateFormat="dd-MM-yyyy"
                  placeholderText="dd-mm-yyyy"
                />
              )}
            />
            {errors.deliveryDate && (
              <span className="ml-2 text-sm text-red-500">
                {errors.deliveryDate.message}
              </span>
            )}
          </div>

          {/* Address */}
          <div className="mt-[20px]">
            <label htmlFor="underline_select" className="sr-only">
              Underline select
            </label>
            <Controller
              name="deliveryOption"
              control={control}
              defaultValue=""
              rules={{
                required: 'Delivery Option is required',
              }}
              render={({ field }) => (
                <select
                  id="underline_select"
                  className="block py-2.5 px-0 w-full text-sm text-gray-500 bg-transparent border-0 border-b-2 border-gray-200 appearance-none dark:text-gray-400 dark:border-gray-700 focus:outline-none focus:ring-0 focus:border-gray-200 peer"
                  {...field}
                >
                  <option defaultValue>Choose a location</option>
                  <option value="tailor">Tailor's location</option>
                  <option value="designer">Your location</option>
                </select>
              )}
            />
            {errors.deliveryOption && (
              <span className="ml-2 text-sm text-red-500">
                {errors.deliveryOption.message}
              </span>
            )}

            {watch('deliveryOption') === 'tailor' ||
            watch('deliveryOption') === 'designer' ? (
              <div className="mt-[20px]">
                <Paragraph className="text-white">Confirm Address</Paragraph>
                <Controller
                  name="address"
                  control={control}
                  defaultValue=""
                  rules={{
                    required:
                      'Address is required for selected delivery option',
                  }}
                  render={({ field }) => (
                    <Input
                      type="text"
                      placeholder="Kimironko"
                      className="mt-[5px] text-[#000000] text-[12px] w-[100%] rounded-[3px] h-[40px] px-4 py-2 bg-[#18181C] focus:outline-none bg-[#c3c3c379]"
                      name="address"
                      {...field}
                    />
                  )}
                />

                {errors.address && (
                  <span className="ml-2 text-sm text-red-500">
                    {errors.address.message}
                  </span>
                )}
              </div>
            ) : null}
          </div>

          <Button
            className="mt-[50px] flex gap-5 w-[200px] h-[40px] text-white my-auto rounded-md bg-gradient-to-br from-yellow-400 to-gray-700"
            type="submit"
            onClick={() => {
              const formValues = getValues();
              console.log('Final Sample Report ✅', formValues);
            }}
          >
            <IoIosSend className="w-5 h-5 text-white" />
            <span className="text-white">Send Request</span>
          </Button>
        </Section>

        {/* Right side */}
        <Section className="hidden lg:flex lg:flex-col lg:justify-center lg:mx-auto lg:my-auto">
          <Paragraph className="text-center text-white">
            {searchTerm ? searchTerm : 'Uploaded image'}
          </Paragraph>
          {searchImage && (
            <Image
              src={searchImage}
              alt="productSample.imgUrl"
              className="w-[300px] max-h-[400px] h-auto rounded-[20px] object-cover mt-[15px]"
            />
          )}
        </Section>
        {openModal && (
          <div className="fixed top-[20vh] lg:ml-[5vw] w-[70vw] backdrop-blur-lg h-[60vh] rounded-md flex flex-col no-scrollbar overflow-y-auto shadow-2xl">
            <div className="flex justify-end mt-8 mb-1 mr-10 lg:mb-0">
              <button className="w-6 h-6 rounded-full" onClick={toggleModal}>
                <AiOutlineCloseCircle className="w-6 h-6 fill-primaryYellow" />
              </button>
            </div>
            <div className="relative block mx-4 lg:mx-32">
              <Input
                type="text"
                placeholder="Search fabrics, supplies, colors, patterns, and more!"
                value={searchTerm}
                onChange={handleInputChange}
                className="py-2 xl:px-9 md:px-[7%] pr-5 md:pr-8 w-full h-8 text-[13.5px] border border-[#1515143a] text-white rounded-[16px] focus:outline-none focus:ring focus:border-[#100e0e30] bg-[#292929]"
              />
              <RiSearchLine className="absolute hidden w-5 h-5 text-white transform cursor-pointer md:block top-2/4 -translate-y-2/4 right-3" />
            </div>
            <div className="flex justify-center w-full">
              {searchTerm !== '' && filteredOptions.length > 0 && (
                <Section className="w-[60vw]">
                  <div className="mb-[20px] px-2 lg:px-0">
                    {filteredOptions.map((productSample) => (
                      <Section
                        key={productSample._id}
                        className="flex flex-col lg:flex-row my-7 lg:mb-7 h-auto lg:h-[130px] lg:w-[94%] bg-gray-50 rounded-[20px] px-2 lg:px-5 py-4"
                      >
                        <Section className="flex text-black">
                          <Image
                            src={productSample?.imageUrl[0]}
                            alt={productSample?.name}
                            className="w-14 h-14 lg:w-[100px] lg:h-[100px] rounded-md object-cover"
                          />
                          <div className="ml-2 lg:ml-[20px] mt-[5px]">
                            <Heading className="text-[15px] lg:text-[18px]">
                              {productSample?.name}
                            </Heading>

                            <div className="flex items-center mt-2">
                              <Paragraph className="text-[#c3c3c3] text-[8px] lg:text-[12px]">
                                Unit Price:
                              </Paragraph>
                              <Paragraph className="text-secondaryYellow ml-2 font-bold text-[10] lg:text-[14px]">
                                {productSample?.unitPriceBeforeProfit}
                              </Paragraph>
                            </div>
                          </div>
                        </Section>

                        <div className="lg:ml-auto mt-3 lg:mt-[2%] cursor-pointer flex gap-3 justify-center lg:justify-start">
                          <button
                            className="flex justify-center cursor-pointer lg:justify-center gap-1 w-20 lg:w-28 lg:gap-3 mx-auto text-sm lg:text-base h-5 lg:h-[25px] text-black my-auto rounded-md border border-black"
                            type="submit"
                            onClick={() => {
                              setSearchTerm(productSample.name);
                              setFilteredOptions([]);
                              setSearchImage(productSample?.imageUrl[0]);
                              setValue('product', productSample._id);
                              toggleModal();
                            }}
                          >
                            <span>Select</span>
                          </button>
                        </div>
                      </Section>
                    ))}
                  </div>
                </Section>
              )}
            </div>
          </div>
        )}
      </form>
    </div>
  );
};

export default SampleRequestForm;
