import React from 'react';
import { Heading, Section } from '../../common';

const StatsCard = ({
  amount,
  subHeading,
  percentage,
  durationText,
  increase,
  className,
  currency,
}) => {
  return (
    <div
      className={`flex flex-col gap-0.5 p-3.5 w-[97%] h-[130px] rounded-[20px] text-[#ffffff] bg-[#000000] border border-[#5f5f5f96] ${className}`}
    >
      <div className="flex">
        <Heading className="text-4xl">{amount}</Heading>
        {currency && (
          <p className="text-[#5f5f5f] text-xs mt-auto pb-[3px]">
            &nbsp;{currency.toUpperCase()}
          </p>
        )}
      </div>
      <Section className="flex flex-row justify-between">
        <div>
          <p className="text-[#5f5f5f] text-[10px]">{subHeading}</p>
          <Heading
            className={`text-md ${
              increase ? 'text-[#39b659]' : 'text-[#FF0000]'
            }`}
          >
            {increase ? '+' : ''}
            {percentage}%
          </Heading>
          <p className="text-[#5f5f5f] text-[10px]">{durationText}</p>
        </div>
        <div className="w-20 h-15">
          <div
            className={`${increase ? 'upTrendCartLine' : 'downTrendCartLine'}`}
          />
        </div>
      </Section>
    </div>
  );
};

export default StatsCard;
