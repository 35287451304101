import { Paragraph, Heading, CategoryForm } from '../../components';

const BodyCategory = () => {
  return (
    <div className="flex items-center w-[95vw] mr-[2.5vw] ml-[2.5vw] md:gap-[30px] my-4 md:mt-0 md:pt-[60px] pb-[40px] pl-[5%] md:pl-[50px] pr-[5%] md:pr-[50px] bg-gradient-to-b from-[#040404] to-[#18181C]">
      <div className="md:mr-[10%]">
        <div className="mb-[10px] md:mb-[60px]">
          <Heading className="capitalize font-sans text-white font-medium text-2xl md:text-3xl lg:text-4xl mt-4 md:mt-8 pb-2 md:pb-0">
            create product category
          </Heading>

          <Paragraph className="text-[#9E9E9E] font-sans pb-8 md:pb-0">
            Unleash the Power of sufurimart: Create and Manage Your Product
            Categories with Ease!
          </Paragraph>
        </div>
        <CategoryForm />
      </div>
    </div>
  );
};
export default BodyCategory;
