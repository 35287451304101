import { Container, Heading, Image, Section, HomeDiscoverSection } from '..';
import { redTuxedo } from '../../utils';
const DiscoverSection = () => {
  return (
    <Container className="text-white flex-shrink-0">
      <Section className="md:container flex flex-col lg:flex-row md:mx-auto mt-10">
        {/* Left */}
        <Section className="flex flex-col md:w-[45%]">
          <Heading className="text-[#C59825] px-[5%] md:pl-[40px] lg:pl-[80px] text-[27px] md:text-[27px] font-[700] md:py-[40px]">
            Discover Everything You Need to Craft Your Design from Start to
            Finish!
          </Heading>

          <Section>
            {/* Red and Yellow circles behind the image */}
            <div className="flex ml-[6%] md:ml-[0px] gap-[70%] md:gap-[390px] md:my-[130px] mt-[80px]">
              <div className="w-[45px] md:w-[90px] h-[45px] md:h-[90px] text-[7px] md:text-[14px] mt-[60px] md:mt-[120px] font-thin bg-gradient-to-b from-[#FF5959] via-[#D41794] to-[#D41794] rounded-full"></div>
              <div className="w-[45px] md:w-[90px] h-[45px] md:h-[90px] text-[7px] md:text-[14px] mt-2 font-thin bg-gradient-to-b from-[#FFD704] via-[#FFD704] to-[#00FBBF] rounded-full"></div>
            </div>

            {/* Blurred divs */}
            <div
              className="ml-[17px] w-[91%] md:ml-[-20px] mt-[-86px] md:mt-[-300px] rounded-[30px] md:rounded-[40px] md:w-[194%] h-[70px] md:h-[130px] backdrop-filter backdrop-blur-md relative"
              style={{
                background:
                  'linear-gradient(180deg, rgba(87, 87, 87, 0.48) 0%, rgba(55, 56, 56, 0.16) 100%)',
                boxShadow: '0px 6px 40px 10px rgba(0, 0, 0, 0.5)',
                backdropFilter: 'blur(20px)',
                flexShrink: 0,
              }}
            ></div>
            <div
              className="ml-[17px] w-[91%] md:ml-[-20px] mt-[-87px] md:mt-[-200px] rounded-[30px] md:rounded-[40px] md:w-[194%] h-[70px] md:h-[130px] backdrop-filter backdrop-blur-md relative"
              style={{
                background:
                  'linear-gradient(180deg, rgba(87, 87, 87, 0.48) 0%, rgba(55, 56, 56, 0.16) 60%)',
                boxShadow: '0px 6px 40px 10px rgba(0, 0, 0, 0.5)',
                backdropFilter: 'blur(20px)',
                flexShrink: 0,
              }}
            ></div>

            <Image
              src={redTuxedo}
              className="lg:h-[330px] w-[64%] lg:w-[280px] ml-[18%] md:ml-[80px] mt-[-150px] md:mt-[-250px] z-10 absolute"
            />
          </Section>
        </Section>

        {/* Right */}
        <Section className="md:mt-[0px] mt-[240px] flex justify-center md:justify-normal md:w-[49%]">
          <HomeDiscoverSection />
        </Section>
      </Section>
    </Container>
  );
};

export default DiscoverSection;
