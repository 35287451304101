// MobileViewDropdown.js

import React, { useEffect } from 'react';
import { FiHeart } from 'react-icons/fi';
import { MdOutlineDashboardCustomize } from 'react-icons/md';
import { RiShoppingCartFill, RiShoppingCartLine } from 'react-icons/ri';
import { Link } from 'react-router-dom';
import { Section } from '../common';
import { SlOptionsVertical } from 'react-icons/sl';
import { useState } from 'react';
import { TbHeartPlus } from 'react-icons/tb';

const MobileViewDropdown = ({
  handleGoToWishlist,
  handleGoToCart,
  cartProducts,
  location,
  toggleDropdown,
  isDropdownOpen,
  wishlist,
}) => {
  const [isIconDropDownOpen, setIsIconDropDownOpen] = useState(false);

  useEffect(() => {
    if (isDropdownOpen) {
      setIsIconDropDownOpen(false);
    }
  }, [isDropdownOpen]);

  const dropDownTextStyle = 'text-[#B1ADAD]';
  const dropDownItemStyle =
    'flex p-3 gap-2 border-b border-b-[#343434] mr-auto w-[100%]';
  return (
    <>
      <div
        className="my-auto relative"
        onClick={() => {
          setIsIconDropDownOpen((prev) => !prev);
          isDropdownOpen && toggleDropdown();
        }}
      >
        <SlOptionsVertical
          className={`w-5 h-5 mb-1 ${
            isIconDropDownOpen ? 'text-yellow-500' : 'text-[#B1ADAD]'
          }`}
        />
        {isIconDropDownOpen && (
          <div className="absolute mt-[80%] right-0 z-[9999]">
            <Section className="flex bg-[#040404] border border-[#343434] rounded-md flex-col items-center gap-[3px] md:gap-[20px] mt-[-9px]">
              {/* Wishlist */}
              <div
                onClick={handleGoToWishlist}
                className={`cursor-pointer ${dropDownItemStyle}`}
              >
                {location.pathname === '/wishlist' ? (
                  <FiHeart className="w-5 h-5 text-latestYellow fill-latestYellow" />
                ) : wishlist.length > 0 ? (
                  <TbHeartPlus className="w-5 h-5 text-latestYellow" />
                ) : (
                  <FiHeart className="w-5 h-5 text-[#B1ADAD]" />
                )}
                <p className={`${dropDownTextStyle} pl-2`}>Wishlist</p>
              </div>

              {/* Moodboard */}
              <Link to="/coming-soon" className={`${dropDownItemStyle}`}>
                <MdOutlineDashboardCustomize className="h-6 w-6 text-[#B1ADAD] mr-1" />
                <p className={`${dropDownTextStyle}`}>Moodboard</p>
              </Link>

              {/* Cart icon */}
              {/* <Link to="/cart"> */}
              <div
                className={`flex items-center ml-[-4px] cursor-pointer border-none ${dropDownItemStyle}`}
                onClick={handleGoToCart}
              >
                {cartProducts.length === 0 ? (
                  <div className="relative flex">
                    <RiShoppingCartLine className="h-6 w-6 text-[#B1ADAD]" />

                    <span className="text-black absolute ml-[18px] mt-[-2px] text-[8px] bg-[#B1ADAD] rounded-full text-center items-center h-[12px] w-[12px] pt-[0.5px]">
                      {0}
                    </span>
                  </div>
                ) : (
                  <div className="relative flex">
                    <RiShoppingCartFill className="w-6 h-6 text-latestYellow" />

                    <span
                      className={`text-black absolute ml-[18px] mt-[-2px] text-[8px] bg-latestYellow rounded-full text-center items-center  
                    ${
                      cartProducts.length > 9
                        ? 'h-[14px] w-[14px] pt-[1.5px]'
                        : 'h-[12px] w-[12px] pt-[0.5px]'
                    }
                    ${
                      cartProducts.length > 99
                        ? 'h-[16px] w-[16px] pt-[2.5px]'
                        : 'h-[12px] w-[12px] pt-[0.5px]'
                    }
                    `}
                    >
                      {cartProducts.length || 0}
                    </span>
                  </div>
                )}
                <p className={`${dropDownTextStyle} pl-2`}>Cart</p>
              </div>
              {/* </Link> */}
            </Section>
          </div>
        )}
      </div>
    </>
  );
};

export default MobileViewDropdown;
