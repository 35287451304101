import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GetAllProducts } from '../../Redux/Features/Products/productSlice';
import { getRandomProducts } from '../../services';
import { Button, Heading } from '../common';
import HomeSuggestedProductsLoaders from '../skeletons/HomeSuggestedProductsLoaders';
import SuggestedProductsCard from './SuggestedProductsCard';

const HomeStitchingDeals = ({
  className,
  products,
  maxVisibleProducts = 3,
  SeeMoreClassName,
  ProductCardsClassName,
}) => {
  const [showMore, setShowMore] = useState(false);

  const toggleShowMore = () => {
    setShowMore((prevShowMore) => !prevShowMore);
  };

  const visibleProducts = getRandomProducts(4, products);

  return (
    <>
      <div className={className}>
        {products.length === 0 ? (
          <HomeSuggestedProductsLoaders maxVisibleProducts={4} />
        ) : (
          <>
            <div
              className={`grid grid-cols-1 md:grid-cols-2 mx-2 gap-10 ${ProductCardsClassName}`}
            >
              {visibleProducts.map((product, index) => (
                <SuggestedProductsCard key={index} product={product} />
              ))}
            </div>
            {products.length > maxVisibleProducts && (
              <div className={`${SeeMoreClassName} flex justify-center mt-4`}>
                <Button
                  className="my-5 border border-[#C38D01] rounded-md px-4 py-2 shadow-md w-[150px] h-[40px]"
                  onClick={toggleShowMore}
                >
                  <span className="text-[#C38D01]">
                    {showMore ? 'See Less' : 'See More'}
                  </span>
                </Button>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default HomeStitchingDeals;
