import React from 'react';
import {
  Container,
  Footer,
  Heading,
  Image,
  Navbar,
  NavbarCategories,
  Paragraph,
  Section,
} from '../components';

function AboutUs() {
  return (
    <Container>
      <Navbar />
      <NavbarCategories />
      <Section className="pt-4 md:pt-8 pb-12 px-3 md:px-9 rounded-lg my-10 mx-[15px] md:mx-[80px] bg-gradient-to-b from-[#292929] via-[#292929] to-[#352f2f2e] min-h-screen">
        {/* Top Paragraph Section */}
        <Section>
          <Heading className="text-4xl md:text-5xl text-[#C38D01] font-[600]">
            Our Story
          </Heading>
          <Section className="flex flex-col md:flex-row">
            <Section className="md:w-[65%]">
              <Paragraph className="text-[#999999] pt-6 md:pt-12">
                How SufuriMart was born..
              </Paragraph>
              <Paragraph className="text-[#D4D4D4] pt-6">
                Sewing threads are special kinds of yarns that are engineered
                and designed to pass through a sewing machine rapidly. They form
                efficient stitches without breaking or becoming distorted during
                the useful life of the product. <br /> <br />
                It used in garments, upholstery, air-supported fabric structures
                and textiles to join different components by forming a seam. The
                primary function of a seam is to provide uniform stress transfer
                from one piece of fabric to another, thus preserving the overall
                integrity of the fabric assembly. It used in garments,
                upholstery, air-supported fabric structures and textiles to join
                different components by forming a seam.
              </Paragraph>
            </Section>

            <Image
              src="https://res.cloudinary.com/dlw8ohn6p/image/upload/v1690018540/logan-weaver-lgnwvr-JqRQtSr2MCI-unsplash_naznbs.jpg"
              alt="about-us-image1"
              className="md:ml-[50px] mt-[40px] md:mt-[80px] w-[100%] md:w-[450px] h-[150px] md:h-[250px] rounded-[20px] md:rounded-[30px] object-cover"
            />
          </Section>
        </Section>

        {/* Middle Paragraph */}
        <Section className="flex flex-col-reverse md:flex-row">
          <Image
            src="https://res.cloudinary.com/dlw8ohn6p/image/upload/v1690018540/logan-weaver-lgnwvr-JqRQtSr2MCI-unsplash_naznbs.jpg"
            alt="about-us-image1"
            className="md:mr-[50px] mt-[40px] md:mt-[80px] w-[100%] md:w-[450px] h-[150px] md:h-[250px] rounded-[20px] md:rounded-[30px] object-cover"
          />
          <Section className="md:w-[65%]">
            <Paragraph className="text-[#999999] pt-12">Solution</Paragraph>
            <Paragraph className="text-[#D4D4D4] pt-6">
              Sewing threads are special kinds of yarns that are engineered and
              designed to pass through a sewing machine rapidly. They form
              efficient stitches without breaking or becoming distorted during
              the useful life of the product. <br /> <br /> It used in garments,
              upholstery, air-supported fabric structures and textiles to join
              different components by forming a seam. The primary function of a
              seam is to provide uniform stress transfer from one piece of
              fabric to another, thus preserving the overall integrity of the
              fabric assembly. It used in garments, upholstery, air-supported
              fabric structures and textiles to join different components by
              forming a seam.
            </Paragraph>
          </Section>
        </Section>

        {/* Last Paragraph */}
        <Section>
          <Paragraph className="text-[#999999] pt-12">Why Choose Us?</Paragraph>
          <Paragraph className="text-[#D4D4D4] pt-6">
            Sewing threads are special kinds of yarns that are engineered and
            designed to pass through a sewing machine rapidly. They form
            efficient stitches without breaking or becoming distorted during the
            useful life of the product. <br /> <br /> It used in garments,
            upholstery, air-supported fabric structures and textiles to join
            different components by forming a seam. The primary function of a
            seam is to provide uniform stress transfer from one piece of fabric
            to another, thus preserving the overall integrity of the fabric
            assembly. It used in garments, upholstery, air-supported fabric
            structures and textiles to join different components by forming a
            seam.
          </Paragraph>
        </Section>
      </Section>
      <Footer />
    </Container>
  );
}

export default AboutUs;
