import React, { useEffect } from 'react';
import { Triangle } from 'react-loader-spinner';
import { useSelector, useDispatch } from 'react-redux';
import { Checkout } from '../../Redux/Features/Orders/checkoutSlice';
import { FetchOrder } from '../../Redux/Features/Orders/orderSlice';
import { Payment } from '../../Redux/Features/Orders/paymentSlice';
import { transferFeePercentage } from '../../utils/checkout';
import { Button, Heading, Image, Paragraph, Section } from '../common';

const CheckoutOrderPreview = ({
  isFormSubmitted,
  updatedFormData,
  paymentStatus,
}) => {
  const dispatch = useDispatch();

  const status = useSelector((state) => state?.checkout?.status);
  const error = useSelector((state) => state?.checkout?.error) || '';

  const orderId = localStorage.getItem('orderId');

  const orderPaymentMethod = localStorage.getItem('orderPaymentMethod');
  const orderPaymentNumber = localStorage.getItem('orderPaymentNumber');

  const orderDetails = useSelector((state) => state?.orders?.data) || [];
  const orderProducts =
    useSelector((state) => state?.orders?.data?.products) || [];

  const deliveryAmount = 2000;

  const orderTotalAmount = orderDetails?.totalPrice;
  const orderItemsAmount = orderDetails?.totalPrice - deliveryAmount;

  const originalPrice = orderItemsAmount / (1 + transferFeePercentage / 100);

  const transferFee = orderItemsAmount - originalPrice;

  const handleContinueToPayment = () => {
    const checkoutDetails = {
      customerName: updatedFormData.name,
      customerEmail: updatedFormData.email,
      customerPhone: updatedFormData.mobileNumber,
      paymentMethod: orderPaymentMethod,
      customerPaymentNumber: orderPaymentNumber,
      location: {
        deliveryOption: updatedFormData.location,
        address: updatedFormData.streetAddress,
      },
      deliveryFee: deliveryAmount,
      transferFeePercentage: transferFeePercentage,
    };

    dispatch(Checkout(checkoutDetails));
    dispatch(Payment());
  };

  useEffect(() => {
    dispatch(FetchOrder(orderId));
  }, [dispatch, orderId]);

  return (
    <div className="mb-11">
      {orderProducts.map((product, index) => (
        <Section
          key={index}
          className="flex border-b border-[#3e3e3e] mb-3 md:h-[110px] bg-[#00000050] py-4"
        >
          <div className="flex text-white">
            <Image
              src={product?.product?.imageUrl[0]}
              alt={product?.product?.name}
              className="w-[65px] md:w-[70px] h-[65px] md:h-[70px] rounded-md object-cover"
            />
            <div className="ml-[16px] md:ml-[20px] md:mt-[2px]">
              <Heading className="text-[13px] md:text-[13px] max-w-[180px] md:max-w-[130px] lg:max-w-[250px] xl:max-w-[400px] truncate">
                {product?.product?.name}
              </Heading>

              <div className="flex items-center mt-[2px] md:mt-[4px]">
                <Paragraph className="text-[#c3c3c3] text-[12px]">
                  Quantity:
                </Paragraph>
                <div className="flex ml-[5px]">
                  <div className="py-[2px]">
                    <Paragraph className="text-[12px]">
                      {product?.quantity}
                    </Paragraph>
                  </div>
                </div>
              </div>

              <div className="flex items-center mt-[2px] md:mt-[4px]">
                <Paragraph className="text-secondaryYellow font-bold text-[14px]">
                  {product?.product?.unitPriceBeforeProfit?.toLocaleString() ||
                    0}{' '}
                  {product?.product?.priceCurrency}
                </Paragraph>
              </div>
            </div>
          </div>
        </Section>
      ))}

      <Section>
        <div className="text-[#5d5d5d] flex justify-between pb-[10px]">
          <Paragraph>Subtotal</Paragraph>
          <Paragraph>{originalPrice.toLocaleString() || 0}</Paragraph>
        </div>
        <div className="text-[#5d5d5d] flex justify-between pb-[10px]">
          <Paragraph>Transfer fee</Paragraph>
          <Paragraph>{transferFee.toLocaleString() || 0}</Paragraph>
        </div>

        <div className="text-[#5d5d5d] flex justify-between pb-3 border-b border-[#333333]">
          <Paragraph>Delivery</Paragraph>
          <Paragraph>{deliveryAmount.toLocaleString()}</Paragraph>
        </div>
        <div className="text-[#5d5d5d] flex justify-between py-3 border-b border-[#333333]">
          <Paragraph className="text-white">Total (RWF)</Paragraph>
          <Paragraph className="text-white font-[400]">
            {orderTotalAmount?.toLocaleString()}
          </Paragraph>
        </div>
      </Section>

      <Section>
        <Button
          className={`${status === 'success' ? 'hidden' : 'block'}
          mt-5 flex text-center items-center justify-center w-full gap-3 h-[40px] text-white my-auto rounded-md bg-gradient-to-br from-latestYellow to-gray-900 disabled:opacity-50 disabled:cursor-not-allowed`}
          type="submit"
          disabled={!isFormSubmitted}
          onClick={handleContinueToPayment}
        >
          {status === 'loading' ? (
            <Triangle
              height="30"
              width="30"
              color="#fff"
              ariaLabel="triangle-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={true}
            />
          ) : (
            'Continue to Payment'
          )}
        </Button>
        <span className={`${error ? 'block' : 'hidden'} mt-3 text-white`}>
          {' '}
          {error ? `❌ Error encountered: ${error}` : ''}
        </span>
      </Section>
    </div>
  );
};

export default CheckoutOrderPreview;
