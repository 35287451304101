import React from 'react';

export const RecentOrderCard = ({ orders }) => {
  if (!Array.isArray(orders)) {
    return null;
  }

  const formatDate = (dateString) => {
    const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
    return new Date(dateString).toLocaleDateString('en-GB', options);
  };
  const recentOrders = orders.slice(0, 4);
  return (
    <>
      {recentOrders?.map((order) => (
        <div
          className="flex px-2 rounded-md py-2 my-3 mx-3 bg-[#202020]"
          key={order?._id}
        >
          <div className="flex items-center justify-center gap-x-2">
            <img
              className="object-cover w-16 h-16 rounded"
              src={order?.products[0]?.product?.imageUrl[0]}
              alt="Something"
            />
          </div>

          <div className="flex ml-4">
            <div className="flex flex-col w-[55vw] gap-y-1">
              <div className="text-sm font-medium text-white">
                {order?.customerName}
              </div>
              <span className="text-sm font-medium text-white">
                {order?.products[0]?.product?.name}
              </span>
              <span className="text-sm font-medium text-latestYellow">
                {order?.totalPrice} Rwf
              </span>
              <div className="text-xs text-gray-400">
                {formatDate(order?.createdAt)}
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};
