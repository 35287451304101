import { Paragraph, Heading } from '..';
import FormSubcategory from '../Forms/formSubcategory';

const BodySubcategory = () => {
  return (
    <div
      className="flex items-center w-[95vw] mr-[2.5vw] ml-[2.5vw] md:gap-[30px] mt-4 md:mt-0 md:pt-[60px] pb-[40px] pl-[5%] md:pl-[50px] pr-[5%] md:pr-[50px]"
      style={{
        background:
          'linear-gradient(180deg, rgba(41, 41, 41, 1), rgba(95, 87, 80, 0))',
      }}
    >
      <div className="md:mr-[10%]">
        <div className="mb-[40px] md:mb-[60px]">
          <Heading className="capitalize font-sans text-white font-medium text-2xl md:text-3xl lg:text-4xl mt-4 md:mt-8 pb-2 md:pb-0">
            create product subCategory
          </Heading>
          <Paragraph className="text-[#9E9E9E] font-sans">
            Unleash the Power of sufurimart: Create and Manage Your Product
            Subcategories with Ease!
          </Paragraph>
        </div>
        <FormSubcategory />
      </div>
    </div>
  );
};
export default BodySubcategory;
