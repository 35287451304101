import React from 'react';
import { BiStopwatch } from 'react-icons/bi';
import { BsCheck } from 'react-icons/bs';
import { IoClose } from 'react-icons/io5';

export const OrdersTableBody = ({ orders }) => {
  if (!Array.isArray(orders)) {
    return null;
  }

  const formatDate = (dateString) => {
    const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
    return new Date(dateString)
      .toLocaleDateString('en-GB', options)
      .replace(/\//g, '.');
  };

  const reducedOrders = orders.slice(0, 10); //!FOR TEMPORARY CASES
  return (
    <tbody className="text-sm">
      {reducedOrders?.map((order) => (
        <>
          <tr key={order?.name}>
            <th
              scope="row"
              className="flex items-center pl-6 text-white py-4 whitespace-nowrap"
            >
              {order?.imageUrl && order?.imageUrl[0] ? (
                <img
                  className="object-cover w-12 h-12 rounded"
                  src={order?.imageUrl[0]}
                  alt="Something"
                />
              ) : null}
              <div className="max-w-[200px] pl-5">
                <div className="truncate font-normal">
                  {formatDate(order.createdAt)}
                </div>
              </div>
            </th>
            <td className="px-4 lg:px-8 py-2 group-hover:bg-[#181717] max-w-[250px] truncate text-white">
              <span className="px-2 lg:px-4 py-2">
                {order?.products[0]?.product?.name}
              </span>
            </td>
            <td className="px-4 lg:px-8 py-2 group-hover:bg-[#181717] rounded-r-md whitespace-nowrap">
              <span className="pr-4 py-2 text-white">
                {order.totalPrice} Rwf
              </span>
            </td>
            <td className="px-4 lg:px-8 py-2 group-hover:bg-[#181717] max-w-[200px] truncate text-white">
              <span className="px-2 lg:px-4 py-2">{order.customerName}</span>
            </td>
            <td className="px-4 lg:px-8 py-2 group-hover:bg-[#181717] max-w-[200px] truncate text-white">
              <span className="px-2 lg:px-4 py-2">{order.customerEmail}</span>
            </td>
            <td className="px-4 lg:px-8 py-2 group-hover:bg-[#181717] max-w-[150px] truncate text-white">
              <div className="flex items-center">
                {order.paymentStatus === 'paid' ? (
                  <BsCheck className="text-green-500" />
                ) : order.paymentStatus === 'pending' ? (
                  <BiStopwatch className="text-yellow-500" />
                ) : (
                  <IoClose className="text-red-500" />
                )}
                <span
                  className={`px-2 py-2 ${
                    order.paymentStatus === 'paid'
                      ? 'text-green-500'
                      : order.paymentStatus === 'pending'
                      ? 'text-yellow-500'
                      : 'text-red-500'
                  }`}
                >
                  {order.paymentStatus
                    ? order.paymentStatus.charAt(0).toUpperCase() +
                      order.paymentStatus.slice(1)
                    : ''}
                </span>
              </div>
            </td>
            <td className="px-4 lg:px-8 py-2 group-hover:bg-[#181717] max-w-[100px] truncate text-white whitespace-nowrap">
              <span className="px-2 lg:px-4 py-2">
                {order.paymentMethod ? order.paymentMethod.toUpperCase() : ''}
              </span>
            </td>
          </tr>
          <tr className="relative">
            <hr className="absolute w-full border border-gray-800 rounded-md" />
          </tr>
        </>
      ))}
    </tbody>
  );
};
