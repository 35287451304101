import React from 'react';

const Input = ({ type, placeholder, name, value, onChange, className, min }) => {
  return (
    <input
      type={type}
      placeholder={placeholder}
      name={name}
      value={value}
      onChange={onChange}
      className={`px-4 py-2 rounded-md ${className}`}
      min={min}
    />
  );
};

export default Input;
