import React from 'react';
import { Outlet, Navigate } from 'react-router-dom';

export function RequireAuth({ allowedRoles }) {
  const role = localStorage.getItem('role');

  if (!role) return <Navigate to="/login" />;

  if (!allowedRoles.includes(role)) return <Navigate to="/forbidden" />;

  return <Outlet />;
}
