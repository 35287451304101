import React, { useEffect, useState } from 'react';
import {
  AiOutlineCloseCircle,
  AiOutlineMinus,
  AiOutlinePlus,
} from 'react-icons/ai';
import { Heading, Image, Paragraph, Section } from '../common';
import { useDispatch, useSelector } from 'react-redux';
import {
  ViewCart,
  updateCartProductQuantity,
} from '../../Redux/Features/Cart/cartSlice';
import { useCartOperations } from '../../hooks';
import { Link } from 'react-router-dom';
import { Triangle } from 'react-loader-spinner';
import debounce from 'lodash.debounce';
import { PushNotification } from '../Notifications';

const CartItems = ({ products }) => {
  const { handleRemoveFromCart, showNotification, handleCloseNotification } =
    useCartOperations();

  const dispatch = useDispatch();
  const updateCartQtyStatus = useSelector(
    (state) => state?.cart?.updateCartQtyStatus,
  );
  const removeCartItemStatus = useSelector(
    (state) => state?.cart?.removeCartItemStatus,
  );

  const [isUpdateQtyLoading, setIsUpdateQtyLoading] = useState({});
  const [isRemoveItemLoading, setIsRemoveItemLoading] = useState({});

  const [localQuantities, setLocalQuantities] = useState(() => {
    const initialQuantities = {};
    products.forEach((product) => {
      initialQuantities[product?.product?._id] = product.quantity;
    });
    return initialQuantities;
  });

  useEffect(() => {
    dispatch(ViewCart());
  }, [dispatch]);

  const debouncedRemoveFromCart = debounce(handleRemoveFromCart, 100);

  const handleUpdateQuantity = (productId, action) => {
    if (!isUpdateQtyLoading[productId]) {
      setIsUpdateQtyLoading((prevLoadingStates) => ({
        ...prevLoadingStates,
        [productId]: true,
      }));

      const currentQuantity = localQuantities[productId] || 1;

      if (action === 'decrease' && currentQuantity === 1) {
        setIsUpdateQtyLoading((prevLoadingStates) => ({
          ...prevLoadingStates,
          [productId]: false,
        }));
        return;
      }

      const updatedQuantity =
        action === 'increase'
          ? currentQuantity + 1
          : Math.max(currentQuantity - 1, 1);

      setLocalQuantities((prevQuantities) => ({
        ...prevQuantities,
        [productId]: updatedQuantity,
      }));

      dispatch(
        updateCartProductQuantity({
          productId: productId,
          action: action,
        }),
      ).then(() => {
        dispatch(ViewCart());
        setIsUpdateQtyLoading((prevLoadingStates) => ({
          ...prevLoadingStates,
          [productId]: false,
        }));
      });
    }
  };

  return (
    <div className="mb-[20px]">
      {products.map((product, index) => (
        <Section
          key={index}
          className="flex mb-7 md:h-[130px] border border-[#111111f8] w-[94%] bg-[#00000050] rounded-[20px] px-5 py-4"
        >
          <div className="flex text-white w-[90%]">
            <Link to={`/product/view/${product.product._id}`}>
              <Image
                src={product?.product?.imageUrl[0]}
                alt={product?.product?.name}
                className="w-[70px] md:w-[100px] h-[70px] md:h-[100px] rounded-md object-cover"
              />
            </Link>
            <div className="ml-[16px] md:ml-[20px] md:mt-[5px] w-[50%] lg:w-[60%]">
              <Link to={`/product/view/${product?.product?._id}`}>
                <Heading className="text-[14px] md:text-[15px] lg:text-[18px] truncate">
                  {product?.product?.name}
                </Heading>
              </Link>

              <div className="flex items-center mt-[2px] md:mt-2">
                <Paragraph className="text-[#c3c3c3] text-[12px]">
                  Quantity:
                </Paragraph>
                <div className="flex ml-2 md:ml-4">
                  <div
                    className="border border-secondaryYellow px-1 rounded-l-md cursor-pointer"
                    onClick={() =>
                      handleUpdateQuantity(product?.product?._id, 'decrease')
                    }
                  >
                    <AiOutlineMinus className="mt-[3px]" />
                  </div>

                  <div className="border-t border-b border-secondaryYellow px-[10px] py-[2px]">
                    <Paragraph className="text-[12px]">
                      {updateCartQtyStatus === 'loading' &&
                      isUpdateQtyLoading[product?.product?._id] ? (
                        <Triangle
                          height="10"
                          width="10"
                          color="#fff"
                          ariaLabel="triangle-loading"
                          wrapperStyle={{ display: 'inline-block' }}
                          wrapperClassName=""
                          visible={true}
                        />
                      ) : (
                        <>
                          {localQuantities[product?.product?._id] ||
                            product.quantity}
                        </>
                      )}
                      {/* {localQuantities[product?.product?._id] ||
                        product.quantity} */}
                    </Paragraph>
                  </div>
                  <div
                    className="border border-secondaryYellow px-1 rounded-r-md cursor-pointer"
                    onClick={() =>
                      handleUpdateQuantity(product?.product?._id, 'increase')
                    }
                  >
                    <AiOutlinePlus className="mt-[3px]" />
                  </div>
                </div>
              </div>

              <div className="flex items-center mt-[3px] md:mt-2">
                <Paragraph className="text-[#c3c3c3] text-[12px]">
                  Price:
                </Paragraph>
                <Paragraph className="text-secondaryYellow ml-2 font-bold text-[14px]">
                  {product?.product?.unitPriceBeforeProfit?.toLocaleString() ||
                    0}{' '}
                  {product?.product?.priceCurrency || ''}
                </Paragraph>
              </div>
            </div>
          </div>
          <div
            className="ml-auto md:mt-[5%] cursor-pointer"
            onClick={() => {
              if (!isRemoveItemLoading[product?.product?._id]) {
                setIsRemoveItemLoading((prevLoadingStates) => ({
                  ...prevLoadingStates,
                  [product?.product?._id]: true,
                }));

                debouncedRemoveFromCart(product?.product?._id);
              }
            }}
          >
            {removeCartItemStatus === 'loading' &&
            isRemoveItemLoading[product?.product?._id] ? (
              <Triangle
                height="20"
                width="20"
                color="#BB2525"
                ariaLabel="triangle-loading"
                wrapperStyle={{ display: 'inline-block' }}
                wrapperClassName=""
                visible={true}
              />
            ) : removeCartItemStatus === 'success' &&
              isRemoveItemLoading[product?.product?._id] ? (
              <Triangle
                height="20"
                width="20"
                color="#BB2525"
                ariaLabel="triangle-loading"
                wrapperStyle={{ display: 'inline-block' }}
                wrapperClassName=""
                visible={true}
              />
            ) : (
              <AiOutlineCloseCircle className="text-secondaryYellow w-5 md:w-7 h-5 md:h-7" />
            )}
          </div>
        </Section>
      ))}
      {showNotification && (
        <PushNotification
          message="Removed from the cart!"
          onClose={handleCloseNotification}
        />
      )}
    </div>
  );
};

export default CartItems;
