import React, { useEffect, useState } from 'react';
import { Container, Header, Heading, Input, Section } from '../../components';
import { FiHeart } from 'react-icons/fi';
import { TableHead } from '../../components/Wishlist/TableHead';
import { TableBody } from '../../components/Wishlist/TableBody';
import { dummyProducts } from '../../data/dummy';
import { RiSearchLine } from 'react-icons/ri';
import { BiChevronLeft } from 'react-icons/bi';
import { FcFullTrash } from 'react-icons/fc';
import { WishlistProductCard } from '../../components/Wishlist/WishlistProductCard';
import {
  AddWishlistToCart,
  ClearWishlist,
  DeleteWishlistItem,
  UpdateWishlist,
  ViewWishlist,
} from '../../Redux/Features/Wishlist/wishlistSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ViewCart } from '../../Redux/Features/Cart/cartSlice';

function Wishlist() {
  const wishlist = useSelector((state) => state?.wishlist?.data) || [];
  const updateWishlistStatus = useSelector(
    (state) => state?.wishlist?.updateWishlistStatus,
  );
  const [isDeleted, setIsDeleted] = useState(false);
  const [isBulkDeleted, setIsBulkDeleted] = useState(false);
  const [isAddedToCart, setIsAddedToCart] = useState(false);
  const [isUpdateQtyLoading, setIsUpdateQtyLoading] = useState({});
  const [selectedProductIds, setSelectedProductIds] = useState([]);
  const [showBottomSection, setShowBottomSection] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(ViewCart());
    dispatch(ViewWishlist());
  }, [dispatch, isDeleted, isBulkDeleted, isAddedToCart]);

  const deletedWishlistId =
    useSelector((state) => state?.wishlist?.deletedWishlistId) || null;

  const deletedWishlistIds =
    useSelector((state) => state?.wishlist?.deletedWishlistIds) || null;

  const addedToCartIds =
    useSelector((state) => state?.wishlist?.addedToCartIds) || null;

  const toggleProductSelection = (productId) => {
    const isSelected = selectedProductIds.includes(productId);

    if (isSelected) {
      setSelectedProductIds((prevIds) =>
        prevIds.filter((id) => id !== productId),
      );
    } else {
      setSelectedProductIds((prevIds) => [...prevIds, productId]);
    }
  };
  const toggleAllWishlistSelection = () => {
    if (wishlist.length === selectedProductIds.length) {
      setSelectedProductIds([]);
    } else {
      const wishlistIds = wishlist.map((product) => product._id);
      setSelectedProductIds(wishlistIds);
    }
  };

  useEffect(() => {
    if (isDeleted) {
      setIsDeleted(false);
    }
    if (isBulkDeleted) {
      setIsBulkDeleted(false);
    }
    if (isAddedToCart) {
      setIsAddedToCart(false);
    }
    if (isDeleted || isBulkDeleted || isAddedToCart) {
      setShowBottomSection(false);
    }
  }, [isDeleted, isBulkDeleted, isAddedToCart]);

  const handleDelete = (wishlistId) => {
    dispatch(DeleteWishlistItem(wishlistId))
      .unwrap()
      .then(() => {
        setIsDeleted(true);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleBulkDelete = async () => {
    await dispatch(ClearWishlist(selectedProductIds))
      .unwrap()
      .then(() => {
        setIsBulkDeleted(true);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleOneWishlistToCart = async (id) => {
    await dispatch(AddWishlistToCart([id]))
      .unwrap()
      .then(() => {
        setIsAddedToCart(true);
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const handleWishlistToCart = async () => {
    await dispatch(AddWishlistToCart(selectedProductIds))
      .unwrap()
      .then(() => {
        setIsAddedToCart(true);
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const handleAllWishlistToCart = async () => {
    const wishlistIds = wishlist.map((product) => product._id);
    await dispatch(AddWishlistToCart(wishlistIds))
      .unwrap()
      .then(() => {
        setIsAddedToCart(true);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    if (deletedWishlistId) {
      setIsDeleted(true);
    }
  }, [deletedWishlistId]);

  useEffect(() => {
    if (deletedWishlistIds.length > 0) {
      setIsBulkDeleted(true);
    }
  }, [deletedWishlistIds]);

  useEffect(() => {
    if (addedToCartIds.length > 0) {
      setIsAddedToCart(true);
    }
  }, [addedToCartIds]);

  useEffect(() => {
    if (selectedProductIds.length > 0) {
      setShowBottomSection(true);
    } else {
      setShowBottomSection(false);
    }
  }, [selectedProductIds]);

  const handleUpdateQuantity = async (wishlistId, action) => {
    if (!isUpdateQtyLoading[wishlistId]) {
      setIsUpdateQtyLoading((prevLoadingStates) => ({
        ...prevLoadingStates,
        [wishlistId]: true,
      }));

      const currentProduct = wishlist.filter((item) => item._id === wishlistId);
      const currentQuantity = currentProduct[0].quantity;

      if (action === 'decrease' && currentQuantity === 1) {
        setIsUpdateQtyLoading((prevLoadingStates) => ({
          ...prevLoadingStates,
          [wishlistId]: false,
        }));
        return;
      }

      const updatedQuantity =
        action === 'increase'
          ? currentQuantity + 1
          : Math.max(currentQuantity - 1, 1);

      await dispatch(
        UpdateWishlist({
          wishlistId,
          quantity: updatedQuantity,
        }),
      ).then(async () => {
        setIsUpdateQtyLoading((prevLoadingStates) => ({
          ...prevLoadingStates,
          [wishlistId]: true,
        }));
        await dispatch(ViewWishlist());
        setIsUpdateQtyLoading((prevLoadingStates) => ({
          ...prevLoadingStates,
          [wishlistId]: false,
        }));
      });
    }
  };

  return (
    <Container className="w-full">
      <Header />
      <Section className="grid justify-items-center">
        <FiHeart className="w-8 h-8 my-2 lg:w-10 lg:h-10 text-latestYellow" />
        <Heading className="text-4xl text-center text-white lg:text-5xl">
          Wishlist
        </Heading>
        <Section className="flex flex-col-reverse lg:flex-row mt-5 lg:mt-8 mb-5 lg:mb-16 gap-y-2 lg:gap-y-0 lg:gap-x-4 w-[80%] justify-center">
          <div
            className="relative w-[90%] mx-[5%] lg:mx-0 lg:w-[30%]"
            title="Search Coming Soon"
          >
            <input
              type="text"
              placeholder="Search wishlist fabrics, supplies, colors..."
              // value={searchTerm}
              // onChange={handleInputChange}
              className="!cursor-not-allowed py-2 xl:px-7 px-[7%] pr-5 md:pr-8 w-full h-8 text-[13.5px] border border-[#1515143a] text-white rounded-full focus:outline-none focus:ring focus:border-[#100e0e30] bg-[#181717]"
              disabled
            />
            <RiSearchLine className="!cursor-not-allowed absolute bg-[#181717] w-5 h-5 text-gray-500 transform top-2/4 -translate-y-2/4 right-3" />
          </div>
          <button className="w-[49] mx-[25.5%] md:w-[46%] lg:w-auto md:mx-[27%] lg:mx-0">
            <div
              className="flex items-center text-white"
              onClick={() => navigate('/product/browse-products')}
            >
              <BiChevronLeft className="w-5 h-5" />
              <h4 className="ml-1 text-xs md:text-sm">Continue shopping</h4>
            </div>
            <hr className="border-gray-400 rounded-full lg:border" />
          </button>
        </Section>
      </Section>
      {wishlist.length > 0 ? (
        <>
          <Section className="hidden lg:flex lg:justify-center lg:w-full">
            <table className="w-[80%]">
              <TableHead
                toggleAllWishlistSelection={toggleAllWishlistSelection}
                selectedProductIds={selectedProductIds}
                wishlist={wishlist}
              />
              <TableBody
                products={wishlist}
                handleDelete={handleDelete}
                handleUpdateQuantity={handleUpdateQuantity}
                updateWishlistStatus={updateWishlistStatus}
                isUpdateQtyLoading={isUpdateQtyLoading}
                toggleProductSelection={toggleProductSelection}
                selectedProductIds={selectedProductIds}
                handleOneWishlistToCart={handleOneWishlistToCart}
              />
            </table>
          </Section>

          <Section className="grid grid-cols-1 lg:hidden">
            <div className="lg:hidden">
              <WishlistProductCard
                products={wishlist}
                handleDelete={handleDelete}
                handleUpdateQuantity={handleUpdateQuantity}
                isUpdateQtyLoading={isUpdateQtyLoading}
                updateWishlistStatus={updateWishlistStatus}
                toggleProductSelection={toggleProductSelection}
                selectedProductIds={selectedProductIds}
                handleOneWishlistToCart={handleOneWishlistToCart}
              />
            </div>
          </Section>
        </>
      ) : (
        <h2 className="mt-16 text-2xl text-center text-gray-400 lg:mt-0">
          No wishlist item saved
        </h2>
      )}

      {showBottomSection && (
        <Section className="fixed flex justify-center w-full lg:bottom-7 bottom-5">
          <div className="w-[90%] lg:w-[80%]">
            <div className="rounded-full bg-[#181717] py-3 px-3 lg:px-7 gap-x-1 lg:gap-x-0 flex justify-around lg:justify-between">
              <button
                onClick={() => setSelectedProductIds([])}
                className="py-2 xl:px-4 w-16 lg:w-auto lg:pr-5 md:pr-8 h-8 text-xs border border-[#1515143a] text-white rounded-full focus:outline-none focus:ring focus:border-[#100e0e30] bg-[#6e2626]"
              >
                Discard
              </button>
              <div className="hidden lg:flex lg:justify-end lg:gap-x-2">
                <button
                  onClick={() => handleWishlistToCart()}
                  className="py-2 xl:px-4 md:px-[7%] pr-5 md:pr-8 w-auto h-8 text-xs border border-[#1515143a] text-white rounded-full focus:outline-none focus:ring focus:border-[#100e0e30] bg-[#504d4d]"
                >
                  Add selected to cart
                </button>
                <button
                  onClick={() => handleAllWishlistToCart()}
                  className="py-2 xl:px-4 md:px-[7%] pr-5 md:pr-8 w-auto h-8 text-xs border border-[#1515143a] text-white rounded-full focus:outline-none focus:ring focus:border-[#100e0e30] bg-[#504d4d]"
                >
                  Add all to cart
                </button>
                <button
                  onClick={() => handleBulkDelete()}
                  className="py-2 xl:px-4 md:px-[7%] pr-5 md:pr-8 w-auto h-8 text-xs border border-[#1515143a] text-white rounded-full focus:outline-none focus:ring focus:border-[#100e0e30] bg-[#504d4d]"
                >
                  Delete selected
                </button>
              </div>
              <div className="flex justify-end gap-x-1 lg:hidden">
                <button
                  onClick={() => handleWishlistToCart()}
                  className="py-2 xl:px-4 md:pr-8 w-[90px] h-8 text-xs border border-[#1515143a] text-white rounded-full focus:outline-none focus:ring focus:border-[#100e0e30] bg-[#504d4d]"
                >
                  Add to cart
                </button>
                <button
                  onClick={() => handleAllWishlistToCart()}
                  className="py-2 xl:px-4 md:pr-8 w-28 h-8 text-xs border border-[#1515143a] text-white rounded-full focus:outline-none focus:ring focus:border-[#100e0e30] bg-[#504d4d]"
                >
                  Add all to cart
                </button>
                <button onClick={() => handleBulkDelete()}>
                  <FcFullTrash className="w-8 h-8" />
                </button>
              </div>
            </div>
          </div>
        </Section>
      )}
    </Container>
  );
}

export default Wishlist;
